import React from 'react'
import {Link as RouterLink, useHistory} from 'react-router-dom'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import {
  Button,
  Card,
  CircularProgress,
  Divider,
  Link,
  Toolbar,
  Typography,
} from '@material-ui/core'
import StoreIcon from '@material-ui/icons/Store'
import SchoolIcon from '@material-ui/icons/School'

import {useCartStyles} from './CartStyles'
import {ShoppingContainer} from '../../../components/utils-components/VendorStyles'
import ChooseAddressModal from './ChooseAddressModal'
import ChooseCourierModal from './ChooseCourierModal'
import PaymentMethodModal from './PaymentMethodModal'

import defaultProductImage from '../../../assets/images/default_product.png'

import {cbify, convertToRupiah, useSearchParams} from '../../../utils/helpers'
import {
  GET_CART_PRODUCT,
  GET_SHOPPING_CART,
} from '../../../graphql/queries/profile/getCart.query'
import {GET_ALL_ADDRESS} from '../../../graphql/queries/profile/getAllAddressPerson'
import {GET_COURIER_PROVIDERS} from '../../../graphql/queries/profile/getListShipping'
import {CHECKOUT_CART} from '../../../graphql/mutations/product/checkoutCart'

const STATE_LOADING = 1
const STATE_HASH_MISMATCH = 2
const STATE_HASH_MATCH = 3
const STATE_DISPATCHING = 4

const INITIAL_CHECKOUT_STATE = {
  state: STATE_LOADING,
  address: null,
  couriers: new Map(),

  openAddress: false,
  openCourier: false,
  openPayment: false,

  _selectedWeight: 0,
  _selectedVendor: null,
}

const CheckoutPage = () => {
  const styles = useCartStyles()

  const client = useApolloClient()
  const history = useHistory()

  const {enqueueSnackbar} = useSnackbar()
  const [searchParams] = useSearchParams()

  const [formState, setFormState] = React.useState(INITIAL_CHECKOUT_STATE)

  const selectedIds = searchParams.getAll('s')
  const expectedHash = searchParams.get('h')

  const {data, loading, error, refetch} = useQuery(GET_SHOPPING_CART, {
    pollInterval: 60 * 1000,
    variables: {
      where: {
        id: {_in: selectedIds},
      },
    },
  })

  const marketplaceIds = React.useMemo(() => {
    const ids = []

    if (data) {
      if (data) {
        for (const item of data.marketplace_user_carts) {
          const productId = item.item_id

          if (item.item_table === 'marketplace_products') {
            ids.push(productId)
          }
        }
      }
    }

    return ids
  }, [data])

  const {data: productData, error: productError} = useQuery(GET_CART_PRODUCT, {
    skip: !data || marketplaceIds.length < 1,
    fetchPolicy: 'cache-and-network',
    variables: {
      products: marketplaceIds,
    },
  })

  React.useEffect(() => {
    let timeout = null

    const listener = () => {
      if (document.visibilityState !== 'visible' || timeout) {
        return
      }

      // NOTE(intrnl): Not sure why this throws.
      try {
        refetch()
        // eslint-disable-next-line no-empty
      } catch {}

      timeout = setTimeout(() => (timeout = null), 1000)
    }

    document.addEventListener('visibilitychange', listener)
    return () => document.addEventListener('visibilitychange')
  }, [refetch])

  React.useEffect(() => {
    if (formState.state >= STATE_DISPATCHING) {
      return
    }

    if (selectedIds.length < 1) {
      setFormState({...formState, state: STATE_HASH_MISMATCH})
      return
    }

    setFormState({...formState, state: STATE_LOADING})

    if (!data) {
      return
    }

    const sorted = [...data.marketplace_user_carts].sort((a, b) => a.id - b.id)

    const encoder = new TextEncoder()
    const buf = encoder.encode(JSON.stringify(sorted))

    const promise = crypto.subtle.digest('sha-256', buf)

    promise.then(
      (buffer) => {
        const array = Array.from(new Uint8Array(buffer))
        const hex = array.map((b) => b.toString(16).padStart(2, '0')).join('')

        const matches = hex === expectedHash

        setFormState({
          ...formState,
          state: matches ? STATE_HASH_MATCH : STATE_HASH_MISMATCH,
        })
      },
      () => {
        setFormState({...formState, state: STATE_HASH_MISMATCH})
      }
    )
  }, [data, expectedHash])

  const {productMap, hasDelivery} = React.useMemo(() => {
    const productMap = new Map()
    const hasDelivery = new Set()

    if (productData) {
      for (const item of productData.v_marketplace_product) {
        productMap.set(item.id, item)

        if (!item.is_digital_product) {
          hasDelivery.add(item.company_profile.id)
        }
      }
    }

    return {productMap, hasDelivery}
  }, [productData])

  const {totalCost, deliveryCost, groupedCart, groupedCost, overQty} =
    React.useMemo(() => {
      const groupedCart = new Map()
      const groupedCost = new Map()
      let totalCost = 0
      let deliveryCost = 0
      let overQty = false

      if (data) {
        for (const item of data.marketplace_user_carts) {
          const productId = item.item_id
          const vendorId = item.item_vendor_id
          const qty = item.item_qty

          const product = productMap.get(productId)
          const price = +item.item_object.price
          const stock = product ? product.available_stock : 1
          const qtyPrice = price * item.item_quantity

          let array = groupedCart.get(vendorId)

          if (!array) {
            array = []
            groupedCart.set(vendorId, array)
          }

          if (qty > stock) {
            overQty = true
          }

          array.push(item)

          totalCost += qtyPrice
          groupedCost.set(vendorId, (groupedCost.get(vendorId) || 0) + qtyPrice)
        }
      }

      for (const [vendorId, courier] of formState.couriers) {
        const cost = courier.cost[0].value

        deliveryCost += cost
        groupedCost.set(vendorId, (groupedCost.get(vendorId) || 0) + cost)
      }

      return {
        totalCost,
        deliveryCost,
        groupedCart: [...groupedCart.values()],
        groupedCost,
        overQty,
      }
    }, [data, productMap, formState.couriers])

  const {data: addressData, error: addressError} = useQuery(GET_ALL_ADDRESS, {
    skip: hasDelivery.size < 1,
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: 1,
    },
  })

  const {data: courierData, error: courierError} = useQuery(
    GET_COURIER_PROVIDERS,
    {
      skip: hasDelivery.size < 1,
      fetchPolicy: 'cache-and-network',
    }
  )

  if (!formState.address && addressData?.people_shipping_addresses[0]) {
    setFormState({
      ...formState,
      address: addressData.people_shipping_addresses[0],
    })
    return null
  }

  const handleAddressOpen = () => {
    setFormState({...formState, openAddress: true})
  }

  const handleAddressClose = () => {
    setFormState({...formState, openAddress: false})
  }

  const handleAddressConfirm = (address) => {
    setFormState({
      ...formState,
      openAddress: false,
      address,
      couriers: new Map(),
    })
  }

  const handleCourierOpen = (vendorId, weight) => {
    setFormState({
      ...formState,
      openCourier: true,
      _selectedVendor: vendorId,
      _selectedWeight: weight,
    })
  }

  const handleCourierClose = () => {
    setFormState({...formState, openCourier: false})
  }

  const handleCourierConfirm = (courier) => {
    const next = new Map(formState.couriers)
    next.set(formState._selectedVendor, courier)

    setFormState({...formState, openCourier: false, couriers: next})
  }

  const handlePaymentOpen = () => {
    setFormState({...formState, openPayment: true})
  }

  const handlePaymentClose = () => {
    setFormState({...formState, openPayment: false})
  }

  const handlePaymentConfirm = (methods) => {
    if (formState.state === STATE_HASH_MISMATCH) {
      return
    }

    setFormState({...formState, state: STATE_DISPATCHING, openPayment: false})

    const address = formState.address

    const cart = []
    const shipping = []
    const banks = []

    for (const item of data.marketplace_user_carts) {
      cart.push({id: item.id, quantity: item.item_quantity})
    }

    for (const [vendorId, courier] of formState.couriers) {
      shipping.push({
        vendor: vendorId,
        courier: courier.provider,
        service: courier.service,
      })
    }

    for (const [vendorId, bank] of methods) {
      banks.push({vendor: vendorId, bank_id: bank.id})
    }

    if (cart.length < 1) {
      return
    }

    const promise = client.mutate({
      mutation: CHECKOUT_CART,
      variables: {
        cart,
        payment: 'direct_transfer',
        vendor_bank_selection: banks,

        people_address: address ? address.id : undefined,
        address: address ? address.address : undefined,
        subdistrict: address ? address.district_code : undefined,
        shipping,
      },
    })

    cbify(promise, (err, result) => {
      if (
        err ||
        result.errors ||
        !result.data ||
        !result.data.checkout.success
      ) {
        enqueueSnackbar(`Failed to process payment`, {variant: 'error'})
        setFormState({...formState, state: STATE_LOADING})
      } else {
        enqueueSnackbar(`Payment has been processed`)
        history.push(`/transaction/Awaiting`)
      }
    })
  }

  const isProductsLoading = marketplaceIds.length > 0 && !productData

  const isDisabled =
    loading ||
    isProductsLoading ||
    error ||
    productError ||
    addressError ||
    courierError ||
    overQty ||
    (hasDelivery.size > 0 && !formState.address) ||
    formState.state < STATE_HASH_MATCH ||
    formState.state >= STATE_DISPATCHING

  const isUnfulfilled =
    formState.couriers.size < hasDelivery.size ||
    (hasDelivery.size > 0 && !formState.address)

  return (
    <ShoppingContainer style={{background: '#f7f8f9'}}>
      <Toolbar disableGutters>
        <Typography variant="h6">Checkout</Typography>
      </Toolbar>

      <div className={styles.root}>
        <div className={styles.list}>
          {formState.state === STATE_HASH_MISMATCH && (
            <Card style={{padding: 16, fontSize: 14}}>
              Your cart has been modified.{' '}
              <Link
                component={RouterLink}
                to="/profile/shopping-cart"
                color="secondary"
                replace
              >
                Go back
              </Link>
            </Card>
          )}

          {(isProductsLoading || hasDelivery.size > 0) && (
            <>
              <Typography className={styles.subtitle} variant="h6">
                Delivery Address
              </Typography>

              {addressError ? (
                <Typography variant="subtitle2" color="error">
                  Something went wrong while retrieving address data
                </Typography>
              ) : !addressData ? (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <CircularProgress />
                </div>
              ) : !formState.address ? (
                <Typography variant="subtitle2">
                  You don&apos;t have any delivery addresses set, please{' '}
                  <Link
                    component="button"
                    onClick={handleAddressOpen}
                    color="secondary"
                  >
                    create one
                  </Link>{' '}
                  first.
                </Typography>
              ) : (
                <Card className={styles.address}>
                  <div>
                    <h3 className={styles.addressName}>
                      {formState.address.label_name}
                    </h3>
                    <h4 className={styles.addressRecipient}>
                      {formState.address.recipient_name} (
                      {formState.address.phone})
                    </h4>
                    <p className={styles.addressDetail}>
                      {formState.address.address} {formState.address.post_code},{' '}
                      {formState.address.district_name},{' '}
                      {formState.address.city_name},{' '}
                      {formState.address.province_name}
                    </p>
                  </div>

                  <div>
                    <Link
                      component="button"
                      disabled={isDisabled}
                      onClick={handleAddressOpen}
                      color="secondary"
                    >
                      Change Address
                    </Link>
                  </div>
                </Card>
              )}
            </>
          )}

          <Typography className={styles.subtitle} variant="h6">
            Daftar Produk
          </Typography>

          {error || productError ? (
            <div>Something went wrong while retrieving the cart data</div>
          ) : (
            (!data || isProductsLoading) && (
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress />
              </div>
            )
          )}

          {groupedCart.map((items) => {
            const firstItem = items[0]
            const firstProduct = productMap.get(firstItem.item_id)

            const table = firstItem.item_table
            const vendorId = firstItem.item_vendor_id

            const isVendor = table === 'marketplace_products'

            const companyName = firstItem.item_vendor
            const companyIndustry =
              firstProduct?.company_profile.global_company_industry_type?.name

            const nodes = []
            let hasDelivery = false
            let weight = 0

            for (const item of items) {
              const itemId = item.id
              const productId = item.item_id
              const itemObj = item.item_object
              const itemQty = item.item_quantity

              const product = productMap.get(productId)

              const name = itemObj.name
              const image = itemObj.image
              const price = itemObj.price
              const stock = product ? product.available_stock : 1
              const note = itemObj.note

              if (product && !product.is_digital_product) {
                hasDelivery = true
                weight += product.weight
              }

              nodes.push(
                <div key={itemId} className={styles.item}>
                  <img
                    className={styles.productImage}
                    src={image || defaultProductImage}
                  />

                  <div className={styles.itemMain}>
                    <h4 className={styles.productName}>{name}</h4>

                    <p
                      className={styles.productPrice}
                      style={{color: itemQty > stock ? '#f44336' : ''}}
                    >
                      {'' + itemQty}{' '}
                      {product &&
                        !product.is_digital_product &&
                        `(${product.weight} gram)`}{' '}
                      x {convertToRupiah(price)}
                    </p>

                    {note && (
                      <>
                        <h5 className={styles.noteHeader}>Catatan</h5>
                        <p className={styles.noteBody}>{note}</p>
                      </>
                    )}
                  </div>

                  <div className={styles.itemEnd}>
                    <b>{convertToRupiah(price * itemQty)}</b>
                  </div>
                </div>
              )
            }

            const selectedCourier = formState.couriers.get(vendorId)
            const courier =
              hasDelivery &&
              courierData &&
              selectedCourier &&
              courierData.global_shipping_providers.find(
                (provider) => provider.name === selectedCourier.provider
              )

            return (
              <Card key={firstItem.id}>
                <div className={styles.header}>
                  {isVendor ? (
                    <StoreIcon className={styles.headerIcon} />
                  ) : (
                    <SchoolIcon className={styles.headerIcon} />
                  )}

                  <div className={styles.headerTitle}>
                    <h2 className={styles.headerName}>{companyName}</h2>

                    {companyIndustry && (
                      <h4 className={styles.headerBlurb}>{companyIndustry}</h4>
                    )}

                    {!isVendor && (
                      <h4 className={styles.headerBlurb}>
                        Kontributor Pembelajaran
                      </h4>
                    )}
                  </div>
                </div>

                {nodes}

                {hasDelivery && (
                  <div className={styles.footer}>
                    <div className={styles.courier}>
                      {courier ? (
                        <>
                          <img
                            src={courier.logo}
                            className={styles.courierImage}
                          />
                          <div className={styles.courierDetails}>
                            <span>
                              {selectedCourier.provider}{' '}
                              {selectedCourier.service}
                            </span>
                            <span>
                              {convertToRupiah(selectedCourier.cost[0].value)} (
                              {selectedCourier.cost[0].etd || '0'} day)
                            </span>
                          </div>
                        </>
                      ) : (
                        <div>Shipping service not set</div>
                      )}
                    </div>
                    <div>
                      <Button
                        disabled={isDisabled}
                        onClick={() => handleCourierOpen(vendorId, weight)}
                        variant="contained"
                        color="primary"
                      >
                        Change Shipping Service
                      </Button>
                    </div>
                  </div>
                )}
              </Card>
            )
          })}
        </div>
        <div>
          <Card className={styles.card}>
            <h2 className={styles.cardTitle}>Ringkasan Pesanan</h2>

            <Divider />

            <table className={styles.table}>
              <tbody>
                <tr>
                  <th>
                    Total ({'' + (data?.marketplace_user_carts.length || '0')}{' '}
                    Produk)
                  </th>
                  <td>{convertToRupiah(totalCost)}</td>
                </tr>
                <tr>
                  <th>Pengiriman</th>
                  <td>{convertToRupiah(deliveryCost)}</td>
                </tr>
                <tr>
                  <th>Diskon</th>
                  <td>{convertToRupiah(0)}</td>
                </tr>
                <tr>
                  <th>Total Akhir</th>
                  <td>{convertToRupiah(totalCost + deliveryCost)}</td>
                </tr>
              </tbody>
            </table>

            <Button
              disabled={isDisabled || isUnfulfilled}
              onClick={handlePaymentOpen}
              variant="contained"
              color="secondary"
            >
              Checkout
            </Button>
          </Card>
        </div>
      </div>

      <ChooseAddressModal
        open={formState.openAddress}
        onClose={handleAddressClose}
        onAddressConfirm={handleAddressConfirm}
      />

      <ChooseCourierModal
        key={formState._selectedVendor}
        open={formState.openCourier}
        onClose={handleCourierClose}
        onCourierConfirm={handleCourierConfirm}
        courierData={courierData}
        destination={formState.address}
        companyId={formState._selectedVendor}
        productWeight={formState._selectedWeight}
      />

      <PaymentMethodModal
        open={formState.openPayment}
        onClose={handlePaymentClose}
        onPaymentConfirm={handlePaymentConfirm}
        groupedCart={groupedCart}
        groupedCost={groupedCost}
        productMap={productMap}
        couriersMap={formState.couriers}
        totalCost={totalCost + deliveryCost}
      />
    </ShoppingContainer>
  )
}

export default CheckoutPage
