import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Card,
  Box,
  CircularProgress,
  Button,
  Typography,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import BookIcon from '@material-ui/icons/Book'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import StarIcon from '@material-ui/icons/Star'

import {FotoVendor} from '../ProductStyles'
import ButtonFollowVendor from './ButtonFollowVendor'

import {MATRIX_URL} from '../../../../utils/globals'
import {
  GET_FOLLOWED_VENDOR,
  GET_FOLLOWER_VENDOR,
  GET_ID_CHAT_VENDOR,
} from '../../../../graphql/queries/index'
import {GET_DETAIL_VENDOR} from '../../../../graphql/queries/getDetailVendor'

const useStyles = makeStyles(theme => ({
  logo: {
    width: '50%',
  },

  icon: {
    color: theme.palette.secondary.main,
    fontSize: theme.spacing(2.25),
    marginRight: theme.spacing(1),
  },
  detail: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
    fontWeight: 600,
  },
}))

function CardVendor({data}) {
  const styles = useStyles()

  const {
    data: followedData,
    loading: loadingFollowed,
    error: errorFollowed,
  } = useQuery(GET_FOLLOWED_VENDOR)

  const {
    data: followerData,
    loading: loadingFollower,
    error: errorFollower,
  } = useQuery(GET_FOLLOWER_VENDOR(data.id))

  const {
    data: vendorData,
    loading: loadingVendor,
    error: errorVendor,
  } = useQuery(GET_DETAIL_VENDOR, {
    variables: {id: data.id},
  })

  const {data: chatData, loading: loadingChat, error: errorChat} = useQuery(
    GET_ID_CHAT_VENDOR,
    {
      variables: {
        company: data.id,
      },

      context: {
        headers: {
          'X-Hasura-Role': 'public',
        },
      },
    }
  )

  if (loadingFollowed || loadingFollower || loadingVendor || loadingChat) {
    return <CircularProgress />
  }

  if (errorFollowed || errorFollower || errorVendor || errorChat) {
    return (
      <div>
        {JSON.stringify(
          errorFollowed || errorFollower || errorVendor || errorChat,
          null,
          2
        )}
      </div>
    )
  }

  const isFollowed = followedData.getFollowingCompany.company.some(
    val => val === data.id
  )

  return (
    <Card>
      <Box display="grid" p={2} gridGap={16}>
        <Box display="flex">
          <div className={styles.logo}>
            <FotoVendor src={data.logo} />
          </div>
          <Box display="grid" gridGap={4}>
            <Box display="flex">
              <StarIcon className={styles.icon} />
              <Typography className={styles.detail}>
                {vendorData.rating.aggregate.avg.value
                  ? vendorData.rating.aggregate.avg.value.toFixed(1)
                  : 0}{' '}
                Vendor Ratings
              </Typography>
            </Box>
            <Box display="flex">
              <ChatBubbleIcon className={styles.icon} />
              <Typography className={styles.detail}>
                {vendorData.rating.aggregate.count} Reviews
              </Typography>
            </Box>
            <Box display="flex">
              <PersonIcon className={styles.icon} />
              <Typography className={styles.detail}>
                {followerData.getTotalFollowersCompany.total} Followers
              </Typography>
            </Box>
            <Box display="flex">
              <BookIcon className={styles.icon} />
              <Typography className={styles.detail}>
                {data.marketplace_products_aggregate.aggregate.count} Products
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography style={{fontSize: 18, fontWeight: 600}}>
            {data.brand_name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {vendorData.company_profiles[0].global_company_industry_type?.name}
          </Typography>
        </Box>

        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gridGap={16}>
          <ButtonFollowVendor
            followed={isFollowed}
            companyId={data.id}
            name={data.brand_name}
          />

          <Button
            href={
              process.env.REACT_APP_CHAT +
              `/?chat=@${
                chatData.people_work_placements
                  ? chatData.people_work_placements[0].user
                  : ''
              }:${MATRIX_URL}#/`
            }
            variant="contained"
            color="primary"
          >
            Message
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default CardVendor
