import React, {useState} from 'react'

// grapql
import {useMutation} from '@apollo/react-hooks'
import {ADD_WISHLIST} from '../../../../graphql/mutations/product/addWishlist'
import {DELETE_WISHLIST} from '../../../../graphql/mutations/product/deleteWishlist'
import {useSnackbar} from 'notistack'

// Icon
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

// Styled
import {Favorite} from '../ProductStyles'
import {
  LitleTextBlue,
  LitleTextWaterMelon,
} from '../../../utils-components/VendorStyles'
import classNames from 'classnames'
import useStyles from '../../../../pages/transaction/TransactionPageStyles'

function FavoriteComponent({
  type,
  refetch,
  item,
  conditionQuery,
  color,
  output,
}) {
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const [mutationAdd] = useMutation(ADD_WISHLIST)
  const [mutationDelete] = useMutation(DELETE_WISHLIST)
  const [condition, setCondition] = useState(type)

  const handleFavorite = () => {
    // console.log(item)
    if (condition === 'Favorite') {
      if (conditionQuery !== 'Wishlist') {
        setCondition('Non Favorite')
      }
      mutationDelete({
        variables: {
          id: item.id,
        },
      })
        .then(() => {
          if (refetch) {
            refetch()
          }

          enqueueSnackbar('Item removed')
        })
        .catch(() => {
          setCondition('Favorite')
        })
    } else {
      if (conditionQuery !== 'Wishlist') {
        setCondition('Favorite')
      }
      mutationAdd({
        variables: {
          objects: {
            item_id: item.id,
            item_object: {
              id: item.id,
              name: item.name,
              image: item.images[0] ? item.images[0] : '',
              price: item.price,
            },
            item_table: '"marketplace_product',
            item_vendor: item.company_profile.brand_name,
            item_vendor_id: item.company_profile.id,
            item_vendor_table: 'company_profile',
          },
        },
      })
        .then(() => {
          refetch()
          enqueueSnackbar('Added to wishlist', {})
        })
        .catch(() => {
          setCondition('Non Favorite')
        })
    }
  }

  return (
    <>
      {output !== 'text' && (
        <Favorite
          onClick={handleFavorite}
          style={{
            color: color ? '#' + color : '#ef4d5e',
          }}
        >
          {condition === 'Favorite' ? (
            <FavoriteIcon style={{padding: '1px', fontSize: '20px'}} />
          ) : (
            <FavoriteBorderIcon style={{padding: '1px', fontSize: '20px'}} />
          )}
        </Favorite>
      )}
      {output === 'text' && (
        <>
          {condition !== 'Favorite' ? (
            <LitleTextBlue
              onClick={handleFavorite}
              className={classNames(
                classes.displayBlock,
                classes.margin0,
                classes.alignStart
              )}
            >
              Pindahkan ke daftar keinginan
            </LitleTextBlue>
          ) : (
            <LitleTextWaterMelon
              onClick={handleFavorite}
              className={classNames(
                classes.displayBlock,
                classes.margin0,
                classes.alignStart
              )}
            >
              Hapus dari daftar keinginan
            </LitleTextWaterMelon>
          )}
        </>
      )}
    </>
  )
}

export default FavoriteComponent
