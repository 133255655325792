import React from 'react'
import {Card, CardContent} from '@material-ui/core'
import {VerticalContainer} from '../../general-container/FlexContainerStyles'
import Review from '../card-product/component/Review'

const DetailVendorReview = ({id, dataDetail}) => {
  return (
    <Card style={{marginLeft: 15, width: '100%'}}>
      <CardContent>
        <VerticalContainer
        // paddingTop={'30px'}
        // paddingLeft={'47px'}
        // paddingRight={'22px'}
        // paddingBottom={'62px'}
        >
          <Review type={'store'} id={id} company={dataDetail} />
        </VerticalContainer>
      </CardContent>
    </Card>
  )
}

export default DetailVendorReview
