import React from 'react'

import {withRouter} from 'react-router-dom'

// view
import List from './view/List'
import Address from './view/Address'

const ProfilePage = props => {
  let regexUrl = ''

  if (props.location.pathname) {
    const regexPattern = /[a-z0-9-]+/gm
    regexUrl = props.location.pathname.match(regexPattern)
  }

  const type = regexUrl[1] ? `${regexUrl[1]}` : 'wishlist'

  return (
    <>
      {type === 'wishlist' && <List type={type} />}
      {type === 'followed-vendor' && <List type={type} />}
      {type === 'address-list' && <Address type={type} />}
    </>
  )
}

export default withRouter(ProfilePage)
