import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {Box, Button, CircularProgress, IconButton} from '@material-ui/core'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import MailIcon from '@material-ui/icons/Mail'

import {format as formatDate, parse as parseDate} from 'date-fns'

import {
  HeaderContainer3,
  SubHeaderContainer3,
  BackgroundImage,
  VendorProfileContainer,
  VendorNameLabel,
  VendorTypeLabel,
  VendorBioLabel,
} from './HeaderStyles'
import {
  VerticalContainer,
  HorizontalContainer,
} from '../general-container/FlexContainerStyles'
import RatingStars from '../rating-stars/RatingStars'
import {FlexRow, TextBlue} from '../utils-components/VendorStyles'
import Schedule from './popup/Schedule'
import ButtonFollowVendor from '../vendor-tabs/card-product/component/ButtonFollowVendor'

import {MATRIX_URL} from '../../utils/globals'
import {
  GET_FOLLOWED_VENDOR,
  GET_FOLLOWER_VENDOR,
  GET_ID_CHAT_VENDOR,
} from '../../graphql/queries/index'

const findOpenSchedule = schedules => {
  let isOpen = false
  let openUntil = null
  let openIn = null

  const now = Date.now()
  const todayField = formatDate(now, 'EEEE').toLowerCase()

  const sched = schedules?.[todayField]

  if (sched && sched.from && sched.to) {
    const from = parseDate(sched.from, 'HH.mm', now)
    const to = parseDate(sched.to, 'HH.mm', now)

    isOpen = now >= from.getTime() && now <= to.getTime()
    openUntil = sched.to
  }

  if (schedules && !isOpen) {
    // the worst case scenario is when the shop isn't open at all, O(14),
    // we can solve that by limiting to the next worst case scenario, O(8)
    //
    // 0 1 2 3 4 5 6 | 0 1 2 3 4 5 6
    // ^ ~ ~ ~ ~ ~ ~ ~ ^

    const placeholder = new Date(now)

    const today = placeholder.getDay()
    const dOM = placeholder.getDate()
    const limit = today + 7

    for (let i = today; i <= limit; i++) {
      const isNextWeek = i > 6
      placeholder.setDate(dOM + ((i - today) % 7))

      const day = formatDate(placeholder, 'EEEE')
      const sched = schedules[day.toLowerCase()]

      if (sched && sched.from && sched.to) {
        if (i === today) {
          const from = parseDate(sched.from, 'HH.mm', now)

          if (now < from.getTime()) {
            openIn = sched.from
          }
        } else if (i === today + 1) {
          openIn = 'tomorrow'
        } else {
          openIn = (isNextWeek ? 'next ' : '') + day
        }
      }

      if (openIn) {
        break
      }
    }
  }

  return {isOpen, openUntil, openIn}
}

export const HeaderVendorDetail = ({id, dataDetail, rating, product_sold}) => {
  const [openSchedule, setOpenSchedule] = React.useState(false)
  const {data: dataFollow, loading, error: error1} = useQuery(
    GET_FOLLOWED_VENDOR
  )
  const {data: dataFollower, loading: loading3, error: error3} = useQuery(
    GET_FOLLOWER_VENDOR(id)
  )

  const {data: dataChat, loading: loading2, error: error2} = useQuery(
    GET_ID_CHAT_VENDOR,
    {
      variables: {
        company: id,
      },

      context: {
        headers: {
          'X-Hasura-Role': 'public',
        },
      },
    }
  )

  const {isOpen, openUntil, openIn} = React.useMemo(() => {
    return findOpenSchedule(dataDetail.profile_field.schedule)
  }, [dataDetail])

  if (loading3) {
    return <CircularProgress />
  } else if (error3 !== undefined) {
    return <div>{error3}</div>
  }

  if (loading2) {
    return <CircularProgress />
  } else if (error2 !== undefined) {
    return <div>{error2}</div>
  }
  if (loading) {
    return <CircularProgress />
  } else if (error1 !== undefined) {
    return <div>{error1}</div>
  }

  const isFollowed = dataFollow.getFollowingCompany.company.some(
    val => val === id
  )

  const handleAddressClose = () => {
    setOpenSchedule(false)
  }

  return (
    <React.Fragment>
      <HeaderContainer3>
        <BackgroundImage
          src={`${
            dataDetail.profile_field.banner
              ? dataDetail.profile_field.banner
              : ''
          }`}
          height="300"
        />
        <VendorProfileContainer>
          <img src={`${dataDetail.logo}`} width="200" height="200" />
          <VerticalContainer
            style={{width: '50%'}}
            paddingTop={'20px'}
            paddingLeft={'10px'}
          >
            <VendorNameLabel>{dataDetail.brand_name}</VendorNameLabel>
            {/* <VendorTypeLabel>{dataDetail.legal_name}</VendorTypeLabel> */}
            <VendorTypeLabel>
              {dataDetail.global_company_industry_type &&
                dataDetail.global_company_industry_type.name}
            </VendorTypeLabel>
            <VendorBioLabel marginTop={'22px'}>
              {dataDetail.profile_field.specialities}
            </VendorBioLabel>
            <VendorBioLabel marginTop={'8px'}>
              {dataDetail &&
                dataDetail.profile_field &&
                dataDetail.profile_field.headline}
            </VendorBioLabel>
            <br />
            <FlexRow>
              <Button
                style={{
                  backgroundColor: !isOpen ? '#ef4d5e' : '#4caf50',
                  width: '72px',
                  height: '25px',
                  color: 'white',
                  cursor: 'default',
                }}
              >
                {isOpen ? 'Open' : 'Closed'}
              </Button>
              <VendorBioLabel style={{marginLeft: '10px'}}>
                {!isOpen
                  ? openIn
                    ? `Opens ${openIn}`
                    : null
                  : `Until ${openUntil} today`}
              </VendorBioLabel>
              <TextBlue
                style={{marginLeft: '10px', cursor: 'pointer'}}
                onClick={() => {
                  setOpenSchedule(true)
                }}
              >
                View Schedule
              </TextBlue>
            </FlexRow>
          </VerticalContainer>
          <VerticalContainer style={{width: '20%'}} paddingTop={'20px'}>
            <VendorTypeLabel>Follower</VendorTypeLabel>
            <VendorBioLabel>
              {dataFollower.getTotalFollowersCompany.total
                ? dataFollower.getTotalFollowersCompany.total
                : 0}
            </VendorBioLabel>
          </VerticalContainer>
          <VerticalContainer style={{width: '20%'}} paddingTop={'20px'}>
            <VendorTypeLabel>Product Sold</VendorTypeLabel>
            <VendorBioLabel>
              {product_sold.aggregate.sum.qty
                ? product_sold.aggregate.sum.qty
                : 0}
            </VendorBioLabel>
          </VerticalContainer>
          <VerticalContainer style={{width: '30%'}} paddingTop={'20px'}>
            <VendorTypeLabel>Vendor Rating</VendorTypeLabel>
            <HorizontalContainer>
              <RatingStars
                data={
                  rating.aggregate.avg.value
                    ? rating.aggregate.avg.value.toFixed(1)
                    : 0
                }
                size="small"
                disabled={true}
              />
              <VendorTypeLabel>
                {' '}
                ({rating.aggregate.count} Ratings)
              </VendorTypeLabel>
            </HorizontalContainer>
          </VerticalContainer>
        </VendorProfileContainer>
      </HeaderContainer3>
      <SubHeaderContainer3>
        <Box
          display="grid"
          ml={1.5}
          gridTemplateColumns="repeat(4, auto)"
          gridGap={8}
        >
          <IconButton>
            <LinkedInIcon />
          </IconButton>
          <IconButton>
            <TwitterIcon />
          </IconButton>
          <IconButton>
            <FacebookIcon />
          </IconButton>
          <IconButton>
            <MailIcon />
          </IconButton>
        </Box>
        <Box
          display="grid"
          mr={3}
          gridTemplateColumns="repeat(2, auto)"
          gridGap={16}
        >
          <Button
            href={
              process.env.REACT_APP_CHAT +
              `/?chat=@${
                dataChat.people_work_placements
                  ? dataChat.people_work_placements[0].user
                  : ''
              }:${MATRIX_URL}#/`
            }
            variant="outlined"
            color="primary"
          >
            Send Message
          </Button>

          <ButtonFollowVendor
            followed={isFollowed}
            companyId={id}
            name={dataDetail.brand_name}
          />
        </Box>

        <Schedule
          scheduleData={dataDetail.profile_field.schedule}
          open={openSchedule}
          close={handleAddressClose}
        />
      </SubHeaderContainer3>

      <Schedule />
    </React.Fragment>
  )
}
