import React from 'react'
import {withRouter} from 'react-router-dom'

import {makeStyles, Toolbar, IconButton, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {ShoppingContainer} from '../../../../components/utils-components/VendorStyles'

import ClaimSummaryCard from './ClaimSummaryCard'
import ClaimHistory from './ClaimHistory'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: `290px minmax(0, 1fr)`,
    gridGap: theme.spacing(3),
  },

  filters: {
    justifyContent: 'space-between',
  },
}))

function ClaimActivityPage(props) {
  const styles = useStyles()

  return (
    <ShoppingContainer style={{background: '#f7f8f9'}}>
      <Toolbar disableGutters>
        <IconButton
          title="Go back to previous page"
          onClick={props.history.goBack}
          edge="start"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Claim Limit Detail</Typography>
      </Toolbar>
      <div className={styles.root}>
        <div>
          <ClaimSummaryCard />
        </div>
        <div>
          <ClaimHistory />
        </div>
      </div>
    </ShoppingContainer>
  )
}

export default withRouter(ClaimActivityPage)
