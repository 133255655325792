import styled from 'styled-components'

export const MainWrapper = styled.div`
  width: 100vw !important;
  height: 100%;
  /* position: relative; */
  display: flex;
  flex-direction: row;
  @media (max-width: 835px) {
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }
  padding: 20px 67.5px 0px 67.5px;
  background-color: #f7f8f9;
  font-family: ${(props) => props.theme.font};
  padding-bottom: 15px;
`

export const LeftSideWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
`

export const TypeMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 15px;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: flex-start;
`

export const VacancyListsMainArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`
