import React from 'react'
import {NavLink} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import clsx from 'classnames'
import {
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import StorefrontIcon from '@material-ui/icons/Storefront'
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined'

import {GetModularSettings} from '@worklifebeyond/wlb-utils-components'

import {COMPANY_ID, USER_ID} from '../../utils/globals'
import {hasModule} from '../../utils/helpers'
import {
  GET_NAVIGATION_NOTIFICATION,
  GET_TRANSACTION_NOTIFICATION,
} from '../../graphql/queries/getNotifCount.query'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.spacing(1.75),
    color: theme.palette.primary.main,
    fontWeight: 700,
    padding: theme.spacing(2, 3, 1),
  },

  item: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  icon: {
    color: theme.palette.primary.main,
    minWidth: 'unset',
    marginRight: theme.spacing(1),
  },
  label: {
    fontWeight: 700,
    fontSize: theme.spacing(1.75),
  },
  badge: {
    transform: 'unset',
    position: 'unset',
  },

  inactive: {
    color: theme.palette.grey[600],
  },
  active: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    color: theme.palette.secondary.main,
    boxShadow: `${theme.spacing(0.5)}px 0 0 0 ${
      theme.palette.secondary.main
    } inset`,
  },
}))

export function UserNavigationSection() {
  const {data} = useQuery(GET_NAVIGATION_NOTIFICATION, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      userID: USER_ID,
    },
  })

  return (
    <List>
      <NavItem
        to="/profile/shopping-cart"
        icon={<ShoppingCartOutlinedIcon />}
        count={data?.cart.aggregate.count}
      >
        Keranjang Belanja
      </NavItem>
      <NavItem
        to="/profile/wishlist"
        icon={<FavoriteBorderIcon />}
        count={data?.wishlist.aggregate.count}
      >
        Wishlist
      </NavItem>
      <NavItem to="/profile/followed-vendor" icon={<StorefrontIcon />}>
        Followed Vendor
      </NavItem>
      <NavItem to="/profile/address-list" icon={<MeetingRoomOutlinedIcon />}>
        Address List
      </NavItem>
    </List>
  )
}

export function UserTransactionSection() {
  const {data: moduleData} = GetModularSettings(COMPANY_ID)

  const {data} = useQuery(GET_TRANSACTION_NOTIFICATION, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      userID: USER_ID,
    },
  })

  const hasVendorModule = React.useMemo(() => {
    return !moduleData || hasModule(moduleData, 'vendor')
  }, [moduleData])

  // eslint-disable-next-line no-unused-vars
  const matchDefault = (_, location) => {
    const pathname = location.pathname
    return pathname === '/transaction' || pathname === '/transaction/Awaiting'
  }

  return (
    <List subheader={<NavHeader>Purchase</NavHeader>}>
      <NavItem
        nav
        to="/transaction/Awaiting"
        isActive={matchDefault}
        count={data?.waiting_payment.aggregate.count}
      >
        Awaiting Payment
      </NavItem>

      {hasVendorModule && (
        <>
          <NavItem
            nav
            to="/transaction/Awaiting Vendor Confirmation"
            count={data?.waiting_for_vendor.aggregate.count}
          >
            Awaiting Vendor Confirmation
          </NavItem>
          <NavItem
            nav
            to="/transaction/On Process"
            count={data?.onprocess.aggregate.count}
          >
            On Process
          </NavItem>
          <NavItem
            nav
            to="/transaction/On Delivery"
            count={data?.ondelivery.aggregate.count}
          >
            On Delivery
          </NavItem>
        </>
      )}

      <NavItem
        nav
        to="/transaction/Purchase History"
        count={data?.history.aggregate.count}
      >
        Purchase History
      </NavItem>
    </List>
  )
}

function NavHeader(props) {
  const {children} = props

  const styles = useStyles()

  return <Typography className={styles.title}>{children}</Typography>
}

function NavItem(props) {
  const {to, isActive, icon, children, count = 0, nav} = props

  const styles = useStyles()

  return (
    <ListItem
      component={NavLink}
      to={to}
      isActive={isActive}
      button
      className={clsx(styles.item, nav && styles.inactive)}
      activeClassName={styles.active}
    >
      {icon && <ListItemIcon className={styles.icon}>{icon}</ListItemIcon>}
      <ListItemText classes={{primary: styles.label}} primary={children} />

      {count > 0 && (
        <Badge
          badgeContent={count}
          color="error"
          classes={{badge: styles.badge}}
        />
      )}
    </ListItem>
  )
}
