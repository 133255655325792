import React from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/react-hooks'

import {makeStyles, Typography, Toolbar, IconButton} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {useSnackbar} from 'notistack'

import AlertConfirm from '../../../../components/vendor-tabs/alert/AlertConfirm'
import {ShoppingContainer} from '../../../../components/utils-components/VendorStyles'

import RequestSummaryCard from './RequestSummaryCard'
import ClaimRequestHistory from './ClaimRequestHistory'

import {USER_ID} from '../../../../utils/globals'
import {GET_CLAIM_REQUEST_INFO} from '../../../../graphql/queries/profile/getClaims.query'
import {REQUEST_CLAIM} from '../../../../graphql/mutations/profile/requestClaim.mutation'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) 320px',
    gridGap: theme.spacing(3),
  },
}))

function ClaimRequestPage(props) {
  const {enqueueSnackbar} = useSnackbar()

  const styles = useStyles()

  const {data, loading, error} = useQuery(GET_CLAIM_REQUEST_INFO, {
    variables: {
      userID: USER_ID,
    },
  })

  const [dispatchRequest, {loading: dispatching}] = useMutation(REQUEST_CLAIM)

  const [isConfirmationOpen, setConfirmationOpen] = React.useState(false)

  const [selection, setSelection] = React.useState(() => [])

  const handleSelectionChange = (item, addition) => {
    const next = selection.slice()

    if (addition) {
      next.push(item)
    } else {
      const idx = next.findIndex(curr => curr.id === item.id)

      if (idx > -1) {
        next.splice(idx, 1)
      }
    }

    setSelection(next)
  }

  const handleRemoveAll = () => {
    setSelection([])
  }

  const handleSelectAll = slices => {
    const selected = new Set(selection.map(item => item.id))
    const next = selection.slice()

    for (const item of slices) {
      if (selected.has(item.id)) {
        continue
      }

      next.push(item)
    }

    setSelection(next)
  }

  const handleConfirmationOpen = () => {
    setConfirmationOpen(true)
  }

  const handleConfirmationConfirm = () => {
    setConfirmationOpen(false)

    const variables = {
      invoiceIds: selection.map(item => item.id),
    }

    dispatchRequest({variables}).then(
      () => {
        enqueueSnackbar('Claim request submitted')
        props.history.push('/profile/claim')
      },
      error => {
        enqueueSnackbar('Failed to request claim')
        console.error(error)
      }
    )
  }

  const handleConfirmationCancel = () => {
    setConfirmationOpen(false)
  }

  return (
    <ShoppingContainer style={{background: '#f7f8f9'}}>
      <Toolbar disableGutters>
        <IconButton
          title="Go back to previous page"
          onClick={props.history.goBack}
          edge="start"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Select Transaction</Typography>
      </Toolbar>

      {error ? (
        <div>{JSON.stringify(error)}</div>
      ) : (
        <div className={styles.root}>
          <div>
            <ClaimRequestHistory
              selection={selection}
              onSelectionChange={handleSelectionChange}
              onSelectAll={handleSelectAll}
              onRemoveAll={handleRemoveAll}
            />
          </div>
          <div>
            <RequestSummaryCard
              selection={selection}
              hasBankAccount={data?.placements[0].accounts.length > 0}
              loading={loading}
              disabled={dispatching}
              onSubmit={handleConfirmationOpen}
            />
          </div>
        </div>
      )}

      <AlertConfirm
        open={isConfirmationOpen}
        title="Request Claim?"
        message="Are you sure you want to claim?"
        onConfirm={handleConfirmationConfirm}
        onClose={handleConfirmationCancel}
      />
    </ShoppingContainer>
  )
}

export default withRouter(ClaimRequestPage)
