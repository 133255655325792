import React from 'react'

import {Dialog, Box, Typography} from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'

import {ModalHeader, ModalContent} from '../../modal/Modal'

const Schedule = props => {
  const {open, scheduleData, close} = props

  const nodes = React.useMemo(() => {
    const _nodes = []

    const schedules = [
      ['Monday', scheduleData?.monday],
      ['Tuesday', scheduleData?.tuesday],
      ['Wednesday', scheduleData?.wednesday],
      ['Thursday', scheduleData?.thursday],
      ['Friday', scheduleData?.friday],
      ['Saturday', scheduleData?.saturday],
      ['Sunday', scheduleData?.sunday],
    ]

    for (const [day, sched] of schedules) {
      const isOpen = sched && sched.from && sched.to

      const node = (
        <Box key={day} display="flex" alignItems="center">
          <DateRangeIcon />
          <Box ml={2}>
            <Typography>{day}</Typography>
            <Typography color="textSecondary" variant="subtitle2">
              {isOpen ? `${sched.from} to ${sched.to}` : 'Closed'}
            </Typography>
          </Box>
        </Box>
      )

      _nodes.push(node)
    }

    return _nodes
  }, [scheduleData])

  return (
    <Dialog onClose={close} open={open} fullWidth>
      <ModalHeader onClose={close}>
        <Typography>Vendor Operational Schedule</Typography>
      </ModalHeader>

      <ModalContent>
        <Box display="grid" gridGap={16}>
          {nodes}
        </Box>
      </ModalContent>
    </Dialog>
  )
}

export default Schedule
