import gql from 'graphql-tag'

export const GET_USER_BALANCE = gql`
  query($userID: uuid!, $startDate: timestamp, $endDate: timestamp) {
    total: global_transactions_aggregate(
      where: {
        transaction_item_table: {_eq: "system"}
        globalWalletAccountByTo: {foreign_owner_id: {_eq: $userID}}
        date_created: {_gte: $startDate, _lte: $endDate}
      }
    ) {
      aggregate {
        sum {
          value
        }
      }
    }
    claimed: people_work_claims_aggregate(
      where: {
        people_work_placement: {user: {_eq: $userID}, status: {_eq: "ACTIVE"}}
        invoice_id: {_is_null: false}
        status: {_eq: "approved"}
        last_modified: {_gte: $startDate, _lte: $endDate}
      }
    ) {
      aggregate {
        sum {
          value
        }
      }
    }
  }
`
