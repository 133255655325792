import React from 'react'
import {useMutation} from '@apollo/react-hooks'
import {withRouter} from 'react-router-dom'

import {Box, Dialog, Typography, TextField, Button} from '@material-ui/core'

import {ModalHeader, ModalContent, ModalActions} from '../../../modal/Modal'
import {
  WraperTrasanction,
  TwoColumnTrasaction,
  LitleTextBlack,
  PaperTransaction,
  LitleRProductImg,
  LitleTextGrey,
  BiggerTextBlack,
  TitleTransactionDarkBlue,
  TextHelper,
} from '../../../utils-components/VendorStyles'
import RatingStars from '../../../../components/rating-stars/RatingStars'
import useStyles from '../../../../pages/transaction/TransactionPageStyles'

import {convertToRupiah} from '../../../../utils/helpers'
import {REVIEW_PRODUCT} from '../../../../graphql/mutations'

const ReviewModal = props => {
  const {
    open,
    close,
    product,
    vendorName,
    puchaseId,
    setReview,
    dataReview,
    displayCode,
  } = props
  const classes = useStyles()
  const products = product && product.product
  const vendorId = product && product.vendorId

  const [dataRating, setDataRating] = React.useState(0)
  const [dataComment, setDataComment] = React.useState('')
  const [isError, setIsError] = React.useState(false)

  const changeValueStarts = value => {
    setDataRating(value)
  }

  const changeDataComment = event => {
    setDataComment(event.target.value)
  }

  const dataReview1 = {
    invoice_id: puchaseId,
    product_id: products && products.item_table_id,
    vendor_id: vendorId,
    value: dataRating,
    comment: dataComment,
  }

  const [saveReview] = useMutation(REVIEW_PRODUCT)

  const handleSaveReview = () => {
    if (!dataRating) {
      setIsError(true)
    } else {
      setIsError(false)
      saveReview({
        variables: {
          objects: dataReview1,
        },
      })
        .then(() => {
          setTimeout(() => {
            close()
            const a = dataReview
            a.push(products && products.item_table_id)
            setReview(a)
            // setLoadEffect(true)
          }, 3000)
        })
        .catch(e => {
          console.error(e)
        })
    }
  }

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <ModalHeader onClose={close}>
        <Typography>Review</Typography>
      </ModalHeader>

      <Box
        bgcolor="grey.200"
        display="flex"
        justifyContent="space-between"
        px={3}
        py={1}
      >
        <Typography color="textSecondary">Purchase Number</Typography>
        <Typography color="textSecondary">{displayCode}</Typography>
      </Box>

      <ModalContent>
        <PaperTransaction>
          <TwoColumnTrasaction>
            <WraperTrasanction className={classes.widthProuctReview}>
              <LitleRProductImg src={`${products.thumbnail}`} />
            </WraperTrasanction>
            <WraperTrasanction className={classes.widthReviewContent}>
              <LitleTextBlack className={classes.margin0}>
                {products.itemname}
              </LitleTextBlack>
              <LitleTextGrey>Note: {products.note}</LitleTextGrey>
              <TwoColumnTrasaction>
                <LitleTextGrey className={classes.marginTop5}>
                  By: {vendorName}
                </LitleTextGrey>
                <BiggerTextBlack className={classes.margin0}>
                  {convertToRupiah(products && products.single_price)}
                </BiggerTextBlack>
              </TwoColumnTrasaction>
            </WraperTrasanction>
          </TwoColumnTrasaction>
        </PaperTransaction>
        <PaperTransaction className={classes.marginTop15}>
          <TitleTransactionDarkBlue>Rate Product</TitleTransactionDarkBlue>
          <RatingStars
            data={dataRating}
            changeValueStarts={changeValueStarts}
            size="large"
            className={classes.marginTop15}
            disabled={false}
          />
          <TextHelper className={classes.marginLeft0}>
            {!dataRating && isError ? 'This field is required' : ''}
          </TextHelper>
          <TitleTransactionDarkBlue className={classes.marginTop30}>
            Description
          </TitleTransactionDarkBlue>
          <TextField
            id="outlined-multiline-static"
            multiline
            value={dataComment}
            onChange={changeDataComment}
            rows="6"
            variant="outlined"
            className={classes.marginTop15}
          />
        </PaperTransaction>
      </ModalContent>
      <ModalActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={handleSaveReview} variant="contained" color="primary">
          Submit
        </Button>
      </ModalActions>
    </Dialog>
  )
}

export default withRouter(ReviewModal)
