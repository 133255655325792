import gql from 'graphql-tag'

export const GET_LIST_TRANSACTION = gql`
  query(
    $user: uuid
    $orderBy: order_by
    $offset: Int
    $limit: Int
    $status: [String!]
    $search: String
    $dateFrom: timestamp
    $dateTo: timestamp
    $totalFrom: float8
    $totalTo: float8
  ) {
    total: global_payments_aggregate(
      where: {
        payment_data: {_is_null: false}
        payment_status: {_in: $status}
        date_created: {_gte: $dateFrom, _lte: $dateTo}
        final_price: {_gte: $totalFrom, _lte: $totalTo}
        global_wallet_account: {global_users: {id: {_eq: $user}}}
        _or: [
          {global_payment_method: {name: {_ilike: $search}}}
          {
            global_invoices: {
              global_wallet_vendor: {
                company_profiles: {brand_name: {_ilike: $search}}
              }
            }
          }
          {
            global_invoices: {
              global_invoice_items: {itemname: {_ilike: $search}}
            }
          }
          {
            global_invoices: {
              global_shipping_provider: {name: {_ilike: $search}}
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    global_payments(
      where: {
        payment_data: {_is_null: false}
        payment_status: {_in: $status}
        date_created: {_gte: $dateFrom, _lte: $dateTo}
        final_price: {_gte: $totalFrom, _lte: $totalTo}
        global_wallet_account: {global_users: {id: {_eq: $user}}}
        _or: [
          {global_payment_method: {name: {_ilike: $search}}}
          {
            global_invoices: {
              global_wallet_vendor: {
                company_profiles: {brand_name: {_ilike: $search}}
              }
            }
          }
          {
            global_invoices: {
              global_invoice_items: {itemname: {_ilike: $search}}
            }
          }
          {
            global_invoices: {
              global_shipping_provider: {name: {_ilike: $search}}
            }
          }
        ]
      }
      order_by: [{date_created: $orderBy}]
      offset: $offset
      limit: $limit
    ) {
      id
      payment_status
      date_created
      expires_at
      final_price
      payment_method
      payment_data
      payment_proof
      global_payment_method {
        id
        name
        logo
        how_to_pay
      }
      global_invoices {
        id
        code
        global_wallet_vendor {
          id
          company_profiles {
            id
            brand_name
            legal_name
            logo
          }
          global_users {
            id
            name
          }
        }
        final_price
        delivery_option
        delivery_data
        products: global_invoice_items(where: {type: {_neq: "courier"}}) {
          id
          item_table
          item_table_id
          type
          itemname
          single_price
          thumbnail
          weight
          qty
          note
        }
        courier: global_invoice_items(where: {type: {_eq: "courier"}}) {
          id
          type
          itemname
          single_price
          thumbnail
          weight
          qty
        }
      }
      global_direct_transfer {
        id
        expires_at
        global_direct_transfer_proofs {
          id
          file
          type
          name
        }
      }
    }
  }
`

export const GET_LIST_ON_PROCESS = gql`
  query(
    $user: uuid
    $orderBy: order_by
    $offset: Int
    $limit: Int
    $status: [String!]
    $search: String
    $dateFrom: timestamp
    $dateTo: timestamp
    $totalFrom: float8
    $totalTo: float8
  ) {
    total: global_invoices_aggregate(
      where: {
        vendor_id: {_is_null: false}
        global_wallets: {global_users: {id: {_eq: $user}}}
        global_payment: {}
        global_wallet_vendor: {}
        status: {_in: $status}
        date_added: {_gte: $dateFrom, _lte: $dateTo}
        final_price: {_gte: $totalFrom, _lte: $totalTo}
        _or: [
          {global_payment: {global_payment_method: {name: {_ilike: $search}}}}
          {
            global_wallet_vendor: {
              company_profiles: {brand_name: {_ilike: $search}}
            }
          }
          {global_invoice_items: {itemname: {_ilike: $search}}}
          {global_shipping_provider: {name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    global_invoices(
      where: {
        vendor_id: {_is_null: false}
        global_wallets: {global_users: {id: {_eq: $user}}}
        global_payment: {}
        global_wallet_vendor: {}
        status: {_in: $status}
        date_added: {_gte: $dateFrom, _lte: $dateTo}
        final_price: {_gte: $totalFrom, _lte: $totalTo}
        _or: [
          {global_payment: {global_payment_method: {name: {_ilike: $search}}}}
          {
            global_wallet_vendor: {
              company_profiles: {brand_name: {_ilike: $search}}
            }
          }
          {global_invoice_items: {itemname: {_ilike: $search}}}
          {global_shipping_provider: {name: {_ilike: $search}}}
        ]
      }
      order_by: [{date_added: $orderBy}]
      offset: $offset
      limit: $limit
    ) {
      id
      status
      code
      marketplace_refund_requests(limit: 1) {
        id
        marketplace_refund_request_files(where: {vendor_side: {_eq: true}}) {
          id
          name
          file
        }
      }
      global_wallet_vendor {
        id
        company_profiles {
          id
          legal_name
          brand_name
          logo
        }
        global_users {
          id
          name
        }
      }
      final_price
      delivery_option
      delivery_data
      global_shipping_provider {
        id
        name
        logo
      }
      products: global_invoice_items(where: {type: {_neq: "courier"}}) {
        id
        item_table
        item_table_id
        type
        itemname
        single_price
        thumbnail
        weight
        qty
        note
      }
      courier: global_invoice_items(where: {type: {_eq: "courier"}}) {
        id
        type
        itemname
        single_price
        thumbnail
        weight
        qty
      }
      global_payment {
        id
        code
        date_created
        final_price
        payment_method
        payment_data
        payment_proof
        payment_status
        global_wallet_account {
          id
          latest_balance
          global_users {
            id
            name
            avatar
          }
        }
        global_direct_transfer {
          id
          expires_at
          global_direct_transfer_proofs {
            id
            file
            type
            name
          }
        }
      }
    }
  }
`
export const GetTypeBook = gql`
  query($isbn: [String!]) {
    academy_books(where: {isbn: {_in: $isbn}}) {
      isbn
      book_type
    }
  }
`
