import React from 'react'
// style
import useStyles, {
  TextBlack,
  TextBlue,
  FlexRow,
  TransactionContainer,
  TablePaginationCus,
} from '../../components/utils-components/VendorStyles'

// material
import {Grid, IconButton, InputAdornment} from '@material-ui/core'

import CarouselProduct from '../../components/vendor-tabs/card-product/CarouselProduct'
import CarouselPromotion from '../../components/vendor-tabs/card-product/CarouselPromotion'
import CarouselVendor from '../../components/vendor-tabs/card-product/CarouselVendor'

import {
  TypeFilterContext,
  CategoryFilterContext,
} from '../../contexts/FilterContext'
import {Link, Redirect} from 'react-router-dom'
// icon
import SearchIcon from '@material-ui/icons/Search'
import ListProduct from '../../components/vendor-tabs/card-product/ListProduct'
import {
  FormControlSearch,
  TextFieldSearch,
} from '../../components/Filter/FilterStyles'
import {ROW_PAGENATION} from '../../utils/helpers'

import {hasResolved} from '../PlaceholderIndexPage'

export default function HomePage(props) {
  // NOTE(intrnl): Putting it here as we don't want to unnecessarily initialize
  // everything when we're not even sure if we should be here yet.

  // Technically this isn't breaking the rules of hooks, as `hasResolved` is a
  // constant that only changes while this component is unmounted.
  if (!hasResolved) {
    return <Redirect to="/" />
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const classes = useStyles()
  const filter = {}
  const [search, setSearch] = React.useState('')
  const TypeFilterContextValue = React.useContext(TypeFilterContext)
  const CategoryFilterContextValue = React.useContext(CategoryFilterContext)

  const [rowsPerPage, setRowsPerPage] = React.useState(ROW_PAGENATION[0])
  const [page, setPage] = React.useState(0)
  const [count, setCount] = React.useState(0)
  /* eslint-enable react-hooks/rules-of-hooks */

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TransactionContainer>
      <Grid container>
        <Grid container>
          <Grid item sm={12}>
            <FormControlSearch variant="outlined">
              <TextFieldSearch
                style={{backgroundColor: '#ffffff'}}
                placeholder="Search"
                onChange={e => {
                  setSearch(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    props.history.push(
                      `/Search/product/${
                        e.target.value !== '' ? e.target.value : 'null'
                      }`
                    )
                  }
                }}
                value={search}
                name="search_filter"
                // size="small"
                classes={{
                  notchedOutline: {
                    noBorder: {
                      border: 'none',
                    },
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="center">
                      <IconButton edge="center">
                        <SearchIcon style={{color: '#014a62'}} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControlSearch>
          </Grid>
        </Grid>
        <Grid item sm={12} style={{paddingTop: '19px'}}>
          <CarouselPromotion />
        </Grid>

        <Grid item sm={12} style={{paddingTop: '10px'}}>
          <FlexRow>
            <TextBlack>Recommended Product</TextBlack>
            <TextBlue
              component={Link}
              to={'/see-all/product/Recommended Product'}
              style={{paddingLeft: '33px'}}
            >
              {' '}
              See all
            </TextBlue>
          </FlexRow>
        </Grid>
        <Grid item sm={12} style={{paddingTop: '19px'}}>
          <CarouselProduct
            searchText={filter.search}
            typeFilterContext={TypeFilterContextValue}
            categoryFilterContext={CategoryFilterContextValue}
            type="new"
          />
        </Grid>

        <Grid item sm={12} style={{paddingTop: '10px'}}>
          <FlexRow>
            <TextBlack>Top Product</TextBlack>
            <TextBlue
              component={Link}
              to={'/see-all/product/Top Product'}
              style={{paddingLeft: '33px'}}
            >
              {' '}
              See all
            </TextBlue>
          </FlexRow>
        </Grid>
        <Grid item sm={12} style={{paddingTop: '19px'}}>
          <CarouselProduct
            searchText={filter.search}
            typeFilterContext={TypeFilterContextValue}
            categoryFilterContext={CategoryFilterContextValue}
            type="top"
          />
        </Grid>

        <Grid item sm={12} style={{paddingTop: '19px'}}>
          <FlexRow>
            <TextBlack>Vendor</TextBlack>
            <TextBlue
              component={Link}
              to={'/see-all/store/Vendor'}
              style={{paddingLeft: '33px'}}
            >
              {' '}
              See all
            </TextBlue>
          </FlexRow>
        </Grid>
        <Grid item sm={12} style={{paddingTop: '19px'}}>
          <CarouselVendor
            filter={filter}
            typeFilterContext={TypeFilterContextValue}
            categoryFilterContext={CategoryFilterContextValue}
            type={'see-all'}
          />
        </Grid>

        <Grid item sm={12} style={{paddingTop: '10px'}}>
          <FlexRow>
            <TextBlack>All Product</TextBlack>
            {/* <TextBlue
              style={{paddingLeft: '33px'}}
              component={Link}
              to={'/see-all/product/All Product'}
            >
              {' '}
              See all
            </TextBlue> */}
          </FlexRow>
        </Grid>
        <Grid item sm={12} style={{paddingTop: '19px'}}>
          {/* <CarouselProduct
            searchText={filter.search}
            typeFilterContext={TypeFilterContextValue}
            categoryFilterContext={CategoryFilterContextValue}
            type="all"
          /> */}
          <ListProduct
            className={classes.marginTop30}
            // searchText={filter.search}

            rowsPerPage={rowsPerPage}
            page={page}
            filter={filter}
            typeFilterContext={TypeFilterContextValue}
            categoryFilterContext={CategoryFilterContextValue}
            setCount={setCount}
            type={'All Product'}
          />
        </Grid>

        <Grid item sm={12} style={{paddingTop: '19px'}}>
          <TablePaginationCus
            rowsPerPageOptions={ROW_PAGENATION}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </TransactionContainer>
  )
}
