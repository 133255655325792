import React, {useState} from 'react'
import {HeaderVendorDetail} from '../../components/header/HeaderVendorDetail'
import VendorDetailExpansionPanel from '../../components/side-bar/VendorDetailExpansionPanel'
import DetailVendor from '../../components/vendor-tabs/component-profile/DetailVendor'
import {HorizontalContainer} from '../../components/general-container/FlexContainerStyles'

import {withRouter} from 'react-router-dom'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {GET_DETAIL_VENDOR} from '../../graphql/queries/getDetailVendor'
import {CircularProgress} from '@material-ui/core'

const VendorDetailPage = props => {
  let regexUrl = ''
  if (props.location.pathname) {
    const regexPattern = /[a-z0-9-]+/gm
    regexUrl = props.location.pathname.match(regexPattern)
  }

  const [selectedSideBar, setSelectedSideBar] = useState('description')
  const [title, setTitle] = useState(null)
  const [filter, setFilter] = useState({
    vendor: regexUrl[3],
  })

  const {data, error, loading} = useQuery(GET_DETAIL_VENDOR, {
    variables: {id: regexUrl[3]},
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  if (loading && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <React.Fragment>
      <div
        style={{
          padding: '35px 0px 40px 94px',
        }}
      >
        <HeaderVendorDetail
          id={regexUrl[3]}
          dataDetail={data.company_profiles[0]}
          rating={data.rating}
          product_sold={data.product_sold}
        />
        <HorizontalContainer paddingTop={'30px'}>
          <VendorDetailExpansionPanel
            id={regexUrl[3]}
            setTitle={setTitle}
            filter={filter}
            setSelectedSideBar={setSelectedSideBar}
            setFilter={setFilter}
          />
          <DetailVendor
            id={regexUrl[3]}
            selectedSideBar={selectedSideBar}
            filter={filter}
            setFilter={setFilter}
            title={title}
            dataDetail={data.company_profiles[0]}
          />
        </HorizontalContainer>
      </div>
    </React.Fragment>
  )
}

export default withRouter(VendorDetailPage)
