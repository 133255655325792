// import { Button, Modal } from 'antd';
import {Button, Dialog} from '@material-ui/core'
import React, {memo} from 'react'
import {ModalWrapperDeleteCheck} from './ModalDeleteCheck.style'

export default memo(function ModalDeleteCheck({
  open,
  target,
  message,
  confirm,
  onConfirm,
  onClose,
}) {
  const handleDelete = () => {
    onConfirm()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <ModalWrapperDeleteCheck>
        <div className="header">{target}</div>
        <div className="body">
          <p>{message}</p>
        </div>
        <div className="action">
          <Button
            onClick={handleDelete}
            style={{backgroundColor: '#014a62', color: 'white'}}
            className="delete"
          >
            {confirm}
          </Button>
        </div>
      </ModalWrapperDeleteCheck>
    </Dialog>
  )
})
