import React, {useState, useEffect} from 'react'
import {
  FlexColumnFirst,
  SelectCust,
  TextDeepTeal,
  FlexRowFristCenter,
  TextSmallBlack,
  TextSmallGrey,
  FormSearch,
} from '../../../../components/utils-components/VendorStyles'

// graphql
import {useQuery, useApolloClient} from '@apollo/react-hooks'

import {
  LIST_PROVINCE,
  LIST_CITY,
} from '../../../../graphql/queries/getPlacement.query'
import {
  CircularProgress,
  makeStyles,
  FormGroup,
  Checkbox,
  InputAdornment,
} from '@material-ui/core'
import RatingStars from '../../../../components/rating-stars/RatingStars'
import {formatNominal, convertToAngka} from '../../../../utils/helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 360,
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
    width: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  fullWidth: {
    width: '100% !important',
  },
  fontSize14: {
    fontSize: '14px !important',
  },
  heightForm: {
    paddingTop: '15px',
  },
}))

function Filter({setTypeFilter, typeTab}) {
  const classes = useStyles()
  const client = useApolloClient()
  const [filter, setFilter] = React.useState([])
  const {data: province, error: error3} = useQuery(LIST_PROVINCE)
  const [StarsFilter, setStar] = React.useState([
    {
      value: 5,
      checked: false,
    },
    {
      value: 4,
      checked: false,
    },
    {
      value: 3,
      checked: false,
    },
    {
      value: 2,
      checked: false,
    },
    {
      value: 1,
      checked: false,
    },
  ])
  const optionsProv = [
    {
      value: '',
      label: '',
    },
  ]
  const [optionsCity, setOptionsCity] = useState([
    {
      value: '',
      label: '',
    },
  ])
  const [prov, setProv] = useState([
    {
      value: '',
      label: 'Province',
    },
  ])

  const [city, setCity] = useState([
    {
      value: '',
      label: 'City',
    },
  ])

  useEffect(() => {
    setTypeFilter(filter)
  }, [filter])

  if (province === undefined && error3 === undefined) {
    return <CircularProgress />
  } else if (error3 !== undefined) {
    return <div>{error3}</div>
  }

  const handleChangeProv = async (e) => {
    setProv(e)
    setFilter({...filter, province: e.value})
    const obj = []
    try {
      const {loading, data: cty} = await client.query({
        query: LIST_CITY(e.value),
      })
      if (!loading) {
        setOptionsCity([])
        cty &&
          cty.getCity.forEach((row) => {
            obj.push({
              value: row.city_id,
              label: row.type + ' ' + row.city_name,
              postal_code: row.postal_code,
            })
          })
        setOptionsCity(obj)
      }
    } catch (error) {
      return <div>{error}</div>
    }
  }

  const handleChangeCity = async (e) => {
    setCity(e)
    setFilter({...filter, city: e.value})
  }
  province &&
    province.getProvince.forEach((row) => {
      optionsProv.push({
        value: row.province_id,
        label: row.province,
      })
    })

  // const handleChangeFilter = event => {
  //   setFilter({...filter, [event.target.name]: event.target.value})
  // }
  const handleChangePrice = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: !Number.isNaN(convertToAngka(event.target.value))
        ? convertToAngka(event.target.value)
        : null,
    })
  }
  return (
    <>
      <div
        style={{
          width: '110%',
          border: '0.5px solid #eff2f4',
          marginTop: '7px',
          marginBottom: '7px',
          marginLeft: '-16px',
        }}
      />
      <TextDeepTeal style={{marginBottom: 15}}>Location</TextDeepTeal>
      <FormGroup column="true" className={classes.fullWidth}>
        <FlexColumnFirst style={{width: '95%'}}>
          <SelectCust
            className={classes.fullWidth}
            onChange={handleChangeProv}
            options={optionsProv}
            value={prov}
            fullWidth
            id="outlined-basic"
            variant="outlined"
            placeholder="The Province"
          />
          <SelectCust
            className={classes.fullWidth}
            onChange={handleChangeCity}
            value={city}
            options={optionsCity}
            fullWidth
            id="outlined-basic"
            variant="outlined"
            placeholder="City"
          />
        </FlexColumnFirst>
        {/* <HomeSidebar type={'category'} filter={setCategoryFilter} /> */}
      </FormGroup>

      {typeTab === 'product' ||
        (typeTab === undefined && (
          <>
            <div
              style={{
                width: '110%',
                border: '0.5px solid #eff2f4',
                marginTop: '7px',
                marginBottom: '7px',
                marginLeft: '-16px',
              }}
            />
            <TextDeepTeal>Price</TextDeepTeal>
            <FormGroup column="true" className={classes.fullWidth}>
              <FlexColumnFirst style={{width: '95%'}}>
                <FormSearch
                  zIndex="0"
                  className={classes.heightForm}
                  variant={'outlined'}
                  placeholder={'Minimum Price'}
                  name={'priceFrom'}
                  value={
                    filter.priceFrom
                      ? formatNominal(parseInt(filter.priceFrom))
                      : filter.priceFrom
                  }
                  onChange={handleChangePrice}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                />

                <FormSearch
                  zIndex="0"
                  className={classes.heightForm}
                  variant={'outlined'}
                  placeholder={'Maximum Price'}
                  name={'priceTo'}
                  value={
                    filter.priceTo
                      ? formatNominal(parseInt(filter.priceTo))
                      : filter.priceTo
                  }
                  onChange={handleChangePrice}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                />
              </FlexColumnFirst>
            </FormGroup>
          </>
        ))}

      <div
        style={{
          width: '110%',
          border: '0.5px solid #eff2f4',
          marginTop: '40px',
          marginBottom: '7px',
          marginLeft: '-16px',
        }}
      />
      <TextDeepTeal>
        {typeTab ? (typeTab !== 'product' ? 'Store' : 'Product') : 'Product'}{' '}
        Rating
      </TextDeepTeal>
      <FormGroup column="true" className={classes.fullWidth}>
        <FlexColumnFirst>
          {StarsFilter.map((row, key) => (
            <FlexRowFristCenter key={key}>
              <Checkbox
                checked={row.checked}
                value={row.value}
                onChange={(event) => {
                  const tStar = []
                  StarsFilter.forEach((val) => {
                    if (
                      parseFloat(val.value) === parseFloat(event.target.value)
                    ) {
                      val.checked = !val.checked
                    }
                  })
                  setStar(StarsFilter)

                  StarsFilter.forEach((res) => {
                    if (res.checked) {
                      tStar.push(res.value)
                    }
                  })
                  setFilter({...filter, rating: tStar})
                }}
                inputProps={{'aria-label': 'primary checkbox'}}
              />
              <RatingStars
                data={row.value}
                // changeValueStarts={changeValueStarts}
                size="small"
                className={classes.marginTop15}
                disabled={true}
              />
              <TextSmallBlack>{row.value} &</TextSmallBlack>{' '}
              <TextSmallGrey>up 50</TextSmallGrey>
            </FlexRowFristCenter>
          ))}
        </FlexColumnFirst>
      </FormGroup>
    </>
  )
}

export default Filter
