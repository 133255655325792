import gql from 'graphql-tag'

export const UPDATE_ORDER = gql`
  mutation($status: String!, $id: String!) {
    updateInvoice(status: $status, id: $id) {
      success
    }
  }
`
export const ADD_DIRECT_TRANSFER = gql`
  mutation($proofs: [global_direct_transfer_proofs_insert_input!]!) {
    insert_global_direct_transfer_proofs(objects: $proofs) {
      affected_rows
    }
  }
`
