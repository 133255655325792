import React, {useState} from 'react'
import {FormControlLabel, Checkbox, ListItem} from '@material-ui/core'

import {CategoryFilterContext} from '../../../../contexts/FilterContext'

const AdjustedCheckbox = ({currentFilterValue, setDataFilter, type}) => {
  // console.log(dataFilter)
  const [isChecked, setIsChecked] = React.useState(false)
  return (
    <Checkbox
      value={type.id}
      checked={isChecked}
      onChange={e => {
        // console.log(currentFilterValue)
        // console.log(dataFilter)
        const a = [...currentFilterValue]
        if (isChecked) {
          const index = a.indexOf(type.id)
          if (~index) {
            a.splice(index, 1)
            // console.log(a)
            setDataFilter(a)
          }
        } else {
          setDataFilter(a.concat(parseInt(e.target.value)))
        }

        setIsChecked(!isChecked)
      }}
    />
  )
}

const Category = ({data, filter}) => {
  const currentFilterValue = React.useContext(CategoryFilterContext)
  const [dataFilter, setDataFilter] = useState(currentFilterValue)
  // console.log(dataFilter)
  // console.log(currentFilterValue)
  React.useEffect(() => {
    filter(dataFilter)
  }, [dataFilter])
  return (
    <React.Fragment>
      {data.map((type, index) => {
        return (
          <ListItem key={index}>
            <FormControlLabel
              control={
                <AdjustedCheckbox
                  currentFilterValue={currentFilterValue}
                  setDataFilter={setDataFilter}
                  type={type}
                />
              }
              label={type.name}
              key={index}
            />
          </ListItem>
        )
      })}
    </React.Fragment>
  )
}

export default Category
