import gql from 'graphql-tag'

export const GET_COURIER_PROVIDERS = gql`
  {
    global_shipping_providers {
      id
      name
      logo
      service_type
    }
  }
`
export const GET_VENDOR_COURIERS = gql`
  query($vendor: uuid, $limit: Int) {
    company_shipping_availables(
      where: {company: {_eq: $vendor}}
      limit: $limit
    ) {
      company
      courier_services
    }
  }
`
