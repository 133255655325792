import gql from 'graphql-tag'

export const DELETE_CART_ITEM = gql`
  mutation($itemId: bigint!) {
    delete_marketplace_user_carts_by_pk(id: $itemId) {
      id
    }
  }
`

export const UPDATE_CART_ITEM_QTY = gql`
  mutation($itemId: bigint!, $quantity: Int!, $object: jsonb) {
    update_marketplace_user_carts_by_pk(
      pk_columns: {id: $itemId}
      _set: {item_quantity: $quantity}
      _append: {item_object: $object}
    ) {
      id
      item_quantity
      item_object
    }
  }
`

export const UPDATE_CART_ITEM_OBJECT = gql`
  mutation($itemId: bigint!, $object: jsonb!) {
    update_marketplace_user_carts_by_pk(
      pk_columns: {id: $itemId}
      _append: {item_object: $object}
    ) {
      id
      item_object
    }
  }
`
