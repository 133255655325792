export const ACTIVITY_FILTER_CONFIG = [
  {
    fieldName: 'status',
    name: 'Status',
    type: 'checkbox',
    options: {
      list: [
        {value: 'waiting', label: 'Waiting'},
        {value: 'processing', label: 'Processing'},
        {value: 'approved', label: 'Approved'},
        {value: 'rejected', label: 'Rejected'},
        {value: 'cancelled', label: 'Cancelled'},
      ],
    },
  },
  {
    fieldName: 'date',
    name: 'Request Date',
    type: 'date',
  },
]

export function getActivityFilterVariables(filterData) {
  if (!filterData) {
    return null
  }

  const {0: inc} = filterData

  const status = inc?.status
  const dateFrom = inc?.date?.from
  const dateTo = inc?.date?.to

  const variables = {
    status: status?.length ? status.map(field => field.value) : undefined,
    startDate: dateFrom ? new Date(dateFrom).toISOString() : undefined,
    endDate: dateTo ? new Date(dateTo).toISOString() : undefined,
  }

  return variables
}
