import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {
  Box,
  CircularProgress,
  Dialog,
  Divider,
  Typography,
} from '@material-ui/core'

import {ModalHeader, ModalContent} from '../../../../components/modal/Modal'
import DetailsTable from '../../../../components/utils-components/DetailsTable'

import {convertToRupiah, getShortDate} from '../../../../utils/helpers'
import {USER_ID} from '../../../../utils/globals'
import {GET_CLAIM_HISTORY_DETAILS} from '../../../../graphql/queries/profile/getClaims.query'

const PROFILE_CONFIG = [
  {title: 'Employee Name', render: x => x.user.name},
  {title: 'Employee ID', render: x => x.user.placements[0].regno},
  {title: 'Position', render: x => x.user.placements[0].profile.title},
]

const TRANSACTION_CONFIG = [
  {title: 'Transaction ID', render: x => x.details.formatted_id},
  {
    title: 'Transaction Date',
    render: x => getShortDate(x.details.date_created),
  },
  {
    title: 'Type',
    render: x => {
      const isDeposit = x.details.nominal >= 0
      return (
        <span style={{color: isDeposit ? '#4caf50' : '#ef4d5e'}}>
          {isDeposit ? 'In' : 'Out'}
        </span>
      )
    },
  },
  {title: 'Nominal', render: x => convertToRupiah(Math.abs(x.details.nominal))},
  {title: 'Description', render: x => x.details.description || '-'},
]

function ClaimHistoryModal(props) {
  const {open, id, onClose} = props

  const {data, loading, error} = useQuery(GET_CLAIM_HISTORY_DETAILS, {
    skip: !id,
    variables: {
      userID: USER_ID,
      transactionID: id,
    },
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <ModalHeader onClose={onClose}>
        <Typography>Claim Limit History Detail</Typography>
      </ModalHeader>

      <ModalContent>
        {!open ? null : loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <div>{JSON.stringify(error)}</div>
        ) : (
          <>
            <DetailsTable data={data} fields={PROFILE_CONFIG} />
            <Divider style={{marginTop: 24, marginBottom: 24}} />
            <DetailsTable data={data} fields={TRANSACTION_CONFIG} />
          </>
        )}
      </ModalContent>
    </Dialog>
  )
}

export default ClaimHistoryModal
