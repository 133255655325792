import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'

import {SnackbarProvider} from 'notistack'

import App from './App'
import {SSO_URL} from './utils/globals'
import {getCookie} from './utils/helpers'

if (process.env.NODE_ENV !== 'development' && !getCookie('userData')) {
  window.location = SSO_URL
} else {
  ReactDOM.render(
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>,
    document.getElementById('root')
  )
}
