import gql from 'graphql-tag'

export const ADD_ADDRESS = gql`
  mutation($objects: [people_shipping_addresses_insert_input!]!) {
    insert_people_shipping_addresses(objects: $objects) {
      affected_rows
    }
  }
`

export const UPDATE_ADDRESS = gql`
  mutation(
    $id: Int
    $label_name: String
    $province_code: Int
    $city_code: Int
    $district_code: Int
    $province_name: String
    $city_name: String
    $district_name: String
    $post_code: Int
    $address: String
    $recipient_name: String
    $phone: String
    $location_fields: jsonb
  ) {
    update_people_shipping_addresses(
      where: {id: {_eq: $id}}
      _set: {
        id: $id
        label_name: $label_name
        province_code: $province_code
        city_code: $city_code
        district_code: $district_code
        province_name: $province_name
        city_name: $city_name
        district_name: $district_name
        post_code: $post_code
        address: $address
        recipient_name: $recipient_name
        phone: $phone
        location_fields: $location_fields
      }
    ) {
      affected_rows
    }
  }
`
export const DELETE_ADDRESS = gql`
  mutation($id: Int) {
    update_people_shipping_addresses(
      where: {id: {_eq: $id}}
      _set: {deleted_at: "now()"}
    ) {
      affected_rows
    }
  }
`
