import gql from 'graphql-tag'

export const GET_PRODUCT_AGGREGATE_SCORES = gql`
  query($id: uuid!) {
    total: marketplace_product_reviews_aggregate(
      where: {product_id: {_eq: $id}}
    ) {
      aggregate {
        count
        avg {
          value
        }
      }
    }
    star5: marketplace_product_reviews_aggregate(
      where: {product_id: {_eq: $id}, value: {_eq: 5}}
    ) {
      aggregate {
        count
      }
    }
    star4: marketplace_product_reviews_aggregate(
      where: {product_id: {_eq: $id}, value: {_eq: 4}}
    ) {
      aggregate {
        count
      }
    }
    star3: marketplace_product_reviews_aggregate(
      where: {product_id: {_eq: $id}, value: {_eq: 3}}
    ) {
      aggregate {
        count
      }
    }
    star2: marketplace_product_reviews_aggregate(
      where: {product_id: {_eq: $id}, value: {_eq: 2}}
    ) {
      aggregate {
        count
      }
    }
    star1: marketplace_product_reviews_aggregate(
      where: {product_id: {_eq: $id}, value: {_eq: 1}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_PRODUCT_REVIEWS = gql`
  query($id: uuid!, $value: Int, $offset: Int, $limit: Int) {
    reviews: marketplace_product_reviews(
      where: {product_id: {_eq: $id}, value: {_eq: $value}}
      offset: $offset
      limit: $limit
    ) {
      id
      global_user {
        id
        name
        avatar
      }
      value
      comment
      respond
      date_added
      last_modified
    }
  }
`

export const GET_STORE_AGGREGATE_SCORES = gql`
  query($id: uuid!) {
    total: marketplace_product_reviews_aggregate(
      where: {vendor_id: {_eq: $id}}
    ) {
      aggregate {
        count
        avg {
          value
        }
      }
    }
    star5: marketplace_product_reviews_aggregate(
      where: {vendor_id: {_eq: $id}, value: {_eq: 5}}
    ) {
      aggregate {
        count
      }
    }
    star4: marketplace_product_reviews_aggregate(
      where: {vendor_id: {_eq: $id}, value: {_eq: 4}}
    ) {
      aggregate {
        count
      }
    }
    star3: marketplace_product_reviews_aggregate(
      where: {vendor_id: {_eq: $id}, value: {_eq: 3}}
    ) {
      aggregate {
        count
      }
    }
    star2: marketplace_product_reviews_aggregate(
      where: {vendor_id: {_eq: $id}, value: {_eq: 2}}
    ) {
      aggregate {
        count
      }
    }
    star1: marketplace_product_reviews_aggregate(
      where: {vendor_id: {_eq: $id}, value: {_eq: 1}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_STORE_REVIEWS = gql`
  query($id: uuid, $value: Int, $offset: Int, $limit: Int) {
    reviews: marketplace_product_reviews(
      where: {vendor_id: {_eq: $id}, value: {_eq: $value}}
      offset: $offset
      limit: $limit
    ) {
      global_user {
        id
        name
        avatar
      }
      marketplace_product {
        id
        name
        images
      }
      value
      comment
      respond
      date_added
      last_modified
    }
  }
`

export const CEK_REVIEW_PRODUCT = gql`
  query($product: uuid) {
    marketplace_products(where: {id: {_eq: $product}}) {
      id
      company_profile {
        id
        brand_name
        logo
        marketplace_product_reviews_aggregate {
          aggregate {
            count
            avg {
              value
            }
          }
        }
        marketplace_products_aggregate {
          aggregate {
            count
          }
        }
        company_origin_shipping_addresses(
          where: {status: {_eq: "ACTIVE"}}
          limit: 1
        ) {
          id
          province_code
          province_name
          city_code
          city_name
          district_code
          district_name
          post_code
        }
      }
      name
      marketplace_product_category {
        id
        name
      }
      marketplace_vendor_showcase {
        id
        name
      }
      description
      minimum_order
      current_stock
      weight
      date_added
      last_modified
      images
      price
      is_always_available
      is_digital_product
      is_preorder
      preorder_time
      detail_field
    }
  }
`
