import React from 'react'

import clsx from 'classnames'
import {
  makeStyles,
  Typography,
  Link,
  Checkbox,
  ListItem,
} from '@material-ui/core'

import {getClaimType, STATUS_COLORS} from './ClaimStyles'

import {
  Capitalize,
  convertToRupiah,
  getLongDate,
  getShortDate,
} from '../../../utils/helpers'

const useStyles = makeStyles(theme => ({
  item: {
    display: 'grid',
    gridTemplateColumns: '5fr 1fr',
    gridGap: theme.spacing(2),
  },
  selection: {
    gridTemplateColumns: 'min-content 5fr 1fr',
  },

  checkbox: {
    padding: 0,
    paddingTop: 1,
    marginLeft: -2,
  },

  summary: {
    display: 'grid',
    justifyItems: 'start',
    gridGap: theme.spacing(0.5),
  },

  title: {
    color: theme.palette.grey[800],
    fontSize: theme.spacing(2.25),
    fontWeight: 700,
  },
  timestamp: {
    color: theme.palette.grey[600],
  },

  status: {
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
    padding: theme.spacing(0.5, 1.5),
    margin: theme.spacing(0.5, 0),
    borderRadius: 4,
  },

  action: {
    cursor: 'pointer',
  },

  nominal: {
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    textAlign: 'right',
  },
  addition: {
    color: '#4caf50',
  },
  removal: {
    color: '#ef4d5e',
  },
}))

const useStyles2 = makeStyles(theme => ({
  item: {
    display: 'flex',
    padding: theme.spacing(1.5, 3),
  },

  start: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'start',
  },
  end: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    alignItems: 'end',
  },

  name: {
    color: theme.palette.primary.main,
    margin: 0,
  },
  nominal: {
    fontSize: theme.spacing(1.75),
    margin: theme.spacing(1, 0, 0),
  },
  blurb: {
    fontSize: theme.spacing(1.75),
    margin: 0,
    color: theme.palette.grey[600],
  },
  status: {
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
    padding: theme.spacing(0.5, 1.5),
    margin: theme.spacing(0.5, 0),
    borderRadius: 4,
  },
}))

export function ClaimActivityItem(props) {
  const {data, onDetailsClick} = props

  const styles = useStyles2()

  const status = data.status

  const dateAdded = React.useMemo(() => getShortDate(data.date_added), [data])
  const dateIssued = React.useMemo(() => getShortDate(data.date_issued), [data])
  const nominal = React.useMemo(
    () =>
      convertToRupiah(data.invoice ? data.invoice.final_price : data.nominal),
    [data]
  )

  const handleDetailsClick = () => {
    onDetailsClick(data.id)
  }

  return (
    <ListItem
      onClick={onDetailsClick && handleDetailsClick}
      button
      className={styles.item}
    >
      <div className={styles.start}>
        <p className={styles.name}>
          {data.policy ? data.policy.name : `${getClaimType(data)} Claim`}
        </p>
        <p className={styles.blurb}>{data.formatted_id}</p>

        <p className={styles.nominal}>{nominal}</p>
        <p className={styles.blurb}>for {dateIssued}</p>
      </div>

      <div className={styles.end}>
        <p className={styles.blurb}>{dateAdded}</p>

        <div className={styles.status} style={STATUS_COLORS[status]}>
          {Capitalize(status)}
        </div>
      </div>
    </ListItem>
  )
}

export function ClaimHistoryItem(props) {
  const {data, onDetailsClick, selection, onSelectionChange, noDiff} = props

  const styles = useStyles()

  const formattedDate = React.useMemo(
    () => getLongDate(data.date_created || data.date_added || 0),
    [data]
  )

  const formattedNominal = React.useMemo(
    () => convertToRupiah(data.final_price ?? Math.abs(data.nominal)),
    [data]
  )

  const isDeposit = data.nominal >= 0

  const handleDetailsClick = () => {
    onDetailsClick(data.id)
  }

  const handleSelectionChange = ev => {
    onSelectionChange(data, ev.target.checked)
  }

  return (
    <div className={clsx(styles.item, selection && styles.selection)}>
      {selection && (
        <div>
          <Checkbox
            checked={!!selection.find(item => item.id === data.id)}
            onChange={handleSelectionChange}
            color="primary"
            className={styles.checkbox}
          />
        </div>
      )}

      <div className={styles.summary}>
        <Typography className={styles.title}>
          {data.code || data.formatted_id || data.id}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {formattedDate}
        </Typography>

        {!!onDetailsClick && (
          <Link
            onClick={handleDetailsClick}
            color="secondary"
            className={styles.action}
          >
            Details
          </Link>
        )}
      </div>

      <div>
        <Typography
          className={clsx(
            styles.nominal,
            !noDiff ? (isDeposit ? styles.addition : styles.removal) : false
          )}
        >
          {!noDiff ? (isDeposit ? '+ ' : '- ') : null}
          {formattedNominal}
        </Typography>
      </div>
    </div>
  )
}
