import React from 'react'

import {Button} from '@material-ui/core'
import {useSnackbar} from 'notistack'

import {TOKEN, USER_ID, API_PCS_URL} from '../../../../utils/globals'

function ButtonFollowVendor({followed, companyId, name}) {
  const [isFollowed, setIsFollowed] = React.useState(followed)
  const [isDispatching, setIsDispatching] = React.useState(false)

  const {enqueueSnackbar} = useSnackbar()

  const handleFollow = () => {
    const typeRest = isFollowed ? 'unfollow' : 'follow'

    setIsDispatching(true)

    const promise = fetch(
      `${API_PCS_URL}/users/${USER_ID}/company/${companyId}/${typeRest}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify({companyId: `${companyId}`}),
      }
    )

    promise
      .then(response => response.json())
      .then(data => {
        let message = `Followed ${name}`

        if (data.status === 200) {
          setIsFollowed(!isFollowed)

          if (isFollowed) {
            message = `Unfollowed ${name}`
          }
        } else {
          message = `Failed to ${isFollowed ? 'unfollow' : 'follow'} ${name}`
        }

        enqueueSnackbar(message)
      })
      .finally(() => {
        setIsDispatching(false)
      })
  }

  return (
    <Button
      onClick={handleFollow}
      variant={isFollowed ? 'outlined' : 'contained'}
      disabled={isDispatching}
      color="primary"
    >
      {isFollowed ? 'Followed' : 'Follow'}
    </Button>
  )
}

export default ButtonFollowVendor
