export const HISTORY_FILTER_CONFIG = [
  {
    fieldName: 'date',
    name: 'Transaction Date',
    type: 'date',
  },
]

export function getHistoryFilterVariables(filterData) {
  if (!filterData) {
    return null
  }

  const {0: inc} = filterData

  const dateFrom = inc?.date?.from
  const dateTo = inc?.date?.to

  const variables = {
    startDate: dateFrom ? new Date(dateFrom).toISOString() : undefined,
    endDate: dateTo ? new Date(dateTo).toISOString() : undefined,
  }

  return variables
}
