export const STATUS_COLORS = {
  waiting: {
    color: '#ffffff',
    backgroundColor: '#ffa000',
  },
  approved: {
    color: '#ffffff',
    backgroundColor: '#4caf50',
  },
  processing: {
    color: '#ffffff',
    backgroundColor: '#a35a31',
  },
  rejected: {
    color: '#ffffff',
    backgroundColor: '#ef4d5e',
  },
  cancelled: {
    color: '#ffffff',
    backgroundColor: '#a9a8a8',
  },
}

export function getClaimType(data) {
  if (!data.invoice) {
    return 'Financial'
  }

  switch (data.invoice.module_name) {
    case 'marketplace':
      return 'Vendor'
    case 'academy':
    case 'book':
    case 'classroom':
    case 'microlearning':
      return 'Learning'
    default:
      return 'Unknown'
  }
}
