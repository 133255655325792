import React, {useState} from 'react'
import {useQuery, useMutation} from '@apollo/react-hooks'

import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  CircularProgress,
  Card,
  Typography,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'

import {ButtonRed} from '../../utils-components/VendorStyles'
import AlertConfirm from '../alert/AlertConfirm'

import {GET_ALL_ADDRESS} from '../../../graphql/queries/profile/getAllAddressPerson'
import {DELETE_ADDRESS} from '../../../graphql/mutations/profile/addAddress'
import NoDataListFree from '../NoDataListFree'

export default function ListAddress({handleData}) {
  const {enqueueSnackbar} = useSnackbar()

  const [mutationDelete] = useMutation(DELETE_ADDRESS)

  const {data, error} = useQuery(GET_ALL_ADDRESS, {
    variables: {
      // offset: 0,
      // limit: 10,
    },
  })

  const [isOpen, setIsOpen] = useState(false)
  const [id, setId] = useState(null)

  const handleConfirmationOpen = () => {
    setIsOpen(true)
  }
  const handleConfirmationClose = () => {
    setIsOpen(false)
  }

  const handleConfirmationConfirm = () => {
    handleConfirmationClose()

    mutationDelete({
      variables: {
        id,
      },
    })
      .then(() => {
        enqueueSnackbar('Address deleted')
      })
      .catch(() => {
        enqueueSnackbar('Failed to delete address')
      })
  }

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <Box display="grid" gridGap={16}>
      {data.people_shipping_addresses.length > 0 ? (
        data.people_shipping_addresses.map((row, index) => {
          return (
            <Card key={index}>
              <Box display="flex" p={2} pl={3}>
                <div>
                  <FormControlLabel value="female" control={<Radio />} />
                </div>
                <Box py={1} flexGrow={1}>
                  <Typography style={{fontWeight: 600}}>
                    {row.label_name}
                  </Typography>
                  <Typography style={{fontWeight: 600}}>
                    {row.recipient_name} ({row.phone})
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2">
                    {`${row.address} ${row.post_code} ${row.district_name} ${row.city_name}, ${row.province_name}`}
                  </Typography>
                </Box>
                <Box display="grid" gridGap={8} width={180}>
                  <Button
                    onClick={() => {
                      handleData('Edit Address', row)
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    Edit address
                  </Button>
                  <ButtonRed
                    onClick={() => {
                      setId(row.id)
                      handleConfirmationOpen()
                    }}
                    variant="outlined"
                  >
                    Delete address
                  </ButtonRed>
                </Box>
              </Box>
            </Card>
          )
        })
      ) : (
        <NoDataListFree message1="Sorry, No List" message2="" />
      )}

      <AlertConfirm
        danger
        title="Delete this address?"
        message="Are you sure to delete this address?"
        open={isOpen}
        onClose={handleConfirmationClose}
        onConfirm={handleConfirmationConfirm}
      />
    </Box>
  )
}
