import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useMutation} from '@apollo/react-hooks'

import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  Card,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import {useSnackbar} from 'notistack'

import {convertToRupiah} from '../../../../utils/helpers'
import {ADD_TO_CART} from '../../../../graphql/mutations/product/addToCart'

const CardAmount = props => {
  const {data, soldout} = props

  const {enqueueSnackbar} = useSnackbar()

  const [mutationAdd] = useMutation(ADD_TO_CART)
  const [total, setTotal] = useState(data.price)
  const [amount, setAmount] = useState(0)

  React.useEffect(() => {
    setTotal(data.price)
    setAmount(data.available_stock > 0 ? 1 : 0)
  }, [data])

  const handleAmount = type => {
    let i = amount
    if (type === 'add') {
      i = amount + 1
    } else {
      if (amount > 0) {
        i = amount - 1
      }
    }
    if (data.available_stock >= i) {
      setAmount(i)
      setTotal(i * data.price)
    }
  }

  const handleAddToCard = redirect => {
    const promise = mutationAdd({
      variables: {
        objects: [
          {
            item_id: data.id,
            item_object: {
              name: data.name,
              image: data.images[0] ? data.images[0] : '',
              price: data.price,
              weight: data.weight,
              note: '',
            },
            item_table: 'marketplace_products',
            item_vendor: data.company_profile.brand_name,
            item_vendor_id: data.company_profile.id,
            item_vendor_table: 'company_profiles',
            item_quantity: amount,
          },
        ],
      },
    })

    promise.then(
      result => {
        enqueueSnackbar('Added to cart')

        if (redirect) {
          const itemId =
            result.data.insert_marketplace_user_carts.returning[0].id
          props.history.push(`/profile/shopping-cart?s=${itemId}`)
        }
      },
      () => {
        enqueueSnackbar('Failed to add to cart')
      }
    )
  }

  return (
    <Card>
      {!soldout ? (
        <Box display="grid" gridGap={16} p={2}>
          <div>
            <Typography color="primary" gutterBottom style={{fontWeight: 600}}>
              Amount
            </Typography>

            <ButtonGroup
              style={{backgroundColor: '#eff2f4', justifySelf: 'baseline'}}
            >
              <IconButton
                disabled={amount === 0}
                onClick={() => handleAmount('remove')}
              >
                <RemoveIcon
                  style={{
                    color: amount === 0 ? '#a9a8a8' : '#33a0ff',
                  }}
                />
              </IconButton>
              <Typography
                disabled={data.available_stock <= amount}
                style={{paddingTop: '14px', paddingLeft: '29px'}}
              >
                {amount}
              </Typography>
              <IconButton onClick={() => handleAmount('add')}>
                <AddIcon
                  style={{
                    color:
                      data.available_stock <= amount ? '#a9a8a8' : '#33a0ff',
                  }}
                />
              </IconButton>
            </ButtonGroup>
          </div>

          <div>
            <Typography color="primary" gutterBottom style={{fontWeight: 600}}>
              Total
            </Typography>
            <Typography variant="h6" display="inline" style={{fontWeight: 600}}>
              {convertToRupiah(total)}
            </Typography>
          </div>

          <Button
            disabled={amount === 0}
            onClick={() => handleAddToCard(false)}
            variant="outlined"
            color="primary"
          >
            Add to cart
          </Button>
          <Button
            disabled={amount === 0}
            onClick={() => handleAddToCard(true)}
            variant="contained"
            color="primary"
          >
            Buy now
          </Button>
        </Box>
      ) : (
        <div style={{padding: '33px'}}>
          <span
            style={{
              padding: ' 18px 67px 18px 68px',
              borderRadius: '5px',
              backgroundColor: '#a9a8a8',
              color: '#fff',
            }}
          >
            {' '}
            Product is Sold Out
          </span>
        </div>
      )}
    </Card>
  )
}

export default withRouter(CardAmount)
