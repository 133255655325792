import React from 'react'
import {Link as RouterLink, useHistory} from 'react-router-dom'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import StoreIcon from '@material-ui/icons/Store'
import SchoolIcon from '@material-ui/icons/School'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import {GetModularSettings} from '@worklifebeyond/wlb-utils-components'

import {useCartStyles} from './CartStyles'
import {ShoppingContainer} from '../../../components/utils-components/VendorStyles'
import NoDataListFree from '../../../components/vendor-tabs/NoDataListFree'
import AlertConfirm from '../../../components/vendor-tabs/alert/AlertConfirm'
import CarouselProduct from '../../../components/vendor-tabs/card-product/CarouselProduct'

import defaultProductImage from '../../../assets/images/default_product.png'

import {
  convertToRupiah,
  getCookie,
  hasModule,
  useSearchParams,
} from '../../../utils/helpers'
import {COMPANY_ID, USER_ID} from '../../../utils/globals'
import {
  GET_CART_PRODUCT,
  GET_CART_WISHLIST,
  GET_SHOPPING_CART,
} from '../../../graphql/queries/profile/getCart.query'
import {
  DELETE_CART_ITEM,
  UPDATE_CART_ITEM_OBJECT,
  UPDATE_CART_ITEM_QTY,
} from '../../../graphql/mutations/product/updateCart'
import {ADD_WISHLIST} from '../../../graphql/mutations/product/addWishlist'
import {DELETE_WISHLIST} from '../../../graphql/mutations/product/deleteWishlist'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const globalHistory = window.history

const STATE_INITIAL = 1
const STATE_CHECKOUT = 2

const INITIAL_CART_STATE = {
  state: STATE_INITIAL,
  initialized: false,
  key: null,
  editingNote: null,
  selected: new Set(),
}

const INITIAL_DIALOG_STATE = {
  openDelete: false,
  openWarnType: false,
  warned: false,
  item: null,
}

const getTableKey = table => {
  switch (table) {
    case 'marketplace_products':
      return 'vendor'
    case 'academy_courses':
    case 'academy_books':
    case 'microlearnings':
      return 'learning'
  }
}

const CartPage = () => {
  const styles = useCartStyles()

  const history = useHistory()

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()
  const [searchParams] = useSearchParams()

  const [cartState, setCartState] = React.useState(INITIAL_CART_STATE)
  const [dialogState, setDialogState] = React.useState(INITIAL_DIALOG_STATE)

  const {data: moduleData} = GetModularSettings(COMPANY_ID)

  const hasVendorModule = React.useMemo(() => {
    return moduleData && hasModule(moduleData, 'vendor')
  }, [moduleData])

  const {data, loading, error, refetch} = useQuery(GET_SHOPPING_CART, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      // NOTE(intrnl): We want to automatically select all cart items
      if (!cartState.initialized && data) {
        // NOTE(intrnl): Buy Now button provides the ID that we can use to select
        const array = searchParams.getAll('s').map(i => parseInt(i))
        const selected = new Set(array)
        let key = cartState.key

        if (selected.size > 0) {
          const map = new Map()

          for (const item of data.marketplace_user_carts) {
            map.set(item.id, item)
          }

          for (const id of selected) {
            const item = map.get(id)

            if (item) {
              key = getTableKey(item.item_table)
            } else {
              selected.delete(id)
            }
          }
        }

        setCartState({...cartState, initialized: true, key, selected})
      }
    },
  })

  const {productIds, marketplaceIds} = React.useMemo(() => {
    const productIds = []
    const marketplaceIds = []

    if (data) {
      if (data) {
        for (const item of data.marketplace_user_carts) {
          const productId = item.item_id

          if (item.item_table === 'marketplace_products') {
            marketplaceIds.push(productId)
          }

          productIds.push(productId)
        }
      }
    }

    return {productIds, marketplaceIds}
  }, [data])

  const {data: productData, error: productError} = useQuery(GET_CART_PRODUCT, {
    skip: !data || marketplaceIds.length < 1,
    fetchPolicy: 'cache-and-network',
    variables: {
      products: marketplaceIds,
    },
  })

  const {data: wishlistData, refetch: refetchWishlist} = useQuery(
    GET_CART_WISHLIST,
    {
      skip: !data,
      fetchPolicy: 'cache-and-network',
      variables: {
        products: productIds,
      },
    }
  )

  const isProductsLoading = marketplaceIds.length > 0 && !productData

  const wishlistSet = React.useMemo(() => {
    const set = new Set()

    if (wishlistData) {
      for (const item of wishlistData.marketplace_user_wishlists) {
        set.add(item.item_id)
      }
    }

    return set
  }, [wishlistData])

  const productMap = React.useMemo(() => {
    const map = new Map()

    if (productData) {
      for (const item of productData.v_marketplace_product) {
        map.set(item.id, item)
      }
    }

    return map
  }, [productData])

  const {groupedCart, cartMap} = React.useMemo(() => {
    const groupedCart = new Map()
    const cartMap = new Map()

    if (data) {
      for (const item of data.marketplace_user_carts) {
        const itemId = item.id
        const vendorId = item.item_vendor_id
        let array = groupedCart.get(vendorId)

        if (!array) {
          array = []
          groupedCart.set(vendorId, array)
        }

        array.push(item)
        cartMap.set(itemId, item)
      }
    }

    return {groupedCart: [...groupedCart.values()], cartMap}
  }, [data])

  const {totalCost, groupedCost, overQty} = React.useMemo(() => {
    let totalCost = 0
    let overQty = false
    const groupedCost = new Map()

    for (const id of cartState.selected) {
      const item = cartMap.get(id)

      if (!item) {
        continue
      }

      const productId = item.item_id
      const vendorId = item.item_vendor_id
      const qty = item.item_quantity

      const product = productMap.get(productId)

      const price = +item.item_object.price
      const stock = product ? product.available_stock : 1

      const qtyPrice = price * qty

      if (qty > stock) {
        overQty = true
      }

      totalCost += qtyPrice
      groupedCost.set(vendorId, (groupedCost.get(vendorId) || 0) + qtyPrice)
    }

    return {totalCost, groupedCost, overQty}
  }, [data, productMap, cartState.selected])

  const handleSelectChange = (next, items, table) => {
    const key = getTableKey(table)
    const equal = key === cartState.key || cartState.selected.size < 1
    const nextSet = new Set(equal ? cartState.selected : [])

    for (const id of items) {
      if (next) {
        nextSet.add(id)
      } else {
        nextSet.delete(id)
      }
    }

    setCartState({...cartState, selected: nextSet, key})

    if (!equal && !dialogState.warned) {
      setDialogState({...dialogState, openWarnType: true, warned: true})
    }
  }

  const handleItemQtyChange = (itemId, qty) => {
    const promise = client.mutate({
      mutation: UPDATE_CART_ITEM_QTY,
      variables: {
        itemId: itemId,
        quantity: qty,
        object: {select_id: null},
      },
    })

    promise.catch(() => {
      enqueueSnackbar(`Failed to change cart quantity`, {variant: 'error'})
    })
  }

  const handleItemWishlist = item => {
    const productId = item.item_id
    const next = !wishlistSet.has(productId)
    let promise

    if (next) {
      promise = client.mutate({
        mutation: ADD_WISHLIST,
        variables: {
          objects: {
            ...item,
            id: undefined,
            __typename: undefined,
          },
        },
      })
    } else {
      promise = client.mutate({
        mutation: DELETE_WISHLIST,
        variables: {
          id: productId,
        },
      })
    }

    promise.then(
      () => {
        refetchWishlist()
      },
      () => {
        enqueueSnackbar(
          `Failed to ${next ? 'add to' : 'remove from'} wishlist`,
          {
            variant: 'error',
          }
        )
      }
    )
  }

  const handleCheckout = () => {
    setCartState({...cartState, state: STATE_CHECKOUT})

    // NOTE(intrnl): The checkout page is separate, and to prevent things from
    // being tampered, we'll make a hash of our expected cart state.

    // We do an additional sort operation to make sure because the hash is
    // order-sensitive.

    const params = new URLSearchParams()
    const cart = []

    for (const item of data.marketplace_user_carts) {
      const itemId = item.id

      if (!cartState.selected.has(itemId)) {
        continue
      }

      params.append('s', itemId)
      cart.push(item)
    }

    cart.sort((a, b) => a.id - b.id)

    const encoder = new TextEncoder()
    const buf = encoder.encode(JSON.stringify(cart))

    const promise = crypto.subtle.digest('sha-256', buf)

    promise.then(buffer => {
      const array = Array.from(new Uint8Array(buffer))
      const hex = array.map(b => b.toString(16).padStart(2, '0')).join('')

      // NOTE(intrnl): this allows the user to come back to the shopping page
      // with what they had selected prior, no need to re-select.
      history.replace({search: '' + params})

      params.append('h', hex)

      history.push(`/checkout?${params}`)
    })
  }

  const handleDeleteClose = () => {
    setDialogState({...dialogState, openDelete: false})
  }

  const handleDeleteConfirm = () => {
    const itemId = dialogState.item.id

    handleDeleteClose()

    if (cartState.selected.has(itemId)) {
      const next = new Set(cartState.selected)
      next.delete(itemId)

      setCartState({...cartState, selected: next})
    }

    const promise = client.mutate({
      mutation: DELETE_CART_ITEM,
      variables: {
        itemId: itemId,
      },
    })

    promise.then(
      () => {
        refetch()
      },
      () => {
        enqueueSnackbar(`Failed to remove cart item`, {variant: 'error'})
      }
    )
  }

  const handleWarnClose = () => {
    setDialogState({...dialogState, openWarnType: false})
  }

  return (
    <ShoppingContainer style={{background: '#f7f8f9'}}>
      <Toolbar disableGutters>
        {hasVendorModule && globalHistory.state !== null && (
          <IconButton onClick={history.goBack} edge="start">
            <ArrowBackIcon />
          </IconButton>
        )}

        <Typography variant="h6">Keranjang Belanja</Typography>
      </Toolbar>

      {error || productError ? (
        <div>Something went wrong</div>
      ) : !data || isProductsLoading ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </div>
      ) : (
        <div className={styles.root}>
          <div className={styles.list}>
            {groupedCart.length < 1 && (
              <NoDataListFree message1="Keranjang Belanja Kosong" message2="" />
            )}

            {groupedCart.map(items => {
              const firstItem = items[0]
              const firstProduct = productMap.get(firstItem.item_id)

              const table = firstItem.item_table
              const vendorId = firstItem.item_vendor_id

              const isVendor = table === 'marketplace_products'

              const companyName = firstItem.item_vendor
              const companyIndustry =
                firstProduct?.company_profile.global_company_industry_type?.name

              const length = items.length
              const ids = []
              const nodes = []

              let selection = 0

              for (const item of items) {
                const itemId = item.id
                const productId = item.item_id
                const itemObj = item.item_object
                const itemQty = item.item_quantity

                const product = productMap.get(productId)
                const selected = cartState.selected.has(itemId)

                const name = itemObj.name
                const image = itemObj.image
                const price = itemObj.price
                const stock = product ? product.available_stock : 1
                const note = itemObj.note

                selection += selected
                ids.push(itemId)

                nodes.push(
                  <div key={itemId} className={styles.item}>
                    <Checkbox
                      className={styles.checkbox}
                      disabled={loading}
                      checked={selected}
                      onChange={ev =>
                        handleSelectChange(ev.target.checked, [itemId], table)
                      }
                    />

                    <img
                      className={styles.productImage}
                      src={image || defaultProductImage}
                    />

                    <div className={styles.itemMain}>
                      {isVendor ? (
                        <RouterLink
                          to={`/profile/detail/produk/${productId}`}
                          className={styles.productName}
                        >
                          {name}
                        </RouterLink>
                      ) : (
                        <h4 className={styles.productName}>{name}</h4>
                      )}

                      {cartState.editingNote === itemId ? (
                        <>
                          <h5 className={styles.noteHeader}>Catatan</h5>
                          <TextField
                            autoFocus
                            size="small"
                            fullWidth
                            defaultValue={note}
                            onKeyPress={ev => {
                              if (ev.key === 'Enter') {
                                ev.preventDefault()
                                ev.target.blur()
                              }
                            }}
                            onBlur={ev => {
                              const next = ev.target.value

                              setCartState({...cartState, editingNote: null})

                              if (next === note) {
                                return
                              }

                              const promise = client.mutate({
                                mutation: UPDATE_CART_ITEM_OBJECT,
                                variables: {
                                  itemId: itemId,
                                  object: {
                                    note: next,
                                  },
                                },
                              })

                              promise.then(
                                () => {
                                  enqueueSnackbar(`Note updated`, {
                                    variant: 'success',
                                  })
                                },
                                () => {
                                  enqueueSnackbar(`Failed to update note`, {
                                    variant: 'error',
                                  })
                                }
                              )
                            }}
                          />
                        </>
                      ) : note ? (
                        <>
                          <h5 className={styles.noteHeader}>
                            <span>Catatan</span>
                            <Link
                              component="button"
                              onClick={() =>
                                setCartState({
                                  ...cartState,
                                  editingNote: itemId,
                                })
                              }
                              className={`${styles.noteButton} ${styles.noteEdit}`}
                            >
                              Ubah
                            </Link>
                          </h5>
                          <p className={styles.noteBody}>{note}</p>
                        </>
                      ) : (
                        <Link
                          component="button"
                          onClick={() =>
                            setCartState({...cartState, editingNote: itemId})
                          }
                          className={`${styles.noteButton} ${styles.noteEmpty}`}
                        >
                          Tambah catatan
                        </Link>
                      )}
                    </div>

                    <div className={styles.itemEnd}>
                      {isVendor && (
                        <Link
                          component="button"
                          onClick={() => handleItemWishlist(item)}
                          color="secondary"
                        >
                          {wishlistSet.has(productId)
                            ? `Hapus dari daftar keinginan`
                            : `Pindahkan ke daftar keinginan`}
                        </Link>
                      )}

                      <div>
                        <b>{convertToRupiah(price)}</b>

                        <div className={styles.controls}>
                          <IconButton
                            onClick={() =>
                              setDialogState({openDelete: true, item})
                            }
                            className={styles.removeButton}
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>

                          {isVendor && (
                            <Button
                              disabled={itemQty < 2}
                              onClick={() =>
                                handleItemQtyChange(itemId, itemQty - 1)
                              }
                              className={styles.qtyButton}
                              color="primary"
                              variant="contained"
                            >
                              <RemoveIcon />
                            </Button>
                          )}

                          <span
                            className={styles.qty}
                            style={{color: itemQty > stock ? '#f44336' : ''}}
                          >
                            {'' + itemQty}
                          </span>

                          {isVendor && (
                            <Button
                              disabled={itemQty >= stock}
                              onClick={() =>
                                handleItemQtyChange(itemId, itemQty + 1)
                              }
                              className={styles.qtyButton}
                              color="primary"
                              variant="contained"
                            >
                              <AddIcon />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }

              return (
                <Card key={firstItem.id}>
                  <div className={styles.header}>
                    <Checkbox
                      className={styles.checkbox}
                      disabled={loading}
                      checked={selection === length}
                      indeterminate={selection !== length && selection !== 0}
                      onChange={ev =>
                        handleSelectChange(ev.target.checked, ids, table)
                      }
                    />

                    {isVendor ? (
                      <StoreIcon className={styles.headerIcon} />
                    ) : (
                      <SchoolIcon className={styles.headerIcon} />
                    )}

                    <div className={styles.headerTitle}>
                      {firstProduct ? (
                        <RouterLink
                          to={`/profile/detail/vendor/${vendorId}`}
                          className={styles.headerName}
                        >
                          {companyName}
                        </RouterLink>
                      ) : (
                        <h2 className={styles.headerName}>{companyName}</h2>
                      )}

                      {companyIndustry && (
                        <h4 className={styles.headerBlurb}>
                          {companyIndustry}
                        </h4>
                      )}

                      {!isVendor && (
                        <h4 className={styles.headerBlurb}>
                          Kontributor Pembelajaran
                        </h4>
                      )}
                    </div>
                  </div>

                  {nodes}

                  <div className={styles.footer}>
                    <b>Sub Total</b>
                    <b>{convertToRupiah(groupedCost.get(vendorId) || 0)}</b>
                  </div>
                </Card>
              )
            })}
          </div>
          <div>
            <Card className={styles.card}>
              <h2 className={styles.cardTitle}>Ringkasan Pesanan</h2>

              <Divider />

              <table className={styles.table}>
                <tbody>
                  <tr>
                    <th>Total</th>
                    <td>{convertToRupiah(totalCost)}</td>
                  </tr>
                </tbody>
              </table>

              <Button
                disabled={
                  cartState.state >= STATE_CHECKOUT ||
                  cartState.selected.size < 1 ||
                  overQty
                }
                onClick={handleCheckout}
                variant="contained"
                color="secondary"
              >
                Checkout
              </Button>
            </Card>
          </div>

          <AlertConfirm
            danger
            open={dialogState.openDelete}
            title="Hapus item dari Keranjang Belanja?"
            message={
              dialogState.item && (
                <>
                  Apakah kamu yakin untuk menghapus{' '}
                  <b>{dialogState.item.item_object.name}</b> dari keranjang
                  belanja?
                </>
              )
            }
            confirmation="Hapus"
            cancellation="Batal"
            onClose={handleDeleteClose}
            onConfirm={handleDeleteConfirm}
          />

          <AlertConfirm
            open={dialogState.openWarnType}
            title="Cannot select different product types"
            message="Sorry, currently you cannot select vendor and learning products at the same time."
            cancellation={false}
            onClose={handleWarnClose}
            onConfirm={handleWarnClose}
          />
        </div>
      )}

      <MemoizedVendorRecommendationGrid />
    </ShoppingContainer>
  )
}

export default CartPage

const VendorRecommendationGrid = () => {
  const styles = useCartStyles()
  const {data: moduleData} = GetModularSettings(COMPANY_ID)

  const hasVendorModule = moduleData && hasModule(moduleData, 'vendor')

  const cookieData = React.useMemo(() => {
    const raw = getCookie(`related-product${USER_ID}`)
    const data = raw !== '' ? JSON.parse(raw) : []

    return data
  }, [])

  if (!hasVendorModule) {
    return null
  }

  return (
    <div style={{marginTop: 64}}>
      <div style={{display: 'flex', marginBottom: 5}}>
        <h2 className={styles.subtitle} style={{margin: `0 16px 0 0`}}>
          Anda mungkin juga menyukai
        </h2>

        <Link
          component={RouterLink}
          to="/see-all/product/You might also like"
          color="secondary"
        >
          Lihat semua
        </Link>
      </div>
      <Grid item sm={12} style={{paddingTop: 16}}>
        <CarouselProduct
          type="new"
          searchText={null}
          typeFilterContext={[]}
          categoryFilterContext={[]}
          relatedProduct={cookieData}
        />
      </Grid>
    </div>
  )
}

const MemoizedVendorRecommendationGrid = React.memo(VendorRecommendationGrid)
