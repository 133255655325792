import gql from 'graphql-tag'

export const REVIEW_PRODUCT = gql`
  mutation($objects: [marketplace_product_reviews_insert_input!]!) {
    insert_marketplace_product_reviews(objects: $objects) {
      affected_rows
    }
  }
`

export const REPLY_REVIEW = gql`
  mutation($reviewId: Int!, $respond: String!) {
    update_marketplace_product_reviews(
      where: {id: {_eq: $reviewId}}
      _set: {respond: $respond}
    ) {
      affected_rows
    }
  }
`
