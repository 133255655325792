import React from 'react'
import DetailForAdmin from '../../../components/vendor-tabs/card-product/DetailForAdmin'

export default function DetailAdmin(props) {
  let regexUrl = ''
  if (props.location.pathname) {
    const regexPattern = /[a-z0-9-]+/gm
    regexUrl = props.location.pathname.match(regexPattern)
  }
  return (
    <>
      {regexUrl[2] && (
        <DetailForAdmin
          {...props}
          idVendor={props.match.params.vendor}
          id={props.match.params.id}
        />
      )}
    </>
  )
}
