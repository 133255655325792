import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {CircularProgress, IconButton, Menu, MenuItem} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import {
  LitleTextGrey,
  TwoColumnTrasaction,
  PaperTransaction,
  TextBlue,
  TextBlack,
  TextBlackBold,
  FlexColumnFirst,
  FlexRowFrist,
  BoxGrey,
  TablePaginationCus,
  TitleTransactionDarkBlue,
  PaperTransaction2,
  BigTextRed,
} from '../../../utils-components/VendorStyles'
import useStyles from '../../../../pages/transaction/TransactionPageStyles'

import {
  convertToRupiah,
  getShortDate,
  ROW_PAGENATION,
} from '../../../../utils/helpers'
import PurchaseDetails from '../modal-transaction/PurchaseDetails'
import AskRefundModal from '../modal-transaction/AskRefundModal'
import DynamicPhoto from '../../../dynamic-photo/DynamicPhoto'

import {USER_ID} from '../../../../utils/globals'
import {GET_LIST_ON_PROCESS} from '../../../../graphql/queries'
import {
  HorizontalContainer,
  VerticalContainer,
} from '../../../general-container/FlexContainerStyles'
import {VendorBioLabel, VendorTypeLabel} from '../../../header/HeaderStyles'
import NoDataListFree from '../../NoDataListFree'

const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'})

const ListTransaction = props => {
  const {filter, rowsPerPage, setRowsPerPage} = props

  const classes = useStyles()

  const [page, setPage] = React.useState(0)
  const [inPageTransition, setInPageTransition] = React.useState(false)

  const [invoiceData, setInvoiceData] = React.useState(null)
  const [openDetails, setOpenDetails] = React.useState(false)
  const [openRefund, setOpenRefund] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  let status = []
  const type = props.match.params.type

  switch (type) {
    case 'Awaiting Vendor Confirmation': {
      status = ['paid', 'refund_pending']
      break
    }
    case 'On Process': {
      status = ['packing', 'review']
      break
    }
    case 'On Delivery': {
      status = ['shipping']
      break
    }
    case 'Purchase History': {
      status = ['completed', 'refund_paid', 'rejected']
      break
    }
  }

  const {data, error} = useQuery(GET_LIST_ON_PROCESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      status: status,
      offset: page * rowsPerPage,
      limit: page * rowsPerPage + rowsPerPage,
      orderBy: filter.orderBy,
      user: USER_ID,
      search: filter.search_filter !== '' ? filter.search_filter : null,
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
      totalFrom: filter.totalFrom,
      totalTo: filter.totalTo,
    },
    onCompleted: data => {
      if (data && inPageTransition) {
        setInPageTransition(false)
      }
    },
  })

  if (error) {
    console.error(error)
    return <div>Something went wrong</div>
  }

  if (!data || inPageTransition) {
    return (
      <PaperTransaction style={{display: 'flex', alignItems: 'center'}}>
        <CircularProgress />
      </PaperTransaction>
    )
  }

  const open = Boolean(anchorEl)

  const handleDetailsOpen = invoiceData => {
    setInvoiceData(invoiceData)
    setOpenDetails(true)
  }

  const handleDetailsClose = () => {
    setOpenDetails(false)
  }

  const handleRefundOpen = () => {
    setOpenRefund(true)
  }

  const handleRefundClose = () => {
    setOpenRefund(false)
  }

  const handleRefundSubmission = () => {
    handleRefundClose()
    handleDetailsClose()

    if (type !== 'Awaiting Vendor Confirmation') {
      props.history.push(`/transaction/Awaiting Vendor Confirmation`)
    }
  }

  const handleMenuClick = (invoiceData, target) => {
    setInvoiceData(invoiceData)
    setAnchorEl(target)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  // Firefox doesn't seem to like it if we try to scroll while the document
  // is in flux, so we'll use setTimeout to defer the scrolling to potentially
  // after the rerender.

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
    setInPageTransition(true)

    setTimeout(scrollToTop, 0)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setInPageTransition(true)

    setTimeout(scrollToTop, 0)
  }

  return (
    <>
      {data.global_invoices.length < 1 && (
        <PaperTransaction>
          <NoDataListFree message1="Sorry, No List" message2="" />
        </PaperTransaction>
      )}

      {data.global_invoices.map((res, key) => (
        <PaperTransaction2
          key={key}
          style={{
            borderRadius: '5px',
            boxShadow: ' 0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          }}
        >
          <TwoColumnTrasaction>
            <FlexColumnFirst>
              <TextBlack
                className={classes.cursorPointer}
                onClick={() => {
                  handleDetailsOpen(res)
                }}
              >
                {res.global_wallet_vendor &&
                  (res.global_wallet_vendor.company_profiles.length > 0
                    ? res.global_wallet_vendor.company_profiles[0].brand_name
                    : res.global_wallet_vendor.global_users?.[0].name)}
              </TextBlack>
              <LitleTextGrey>
                {res.global_payment
                  ? `Purchased on ${getShortDate(
                      res.global_payment.date_created
                    )}`
                  : ''}
              </LitleTextGrey>

              <TextBlack>({res.code})</TextBlack>
            </FlexColumnFirst>
            <IconButton
              title="More"
              aria-controls={`menu-${res.id}`}
              aria-haspopup="true"
              onClick={ev => handleMenuClick(res, ev.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
          </TwoColumnTrasaction>

          <BoxGrey className={classes.marginTop8}>
            <FlexRowFrist>
              <div
                style={{
                  width: '207px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <DynamicPhoto productData={res.products} />
              </div>
              <FlexColumnFirst
                className={classes.marginLeft20}
                style={{alignSelf: 'center'}}
              >
                <TextBlackBold>
                  {res.products.length} Items in Total
                </TextBlackBold>
                <TitleTransactionDarkBlue className={classes.marginTop8}>
                  Total
                </TitleTransactionDarkBlue>
                <TextBlackBold component="span">
                  {convertToRupiah(res.final_price)}
                </TextBlackBold>

                {res.status === 'refund_paid' && (
                  <BigTextRed className={classes.marginLeft5}>
                    (Refunded)
                  </BigTextRed>
                )}
                {res.status === 'rejected' && (
                  <BigTextRed className={classes.marginLeft5}>
                    (Rejected)
                  </BigTextRed>
                )}
              </FlexColumnFirst>
            </FlexRowFrist>
          </BoxGrey>
          {res.global_shipping_provider !== null && (
            <>
              <LitleTextGrey style={{padding: '10px 0px'}}>
                Shipping
              </LitleTextGrey>
              <HorizontalContainer>
                <div style={{width: '100px'}}>
                  <div
                    style={{
                      height: '40px',
                      width: '100%',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                      backgroundImage: `url(${res.global_shipping_provider.logo})`,
                    }}
                    // src={`${res.global_shipping_provider.logo}`}
                  />
                </div>
                <VerticalContainer paddingLeft={'17px'}>
                  <VendorTypeLabel style={{fontSize: '12px'}}>
                    {'Shipping Number'}
                  </VendorTypeLabel>
                  <VendorBioLabel style={{color: '#039BE5', fontSize: '14px'}}>
                    {res.delivery_data.id_shipping !== ''
                      ? res.delivery_data.id_shipping
                      : '-'}
                  </VendorBioLabel>
                </VerticalContainer>
              </HorizontalContainer>
            </>
          )}
        </PaperTransaction2>
      ))}

      <TablePaginationCus
        rowsPerPageOptions={ROW_PAGENATION}
        component="div"
        count={data ? data.total.aggregate.count : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {invoiceData && (
        <Menu
          id={`menu-${invoiceData.id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
        >
          <MenuItem
            component={Link}
            to={{
              pathname: `/transaction/print-bills/show/${invoiceData.id}/paid`,
            }}
          >
            <TextBlue>Print Invoice</TextBlue>
          </MenuItem>
        </Menu>
      )}

      <PurchaseDetails
        data={invoiceData}
        open={openDetails}
        onClose={handleDetailsClose}
        onAskRefund={handleRefundOpen}
      />

      <AskRefundModal
        open={openRefund}
        key={invoiceData?.id}
        invoiceId={invoiceData?.id}
        onClose={handleRefundClose}
        onRefundSubmission={handleRefundSubmission}
      />
    </>
  )
}

export default withRouter(ListTransaction)
