import gql from 'graphql-tag'

export const GET_NAVIGATION_NOTIFICATION = gql`
  query($userID: uuid!) {
    cart: marketplace_user_carts_aggregate(where: {user: {_eq: $userID}}) {
      aggregate {
        count
      }
    }

    wishlist: marketplace_user_wishlists_aggregate(
      where: {user: {_eq: $userID}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_TRANSACTION_NOTIFICATION = gql`
  query($userID: uuid!) {
    waiting_payment: global_payments_aggregate(
      where: {
        payment_data: {_is_null: false}
        payment_status: {_in: ["pending", "inconfirmation"]}
        global_wallet_account: {global_users: {id: {_eq: $userID}}}
      }
    ) {
      aggregate {
        count
      }
    }

    waiting_for_vendor: global_invoices_aggregate(
      where: {
        vendor_id: {_is_null: false}
        global_wallets: {global_users: {id: {_eq: $userID}}}
        status: {_in: ["refund_pending", "paid"]}
      }
    ) {
      aggregate {
        count
      }
    }

    onprocess: global_invoices_aggregate(
      where: {
        vendor_id: {_is_null: false}
        global_wallets: {global_users: {id: {_eq: $userID}}}
        global_payment: {}
        status: {_in: ["review", "packing"]}
      }
    ) {
      aggregate {
        count
      }
    }

    ondelivery: global_invoices_aggregate(
      where: {
        vendor_id: {_is_null: false}
        global_wallets: {global_users: {id: {_eq: $userID}}}
        global_payment: {}
        status: {_in: ["shipping"]}
      }
    ) {
      aggregate {
        count
      }
    }

    history: global_invoices_aggregate(
      where: {
        vendor_id: {_is_null: false}
        global_wallets: {global_users: {id: {_eq: $userID}}}
        global_payment: {}
        status: {_in: ["completed", "refund_paid", "rejected"]}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
