import React, {useState, useEffect} from 'react'
import {withRouter, Link} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/react-hooks'

import {
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {useSnackbar} from 'notistack'

import {
  LitleTextGrey,
  TwoColumnTrasaction,
  PaperTransaction,
  TextBlue,
  TextBlack,
  FlexColumnFirst,
  BoxGrey,
  TablePaginationCus,
  LitleTextWaterMelon,
  FlexRowEnd,
} from '../../../utils-components/VendorStyles'
import useStyles from '../../../../pages/transaction/TransactionPageStyles'

import AlertConfirm from '../../alert/AlertConfirm'
import DetailTransaction from '../DetailTransaction'
import PurchaseDetails from '../modal-transaction/PurchaseDetails'

import NoDataListFree from '../../../vendor-tabs/NoDataListFree'
import HowToPayModal from '../modal-transaction/HowToPayModal'
import UploadPayment from '../modal-transaction/UploadPayment'

import {USER_ID} from '../../../../utils/globals'
import {getShortDate, ROW_PAGENATION} from '../../../../utils/helpers'
import {GET_LIST_TRANSACTION} from '../../../../graphql/queries/index'
import {ADD_DIRECT_TRANSFER} from '../../../../graphql/mutations/transaction/updateOrder.mutation'
import {CANCEL_ORDER} from '../../../../graphql/mutations/product/updateOrder'

const ListAwaiting = props => {
  const {filter} = props

  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()

  const [rowsPerPage, setRowsPerPage] = useState(ROW_PAGENATION[0])
  const [page, setPage] = useState(0)
  const [currentId, setCurrentId] = useState(0)
  const [count, setCount] = useState(0)
  const [openDetail, setDetail] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [payData, setPayData] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [paymentModalState, setPaymentModalState] = useState({
    data: null,
    openGuide: false,
    openUpload: false,
    openConfirm: false,
  })

  const [mutationDelete] = useMutation(CANCEL_ORDER)
  const [uploadPayments] = useMutation(ADD_DIRECT_TRANSFER)
  const [valueAttachment, setValueAttachment] = useState([])

  const {data, error, loading, refetch} = useQuery(GET_LIST_TRANSACTION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      status: ['pending', 'inconfirmation'],
      offset: page * rowsPerPage,
      limit: page * rowsPerPage + rowsPerPage,
      orderBy: filter.orderBy,
      user: USER_ID,
      search: filter.search_filter !== '' ? filter.search_filter : null,
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
      totalFrom: filter.totalFrom,
      totalTo: filter.totalTo,
    },
  })

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (data) {
      setCount(data.total ? data.total.aggregate.count : 0)
    }
  }, [data])

  if (loading) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const handleHowToPay = data => {
    if (data.payment_method === 'direct_transfer') {
      setPaymentModalState({openGuide: true, data: data})
    } else if (data.payment_data.length > 0) {
      window.open(data.payment_data[0].redirect_url, '_blank')
    }
  }

  const handleOpenDetail = pay => {
    setPayData(pay)
    setDetail(true)
  }

  const handleMenuOpen = (id, target) => {
    setCurrentId(id)
    setAnchorEl(target)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleDetailClose = () => {
    setDetail(false)
  }

  const handleConfirmationOpen = () => {
    setIsOpen(true)
  }

  const handleConfirmationClose = () => {
    setIsOpen(false)
  }

  const handleConfirmationConfirm = () => {
    handleConfirmationClose()

    mutationDelete({
      variables: {
        paymentId: currentId,
      },
    })
      .then(() => {
        enqueueSnackbar('Successfully cancelled')
        handleDetailClose()
        refetch()
      })
      .catch(() => {
        setAnchorEl(null)
        enqueueSnackbar('Failed to cancel')
      })
  }

  const dataNull = () => {
    if (data.global_payments.length === 0) {
      return (
        <PaperTransaction>
          <NoDataListFree message1="Sorry, No List" message2="" />
        </PaperTransaction>
      )
    }
  }

  const handleMutation = () => {
    const data = paymentModalState.data

    const proofs = valueAttachment.map(file => ({
      direct_transfer_id: data.global_direct_transfer.id,
      name: file.name,
      file: file.url,
      type: file.type,
    }))

    const promise = uploadPayments({variables: {proofs}})

    promise.then(
      () => {
        handleDetailClose()
        setPaymentModalState({
          ...paymentModalState,
          data: null,
          openUpload: false,
          openConfirm: false,
        })

        enqueueSnackbar(`Payment proof attached`, {variant: 'success'})
        refetch()
      },
      () => {
        setPaymentModalState({
          ...paymentModalState,
          openUpload: true,
          openConfirm: false,
        })

        enqueueSnackbar(`Failed to attach payment proof`, {variant: 'error'})
      }
    )
  }

  return (
    <>
      {data &&
        data.global_payments.map((res, key) => {
          const expiredDate = new Date(res.global_direct_transfer.expires_at)

          const invoice = res.global_invoices[0]

          const vendorWallet = invoice.global_wallet_vendor
          const isMarketplace = vendorWallet?.company_profiles.length > 0

          const vendorName = isMarketplace
            ? vendorWallet?.company_profiles[0].brand_name
            : vendorWallet?.global_users[0].name

          return (
            <PaperTransaction
              key={key}
              style={{
                borderRadius: '5px',
                boxShadow: ' 0 3px 6px 0 rgba(0, 0, 0, 0.16)',
              }}
            >
              <TwoColumnTrasaction>
                <FlexColumnFirst>
                  <TextBlack
                    className={classes.cursorPointer}
                    onClick={() => {
                      handleOpenDetail(res)
                    }}
                  >
                    {vendorName}
                  </TextBlack>
                  <LitleTextGrey>
                    Purchased on {getShortDate(res.date_created)}
                  </LitleTextGrey>
                  <TextBlack
                    className={`${classes.marginTop5} ${classes.cursorPointer}`}
                    onClick={() => {
                      handleOpenDetail(res)
                    }}
                  >
                    ({res.global_invoices[0].code})
                  </TextBlack>
                </FlexColumnFirst>
                <IconButton
                  title="More"
                  aria-controls={`menu-${res.id}`}
                  aria-haspopup="true"
                  onClick={ev => handleMenuOpen(res.id, ev.currentTarget)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id={`menu-${res.id}`}
                  open={open && currentId === res.id}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                >
                  <MenuItem
                    component={Link}
                    to={{
                      pathname: `/transaction/print-bills/show/${res.id}/Awaiting`,
                    }}
                  >
                    <TextBlue>Print Invoice</TextBlue>
                  </MenuItem>
                  <MenuItem onClick={handleConfirmationOpen}>
                    <LitleTextWaterMelon>Cancel Order</LitleTextWaterMelon>
                  </MenuItem>
                </Menu>
              </TwoColumnTrasaction>

              <BoxGrey className={classes.marginTop8}>
                <DetailTransaction res={res} date_expired={expiredDate} />

                <FlexRowEnd className={classes.marginTop8}>
                  <Button
                    onClick={() => handleHowToPay(res)}
                    variant="contained"
                    color="primary"
                  >
                    How to Pay
                  </Button>
                </FlexRowEnd>
              </BoxGrey>
            </PaperTransaction>
          )
        })}

      {dataNull()}

      <TablePaginationCus
        rowsPerPageOptions={ROW_PAGENATION}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <PurchaseDetails
        data={payData}
        open={openDetail}
        setId={setCurrentId}
        onHowToPay={handleHowToPay}
        onCancelOrder={() => handleConfirmationOpen(true)}
        onClose={handleDetailClose}
      />

      <AlertConfirm
        danger
        open={isOpen}
        title="Cancel this order?"
        message="Are you sure to cancel this order?"
        confirmation={'Cancel Order'}
        onClose={handleConfirmationClose}
        onConfirm={handleConfirmationConfirm}
      />
      <HowToPayModal
        open={paymentModalState.openGuide}
        paymentData={paymentModalState.data}
        onClose={() => {
          setPaymentModalState({
            ...paymentModalState,
            openGuide: false,
          })
        }}
        onPaid={() => {
          setPaymentModalState({
            ...paymentModalState,
            openGuide: false,
            openUpload: true,
          })
        }}
      />

      <UploadPayment
        key={paymentModalState.data}
        open={paymentModalState.openUpload}
        onClose={() => {
          setPaymentModalState({
            ...paymentModalState,
            openUpload: false,
          })
        }}
        onCancel={() => {
          setPaymentModalState({
            ...paymentModalState,
            openGuide: true,
            openUpload: false,
          })
        }}
        onConfirm={result => {
          setValueAttachment(result.files)
          setPaymentModalState({
            ...paymentModalState,
            openConfirm: true,
          })
        }}
      />

      <AlertConfirm
        title="Upload Payment Attachment?"
        message="Are you sure the file that you upload is correct? The file can't be changed once it's submitted"
        open={paymentModalState.openConfirm}
        onClose={() => {
          setPaymentModalState({
            ...paymentModalState,
            openConfirm: false,
            openUpload: true,
          })
        }}
        onConfirm={handleMutation}
      />
    </>
  )
}

export default withRouter(ListAwaiting)
