import React from 'react'
import {HC_URL, NEW_LEARNING_URL} from '../../utils/globals'
import {
  ProductImg,
  LitleProductImg,
  ImgCatcher,
  TextImgCatcher,
} from '../utils-components/VendorStyles'
import {withRouter} from 'react-router-dom'
import {useApolloClient} from '@apollo/react-hooks'
import {GET_COURSE_SLUG} from '../../graphql/queries'

import defaultProduct from '../../assets/images/default_product.png'

const DynamicPhoto = (props) => {
  const {productData} = props
  const client = useApolloClient()
  const html = []

  const redirectLink = async (type, id) => {
    if (type === 'academy_courses') {
      await client
        .query({
          query: GET_COURSE_SLUG,
          variables: {
            id,
          },
        })
        .then((res) => {
          const slug = res?.data?.academy_courses[0]?.slug
          if (slug) {
            window.open(
              `${NEW_LEARNING_URL}/learner/course/${slug}`,
              '_blank',
              'noopener,noreferrer'
            )
          }
        })
        .catch((err) => {
          console.error(err)
        })
    } else if (type === 'academy_books') {
      window.open(
        `${NEW_LEARNING_URL}/learner/book/${id}`,
        '_blank',
        'noopener,noreferrer'
      )
    } else if (type === 'classrooms') {
      window.open(
        `${HC_URL}/social-learning/${id}/discussion`,
        '_blank',
        'noopener,noreferrer'
      )
    } else if (type === 'microlearnings') {
      window.open(
        `${NEW_LEARNING_URL}/learner/micro_learning/${id}`,
        '_blank',
        'noopener,noreferrer'
      )
    } else {
      props.history.push('/profile/detail/produk/' + id)
    }
  }

  productData.slice(0, productData.length > 4 ? 3 : 4).forEach((res) => {
    if (productData.length > 1) {
      html.push(
        <LitleProductImg
          key={res.id}
          src={`${res.thumbnail || defaultProduct}`}
          onClick={() => {
            redirectLink(res.item_table, res.item_table_id)
          }}
        ></LitleProductImg>
      )
    } else {
      html.push(
        <ProductImg
          key={res.id}
          src={`${res.thumbnail || defaultProduct}`}
          onClick={() => {
            redirectLink(res.item_table, res.item_table_id)
          }}
        ></ProductImg>
      )
    }
  })

  if (productData.length > 4) {
    const key = productData.length
    html.push(
      <ImgCatcher key={key}>
        <TextImgCatcher>+{productData.length - 3}</TextImgCatcher>
      </ImgCatcher>
    )
  }

  return html
}

export default withRouter(DynamicPhoto)
