import React from 'react'
import {useApolloClient} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import {
  makeStyles,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import AsyncSelect from 'react-select/async'

import axios from 'axios'

import {
  UploadButton,
  AttachmentItem,
  getFileType,
} from '@worklifebeyond/wlb-utils-components'

import {ModalContent, ModalHeader} from '../../../modal/Modal'

import {TOKEN, UPLOAD_URL, USER_ID} from '../../../../utils/globals'
import {
  GET_BANK_PROVIDERS,
  GET_USER_BANK_ACCOUNTS,
} from '../../../../graphql/queries'
import {ADD_BANK_ACCOUNT, REQUEST_REFUND} from '../../../../graphql/mutations'
import {cbify} from '../../../../utils/helpers'

const useStyles = makeStyles(() => ({
  mt0: {
    marginTop: 0,
  },
  mb4: {
    marginBottom: 4,
  },
}))

const renderError = message => <FormHelperText error>{message}</FormHelperText>

const TYPE_REGISTERED = 'reg'
const TYPE_OTHER = 'etc'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_STATE = {
  type: TYPE_REGISTERED,

  _bank: null,
  _provider: null,

  account_provider: null,
  _account_provider_name: null,
  account_name: '',
  account_number: '',
  account_save: false,

  reason: '',
  attachments: {
    files: [],
    pending: 0,
    error: false,
    invalidType: false,
    overSize: false,
  },
}

const AskRefundModal = props => {
  const {open, invoiceId, onClose, onRefundSubmission} = props

  const styles = useStyles()

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const [formData, setFormData] = React.useState(INITIAL_FORM_STATE)
  const [formState, setFormState] = React.useState(STATE_INITIAL)

  const isError = formState === STATE_ERROR
  const isDisabled = formState >= STATE_DISPATCH

  const isTypeRegistered = formData.type === TYPE_REGISTERED
  const isTypeOther = formData.type === TYPE_OTHER

  const isRegisteredBankEmpty = isTypeRegistered && !formData._bank

  const isOtherProviderEmpty = isTypeOther && !formData.account_provider
  const isOtherNameEmpty = isTypeOther && !formData.account_name
  const isOtherNumberEmpty = isTypeOther && !formData.account_number

  const isReasonEmpty = !formData.reason

  const isAttachmentsUploading = formData.attachments.pending > 0

  const handleRegisteredBankSearch = async searchText => {
    searchText = searchText.trim()

    const {data} = await client.query({
      query: GET_USER_BANK_ACCOUNTS,
      fetchPolicy: 'network-only',
      variables: {
        userId: USER_ID,
        search: searchText ? `%${searchText}%` : undefined,
      },
    })

    const result = data?.accounts.map(account => ({
      account,
      value: account.id,
      label: `${account.provider.name} - ${account.account_number} - ${account.account_name}`,
    }))

    return result || []
  }

  const handleBankProviderSearch = async searchText => {
    searchText = searchText.trim()

    const {data} = await client.query({
      query: GET_BANK_PROVIDERS,
      variables: {
        search: searchText ? `%${searchText}%` : undefined,
      },
    })

    const result = data?.providers.map(provider => ({
      provider,
      value: provider.id,
      label: provider.name,
    }))

    return result || []
  }

  const handleTypeChange = ev => {
    setFormData({
      ...formData,
      type: ev.target.value,
      _bank: INITIAL_FORM_STATE._bank,
      _provider: INITIAL_FORM_STATE._provider,
      account_provider: INITIAL_FORM_STATE.account_provider,
      _account_provider_name: INITIAL_FORM_STATE._account_provider_name,
      account_name: INITIAL_FORM_STATE.account_name,
      account_number: INITIAL_FORM_STATE.account_number,
      account_save: INITIAL_FORM_STATE.account_save,
    })
  }

  const handleRegisteredBankChange = option => {
    const account = option.account

    setFormData({
      ...formData,
      _bank: option,
      account_provider: account.provider.id,
      account_name: account.account_name,
      account_number: account.account_number,
    })
  }

  const handleBankProviderChange = option => {
    const provider = option.provider

    setFormData({
      ...formData,
      _provider: option,
      account_provider: provider.id,
      _account_provider_name: provider.name,
    })
  }

  const handleAttachmentAdd = ev => {
    const pending = Array.from(ev.target.files)
    const queue = []

    let invalidType = false
    let overSize = false

    for (const file of pending) {
      if (!getFileType(file.name)) {
        invalidType = true
        continue
      }

      if (file.size >= 25 * 1024 * 1024) {
        overSize = true
        continue
      }

      queue.push(file)
    }

    setFormData(prev => {
      const prevd = prev.attachments

      return {
        ...prev,
        attachments: {
          ...prevd,
          error: prevd.pending ? prevd.error : false,
          pending: prevd.pending + queue.length,
          invalidType,
          overSize,
        },
      }
    })

    for (const file of queue) {
      const form = new FormData()
      form.append('file', file)

      const promise = axios.post(UPLOAD_URL, form, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          'Content-Type': `multipart/form-data`,
        },
      })

      cbify(promise, (error, response) => {
        const data = response && response.data

        setFormData(prev => {
          const prevd = prev.attachments
          let files = prevd.files

          if (data) {
            files = files.slice()
            files.push({file: data.url, name: file.name, size: file.size})
          }

          return {
            ...prev,
            attachments: {
              files,
              error: prevd.error || error !== null,
              pending: prevd.pending - 1,
            },
          }
        })
      })
    }
  }

  const bindAttachmentFilenameChange = idx => ev => {
    const prevd = formData.attachments
    const files = prevd.files.slice()

    files[idx].name = ev.target.value
    setFormData({...formData, attachments: {...prevd, files}})
  }

  const bindAttachmentRemove = idx => () => {
    const prevd = formData.attachments
    const files = prevd.files.slice()

    files.splice(idx, 1)
    setFormData({...formData, attachments: {...prevd, files}})
  }

  const bindInputChange = field => ev => {
    setFormData({...formData, [field]: ev.target.value})
  }

  const handleSubmit = async () => {
    if (
      isRegisteredBankEmpty ||
      isOtherProviderEmpty ||
      isOtherNameEmpty ||
      isOtherNumberEmpty ||
      isReasonEmpty ||
      isAttachmentsUploading
    ) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    let savedId = null

    if (formData.account_save) {
      try {
        const {data} = await client.mutate({
          mutation: ADD_BANK_ACCOUNT,
          variables: {
            data: {
              bank: formData.account_provider,
              bank_name: formData._account_provider_name,
              account_name: formData.account_name,
              account_number: formData.account_number,
            },
          },
        })

        savedId = data.insert_people_profile_banks_one.id

        enqueueSnackbar(`Bank account saved`, {variant: 'success'})
      } catch (error) {
        console.error(error)
        enqueueSnackbar(`Failed to save bank account`, {variant: 'error'})
      }
    }

    try {
      await client.mutate({
        mutation: REQUEST_REFUND,
        variables: {
          data: {
            invoice_id: invoiceId,
            bank_id: formData.account_provider,
            bank_account_holder: formData.account_name,
            bank_account_num: formData.account_number,
            reason: formData.reason,
            marketplace_refund_request_files: {
              data: formData.attachments.files,
            },
          },
        },
      })

      enqueueSnackbar(`Refund request submitted`, {variant: 'success'})

      if (onRefundSubmission) {
        onRefundSubmission()
      }
    } catch (error) {
      console.error(error)
      enqueueSnackbar(`Failed to request refund`, {variant: 'error'})

      // We successfully added the account, but failed the refund request,
      // here's to prevent the re-adding of the same account.
      if (savedId) {
        setFormData({
          ...formData,
          type: TYPE_REGISTERED,
          _bank: {
            value: savedId,
            label: `${formData._account_provider_name} - ${formData.account_number} - ${formData.account_name}`,
          },
          account_save: false,
        })
      }

      setFormState(STATE_INITIAL)
    }
  }

  const FIELD_REQUIRED = renderError('This field is required')

  return (
    <Dialog open={open} onClose={isDisabled ? null : onClose}>
      <ModalHeader onClose={isDisabled ? null : onClose}>
        <Typography>Ask for Refund</Typography>
      </ModalHeader>

      <ModalContent>
        <FormControl
          disabled={isDisabled}
          required
          margin="normal"
          fullWidth
          className={styles.mt0}
        >
          <FormLabel>Bank Account for Refunding</FormLabel>
          <RadioGroup value={formData.type} onChange={handleTypeChange} row>
            <FormControlLabel
              value={TYPE_REGISTERED}
              control={<Radio />}
              label="Use Registered Bank Account"
            />
            <FormControlLabel
              value={TYPE_OTHER}
              control={<Radio />}
              label="Use Other Bank Account"
            />
          </RadioGroup>
        </FormControl>

        {formData.type === TYPE_REGISTERED && (
          <>
            <FormControl
              disabled={isDisabled}
              required
              margin="normal"
              fullWidth
            >
              <FormLabel className={styles.mb4}>Bank Account</FormLabel>
              <AsyncSelect
                isDisabled={isDisabled}
                defaultOptions
                loadOptions={handleRegisteredBankSearch}
                value={formData._bank}
                onChange={handleRegisteredBankChange}
                placeholder="Choose Bank Account"
                menuPosition="fixed"
              />

              {isError && isRegisteredBankEmpty && FIELD_REQUIRED}
            </FormControl>
          </>
        )}

        {formData.type === TYPE_OTHER && (
          <>
            <FormControl
              disabled={isDisabled}
              required
              margin="normal"
              fullWidth
            >
              <FormLabel className={styles.mb4}>Bank</FormLabel>
              <AsyncSelect
                isDisabled={isDisabled}
                defaultOptions
                loadOptions={handleBankProviderSearch}
                value={formData._provider}
                onChange={handleBankProviderChange}
                placeholder="Choose Bank"
                menuPosition="fixed"
              />

              {isError && isOtherProviderEmpty && FIELD_REQUIRED}
            </FormControl>

            <FormControl
              disabled={isDisabled}
              required
              margin="normal"
              fullWidth
            >
              <FormLabel className={styles.mb4}>
                Bank Account Holder Name
              </FormLabel>
              <OutlinedInput
                value={formData.account_name}
                onChange={bindInputChange('account_name')}
                placeholder="Add Bank Account Holder Name"
                margin="dense"
              />

              {isError && isOtherNameEmpty && FIELD_REQUIRED}
            </FormControl>

            <FormControl
              disabled={isDisabled}
              required
              margin="normal"
              fullWidth
            >
              <FormLabel className={styles.mb4}>Bank Account Number</FormLabel>
              <OutlinedInput
                value={formData.account_number}
                onChange={bindInputChange('account_number')}
                placeholder="Add Bank Account Number"
                margin="dense"
              />

              {isError && isOtherNumberEmpty && FIELD_REQUIRED}
            </FormControl>

            <FormControlLabel
              disabled={isDisabled}
              value={formData.account_save}
              onChange={bindInputChange('account_save')}
              control={<Checkbox />}
              label="Save Bank Account"
            />
          </>
        )}

        <FormControl disabled={isDisabled} required margin="normal" fullWidth>
          <FormLabel className={styles.mb4}>Reason</FormLabel>
          <OutlinedInput
            multiline
            rows={3}
            value={formData.reason}
            onChange={bindInputChange('reason')}
            placeholder="Add Refund Reason"
            margin="dense"
          />

          {isError && isReasonEmpty && FIELD_REQUIRED}
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <FormLabel className={styles.mb4}>
            <span>Attachments</span> <span>(optional)</span>
          </FormLabel>

          <UploadButton
            multiple
            disabled={isDisabled}
            onChange={handleAttachmentAdd}
          />

          <Box display="grid" gridGap={12} mt={1.5}>
            {formData.attachments.files.map((file, idx) => (
              <AttachmentItem
                key={idx}
                name={file.name}
                size={file.size}
                url={file.file}
                disabled={isDisabled}
                onNameChange={bindAttachmentFilenameChange(idx)}
                onRemove={bindAttachmentRemove(idx)}
              />
            ))}

            {formData.attachments.pending > 0 && <AttachmentItem isUploading />}
          </Box>

          {formData.attachments.error &&
            renderError('Some attachments failed to upload')}
          {formData.attachments.invalidType &&
            renderError('Attachments cannot have other file types')}
          {formData.attachments.overSize &&
            renderError('Attachments cannot exceed 25 MB in size')}
          {isError &&
            isAttachmentsUploading &&
            renderError('Attachments are still being uploaded')}
        </FormControl>

        <Button
          disabled={isDisabled}
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
        >
          Submit
        </Button>
      </ModalContent>
    </Dialog>
  )
}

export default AskRefundModal
