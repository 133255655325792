import styled from 'styled-components'
import {Link} from 'react-router-dom'
import ReactHover from 'react-hover'

export const HeaderContainer3 = styled.div`
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  font-family: ${props => props.theme.font};
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  padding-bottom: 12px;
`

export const SubHeaderContainer3 = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f7f8f9;
  font-family: ${props => props.theme.font};
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  justify-content: space-between;
`

export const BackgroundImage = styled.img`
  width: 100%;
`

export const VendorProfileContainer = styled.div`
  padding-top: 245px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center;
  width: 83.4%;
`

export const VendorNameLabel = styled.label`
  color: #000;
  font-size: 22px;
  font-weight: bold;
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)};
`
export const VendorAverageRating = styled.label`
  color: #000;
  font-size: 125px;

  margin-top: ${props => (props.marginTop ? props.marginTop : 0)};
`
export const VendorTypeLabel = styled.label`
  color: #a9a8a8;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)};
`
export const VendorBioLabel = styled.label`
  color: #111111;
  margin-top: ${props => (props.marginTop ? props.marginTop : '1px')};
`

export const HeaderContainer = styled.div`
  height: ${props => (props.right ? '60.5px' : 'auto')};
  width: 100%;
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  background: #fff;
  font-family: ${props => props.theme.font};
  z-index: 1;

  padding: 10px 80.5px;
  box-sizing: border-box;
  justify-content: space-between;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 3;
`

export const SubHeaderContainer = styled.div`
  height: ${props => (props.right ? '67.5px' : '41px')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f8f9;
  padding: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  font-family: ${props => props.theme.font};
  border-radius: 3px;
  box-shadow: 0 4px 2px -2px black;
  border-style: solid;

  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;

  border-bottom-width: 1px;
  border-bottom-color: #a9a8a8;
`
export const SubHeaderContainerLabel = styled.label`
  margin: 20px;
  color: ${props => (props.active === true ? '#039be5' : '#a9a8a8')};
  font-size: 13px;
  cursor: pointer;
`

export const HeaderContainer2 = styled.div`
  height: '67.5px';
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    flex-direction: column;
  }
  background: #fff;
  font-family: ${props => props.theme.font};
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  padding: 12px 0px;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
`

export const LogoContainer = styled(Link)`
  //padding: 11.7px 0 0 67.5px;
  height: auto;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    //padding: 11.7px 0 0 0px;
  }

  display: flex;
  align-items: center;
`

export const OptionsContainer = styled.div`
  z-index: 1;
  display: flex;
  width: ${props => (props.right ? '20vw' : '50vw')};
  justify-content: space-between;
  @media (max-width: 500px) {
    /* flex-direction: column;
    align-items: center; */
    display: none;
  }
  align-items: center;
`

export const OptionLink = styled(Link)`
  padding-top: 11.7px;
  font-style: normal;
  font-stretch: normal;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.fontPrimaryColor};
  margin-left: 39.3px;
  font-size: 12px;
  font-weight: bolder;
  @media (max-width: 600px) {
    display: none;
  }
`

export const OptionLinkIcon = styled(Link)`
  // padding-top: 11.7px;
  font-style: normal;
  font-stretch: normal;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.fontPrimaryColor};
  // margin-left: 39.3px;
  font-size: 12px;
  font-weight: bolder;
  padding: ;
`
export const SearchForm = styled.input`
  background: #f7f8f9;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  font-size: 16px;
  text-indent: 20px;
  width: 100%;
  height: 38px;
  @media (max-width: 800px) {
    display: none;
  }
  ::placeholder {
    color: #a9a8a8;
  }
`

export const HoverCustom = styled(ReactHover)`
  z-index: 20;
`

export const Dropwdown = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 160px;
  width: 180px;
  margin-left: -115px;
  margin-right: 90px;
  border-radius: 3px;
  margin-top: 8px;
  align-self: center;
  padding: 3px;
  h3 {
    color: #039be5;
  }
`

export const DropwdownItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  font-size: 14px;
  text-align: center;
  width: 100%;
  color: #4a4a4a;
  align-self: center;
  justify-content: center;
  padding: 8px;
  &:hover {
    background: #f6f8f9;
  }
`
