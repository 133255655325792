import React, {useState} from 'react'

import {Toolbar, Button, IconButton, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import AddIcon from '@material-ui/icons/Add'

import {ShoppingContainer} from '../../../components/utils-components/VendorStyles'
import ListAddress from '../../../components/vendor-tabs/component-profile/ListAddress'
import DialogAddress from '../../../components/vendor-tabs/component-profile/modal-profile/DialogAddress'

import {withRouter} from 'react-router-dom'

const Address = props => {
  const [isOpen, setIsOpen] = useState(false)

  const [title, setTitle] = useState('')
  const [dataEdit, setDataEdit] = useState({})

  const handleData = (text, row) => {
    setTitle(text)
    setDataEdit(row)
    setIsOpen(true)
  }

  const handleToggleModal = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ShoppingContainer style={{background: '#f7f8f9'}}>
      <Toolbar disableGutters>
        <IconButton
          title="Go back to previous page"
          onClick={props.history.goBack}
          edge="start"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Address List</Typography>
        <Button
          onClick={() => {
            handleData('Add Address', [])
          }}
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          style={{marginLeft: 'auto'}}
        >
          Add New Address
        </Button>
      </Toolbar>

      <ListAddress handleData={handleData} />

      <DialogAddress
        open={isOpen}
        handleTogleModal={handleToggleModal}
        title={title}
        dataEdit={dataEdit}
      />
    </ShoppingContainer>
  )
}

export default withRouter(Address)
