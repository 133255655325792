import {generateRoutesFromConfigs} from '../utils/helpers'

import {HomePageConfig} from '../pages/homepage/HomePageConfig'
import {ProfilePageConfig} from '../pages/profile/ProfilePageConfig'
import {TransactionPageConfig} from '../pages/transaction/TransactionPageConfig'

const routeConfigs = [HomePageConfig, ProfilePageConfig, TransactionPageConfig]

const routes = [...generateRoutesFromConfigs(routeConfigs)]

export default routes
