import gql from 'graphql-tag'
export const GET_CATEGORIES = gql`
  {
    marketplace_product_categories {
      id
      code
      name
      fa_class
      thumbnail
      parent
      count_course
    }
  }
`
