import React from 'react'
import {
  TwoColumnTrasaction,
  WraperTrasanction,
  TransactionContainer,
  FlexBetween,
  TextGrey,
  TablePaginationCus,
  FlexColumnFirst,
} from '../../components/utils-components/VendorStyles'
import {IconButton, Grid, MenuItem, Button} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import useStyles from '../transaction/TransactionPageStyles'
import {Link} from 'react-router-dom'
import Search from '../../components/vendor-tabs/component-profile/Search'
import ListProduct from '../../components/vendor-tabs/card-product/ListProduct'
import ListVendor from '../../components/vendor-tabs/card-product/ListVendor'
import {
  TypeFilterContext,
  CategoryFilterContext,
} from '../../contexts/FilterContext'
import classNames from 'classnames'
import {
  SelectFilter,
  FormControlSearch,
} from '../../components/Filter/FilterStyles'
import {ROW_PAGENATION} from '../../utils/helpers'

function SearchGlobal(props) {
  const classes = useStyles()
  const [filter, setFilter] = React.useState({
    search: props.match.params.text !== 'null' ? props.match.params.text : null,
  })

  const TypeFilterContextValue = React.useContext(TypeFilterContext)
  const CategoryFilterContextValue = React.useContext(CategoryFilterContext)
  const [rowsPerPage, setRowsPerPage] = React.useState(ROW_PAGENATION[0])
  const [page, setPage] = React.useState(0)
  const [count, setCount] = React.useState(0)
  let regexUrl = ''
  if (props.location.pathname) {
    // regexUrl = props.location.pathname.match('[a-z0-9-]+')

    const regexPattern = /[a-z0-9-]+/gm
    regexUrl = props.location.pathname.match(regexPattern)
  }

  const handleChangeFilter = event => {
    setFilter({...filter, [event.target.name]: event.target.value})
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TransactionContainer>
      <TwoColumnTrasaction>
        <WraperTrasanction className={classes.paddingRight10}>
          <IconButton
            edge="end"
            onClick={props.history.goBack}
            aria-label="back"
            className={classes.paddingLeft0}
          >
            <ArrowBack />
          </IconButton>
        </WraperTrasanction>
        <Search type="home" filter={filter} setFilter={setFilter} />
      </TwoColumnTrasaction>
      <FlexColumnFirst className={classes.marginTop20}>
        <Button
          variant="contained"
          component={Link}
          to={`/Search/product/${
            filter.search !== '' ? filter.search : 'null'
          }`}
          className={
            props.match.params.type === 'product'
              ? classes.buttonActive
              : classes.buttonDefault
          }
        >
          Product
        </Button>
        <Button
          variant="contained"
          component={Link}
          to={`/Search/store/${filter.search !== '' ? filter.search : 'null'}`}
          className={classNames(
            classes.marginLeft10,
            props.match.params.type === 'store'
              ? classes.buttonActive
              : classes.buttonDefault
          )}
        >
          Vendor
        </Button>
      </FlexColumnFirst>
      <Grid countainer>
        <Grid Item sm={12} xs={12}>
          <FlexBetween className={classes.marginTop20}>
            <TextGrey>
              {count} Results for{' '}
              {filter.search === ''
                ? count === 1
                  ? 'other product'
                  : 'other products'
                : filter.search}
            </TextGrey>
            {regexUrl[1] === 'product' && (
              <FormControlSearch
                variant="outlined"
                className={classes.widthShopping}
              >
                <SelectFilter
                  variant="standard"
                  placeholder="Sort By"
                  disableUnderline
                  style={{backgroundColor: '#ffffff'}}
                  onChange={handleChangeFilter}
                  inputProps={{
                    name: 'orderBy',
                  }}
                  value={filter.orderBy}
                  displayEmpty
                  renderValue={
                    filter.orderBy !== undefined
                      ? undefined
                      : () => <MenuItem>Sort By</MenuItem>
                  }
                >
                  <MenuItem value="bs">Best Seller</MenuItem>
                  <MenuItem value="l">Latest</MenuItem>
                  <MenuItem value="o">Oldest</MenuItem>
                  <MenuItem value="az">A - Z</MenuItem>
                  <MenuItem value="za">Z - A</MenuItem>
                  <MenuItem value="priceLow">Price (Low - High)</MenuItem>
                  <MenuItem value="priceHigh">Price (High - Low)</MenuItem>
                </SelectFilter>
              </FormControlSearch>
            )}
          </FlexBetween>
          <div className={classes.marginTop15}>
            {regexUrl[1] === 'product' && (
              <ListProduct
                className={classes.marginTop30}
                // searchText={filter.search}

                rowsPerPage={rowsPerPage}
                page={page}
                filter={filter}
                typeFilterContext={TypeFilterContextValue}
                categoryFilterContext={CategoryFilterContextValue}
                setCount={setCount}
                type={regexUrl[2]}
              />
            )}
            {regexUrl[1] === 'store' && (
              <ListVendor
                className={classes.marginTop30}
                filter={filter}
                rowsPerPage={rowsPerPage}
                page={page}
                typeFilterContext={TypeFilterContextValue}
                categoryFilterContext={CategoryFilterContextValue}
                setCount={setCount}
                type={'see-all'}
              />
            )}
          </div>
        </Grid>
        <TablePaginationCus
          rowsPerPageOptions={ROW_PAGENATION}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </TransactionContainer>
  )
}

export default SearchGlobal
