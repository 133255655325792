import styled from 'styled-components'

import {
  makeStyles,
  withStyles,
  Tabs,
  Tab,
  Avatar,
  Button,
  FormControl,
  Typography,
  TextField,
  TablePagination,
} from '@material-ui/core'
import {fade} from '@material-ui/core/styles/colorManipulator'

import {Paper} from '../../components/paper/PaperStyles'
import Select from 'react-select'

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const FlexRowFrist = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
`

export const FlexRowFristCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
`

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const FlexRowEnd = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  align-content: flex-end;
`
export const FlexColumnCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const FlexColumn = styled.div`
  margin: 10px 35px;
  flex-direction: column;
  width: 90%;
`

export const FlexColumnFirst = styled.div`
  flex-direction: column;
  justify-content: flex-start;
`

export const TextDeepTealSmall = styled.div`
  font-family: Muli;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #014a62;
`

export const TextBlue = styled.div`
  font-family: Muli;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #47b5ec;
`

export const SelectContainer = styled.div``

export const StyledTabs = withStyles({
  root: {
    boxShadow: 'none !important',
    backgroundColor: '#fff',
  },
  indicator: {
    backgroundColor: '#039be5',
    height: '3px',
  },
})(Tabs)

export const TabsWrapper = styled.div`
  display: inline-block;
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
  position: sticky;
  top: 62px;
`

export const StyledTab = withStyles({
  root: {
    boxShadow: 'none',
    padding: '8px 24px',
    minWidth: '100px',
    fontSize: '14px',
    fontWeight: '500',
  },
})(Tab)

export const CommentAvatar = styled(Avatar)`
  width: 77.6px;
  height: 77.6px;
`

export const TitleHoverable = styled.div`
  display: flex;
  align-items: center;
  color: #014a62;
  width: max-content;
  cursor: pointer;
  padding: 8px 0 16px;
`

export const FormControlSearch = styled(FormControl)`
  display: flex;
  flex-direction: column;
  height: 49, 97px !important;
  border-radius: 3px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff !important;
  justify-content: ${props => props.justifyContent};
  padding: ${props => props.padding};
`

export const TitleVendor = styled(Typography)`
  height: 32px;
  font-family: muli;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  text-align: left;
  padding-bottom: 15px;
`

export const TextGrey = styled(Typography)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #a9a8a8;
  text-decoration: none;
`

export const TextBlackBold = styled(Typography)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #000000;
  text-decoration: none;
`

export const TextSmallGrey = styled.div`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #707070;
`

export const TextBlack = styled.div`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`

export const TextSmallBlack = styled.div`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000;
`

export const LitleTextBlack = styled.div`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`

export const TextDeepTeal = styled.div`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #014a62;
`
export const BigTextDeepTeal = styled.div`
  font-family: Muli;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #014a62;
`

export const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  margin-left: 12px;
  width: 68vw !important;
`

export const ShoppingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  padding: 25px 76px;
`

export const TitleTransactionDarkBlue = styled.h1`
  font-family: Muli;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 600;
  color: #014a62;
`

export const TitleTransactionLightBlue = styled.h1`
  font-family: Muli;
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #039be5;
`

export const TextLightBlueP = styled.p`
  font-family: Muli;
  margin: 5px 0px;
  font-size: 16px;
  font-weight: 200;
  color: #039be5;
`

export const LitleTextGrey = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #a9a8a8;
`

export const LitleTextDarkBlue = styled.span`
  font-family: Muli;
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #014a62;
`

export const LitleTextGreyBold = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  font-weight: 600;
  color: #a9a8a8;
`

export const TwoColumnTrasaction = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: space-between;
`

export const PaperTransaction = styled(Paper)`
  margin-top: 20px;
  padding: 25px;
`

export const PaperTransaction2 = styled(Paper)`
  margin-top: 20px;
  padding: 12px 25px;
`

export const LitleTextBlue = styled.span`
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  color: #039be5;
`

export const LitleTextWaterMelon = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-align: left;
  color: #ef4d5e;
`

export const ButtonRed = withStyles(theme => ({
  outlined: {
    color: theme.palette.error.main,
    border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,

    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: fade(theme.palette.error.main, 0.08),

      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  contained: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,

    '&:hover': {
      backgroundColor: theme.palette.error.dark,

      '@media (hover: none)': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
}))(Button)

export const WraperTrasanction = styled.div``

export const WraperTrasanction2 = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const BoxSpace = styled.div`
  margin-top: 22px !important;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
`

export const WhiteShadowButton = styled(Button)`
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: #014a62;
  font-size: 20px;
  font-weight: 600;
`

export const BigTextGrey = styled.span`
  font-family: Muli;
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #a9a8a8;
`

export const TextBlack1 = styled.span`
  font-family: Muli;
  font-size: 16px;
  font-weight: 200;
  color: #000000;
`

export const TextBlackP = styled.p`
  font-family: Muli;
  margin: 5px 0px;
  font-size: 16px;
  font-weight: 200;
  color: #000000;
`

export const TextGreen = styled.span`
  font-family: Muli;
  font-size: 16px;
  font-weight: 200;
  color: #4caf50;
`

export const PBigTextGrey = styled.p`
  font-family: Muli;
  font-size: 14px;
  margin-bottom: 0px;
  color: #a9a8a8;
`

export const BiggerTextDarkBlue = styled.span`
  font-family: Muli;
  font-size: ${props => props.size ?? '18px'};
  font-weight: 600;
  color: #014a62;
`

export const BiggestTextDarkBlue = styled.span`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  color: #014a62;
`

export const BiggerTextBlack = styled.p`
  font-family: Muli;
  font-size: 18px;
  margin-top: 0px;
  font-weight: 600;
  color: #000000;
`
export const BiggerTextBlack1 = styled.span`
  font-family: Muli;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
`

export const TitleTextBlack = styled.p`
  font-family: Muli;
  font-size: 25px;
  margin-top: 0px;
  font-weight: 900;
  color: #000000;
`

export const BigTextRed = styled.span`
  font-family: Muli;
  margin: 5px 0px;
  font-size: 16px;
  font-weight: 200;
  color: #ef4d5e;
`

export const JustifyRightTransaction = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: flex-end;
`

export const Sender = styled.img`
  width: 125px;
  height: 30px;
`

export const BankPayment = styled.img`
  width: 92.8px;
  height: 40.8px;
  cursor: pointer;
`

export const ShoppingImg = styled.img`
  object-fit: cover;
  flex-shrink: 0;
  width: 147px;
  height: 140.2px;
  border-radius: 5px;
  cursor: pointer;
`

export const IconContainer = styled.img``

export const BankImg = styled.img`
  width: 147px;
  height: 48px;
`

export const ProductImg = styled.img`
  object-fit: cover;
  width: 188px;
  height: 161px;
  border-radius: 5px;
  cursor: pointer;
`

export const LitleProductImg = styled.img`
  object-fit: cover;
  width: 88px;
  margin-left: 5px;
  margin-top: 5px;
  height: 73px;
  cursor: pointer;
  border-radius: 5px;
`

export const LitleRProductImg = styled.img`
  width: 74px;
  height: 75.2px;
  border-radius: 5px;
`

export const ImgCatcher = styled.div`
  background: #a9a8a8;
  display: grid;
  width: 88px;
  margin-top: 5px;
  margin-left: 5px;
  height: 73px;
  border-radius: 5px;
`

export const TextImgCatcher = styled.span`
  font-size: 26px;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  place-self: center;
`

export const LitleTextWhite = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
`

export const BankContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const LeaveNameChild = styled.div`
  display: flex;
  align-items: center;
`

export const StarsContainer = styled.div`
  display: flex;
  flexdirection: row;
  color: #039be5;
`
export const SelectCust = styled(Select)`
  height: 44.28px;
  width: 186.16px;
  border-radius: 5px;
`

export const FormSearch = styled(TextField)`
  border-radius: 5px !important;
  z-index: ${props => props.zIndex || '99 !important'};
  height: 44.28px !important;

  & .muiinputbase-input : {
    height: 44.28px !important;
  }
`

export const TextHelper = styled.p`
  color: #f44336;
  font-size: 0.75rem;
  margin-left: 25px;
`

export const TextRed = styled.div`
  color: #ef4d5e;
  font-size: 18px;
  font-family: Muli;
  font-weight: 700;
`

export const TablePaginationCus = styled(TablePagination)`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-top: 23px;
`

export const Box = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 5px;
`

export const BoxGrey = styled.div`
  background-color: #eff2f4;
  padding: 15px;
  border-radius: 5px;
`

export const TextComment = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: #4d4f5c;
`

export const PrintBox = styled(Typography)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #47b5ec;
  cursor: pointer;
  text-decoration: none;
`

export const TitleAlert = styled(Typography)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color};
`

export const ContentAlert = styled(Typography)`
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`

const useStyles = makeStyles(() => ({
  search: {
    height: '56px !important',
  },
  paddingTop8: {
    paddingTop: '8px !important',
  },
  marginY20: {
    margin: '20px 0px !important',
  },
  marginTop50: {
    marginTop: '50px !important',
  },
  marginTop15: {
    marginTop: '15px !important',
  },
  marginRight10: {
    marginRight: '10px !important',
  },
  width80P: {
    width: '80%',
  },
  width70P: {
    width: '70%',
  },
  width60P: {
    width: '60%',
  },
  width50P: {
    width: '50%',
  },
  width40P: {
    width: '40%',
  },
  width30P: {
    width: '30%',
  },
  width15P: {
    width: '15%',
  },
  width10P: {
    width: '10%',
  },
  width5P: {
    width: '5%',
  },
  fontWeightNormal: {
    fontWeight: 'normal !important',
  },
  fontWeight600: {
    fontWeight: '600 !important',
  },
  textAlignRight: {
    textAlign: 'right !important',
  },
}))

export default useStyles
