import {makeStyles} from '@material-ui/styles'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  marginTop75: {
    marginTop: '75px',
  },
  marginTop0: {
    marginTop: '0px',
  },
  marginTop50: {
    marginTop: '50px',
  },
  marginTop30: {
    marginTop: '30px !important',
  },
  marginTop20: {
    marginTop: '20px',
  },
  marginTop15: {
    marginTop: '15px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginTop8: {
    marginTop: '8px !important',
  },
  marginTop5: {
    marginTop: '5px',
  },
  marginBottom0: {
    marginBottom: '0px !important',
  },
  marginBottom5: {
    marginBottom: '5px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  marginLeft0: {
    marginLeft: '0px !important',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  marginLeft10: {
    marginLeft: '10px',
  },
  marginLeft20: {
    marginLeft: '20px',
  },
  marginRight25: {
    marginRight: '25px',
  },

  marginRight10: {
    marginRight: '10px',
  },
  margin0: {
    margin: '0px !important',
  },

  padding0: {
    padding: '0px !important',
  },
  padding10: {
    padding: '10px !important',
  },
  paddingFooter: {
    padding: '0rem 1rem 0rem 1rem',
  },
  paddingLeft0: {
    paddingLeft: '0px !important',
  },
  paddingLeft5: {
    paddingLeft: '5px !important',
  },
  paddingLeftRight10: {
    paddingLeft: '10px 10px !important',
  },
  paddingLeft10: {
    paddingLeft: '10px !important',
  },
  paddingBottom15: {
    paddingBottom: '15px !important',
  },
  paddingBottom40: {
    paddingBottom: '40px !important',
  },
  paddingBottom10: {
    paddingBottom: '10px !important',
  },
  paddingContent: {
    padding: '1rem 2rem 1rem 2rem',
  },
  paddingTop10: {
    paddingTop: '10px',
  },
  paddingTopBot10: {
    padding: '10px 0px',
  },
  paymentMethod: {
    marginTop: '15px',
    width: '11%',
  },
  billingNumber: {
    marginTop: '15px',
    width: '60%',
  },
  width200: {
    width: '200px',
  },
  widthImg: {
    width: '25%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  widthCheck: {
    width: '10%',
  },
  widthProuctReview: {
    width: '15%',
  },
  widthShopping: {
    width: '25% !important',
  },
  widthTotalShopping: {
    width: '30%',
  },
  marginTop11: {
    marginTop: '11px !important',
  },
  widthShoppingCard: {
    width: '60%',
  },
  widthShoppingOuterCard: {
    width: '64% !important',
  },
  widthHeaderLogo: {
    width: '70%',
  },
  widthContent: {
    width: '75%',
  },
  widthList: {
    width: '80%',
  },
  widthReviewContent: {
    width: '85%',
  },
  maxWidth70: {
    maxWidth: '70%',
  },
  textAligmentRight: {
    textAlign: 'right!important',
  },
  displayBlock: {
    display: 'block',
    marginTop: '10px',
  },
  displayBlocks: {
    display: 'block',
  },
  displayInline: {
    display: 'inline !important',
  },
  textAdd: {
    width: 20,
    paddingTop: '10px',
  },
  widthModalAwaiting: {
    width: '650px',
  },
  widthModalPurchaseDetail: {
    width: '705px',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  borderRight: {
    borderRight: '2px solid rgba(0, 0, 0, .125)',
    marginBottom: '5px',
  },
  borderBottom: {
    borderBottom: '2px solid rgba(0, 0, 0, .125)',
  },
  borderLightBottom: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
  fontSize50: {
    fontSize: '50px !important',
  },
  fontSize30: {
    fontSize: '30px !important',
  },
  backgroundColorGrey: {
    backgroundColor: '#e5e5e5',
    marginTop: '3px',
  },
  backgroundColorLightBlue: {
    color: '#007fb2;',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  alignStart: {
    alignSelf: 'flex-start',
  },
  textFieldIncDec: {
    borderRadius: 0,
    height: '29px',

    backgroundColor: '#eff2f4',
    width: 35,
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  textUpper: {
    textTransform: 'uppercase',
  },
  root: {
    width: '100%',
    marginTop: '30px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  iconFilledRating: {
    color: '#007fb2',
  },
  minHeightContent: {
    minHeight: '364px',
  },
  fullWidth: {
    width: '100%',
  },
  marginBottom1: {
    marginBottom: '1px',
  },
  marginTopM_9: {
    marginTop: '-9px',
  },
  paddingRight23: {
    paddingRight: '23px',
  },
  paddingRight10: {
    paddingRight: '10px',
  },
  noShadow: {
    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.16) !important',
  },
  buttonDefault: {
    width: '140px',
    height: '45px',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    color: '#a9a8a8',
  },
  buttonActive: {
    width: '140px',
    height: '45px',
    borderRadius: '5px',
    backgroundColor: '#014a62',
    color: '#ffffff',
  },
  bgGrey: {
    backgroundColor: '#eff2f4 !important',
  },
}))

export default useStyles

export const TextAddress = styled.div`
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-000000);
`
