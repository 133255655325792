import React, {useState} from 'react'
import {useApolloClient, useLazyQuery} from '@apollo/react-hooks'
import {withRouter} from 'react-router-dom'

import classnames from 'classnames'
import {Box, Button} from '@material-ui/core'

import {
  PaperTransaction,
  BankContainer,
  LeaveNameChild,
  IconContainer,
  BiggerTextBlack1,
  ShoppingImg,
  TextBlackBold,
  TextBlue,
  LitleTextWhite,
  LitleTextBlack,
  LitleTextDarkBlue,
  FlexRowFrist,
  LitleTextGreyBold,
} from '../../../utils-components/VendorStyles'
import ReviewModal from './ReviewModal'
import useStyles from '../../../../pages/transaction/TransactionPageStyles'

import {convertToRupiah} from '../../../../utils/helpers'
import {GetTypeBook, GET_REVIEW_INVOICE} from '../../../../graphql/queries'
import {HC_URL, LEARNING_URL, USER_ID} from '../../../../utils/globals'

const DetailProduct = (props) => {
  const {data, type, setjumlahProduct, setTotalPrice, setWeight, status} = props

  const client = useApolloClient()
  const classes = useStyles()
  const [product, setProducts] = React.useState({
    product: '',
    puchaseId: '',
    vendorId: '',
  })

  const [getType, {data: dataTypeBook}] = useLazyQuery(GetTypeBook)
  const [typeBook, setType] = useState(null)
  const [idsn, setIdsn] = useState(null)

  const [openReview, setOpenReview] = React.useState(false)
  const [dataReview, setReview] = React.useState([])
  const [allData1, setAllData] = React.useState([])
  const [loadEffect, setLoadEffect] = React.useState(true)

  const [vendorName, setVendorName] = React.useState('')

  const puchaseId = data
    ? type === 'awaiting'
      ? data.global_invoices[0].id
      : data.id
    : ''

  const vendorId = data
    ? type === 'awaiting'
      ? data.global_invoices[0].global_wallet_vendor
        ? data.global_invoices[0].global_wallet_vendor.company_profiles
            .length >= 1
          ? data.global_invoices[0].global_wallet_vendor.company_profiles[0].id
          : ''
        : ''
      : data.global_wallet_vendor
      ? data.global_wallet_vendor.company_profiles.length >= 1
        ? data.global_wallet_vendor.company_profiles[0].id
        : ''
      : ''
    : ''

  let total_weight = 0
  let total_priceProduct = 0
  let total_product = 0

  React.useEffect(() => {
    const cekReview = async (product) => {
      if (status === 'completed') {
        try {
          const {loading, data: review} = await client.query({
            query: GET_REVIEW_INVOICE,
            variables: {
              user: USER_ID,
              product,
              invoice: data.id,
            },
          })
          if (!loading) {
            return review.marketplace_product_reviews_aggregate.aggregate.count
          }
        } catch (error) {
          return <div>{error}</div>
        }
      }
    }

    if (loadEffect) {
      setAllData([])
      setReview([])
      const allData = []
      if (type === 'awaiting') {
        data.global_invoices.forEach((res) => {
          allData.push({
            vendorName: res.global_wallet_vendor
              ? res.global_wallet_vendor.company_profiles.length >= 1
                ? res.global_wallet_vendor.company_profiles[0].brand_name
                : res.global_wallet_vendor.global_users?.[0].name
              : null,
            product: res.products,
          })
        })
      } else {
        allData.push({
          vendorName: data.global_wallet_vendor
            ? data.global_wallet_vendor.company_profiles.length >= 1
              ? data.global_wallet_vendor.company_profiles[0].brand_name
              : data.global_wallet_vendor.global_users?.[0].name
            : '',
          product: data.products,
        })

        data.products.forEach((res) => {
          cekReview(res.item_table_id).then((review) => {
            const reviewDone = []
            if (review > 0) {
              reviewDone.push(res.item_table_id)
              setReview(reviewDone)
            }
          })
        })
      }
      setAllData(allData)
      setLoadEffect(false)
    }
  }, [data, loadEffect])

  React.useEffect(() => {
    if (typeBook === 'academy_books' && dataTypeBook) {
      // console.log(dataTypeBook)
      // console.log(dataTypeBook.academy_books[0].book_type)
      let type_link = 'learning_ebook'
      if (dataTypeBook.academy_books[0].book_type === 3) {
        type_link = 'learning_audiobook'
      }
      window.open(
        `${LEARNING_URL}/${type_link}/${idsn}`,
        '_blank',
        'noopener,noreferrer'
      )
      setType(null)
    }
  }, [typeBook, dataTypeBook])
  const handleReview = (product, name) => {
    setVendorName(name)
    setProducts({
      product,
      puchaseId,
      vendorId,
    })
    setOpenReview(true)
  }

  const handleReviewClose = () => {
    setOpenReview(false)
  }

  const buttonReview = (product, res, res1) => {
    if (status === 'completed') {
      if (dataReview.filter((val) => val === product).length === 0) {
        return (
          <Button
            onClick={() => handleReview(res, res1.vendorName)}
            className={classes.marginTop5}
            variant="outlined"
            color="primary"
          >
            <LitleTextDarkBlue>Review</LitleTextDarkBlue>
          </Button>
        )
      }
    }
  }

  const redirectLink = (type, id) => {
    setType(type)
    setIdsn(id)
    if (type === 'academy_courses') {
      window.open(
        `${LEARNING_URL}/learning_my_course/${id}`,
        '_blank',
        'noopener,noreferrer'
      )
    } else if (type === 'academy_books') {
      const isbn = []
      isbn.push(id)
      getType({
        variables: {
          isbn,
        },
      })
    } else if (type === 'classrooms') {
      window.open(
        `${HC_URL}/social-learning/${id}/discussion`,
        '_blank',
        'noopener,noreferrer'
      )
    } else {
      props.history.push('/profile/detail/produk/' + id)
    }
  }

  return (
    <>
      {allData1 &&
        allData1.map((res1, key) => {
          return (
            <PaperTransaction className={classes.padding10} key={key}>
              <BankContainer
                className={classnames(
                  classes.paddingContent,
                  classes.padding10
                )}
              >
                <LeaveNameChild>
                  <IconContainer
                    src={require('../../../../assets/slicing/etalase.svg')}
                  />
                  <BiggerTextBlack1
                    className={classnames(classes.marginLeft10)}
                  >
                    {res1.vendorName}
                  </BiggerTextBlack1>
                </LeaveNameChild>
              </BankContainer>
              {res1.product &&
                res1.product.map((res, key) => {
                  total_weight = total_weight + res.qty * res.weight
                  total_priceProduct =
                    total_priceProduct + res.qty * res.single_price
                  total_product = total_product + 1
                  setTotalPrice(total_priceProduct)
                  setWeight(total_weight)
                  setjumlahProduct(total_product)
                  return (
                    <Box display="flex" key={key}>
                      <ShoppingImg
                        src={`${res.thumbnail}`}
                        onClick={() => {
                          redirectLink(res.item_table, res.item_table_id)
                        }}
                      />
                      <Box flexGrow={1} mx={1} my={0.75}>
                        <Box display="flex" justifyContent="space-between">
                          <TextBlackBold>{res.itemname}</TextBlackBold>
                          <TextBlue className={classes.margin0}>
                            {convertToRupiah(res.single_price * res.qty)}
                          </TextBlue>
                        </Box>
                        <LitleTextBlack>{`${res.qty} (${res.weight} Gram)`}</LitleTextBlack>
                        <LitleTextBlack>
                          @ {convertToRupiah(res.single_price)}
                        </LitleTextBlack>
                        <FlexRowFrist className={classes.marginTop10}>
                          <LitleTextGreyBold>Notes</LitleTextGreyBold>
                        </FlexRowFrist>
                        <FlexRowFrist className={classes.marginTopM_9}>
                          <LitleTextBlack>{res.note}</LitleTextBlack>
                        </FlexRowFrist>
                      </Box>
                      {res.item_table === 'marketplace_products' && (
                        <Box
                          display="grid"
                          gridGap={8}
                          alignContent="baseline"
                          flexShrink={0}
                        >
                          <Button
                            onClick={() => {
                              redirectLink(res.item_table, res.item_table_id)
                            }}
                            variant="contained"
                            color="primary"
                          >
                            <LitleTextWhite>Buy Again</LitleTextWhite>
                          </Button>
                          {buttonReview(res.item_table_id, res, res1)}
                        </Box>
                      )}
                    </Box>
                  )
                })}
            </PaperTransaction>
          )
        })}

      <ReviewModal
        product={product}
        vendorName={vendorName}
        puchaseId={data.id}
        displayCode={data.code}
        open={openReview}
        close={handleReviewClose}
        setReview={setReview}
        dataReview={dataReview}
      />
    </>
  )
}

export default withRouter(DetailProduct)
