import React from 'react'
import {
  FlexRowFrist,
  FlexColumnFirst,
  TextBlackBold,
  TextGrey,
  TitleTransactionDarkBlue,
  LitleTextGrey,
  LitleTextWaterMelon,
  TwoColumnTrasaction,
} from '../../utils-components/VendorStyles'
import DynamicPhoto from '../../dynamic-photo/DynamicPhoto'
import {convertToRupiah, getLongDate} from '../../../utils/helpers'
import useStyles from '../../../pages/transaction/TransactionPageStyles'

const DetailTransaction = ({res, date_expired}) => {
  const classes = useStyles()
  const dataProduct = []
  res.global_invoices.forEach((row) => {
    row.products.forEach((row1) => {
      dataProduct.push(row1)
    })
  })

  return (
    <FlexRowFrist>
      <div
        style={{
          width: '207px',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <DynamicPhoto productData={dataProduct} />
      </div>
      <TwoColumnTrasaction className={classes.marginLeft20}>
        <FlexColumnFirst>
          <TextBlackBold>{dataProduct.length} Items in Total</TextBlackBold>
          <TextGrey className={classes.marginTop8}>Total</TextGrey>
          <TitleTransactionDarkBlue>
            {convertToRupiah(res.final_price)}
          </TitleTransactionDarkBlue>
          <FlexRowFrist style={{justifyContent: 'center'}}>
            <LitleTextGrey>Pay Before </LitleTextGrey>
            <LitleTextWaterMelon className={classes.paddingLeft5}>
              {getLongDate(date_expired)}
            </LitleTextWaterMelon>
          </FlexRowFrist>
        </FlexColumnFirst>
      </TwoColumnTrasaction>
    </FlexRowFrist>
  )
}

export default DetailTransaction
