import React, {useState} from 'react'
import styled from 'styled-components'
import UserLoggedIn from './user-logged-in/UserLoggedInComponent'
import NotFound from '../pages/error/NotFoundComponent'
import {
  VacancyListsMainArea,
  LeftSideWrapper,
  MainWrapper,
} from './job-portal/JobPortalStyles'
import {
  TypeFilterContext,
  CategoryFilterContext,
} from '../contexts/FilterContext'

const AppWrapper = styled.div`
  font-family: muli;
`

const AppWrapperDetail = styled.div`
  font-family: muli;
  width: 92vw;
`

const Main = props => {
  const WrapperComponent = props.children
  const [typeFilter, setTypeFilter] = useState([])
  const [categoryFilter, setCategoryFilter] = useState([])
  React.useEffect(() => {
    const config = props.settings.layout.config
    props.setHide({
      ...props.hide,
      tabBar: config.tabbar !== undefined ? !config.tabbar : false,
      header: config.header !== undefined ? !config.header : false,
      footer: config.footer !== undefined ? !config.footer : false,
    })
  }, [props.settings.layout.config])

  if (props.settings) {
    const config = props.settings.layout.config

    return (
      <>
        <TypeFilterContext.Provider value={typeFilter}>
          <CategoryFilterContext.Provider value={categoryFilter}>
            {config.sidebar ? (
              <MainWrapper>
                {/* <VacancyListsMainArea> */}
                <LeftSideWrapper>
                  <UserLoggedIn
                    setTypeFilter={setTypeFilter}
                    setCategoryFilter={setCategoryFilter}
                  />
                </LeftSideWrapper>
                <AppWrapper>
                  <WrapperComponent {...props} />
                </AppWrapper>
                {/* </VacancyListsMainArea> */}
              </MainWrapper>
            ) : (
              <VacancyListsMainArea>
                <AppWrapperDetail>
                  <WrapperComponent {...props} />
                </AppWrapperDetail>
              </VacancyListsMainArea>
            )}
          </CategoryFilterContext.Provider>
        </TypeFilterContext.Provider>
      </>
    )
  } else {
    return <NotFound />
  }
}

export default Main
