import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Toolbar,
  Card,
  Divider,
  Button,
  Typography,
  Tabs,
  Tab,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Snackbar,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import {Filter} from '@worklifebeyond/wlb-utils-components'
import Listing from '../../../../components/utils-components/Listing'
import NoDataListFree from '../../../../components/vendor-tabs/NoDataListFree'

import {ClaimActivityItem, ClaimHistoryItem} from '../ClaimList'
import ClaimSubmissionModal from './ClaimSubmissionModal'
import ClaimHistoryModal from './ClaimHistoryModal'

import {USER_ID} from '../../../../utils/globals'
import {
  GET_CLAIM_ACTIVITIES,
  GET_CLAIM_HISTORY,
} from '../../../../graphql/queries/profile/getClaims.query'
import {
  ACTIVITY_FILTER_CONFIG,
  getActivityFilterVariables,
} from './activityFilterConfig'
import {
  HISTORY_FILTER_CONFIG,
  getHistoryFilterVariables,
} from './historyFilterConfig'
import AlertConfirm from '../../../../components/vendor-tabs/alert/AlertConfirm'
import {SET_CLAIM_STATUS} from '../../../../graphql/mutations/profile/requestClaim.mutation'

const useStyles = makeStyles(theme => ({
  filters: {
    justifyContent: 'space-between',
  },
  searchContainer: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  select: {
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '0.875rem',
    padding: '8.75px 8px',
    borderRadius: 4,

    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: 4,
    },
  },
  list: {
    padding: 0,
    gridGap: 0,
    marginTop: theme.spacing(1.5),
  },
}))

const SORT_ASC = 'asc'
const SORT_DESC = 'desc'

function ClaimHistory() {
  const styles = useStyles()

  const [tab, setTab] = React.useState(0)
  const [filter, setFilter] = React.useState(null)
  const [sort, setSort] = React.useState(SORT_DESC)
  const [search, setSearch] = React.useState('')

  const activeFilterList =
    tab === 0 ? ACTIVITY_FILTER_CONFIG : HISTORY_FILTER_CONFIG

  // eslint-disable-next-line no-unused-vars
  const handleTabChange = (event, tab) => {
    setTab(tab)
    setSort(SORT_DESC)
    setSearch('')
    setFilter(null)
  }

  const handleSortChange = event => {
    setSort(event.target.value)
  }

  const handleSearchChange = event => {
    setSearch(event.target.value)
  }

  return (
    <Card>
      <Toolbar>
        <Typography variant="subtitle1" style={{fontWeight: 700}}>
          Claim Limit Detail
        </Typography>
      </Toolbar>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="Claim Activity" value={0} />
        <Tab label="Claim History" value={1} />
      </Tabs>
      <Divider />
      <Toolbar className={styles.filters}>
        <Select
          value={sort}
          onChange={handleSortChange}
          classes={{select: styles.select}}
          disableUnderline
        >
          <MenuItem value={SORT_DESC}>Latest</MenuItem>
          <MenuItem value={SORT_ASC}>Oldest</MenuItem>
        </Select>

        <Filter
          key={activeFilterList}
          listFilter={activeFilterList}
          includeExclude={false}
          onApply={setFilter}
        >
          <Button>Filter</Button>
        </Filter>
      </Toolbar>
      <div className={styles.searchContainer}>
        <OutlinedInput
          value={search}
          onChange={handleSearchChange}
          placeholder="Search..."
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </div>

      <HistoryList
        key={tab}
        type={tab}
        search={search}
        filter={filter}
        sort={sort}
      />
    </Card>
  )
}

export default ClaimHistory

const INITIAL_PAGINATION = {
  page: 0,
  limit: 20,
}

const INITIAL_SNACKBAR = {
  open: false,
  message: '',
}

const SNACKBAR_ANCHOR_CONFIG = {
  vertical: 'bottom',
  horizontal: 'left',
}

function HistoryList(props) {
  const {type, search, filter, sort} = props

  const styles = useStyles()

  const [selected, setSelected] = React.useState(null)
  const [openDetails, setOpenDetails] = React.useState(false)
  const [openCancel, setOpenCancel] = React.useState(false)

  const [snackbarState, setSnackbarState] = React.useState(INITIAL_SNACKBAR)

  const [pagination, setPagination] = React.useState(INITIAL_PAGINATION)

  const filterVariables = React.useMemo(
    () =>
      type === 0
        ? getActivityFilterVariables(filter)
        : getHistoryFilterVariables(filter),
    [type, filter]
  )

  const client = useApolloClient()

  const {data, loading, error} = useQuery(
    type === 0 ? GET_CLAIM_ACTIVITIES : GET_CLAIM_HISTORY,
    {
      variables: {
        userID: USER_ID,
        search: `%${search}%`,
        orderBy: sort,
        offset: pagination.page * pagination.limit,
        limit: pagination.limit,
        ...filterVariables,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  // eslint-disable-next-line no-unused-vars
  const handleChangePage = (event, page) => {
    setPagination({...pagination, page: page})
  }

  const handleChangeLimit = event => {
    setPagination({page: 0, limit: event.target.value})
  }

  const handleDetailsOpen = id => {
    setSelected(id)
    setOpenDetails(true)
  }

  const handleDetailsClose = () => {
    setOpenDetails(false)
  }

  const handleCancelOpen = () => {
    setOpenCancel(true)
  }

  const handleCancelClose = () => {
    setOpenCancel(false)
  }

  const handleCancelConfirm = () => {
    setOpenCancel(false)
    setOpenDetails(false)
    setSelected(null)

    const promise = client.mutate({
      mutation: SET_CLAIM_STATUS,
      variables: {
        requestId: selected,
        status: 'cancelled',
      },
    })

    promise.then(
      result => {
        if (result.errors) {
          return Promise.reject(result.errors)
        }

        setSnackbarState({
          open: true,
          message: 'Claim cancelled successfully',
        })
      },
      () => {
        setSnackbarState({
          open: true,
          message: 'Failed to cancel claim',
        })
      }
    )
  }

  const handleSnackbarClose = () => {
    setSnackbarState({...snackbarState, open: false})
  }

  React.useLayoutEffect(() => {
    if (pagination.page !== 0) {
      handleChangePage(null, 0)
    }
  }, [search, filter])

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  return (
    <>
      {!loading && !data?.total.aggregate.count ? (
        <NoDataListFree
          message1="Sorry, No List"
          message2="You haven't made any claims yet"
        />
      ) : (
        <Listing
          component={type === 0 ? ClaimActivityItem : ClaimHistoryItem}
          onDetailsClick={handleDetailsOpen}
          data={data?.list}
          totalCount={data?.total.aggregate.count}
          loading={loading}
          page={pagination.page}
          limit={pagination.limit}
          onChangePage={handleChangePage}
          onChangeLimit={handleChangeLimit}
          classes={{
            list: type === 0 && styles.list,
          }}
        />
      )}

      <ClaimSubmissionModal
        id={type === 0 && selected}
        open={type === 0 && openDetails}
        onClose={handleDetailsClose}
        onClaimCancel={handleCancelOpen}
      />
      <ClaimHistoryModal
        id={type === 1 && selected}
        open={type === 1 && openDetails}
        onClose={handleDetailsClose}
      />

      <AlertConfirm
        open={openCancel}
        title="Cancel Claim Request?"
        message="Are you sure you want to cancel this claim request?"
        onClose={handleCancelClose}
        onConfirm={handleCancelConfirm}
      />

      <Snackbar
        open={snackbarState.open}
        message={snackbarState.message}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={SNACKBAR_ANCHOR_CONFIG}
      />
    </>
  )
}
