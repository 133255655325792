import {Button, Dialog, Divider} from '@material-ui/core'
import React from 'react'
import {ModalWrapper} from './ModalConfirmation.style'

export default function ModalConfirmation({
  open,
  title,
  content,
  onCancel,
  onSubmit,
  textPrimary = '',
  buttonSize = 'small',
}) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <ModalWrapper>
        <div className="title">{title}</div>
        <Divider />
        <div
          className="content"
          style={{padding: '20px 20px 24px 20px', fontSize: '12px'}}
        >
          {content}
        </div>
        <div className="action">
          <Button
            className="btn-wlb-cancel"
            onClick={onCancel}
            style={{marginRight: 10}}
          >
            Cancel
          </Button>
          <Button
            className={`btn-wlb-${buttonSize}`}
            onClick={onSubmit}
            style={{backgroundColor: '#014a62', color: 'white'}}
            type="primary"
          >
            {textPrimary}
          </Button>
        </div>
      </ModalWrapper>
    </Dialog>
  )
}
