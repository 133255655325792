import gql from 'graphql-tag'

export const GET_DETAIL_VENDOR = gql`
  query($id: uuid) {
    company_profiles(where: {id: {_eq: $id}}) {
      id
      brand_name
      legal_name
      logo
      profile_field
      global_company_industry_type {
        id
        name
      }
      company_addresses {
        address
        office_name
        global_province {
          id
          name
        }
        global_city {
          id
          name
        }
      }
    }
    product_sold: global_invoice_items_aggregate(
      where: {
        global_invoice: {
          status: {_nin: ["pending", "cancelled"]}
          global_wallet_vendor: {foreign_owner_id: {_eq: $id}}
        }
        type: {_neq: "courier"}
      }
    ) {
      aggregate {
        sum {
          qty
        }
      }
    }

    rating: marketplace_product_reviews_aggregate(
      where: {vendor_id: {_eq: $id}}
    ) {
      aggregate {
        count
        avg {
          value
        }
      }
    }
  }
`
