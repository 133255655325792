import React from 'react'

import {LabelSearchDate, FlexBetween, Psm} from './FilterStyles'

import Grid from '@material-ui/core/Grid'

import {TextField} from '@material-ui/core'
import {formatNominal} from '../../utils/helpers'

function TabsPrice(props) {
  const {
    priceFrom,
    priceTo,
    ChangePriceF,
    ChangePriceT,
    handleResetPrice,
  } = props

  return (
    <Grid container>
      <Grid item sm={12}>
        <FlexBetween style={{fontSize: '12px'}}>
          Pick a Price
          <Psm
            style={{cursor: 'pointer', fontSize: '12px'}}
            onClick={handleResetPrice}
          >
            Reset
          </Psm>
        </FlexBetween>
      </Grid>
      <Grid item sm={12}>
        <LabelSearchDate>From</LabelSearchDate>
        <TextField
          fullWidth
          inputVariant="outlined"
          inputProps={{pattern: '[0-9]'}}
          value={priceFrom ? formatNominal(priceFrom) : priceFrom}
          onChange={ChangePriceF}
          name="totalFrom"
        />
      </Grid>
      <Grid item sm={12}>
        <LabelSearchDate>To</LabelSearchDate>
        <TextField
          fullWidth
          inputVariant="outlined"
          inputProps={{pattern: '[0-9]'}}
          value={priceTo ? formatNominal(priceTo) : priceTo}
          onChange={ChangePriceT}
          name="totalTo"
        />
      </Grid>
    </Grid>
  )
}

export default TabsPrice
