import React from 'react'
import Rating from '@material-ui/lab/Rating'
import {withStyles} from '@material-ui/core/styles'
import {StarsContainer} from '../utils-components/VendorStyles'
import useStyles from '../../pages/transaction/TransactionPageStyles'

const RatingStars = props => {
  const {data, changeValueStarts, size, disabled} = props
  const classes = useStyles()
  const StyledRating = withStyles({
    iconFilled: {
      color: '#007fb2',
    },
  })(Rating)

  return (
    <StarsContainer>
      <StyledRating
        name="simple-controlled"
        value={data}
        size={size}
        className={classes.iconFilledRating}
        onChange={(event, newValue) => {
          event.preventDefault()
          if (!disabled) {
            changeValueStarts(newValue)
          }
        }}
        disabled={disabled}
      />
    </StarsContainer>
  )
}

export default RatingStars
