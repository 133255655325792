import {makeStyles} from '@material-ui/core'

export const useCartStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) 360px',
    gridGap: 24,

    '& .MuiLink-button': {
      fontFamily: 'inherit',
      fontSize: 14,
    },
  },
  subtitle: {
    fontSize: 16,
  },

  list: {
    display: 'grid',
    alignContent: 'baseline',
    gridGap: 16,
  },

  checkbox: {
    alignSelf: 'center',
  },

  header: {
    height: 42,
    display: 'flex',
    alignItems: 'center',
    padding: 8,
  },
  headerTitle: {
    marginLeft: 8,
  },
  headerIcon: {
    color: theme.palette.grey[600],
    marginLeft: 8,
  },
  headerName: {
    color: theme.palette.text.primary,
    fontSize: 12,
    textDecoration: 'none',
    fontWeight: 700,
    margin: 0,
  },
  headerBlurb: {
    color: theme.palette.grey[600],
    fontSize: 12,
    margin: 0,
  },

  item: {
    display: 'flex',
    padding: `8px 16px 16px 8px`,
  },
  itemMain: {
    flexGrow: 1,
  },
  itemEnd: {
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  productImage: {
    height: 124,
    width: 124,
    objectFit: 'cover',
    marginLeft: 8,
    marginRight: 16,
    borderRadius: 4,
  },
  productName: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    display: 'block',
    fontSize: 14,
    fontWeight: 700,
    margin: 0,
  },
  productPrice: {
    fontSize: 14,
    margin: `8px 0 0`,
  },

  controls: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  qty: {
    textAlign: 'center',
    width: '3ch',
    padding: `0 8px`,
  },
  qtyButton: {
    boxShadow: 'none',
    height: 20,
    width: 20,
    minWidth: 0,
    padding: 0,

    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 14,
    },
  },
  removeButton: {
    marginRight: 8,
  },

  noteEmpty: {
    marginTop: 16,
  },
  noteHeader: {
    color: theme.palette.grey[600],
    fontSize: 14,
    margin: `16px 0 8px`,
  },
  noteButton: {
    color: theme.palette.secondary.main,
    fontSize: 14,
  },
  noteEdit: {
    position: 'relative',
    top: -2,
    marginLeft: 8,
  },
  noteBody: {
    fontSize: 14,
    margin: `-8px 0 0`,
  },

  footer: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
    padding: 16,
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  card: {
    display: 'grid',
    gridGap: 16,
    padding: 24,
  },
  cardTitle: {
    fontSize: 14,
    margin: 0,
  },

  table: {
    borderSpacing: `0 4px`,
    margin: `-4px 0`,

    '& th': {
      color: theme.palette.grey[600],
      fontSize: 14,
      lineHeight: `26px`,
      textAlign: 'left',
      verticalAlign: 'top',
    },
    '& td': {
      fontSize: 14,
      textAlign: 'right',
    },
  },

  address: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
  },
  addressName: {
    fontSize: 16,
    fontWeight: 700,
    margin: 0,
  },
  addressRecipient: {
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: 400,
    margin: `0 0 8px 0`,
  },
  addressDetail: {
    fontSize: 14,
    margin: 0,
  },

  courier: {
    display: 'flex',
    alignItems: 'center',
  },
  courierImage: {
    height: 36,
    width: 80,
    objectFit: 'contain',
    marginRight: 16,
  },
  courierDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
