import React, {useState} from 'react'
import {
  ButtonGroup,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  MenuItem,
} from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import {
  FlexRowFrist,
  FlexColumnFirst,
  BiggerTextBlack,
  Sender,
  SelectCust,
  FlexBetween,
} from '../../../utils-components/VendorStyles'

import {TableCellCustom, StyledTextField} from '../ProductStyles'

// grapql
import {useQuery, useApolloClient} from '@apollo/react-hooks'
import {
  GET_COURIER_PROVIDERS,
  GET_VENDOR_COURIERS,
} from '../../../../graphql/queries/profile/getListShipping'
import {CalculateCost} from '../../../../graphql/queries/getCalculateCost.query'

import {
  LIST_PROVINCE,
  LIST_CITY,
} from '../../../../graphql/queries/getPlacement.query'
import {convertToRupiah} from '../../../../utils/helpers'

// import {convertToRupiah} from '../../../../utils/helpers'

// const Root = makeStyles({
//   ColorBlue: {
//     color: '#33a0ff',
//   },
// })
function Shipping({id_vendor, weight, district, price}) {
  const client = useApolloClient()
  const [calculate, setCalculate] = useState({})
  const [zipCode, setZipCode] = useState('')
  const [amount, setAmount] = useState(1)
  const [disSelect, setDisSelect] = useState(true)
  const optionsProv = [
    {
      value: '',
      label: '',
    },
  ]
  const [optionsCity, setOptionsCity] = useState([
    {
      value: '',
      label: '',
    },
  ])

  const [prov, setProv] = useState([
    {
      value: '',
      label: '',
    },
  ])
  const [city, setCity] = useState([
    {
      value: '',
      label: '',
    },
  ])

  const {data, error} = useQuery(GET_VENDOR_COURIERS, {
    variables: {
      vendor: `${id_vendor}`,
    },
  })
  const {data: all, error: error2} = useQuery(GET_COURIER_PROVIDERS)

  const {data: province, error: error3} = useQuery(LIST_PROVINCE)

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  if (all === undefined && error2 === undefined) {
    return <CircularProgress />
  } else if (error2 !== undefined) {
    return <div>{error2}</div>
  }

  if (province === undefined && error3 === undefined) {
    return <CircularProgress />
  } else if (error3 !== undefined) {
    return <div>{error3}</div>
  }

  province &&
    province.getProvince.forEach((row) => {
      optionsProv.push({
        value: row.province_id,
        label: row.province,
      })
    })

  const handleChangeProv = async (e) => {
    setProv([e])
    setCalculate({})
    setDisSelect(true)
    const obj = []
    try {
      const {loading, data: cty} = await client.query({
        query: LIST_CITY(e.value),
      })
      if (!loading) {
        setOptionsCity([])
        cty &&
          cty.getCity.forEach((row) => {
            obj.push({
              value: row.city_id,
              label: row.type + ' ' + row.city_name,
              postal_code: row.postal_code,
            })
          })
        setOptionsCity(obj)
      }
    } catch (error) {
      return <div>{error}</div>
    }
  }

  const handleChangeCity = async (e) => {
    setCity(e)
    setZipCode(e.postal_code)
    setCalculate({})
    setDisSelect(false)
  }

  const handleChangeProvider = async (e) => {
    delete calculate[e.target.name]

    const parameter = {
      courier: e.target.name,
      origin: district,
      city: city.value,
      weight: weight * amount,
    }
    let serviceCost = 0
    let allCost = 0
    let day = ''
    try {
      const {loading, data: ongkir} = await client.query({
        query: CalculateCost(parameter),
      })
      if (!loading) {
        const dataOngkir = ongkir.calculateCost[0].costs.filter(
          (val) => val.service === e.target.value
        )
        day = dataOngkir[0].cost[0].etd
        serviceCost = dataOngkir[0].cost[0].value
        allCost = parseInt(price) * parseInt(amount) + parseInt(serviceCost)
      }
      setCalculate({
        ...calculate,
        [e.target.name]: {
          serviceCost,
          allCost,
          day,
        },
      })
    } catch (error) {
      return <div>{error}</div>
    }
  }

  const handleAmount = (type) => {
    let i = 1
    if (type === 'add') {
      i = amount + 1
    } else {
      if (amount > 1) {
        i = amount - 1
      }
    }
    setAmount(i)
    setCalculate({})
  }

  return (
    <FlexColumnFirst style={{paddingTop: '50px'}}>
      <FlexRowFrist>
        <BiggerTextBlack style={{paddingTop: '4px', paddingRight: '120px'}}>
          Amount
        </BiggerTextBlack>
        <ButtonGroup style={{backgroundColor: '#eff2f4'}}>
          <IconButton onClick={() => handleAmount('remove')}>
            <RemoveIcon style={{color: '#33a0ff'}} />
          </IconButton>
          <Typography style={{paddingTop: '14px', paddingLeft: '29px'}}>
            {amount}
          </Typography>
          <IconButton onClick={() => handleAmount('add')}>
            <AddIcon style={{color: '#33a0ff'}} />
          </IconButton>
        </ButtonGroup>
      </FlexRowFrist>

      <FlexRowFrist style={{paddingTop: '20px'}}>
        <BiggerTextBlack style={{paddingTop: '4px', paddingRight: '92px'}}>
          Destination
        </BiggerTextBlack>
        <FlexBetween>
          <SelectCust
            onChange={handleChangeProv}
            options={optionsProv}
            value={prov}
            id="outlined-basic"
            variant="outlined"
            placeholder="The Province"
          />
          <SelectCust
            onChange={handleChangeCity}
            value={city}
            options={optionsCity}
            id="outlined-basic"
            variant="outlined"
            placeholder="City/Districts"
          />
          <StyledTextField
            disabled
            value={zipCode}
            // className={classes.textField}
            id="outlined-basic"
            variant="outlined"
            placeholder="Zip Code"
          />
        </FlexBetween>
      </FlexRowFrist>
      <TableContainer style={{marginTop: 16}}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellCustom fontSize="16px" component="th">
                Estimate Shiping Fee to Destination
              </TableCellCustom>
              <TableCellCustom fontSize="16px" component="th">
                Service Provider
              </TableCellCustom>
              <TableCellCustom fontSize="16px" component="th">
                Delivery Time
              </TableCellCustom>
              <TableCellCustom fontSize="16px" component="th">
                Service Cost
              </TableCellCustom>
              <TableCellCustom fontSize="16px" component="th">
                Cost(Item + Delivery Service)
              </TableCellCustom>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.company_shipping_availables[0] &&
              data.company_shipping_availables[0].courier_services &&
              data.company_shipping_availables[0].courier_services.map(
                (courier, key) => {
                  let logo = ''
                  all.global_shipping_providers
                    .filter(
                      (val) =>
                        val.name.toUpperCase() === courier.courier.toUpperCase()
                    )
                    .forEach((row) => {
                      logo = row.logo
                    })
                  return (
                    <TableRow key={key}>
                      <TableCellCustom>
                        <Sender
                          style={{height: 40, width: 'auto'}}
                          src={`${logo}`}
                        />
                      </TableCellCustom>
                      <TableCellCustom align="center">
                        <TextField
                          select
                          onChange={handleChangeProvider}
                          fullWidth
                          disabled={disSelect}
                          name={courier.courier}
                          placeholder="Select Service"
                        >
                          {courier.type.map((type, key) => {
                            return (
                              <MenuItem key={key} value={type}>
                                {type}
                              </MenuItem>
                            )
                          })}
                        </TextField>
                      </TableCellCustom>
                      <TableCellCustom align="center">
                        {calculate[courier.courier]
                          ? calculate[courier.courier].day + ' Days'
                          : ''}
                      </TableCellCustom>
                      <TableCellCustom align="center">
                        {calculate[courier.courier]
                          ? convertToRupiah(
                              calculate[courier.courier].serviceCost
                            )
                          : ''}
                      </TableCellCustom>
                      <TableCellCustom>
                        {calculate[courier.courier]
                          ? convertToRupiah(calculate[courier.courier].allCost)
                          : ''}
                      </TableCellCustom>
                    </TableRow>
                  )
                }
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </FlexColumnFirst>
  )
}

export default Shipping
