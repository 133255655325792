import React from 'react'
import Search from './Search'
import {Grid} from '@material-ui/core'
// import CarouselProduct from '../card-product/CarouselProduct'
import ListProduct from '../card-product/ListProduct'
import {TitleVendor} from '../../utils-components/VendorStyles'

import {VerticalContainer} from '../../general-container/FlexContainerStyles'

const DetailVendorProducts = props => {
  return (
    <VerticalContainer style={{width: '100%'}} paddingLeft={'30px'}>
      <Search
        filter={props.filter}
        setFilter={props.setFilter}
        customPlaceholder="Search in Smart Learning Store"
      />
      <TitleVendor>{props.title}</TitleVendor>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <ListProduct type={'vendor'} filter={props.filter} />
        </Grid>
        {/* <Grid item sm={12}>
          <CarouselProduct />
        </Grid> */}
      </Grid>
    </VerticalContainer>
  )
}

export default DetailVendorProducts
