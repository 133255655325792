import React from 'react'

import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarHalfIcon from '@material-ui/icons/StarHalf'

function Star({star, size}) {
  const generateStar = () => {
    const starArray = []
    const fullStars = star ? parseInt(star) : 0
    for (let i = 0; i < fullStars; i++) {
      starArray.push('star')
    }
    const halfStars = star ? (star % 1 > 0 ? 1 : 0) : 0
    if (halfStars) {
      starArray.push('star_half')
    }
    for (let i = 0; i < 5 - (fullStars + halfStars); i++) {
      starArray.push('star_border')
    }
    return (
      <>
        {starArray.map((row, key) => {
          if (row === 'star') {
            return <StarIcon key={key} style={{fontSize: size}} />
          } else if (row === 'star_half') {
            return <StarHalfIcon key={key} style={{fontSize: size}} />
          } else {
            return <StarBorderIcon key={key} style={{fontSize: size}} />
          }
        })}
      </>
    )
  }

  return <>{generateStar()}</>
}

export default Star
