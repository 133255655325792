import gql from 'graphql-tag'

export const GET_VENDOR_SHIPPING_ADDRESSES = gql`
  query($companyId: uuid!) {
    company_origin_shipping_addresses(
      where: {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
    ) {
      id
      label_name
      address
      province_code
      province_name
      city_code
      city_name
      district_code
      district_name
      post_code
      status
    }
  }
`
