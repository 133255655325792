import React, {useState} from 'react'
import GoogleMapReact from 'google-map-react'
import {MAP_KEY} from '../../../../utils/globals'
import Geocode from 'react-geocode'
import {MapMarker} from 'mdi-material-ui'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import {TextField, makeStyles, MenuItem} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  outlinedInput: {
    padding: '10px 14px',
  },
}))

export default function MapsMarker({
  latitude,
  setLatitude,
  longitude,
  setLongitude,
}) {
  const classes = useStyles()
  // static defaultProps = {
  //   center: {
  //     lat: 59.95,
  //     lng: 30.33,
  //   },
  //   zoom: 11,git s
  // }
  // const [latitude, setLatitude] = useState(-6.9174639)
  // const [longitude, setLongitude] = useState(107.61912280000001)
  const [address, setAddress] = useState('')
  const [addressPlaceholder, setAddressPlaceholder] = useState('')
  // const [locationInfo, setLocationInfo] = React.useState(null)
  const [isMapLoaded, setMapLoaded] = useState(false)
  const [isError, setIsError] = React.useState(false)
  const handleClickMap = e => {
    Geocode.setApiKey(`${MAP_KEY}`)
    Geocode.fromLatLng(e.lat, e.lng).then(
      response => {
        const address = response.results[0].formatted_address
        setLatitude(e.lat)
        setLongitude(e.lng)
        setAddressPlaceholder(address)
        setAddress(address)
        // console.log(response)
        // console.log(e)
      },
      () => {}
    )
  }
  // console.log(latitude)
  // console.log(longitude)
  // console.log(address)
  // console.log(addressPlaceholder)

  const handleApiLoaded = maps => {
    window.google = {maps}
    if (isMapLoaded === false) setMapLoaded(true)
    else {
      setIsError(true)
    }
  }

  const handleChange = address => {
    // console.log(address)
    setAddress(address)
  }
  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        // console.log(results)
        // setLocationInfo(results)
        return getLatLng(results[0])
      })
      .then(latLng => {
        // console.log('Success', latLng.lat, latLng.lng)
        setLatitude(latLng.lat)
        setLongitude(latLng.lng)
        setAddressPlaceholder(address)
        setAddress(address)
      })
      .catch(() => {})
  }

  const defaultMapOptions = {
    fullscreenControl: false,
  }
  // console.log(latitude)
  // console.log(longitude)
  return (
    // Important! Always set the container height explicitly
    <div
      style={{
        height: '265px',
        borderRadius: '5px',
        border: 'solid 1px #adaeae',
      }}
    >
      {isMapLoaded && (
        <PlacesAutocomplete
          value={address || ''}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
            <div>
              <TextField
                value={addressPlaceholder}
                style={{
                  margin: 8,
                  position: 'absolute',
                  zIndex: '4',
                  width: '81.5%',
                  backgroundColor: '#ffffff',
                }}
                margin="normal"
                variant="outlined"
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                })}
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                fullWidth
                error={address.length < 2 && isError}
                helperText={
                  address.length < 2 && isError ? 'This field is required' : ''
                }
              />
              <div
                className="autocomplete-dropdown-container"
                style={{
                  marginTop: '50px',
                  position: 'absolute',
                  zIndex: '4',
                  width: '81.5%',
                  backgroundColor: '#ffffff',
                }}
              >
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, key) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                    : {backgroundColor: '#ffffff', cursor: 'pointer'}
                  return (
                    <div
                      key={key}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <MenuItem>{suggestion.description}</MenuItem>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: `${MAP_KEY}`,
          libraries: 'places',
        }}
        // defaultCenter={{lat: latitude | -6.917464, lng: longitude | 107.619125}}
        center={{
          lat: latitude,
          lng: longitude,
        }}
        defaultZoom={16}
        options={defaultMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({maps}) => handleApiLoaded(maps)}
        onClick={e => handleClickMap(e)}
      >
        <MapMarker
          style={{color: '#014a62'}}
          lat={latitude}
          lng={longitude}
          title={
            <React.Fragment>
              address
              <span />
            </React.Fragment>
          }
        />
      </GoogleMapReact>
    </div>
  )
}
