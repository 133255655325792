import React from 'react'

// Styled
import useStyles, {
  // CardContained,
  CardProduct,
  Content1,
  Content2,
  TextProduct,
  TextGrey,
  TextBlack,
} from './ProductStyles'

import {FlexRowCenter} from '../../utils-components/VendorStyles'
import {Link} from 'react-router-dom'

// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder''
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// Carousel
import Carousel from 'nuka-carousel'

// material
import {Grid, CircularProgress} from '@material-ui/core'
import classNames from 'classnames'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {
  GET_STORE_BY_FILTER,
  GET_FOLLOWED_VENDOR,
} from '../../../graphql/queries/index'
import RatingStars from '../../rating-stars/RatingStars'
import ButtonFollowVendor from './component/ButtonFollowVendor'
import NoDataListFree from '../NoDataListFree'
import defaultVendot from '../../../assets/images/default_vendor.png'

function CarouselVendor({
  filter,
  categoryFilterContext,
  typeFilterContext,
  view = 'sidebar',
}) {
  const classes = useStyles()

  const limit = 10
  const {data, error} = useQuery(GET_STORE_BY_FILTER, {
    variables: {
      category:
        categoryFilterContext.length !== 0 ? categoryFilterContext : null,
      offset: 0,
      limit,
      search: filter.search ? '%' + filter.search + '%' : null,
      province: typeFilterContext.province ? typeFilterContext.province : null,
      city: typeFilterContext.city ? typeFilterContext.city : null,
      reviews: typeFilterContext.rating
        ? typeFilterContext.rating.length >= 1
          ? typeFilterContext.rating
          : null
        : null,
      filter:
        typeFilterContext.rating !== undefined
          ? typeFilterContext.rating
            ? typeFilterContext.rating.length >= 1
            : false
          : false,
    },
  })
  const {
    data: dataFollow,
    loading,
    error: error1,
  } = useQuery(GET_FOLLOWED_VENDOR)

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{'' + error}</div>
  }

  if (loading) {
    return <CircularProgress />
  } else if (error1 !== undefined) {
    return <div>{'' + error1}</div>
  }

  const vendors = data

  return (
    <Grid container>
      {vendors.company_profiles.length > 0 ? (
        <Carousel
          slidesToShow={4}
          slidesToScroll={4}
          renderCenterRightControls={null}
          renderCenterLeftControls={null}
          className={classNames(classes.noHighlight, classes.paddingCarousel)}
          renderTopRightControls={({
            previousSlide,
            currentSlide,
            slideCount,
            nextSlide,
          }) => {
            return (
              <div style={{marginTop: '-3rem', padding: '5px 15px'}}>
                {currentSlide === 0 ? (
                  <ChevronLeftIcon
                    className={classes.iconDisabled}
                    style={{marginRight: '1rem'}}
                  />
                ) : (
                  <ChevronLeftIcon
                    className={classes.iconChev}
                    style={{marginRight: '1rem'}}
                    onClick={previousSlide}
                  />
                )}
                {slideCount - currentSlide === 4 ? (
                  <ChevronRightIcon className={classes.iconDisabled} />
                ) : (
                  <ChevronRightIcon
                    className={classes.iconChev}
                    onClick={nextSlide}
                  />
                )}
              </div>
            )
          }}
          renderBottomCenterControls={null}
        >
          {vendors.company_profiles.map((vendor, index) => {
            const avgRating =
              vendor.marketplace_product_reviews_aggregate.aggregate.avg
                .value || 0

            const isFollowed = dataFollow.getFollowingCompany.company.some(
              (val) => val === vendor.id
            )

            return (
              <CardProduct
                key={index}
                className={view === 'sidebar' ? classes.fWidth : classes.fView}
              >
                <Content1
                  style={{
                    backgroundImage: `url(${vendor.logo || defaultVendot})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'Center center',
                    backgroundSize: 'cover',
                    justifyContent: 'flex-end',
                    display: 'flex',
                  }}
                ></Content1>
                <Content2>
                  {/* <FlexColumn1 style={{margin: '0 !important'}}> */}
                  <center>
                    <TextProduct
                      style={{textAlign: 'center'}}
                      component={Link}
                      to={'/profile/detail/vendor/' + vendor.id}
                    >
                      {vendor.brand_name.length > 27
                        ? vendor.brand_name.slice(0, 27) + '. . .'
                        : vendor.brand_name}
                    </TextProduct>
                    <TextGrey style={{textAlign: 'center'}}>
                      {vendor.global_company_industry_type
                        ? vendor.global_company_industry_type.name
                        : ''}
                    </TextGrey>
                  </center>
                  <FlexRowCenter
                    style={{
                      paddingTop: '10px',
                      textAlign: 'center',
                      color: '#039be5',
                    }}
                  >
                    <RatingStars
                      data={avgRating.toFixed(1)}
                      size="small"
                      disabled={true}
                    />

                    <TextBlack style={{textAlign: 'center'}}>
                      {avgRating.toFixed(1)}
                    </TextBlack>
                  </FlexRowCenter>
                  <FlexRowCenter className={classes.paddingLeft3}>
                    <TextGrey>
                      (
                      {
                        vendor.marketplace_product_reviews_aggregate.aggregate
                          .count
                      }{' '}
                      ratings)
                    </TextGrey>
                  </FlexRowCenter>
                  {/* </FlexColumn> */}
                  <FlexRowCenter style={{padding: '10px'}}>
                    <ButtonFollowVendor
                      followed={isFollowed}
                      companyId={vendor.id}
                      name={vendor.brand_name}
                    />
                  </FlexRowCenter>
                </Content2>
              </CardProduct>
            )
          })}
        </Carousel>
      ) : (
        <NoDataListFree
          message1="Sorry, No List"
          message2="No Vendor listed currently"
        />
      )}
    </Grid>
  )
}

export default CarouselVendor
