import gql from 'graphql-tag'

export const GET_COURSE_SLUG = gql`
  query($id: Int) {
    academy_courses(where: {id: {_eq: $id}}, order_by: {id: asc}) {
      id
      slug
    }
  }
`
