import React from 'react'

import {Button, Dialog} from '@material-ui/core'

import {ModalHeader, ModalContent, ModalActions} from '../../modal/Modal'
import {
  ButtonRed,
  ContentAlert,
  TitleAlert,
} from '../../utils-components/VendorStyles'

function AlertConfirm({
  danger = false,
  title = 'Are you sure?',
  message = 'Are you sure you want to do this?',
  confirmation = 'Confirm',
  cancellation = 'Cancel',
  open,
  onClose,
  onConfirm,
}) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <ModalHeader onClose={onClose} white>
        <TitleAlert color={!danger ? '#014a62' : '#ef4d5e'}>{title}</TitleAlert>
      </ModalHeader>
      <ModalContent dividers>
        <ContentAlert>{message}</ContentAlert>
      </ModalContent>
      <ModalActions>
        {cancellation && <Button onClick={onClose}>{cancellation}</Button>}
        {danger ? (
          <ButtonRed onClick={onConfirm} variant="contained">
            {confirmation}
          </ButtonRed>
        ) : (
          <Button onClick={onConfirm} variant="contained" color="primary">
            {confirmation}
          </Button>
        )}
      </ModalActions>
    </Dialog>
  )
}

export default AlertConfirm
