import React from 'react'
import {
  FlexColumnFirst,
  FlexRowFrist,
  CommentAvatar,
  TextBlue,
  TextSmallGrey,
  // FlexRowFristCenter,
} from '../../../utils-components/VendorStyles'

import {Br, NamaComment, BoxComment} from '../ProductStyles'
import {Link} from 'react-router-dom'

import Star from './Star'
import {getDate} from '../../../../utils/helpers'
import ReplyComment from './ReplyComment'

function Comment({data, company, id, name, isAdmin, loadQuery, type}) {
  return (
    <>
      {data &&
        data.map((row, index) => {
          const id_prod = row.marketplace_product
            ? row.marketplace_product.id
            : id
          const name_prod = row.marketplace_product
            ? row.marketplace_product.name
            : name
          return (
            <>
              <div
                key={index}
                style={{paddingTop: '30px', paddingBottom: '30px'}}
              >
                <Br />
              </div>
              <FlexRowFrist>
                <CommentAvatar
                  alt={`${row.global_user.name}`}
                  src={`${row.global_user.avatar}`}
                />
                <FlexColumnFirst
                  style={{
                    paddingLeft: '20px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      color: '#039be5',
                    }}
                  >
                    <Star star={row.value} size={17} />
                  </div>
                  <NamaComment>{row.global_user.name}</NamaComment>
                </FlexColumnFirst>
                <FlexColumnFirst
                  style={{
                    paddingLeft: '20px',
                    width: '100%',
                  }}
                >
                  <TextSmallGrey style={{color: '#A9A8A8'}}>
                    {getDate(row.date_added)}
                  </TextSmallGrey>
                  {type === 'store' && (
                    <FlexRowFrist>
                      <TextSmallGrey>Bought</TextSmallGrey>
                      <TextBlue
                        style={{
                          paddingTop: '3px',
                          paddingLeft: '5px',
                          fontSize: '12px',
                        }}
                        component={Link}
                        to={'/profile/detail/produk/' + id_prod}
                      >
                        {name_prod}
                      </TextBlue>
                    </FlexRowFrist>
                  )}
                  <BoxComment>{row.comment}</BoxComment>
                  <ReplyComment
                    row={row}
                    company={company}
                    isAdmin={isAdmin}
                    loadQuery={loadQuery}
                  />
                </FlexColumnFirst>
              </FlexRowFrist>
            </>
          )
        })}
    </>
  )
}

export default Comment
