import gql from 'graphql-tag'

export const LIST_PROVINCE = gql`
  {
    getProvince {
      province_id
      province
    }
  }
`

export const LIST_CITY = id =>
  gql`{
  getCity(province_id: ${id}) {
    city_id
    city_name
    type
    postal_code
  }
}
`

export const LIST_DISTRICT = id =>
  gql`{
    getSubdistrict(city_id:${id}){
        subdistrict_id
        subdistrict_name
    }
}`
