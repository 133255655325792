import React from 'react'

import {
  LabelSearchDate,
  FlexBetween,
  Psm,
  DatePickerFilter,
} from './FilterStyles'

import Grid from '@material-ui/core/Grid'

import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

function TabsDate(props) {
  const {handleResetDate, dateFrom, ChangeDFrom, dateTo, ChangeDTo} = props
  return (
    <Grid container>
      <Grid item sm={12}>
        <FlexBetween style={{fontSize: '12px'}}>
          Pick a Date
          <Psm
            style={{cursor: 'pointer', fontSize: '12px'}}
            onClick={handleResetDate}
          >
            Reset
          </Psm>
        </FlexBetween>
      </Grid>
      <Grid item sm={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <LabelSearchDate>From</LabelSearchDate>
          <DatePickerFilter
            fullWidth
            style={{height: '40px'}}
            inputVariant="outlined"
            format="yyyy/MM/dd"
            value={dateFrom}
            onChange={ChangeDFrom}
            name="dateFrom"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item sm={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <LabelSearchDate>To</LabelSearchDate>
          <DatePickerFilter
            fullWidth
            style={{height: '40px'}}
            inputVariant="outlined"
            format="yyyy/MM/dd"
            value={dateTo}
            onChange={ChangeDTo}
            name="dateTo"
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}

export default TabsDate
