import gql from 'graphql-tag'

export const GET_SHOPPING_CART = gql`
  query($where: marketplace_user_carts_bool_exp) {
    marketplace_user_carts(where: $where, order_by: [{item_vendor_id: asc}]) {
      id
      item_id
      item_vendor
      item_vendor_id
      item_vendor_table
      item_quantity
      item_table
      item_object
    }
  }
`

// NOTE(intrnl): We don't have accurate product information like stock.
export const GET_CART_PRODUCT = gql`
  query($products: [uuid!]!) {
    v_marketplace_product(where: {id: {_in: $products}}) {
      id
      name
      images
      price
      weight
      available_stock
      is_always_available
      is_digital_product
      company_profile {
        id
        logo
        brand_name
        global_company_industry_type {
          id
          name
        }
      }
    }
  }
`

export const GET_CART_WISHLIST = gql`
  query($products: [String!]!) {
    marketplace_user_wishlists(where: {item_id: {_in: $products}}) {
      id
      item_id
    }
  }
`

export const GET_VENDORS_BANK_ACCOUNTS = gql`
  query($companyIds: [uuid!]!, $instructorIds: [uuid!]!) {
    company_profiles(where: {id: {_in: $companyIds}}) {
      id
      avatar: logo
      name: brand_name
      banks: company_profile_bank(distinct_on: bank) {
        id
        provider: global_bank {
          id
          code
          logo
          name
        }
      }
    }
    global_users(where: {id: {_in: $instructorIds}}) {
      id
      avatar
      name
      banks: people_profile_banks(
        distinct_on: bank
        where: {deletedAt: {_is_null: true}}
      ) {
        id
        provider: global_bank {
          id
          code
          logo
          name
        }
      }
    }
  }
`
