import gql from 'graphql-tag'

export const CHECKOUT_CART = gql`
  mutation(
    $shipping: JSON
    $payment: String
    $subdistrict: Int
    $people_address: Int
    $cart: JSON
    $vendor_bank_selection: [VendorBankSelection]
  ) {
    checkout(
      shipping: $shipping
      payment: $payment
      subdistrict: $subdistrict
      people_address: $people_address
      cart: $cart
      vendor_bank_selection: $vendor_bank_selection
    ) {
      success
      payment_data
    }
  }
`
