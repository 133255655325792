import React, {useEffect} from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import {createGlobalStyle} from 'styled-components'
import {Footer} from './components/footer/FooterComponent'
import {Header} from './components/header/HeaderComponent'
import Main from './components/Main'
import {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from './components/utils-components/VendorStyles'
import routes from './configs/routesConfig'
import NotFoundComponent from './pages/error/NotFoundComponent'
import {COMPANY_ID} from './utils/globals'
import {hasModule, listenCookieChange} from './utils/helpers'
import {GetModularSettings} from '@worklifebeyond/wlb-utils-components'
import NoRoles from './pages/error/NoRoles'
import PlaceholderIndexPage from './pages/PlaceholderIndexPage'

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
    font-family: 'Muli';

  }
`

const AppContainer = props => {
  const {data} = GetModularSettings(COMPANY_ID)
  const [hide, setHide] = React.useState({
    tabBar: false,
    header: false,
    footer: false,
  })

  const hasVendorModule = React.useMemo(() => {
    return !data || hasModule(data, 'vendor')
  }, [data])

  let regexUrl = ''
  if (props.location.pathname) {
    regexUrl = props.location.pathname.match('[a-z0-9-]+')
  }
  const activeTab = regexUrl ? `/${regexUrl[0]}` : '/'
  const handleChange = (event, newValue) => {
    event.preventDefault()
    props.history.push({
      pathname: newValue,
    })
  }

  useEffect(() => {
    // detect if user has logged out in another tabs browser
    if (process.env.NODE_ENV !== 'development') {
      listenCookieChange()
    }
  }, [listenCookieChange])

  return (
    <>
      <GlobalStyle />
      <Header hideHeader={hide.header} />
      <TabsWrapper style={{display: hide.tabBar ? 'none' : 'block'}}>
        <StyledTabs
          disabled={!data}
          value={activeTab}
          onChange={handleChange}
          style={customStyle.tabs}
          centered
        >
          {hasVendorModule ? (
            <StyledTab label="Beranda" value="/home" name="home" />
          ) : (
            <StyledTab
              label="Cart"
              value="/profile/shopping-cart"
              name="cart"
            />
          )}

          <StyledTab
            label="Transaksi"
            value="/transaction"
            name="transaction"
          />
          {/* <StyledTab label="Profile" value="/profile" name="profile" /> */}
        </StyledTabs>
      </TabsWrapper>
      {/* <MainWrapper> */}
      <Switch>
        <Route exact path="/" component={PlaceholderIndexPage} />
        <Route path="/access-denied" component={NoRoles} />

        {routes.map((val, key) => (
          <Route
            exact={val.exact}
            key={key}
            path={val.path}
            render={props => (
              <Main
                {...props}
                hide={hide}
                setHide={setHide}
                settings={val.settings}
              >
                {val.component}
              </Main>
            )}
          />
        ))}

        <Route component={NotFoundComponent} />
      </Switch>
      {/* </MainWrapper> */}
      <Footer hide={hide.footer} />
    </>
  )
}

export default withRouter(AppContainer)
