import styled from 'styled-components'
import {
  Typography,
  makeStyles,
  Card,
  TableCell,
  LinearProgress,
  Button,
  TextField,
} from '@material-ui/core'

export const CardContained = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
`
export const CardProduct = styled(Card)`
  width: 24.3%;
  height: 285.2px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background: white;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 5px;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
`
export const Content1 = styled.div`
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 50%;
  font-family: Muli;
`
export const Content2 = styled.div`
  width: 100%;
  padding: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 50%;
  font-family: Muli;
`
export const Favorite = styled.div`
  align-content: center;
  margin-top: 5px;
  margin-right: 5px;
  displat: flex;
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
`
export const TextProduct = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  text-decoration: none;
  line-height: 20px;
  text-align: left;
`
export const TextGrey = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #a9a8a8;
`

export const TextGrey1 = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #a9a8a8;
`

export const TextBlack = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: black;
`

export const BoxWhite = styled.div`
  width: 98%;
  background-color: #ffffff;
  padding: 75.5px;
`
export const BoxFoto = styled.img`
  width: 260.7px;
  height: 296.3px;
  object-fit: contain;
  background-repeat: 'no-repeat';
  background-position: 'Center center';
  background-size: 'cover';
  justify-content: 'flex-end';
  display: 'flex';
`

export const BoxCarousel = styled.div`
  padding: 0px 5px 0px 5px;
`
export const BoxCardFoto = styled.img`
  width: 52.7px;
  height: 52.7px;
  object-fit: contain;
  margin-reght: 5px;
  border-radius: 5px;
  background-repeat: 'no-repeat';
  background-position: 'Center center';
  background-size: 'cover';
  justify-content: 'flex-end';
  display: 'flex';
`

export const TextDetail = styled.div`
  padding-top: 10px;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`

export const FotoVendor = styled.img`
  width: 108px;
  height: 108px;
  object-fit: contain;
`
export const BoxDesctiption = styled.div`
  width: 833.4px;
  min-height: 213.3px;
  padding-top: 27.8px;
  font-family: Muli;
  font-size: 12px;
  line-height: 24px;
  text-align: left;
  color: #262626;
`
export const TableCellCustom = styled(TableCell)`
  line-height: 1.17;
  font-size: ${props => (props.fontSize ? props.fontSize : '18px')};
  font-family: Muli;
  font-weight: 400;
`
export const TitleReview = styled.div`
  width: 114px;
  height: 116px;
  font-family: Muli;
  font-size: 79px;
  font-weight: 300;
  line-height: 116px;
  text-align: left;
  color: #414141;
`
export const ProgressStar = styled(LinearProgress)`
  width: 100%;
  height: 10.4px;
  border-radius: 2px;
  background-color: #dbdbdb;
`

export const ButtonMini = styled(Button)`
  border-radius: 5px;
  margin-right: 2px;
`
export const Line = styled.div`
  width: 0;
  height: 28px;
  border: solid 1px #a9a8a8;
`
export const Br = styled.div`
  width: 100%;
  height: 0;
  border: solid 1px #eff2f4;
`
export const NamaComment = styled.div`
  color: #4d4f5c;
  line-height: 0.93;
  text-align: left;
  font-size: 14px;
  padding-top: 5px;
  font-family: Muli;
  font-weight: 700;
`
export const BoxComment = styled.div`
  font-family: Muli;
  font-size: 12px
  line-height: 22px;
  text-align: left;
  color: #4d4f5c;
`
export const BoxChildComment = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
  object-fit: contain;
  display: flex;
  flex-direction: column;
  background-color: #f7f8f9;
  padding: 0px 0px 13.3px 22.2px;
`

export const StyledTextField = styled(TextField)`
  margin-top: -4px;
  input {
    height: 1.5px;
    width: 186.16px;
    border-radius: 5px;
  }
`

const useStyles = makeStyles(() => ({
  cardFull: {
    width: '21% !important',
    marginRight: '46.1px !important',
  },
  paddingLeft3: {
    paddingLeft: '3px !important',
  },
  cardSidebar: {
    width: '24.3%',
    marginRight: '5px',
  },
  iconDisabled: {
    border: '1px #a9a8a8 solid',
    color: '#a9a8a8',
    borderRadius: '5px',
  },
  iconChev: {
    border: '1px #039be5 solid',
    backgroundColor: '#039be5',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  noHighlight: {
    '&:focus-within .slider-slide': {
      outline: 'none !important',
    },
    '&:focus': {
      outline: 'none !important',
    },
  },
  paddingCarousel: {
    '& >.slider-frame': {
      padding: '1px 0px 20px 0px !important',
    },
  },
  fWidth: {
    width: '98% !important',
  },
  fView: {
    marginTop: '0px',
    width: '86% !important',
  },
}))

export default useStyles
//   - webkit - touch - callout: none;
// -webkit - user - select: none;
// -khtml - user - select: none;
// -moz - user - select: none;
// -ms - user - select: none;
// user - select: none;
