import gql from 'graphql-tag'

export const ADD_TO_CART = gql`
  mutation($objects: [marketplace_user_carts_insert_input!]!) {
    insert_marketplace_user_carts(
      objects: $objects
      on_conflict: {
        constraint: marketplace_user_carts_user_item_table_item_id_key
        update_columns: [item_object, item_quantity, item_vendor]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
