import React from 'react'
import {
  TransactionContainer,
  TitleTransactionDarkBlue,
} from '../../utils-components/VendorStyles'
import {FormControl, Select, MenuItem} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import Filter from '../../Filter/Filter'
import ListTransaction from './list-transcation/ListTransaction'
import {ROW_PAGENATION} from '../../../utils/helpers'

// import classNames from 'classnames'

const Transaction = props => {
  const [filter, setFilter] = React.useState({
    search_filter: null,
    dateFrom: null,
    dateTo: null,
    totalFrom: null,
    totalTo: null,
    orderBy: 'desc',
  })

  const type = props.match.params.type
  const [rowsPerPage, setRowsPerPage] = React.useState(ROW_PAGENATION[0])

  const handleChangeFilter = event => {
    setFilter({...filter, [event.target.name]: event.target.value})
  }

  return (
    <TransactionContainer>
      <TitleTransactionDarkBlue>{type}</TitleTransactionDarkBlue>

      <div style={{paddingTop: '25px'}}>
        <Filter
          filter={filter}
          setFilter={setFilter}
          target={['Date Range', 'Price Range']}
          trigerReset={type}
        />
        <FormControl variant="standard" style={{paddingTop: '10px'}}>
          <Select
            select
            variant="standard"
            disableUnderline
            style={{width: '100%'}}
            onChange={handleChangeFilter}
            value={filter.orderBy}
            inputProps={{
              disableUnderline: true,
              name: 'orderBy',
            }}
          >
            <MenuItem value="desc">Latest</MenuItem>
            <MenuItem value="asc">Oldest</MenuItem>
          </Select>
        </FormControl>
      </div>

      <ListTransaction
        key={type}
        filter={filter}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </TransactionContainer>
  )
}

export default withRouter(Transaction)
