import React from 'react'

import {VendorBioLabel, VendorTypeLabel} from '../../header/HeaderStyles'
import {
  VerticalContainer,
  HorizontalContainer,
} from '../../general-container/FlexContainerStyles'

const DetailVendorShipmentVendor = ({name, type, image}) => {
  return (
    <div
      style={{
        border: '1px solid #eff2f4',
        padding: 12,
        borderRadius: 10,
        cursor: 'pointer',
        marginLeft: 12,
        marginTop: 5,
        width: '27%',
      }}
    >
      <HorizontalContainer>
        <div style={{width: '76px'}}>
          <img style={{height: '30px', width: '100%'}} src={`${image}`} />
        </div>
        <VerticalContainer paddingLeft={'17px'}>
          <VendorTypeLabel style={{textTransform: 'uppercase'}}>
            {name}
          </VendorTypeLabel>
          <VendorBioLabel>{type}</VendorBioLabel>
        </VerticalContainer>
      </HorizontalContainer>
    </div>
  )
}

export default DetailVendorShipmentVendor
