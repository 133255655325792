import React from 'react'

// Styled
import useStyles, {
  CardContained,
  CardProduct,
  Content1,
  Content2,
  TextProduct,
  TextGrey,
  TextBlack,
} from './ProductStyles'

import {FlexRow} from '../../utils-components/VendorStyles'

// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder''
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// Carousel
import Carousel from 'nuka-carousel'

import {CircularProgress} from '@material-ui/core'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {GET_WISHLIST} from '../../../graphql/queries/profile/getProducts.query'
import FavoriteComponent from './component/FavoriteComponent'
import {PRODUCT_BY_CATEGORY_FILTER} from '../../../graphql/queries/index'
import {Link} from 'react-router-dom'
import {convertToRupiah} from '../../../utils/helpers'
import RatingStars from '../../rating-stars/RatingStars'
import classNames from 'classnames'
import NoDataListFree from '../NoDataListFree'
import defaultProduct from '../../../assets/images/default_product.png'

function CarouselProduct({
  type,
  searchText,
  categoryFilterContext,
  typeFilterContext,
  view = 'sidebar',
  relatedProduct = null,
  idVendor = null,
}) {
  const classes = useStyles()

  const {
    data: wl,
    error: error2,
    refetch,
  } = useQuery(GET_WISHLIST, {
    variables: {
      // offset: 0,
      // limit: 10,
      order: [{date_added: 'desc'}],
    },
  })
  const _orderBy =
    type === 'new'
      ? [{date_added: 'desc'}]
      : type === 'top'
      ? [{marketplace_product_reviews_aggregate: {count: 'desc'}}]
      : null

  const {data, error} = useQuery(
    PRODUCT_BY_CATEGORY_FILTER,
    // typeFilterContext.rating ? typeFilterContext.rating : null
    {
      variables: {
        category:
          categoryFilterContext.length !== 0 ? categoryFilterContext : null,
        offset: 0,
        limit: 10,
        search: searchText ? '%' + searchText + '%' : null,
        orderBy: _orderBy,
        priceFrom: typeFilterContext.priceFrom
          ? typeFilterContext.priceFrom
          : null,
        priceTo: typeFilterContext.priceTo ? typeFilterContext.priceTo : null,
        province: typeFilterContext.province
          ? typeFilterContext.province
          : null,
        city: typeFilterContext.city ? typeFilterContext.city : null,

        vendor: idVendor,

        rating1: typeFilterContext.rating
          ? typeFilterContext.rating.find((row) => row === 1) === 1
          : false,
        rating2: typeFilterContext.rating
          ? typeFilterContext.rating.find((row) => row === 2) === 2
          : false,
        rating3: typeFilterContext.rating
          ? typeFilterContext.rating.find((row) => row === 3) === 3
          : false,
        rating4: typeFilterContext.rating
          ? typeFilterContext.rating.find((row) => row === 4) === 4
          : false,
        rating5: typeFilterContext.rating
          ? typeFilterContext.rating.find((row) => row === 5) === 5
          : false,
        all_ratings:
          typeFilterContext.rating !== undefined
            ? typeFilterContext.rating
              ? typeFilterContext.rating.length === 0
              : true
            : true,
        showcase: null,
        relatedProduct:
          relatedProduct !== null
            ? relatedProduct.length > 0
              ? relatedProduct
              : null
            : null,
      },
    }
  )

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  if (wl === undefined && error2 === undefined) {
    return <CircularProgress />
  } else if (error2 !== undefined) {
    return <div>{error2}</div>
  }
  // const wishlist = []
  // wl.marketplace_user_wishlists.length > 0 &&
  //   wl.marketplace_user_wishlists.map(row => {
  //     wishlist.push(row.item_id)
  //   })
  const products = data

  return (
    <CardContained>
      {products.marketplace_products.length > 0 ? (
        <Carousel
          className={classNames(classes.noHighlight, classes.paddingCarousel)}
          slidesToShow={4}
          slidesToScroll={4}
          renderCenterRightControls={null}
          renderCenterLeftControls={null}
          renderTopRightControls={({
            previousSlide,
            currentSlide,
            slideCount,
            nextSlide,
          }) => {
            return (
              <div style={{marginTop: '-3rem', padding: '5px 15px'}}>
                {currentSlide === 0 ? (
                  <ChevronLeftIcon
                    className={classes.iconDisabled}
                    style={{marginRight: '1rem'}}
                  />
                ) : (
                  <ChevronLeftIcon
                    className={classes.iconChev}
                    style={{marginRight: '1rem'}}
                    onClick={previousSlide}
                  />
                )}
                {slideCount - currentSlide === 4 ? (
                  <ChevronRightIcon className={classes.iconDisabled} />
                ) : (
                  <ChevronRightIcon
                    className={classes.iconChev}
                    onClick={nextSlide}
                  />
                )}
              </div>
            )
          }}
          renderBottomCenterControls={null}
        >
          {products.marketplace_products &&
            products.marketplace_products.map((product, index) => {
              let id_wl = null
              let cek_wl = false
              let id_product = product.id
              wl.marketplace_user_wishlists
                .filter((val) => val.item_id === product.id)
                .forEach((row) => {
                  id_wl = row.id
                  cek_wl = true
                })
              let typeFav = 'Non Favorite'
              let item = null
              if (type === 'Wishlist') {
                item = product
                typeFav = 'Favorite'
                id_product = product.item_id
              } else if (cek_wl) {
                item = {
                  id: id_wl,
                }
                typeFav = 'Favorite'
              } else {
                item = product
                typeFav = 'Non Favorite'
              }

              const avgRating =
                product.marketplace_product_reviews_aggregate.aggregate.avg
                  .value || 0
              return (
                <CardProduct
                  key={index}
                  className={
                    view === 'sidebar' ? classes.fWidth : classes.fView
                  }
                >
                  <Content1
                    style={{
                      backgroundImage: `url(${
                        product.images[0] || defaultProduct
                      })`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'Center center',
                      backgroundSize: 'cover',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <FavoriteComponent
                      type={typeFav}
                      refetch={refetch}
                      item={item}
                      conditionQuery={type}
                    />
                  </Content1>
                  <Content2>
                    <TextProduct
                      variant="body1"
                      component={Link}
                      to={
                        idVendor === null
                          ? `/profile/detail/produk/${id_product}`
                          : `/vendor/detail/${idVendor}/product/${id_product}`
                      }
                    >
                      {product.name.length > 27
                        ? product.name.slice(0, 27) + '. . .'
                        : product.name}
                    </TextProduct>
                    <TextGrey>{product.company_profile.brand_name}</TextGrey>
                    <FlexRow style={{paddingTop: '10px'}}>
                      <RatingStars
                        data={avgRating.toFixed(1)}
                        // changeValueStarts={changeValueStarts}
                        size="small"
                        disabled={true}
                      />
                      <TextBlack>{avgRating.toFixed(1)}</TextBlack>
                      <TextGrey className={classes.paddingLeft3}>
                        {` (${product.marketplace_product_reviews_aggregate.aggregate.count})`}
                      </TextGrey>
                    </FlexRow>
                    <FlexRow style={{marginTop: 12, justifyContent: 'center'}}>
                      <TextProduct>
                        {convertToRupiah(product.price)}
                      </TextProduct>
                    </FlexRow>
                  </Content2>
                </CardProduct>
              )
            })}
        </Carousel>
      ) : (
        <NoDataListFree
          message1="Sorry, No List"
          message2={
            type === 'top'
              ? 'No top product listed currently'
              : 'No product recommendation for you currently'
          }
        />
      )}
    </CardContained>
  )
}

export default CarouselProduct
