import gql from 'graphql-tag'
export const GET_TOP_VENDORS = gql`
  query getVendors($search: String) {
    company_profiles(
      where: {_or: [{legal_name: {_ilike: $search}}]}
      limit: 10
      order_by: [
        {date_added: desc}
        {marketplaceStoreReview_aggregate: {count: desc}}
      ]
    ) {
      id
      logo
      brand_name
      legal_name
      global_company_industry_type {
        id
        name
      }
      marketplaceStoreReview_aggregate {
        aggregate {
          count
          avg {
            value
          }
        }
      }
    }
  }
`
export const GET_FOLLOWER_VENDOR = id => {
  return gql`
    query{
        getTotalFollowersCompany(
            company:"${id}"
        ){
            total
            is_following
            is_followed
        }
    }`
}
export const GET_STORE_BY_FILTER = gql`
  query(
    $search: String
    $offset: Int
    $limit: Int
    $category: [Int!]
    $province: Int
    $city: Int
    $reviews: [Int!]
    $filter: Boolean
    $orderLatest: order_by
    $orderName: order_by
    $orderSeller: order_by
  ) {
    total: company_profiles_aggregate(
      where: {
        types: {_ilike: "__1%"}
        _or: [
          {brand_name: {_ilike: $search}}
          {legal_name: {_ilike: $search}}
          {marketplace_products: {name: {_ilike: $search}}}
          {marketplace_products: {description: {_ilike: $search}}}
        ]
        marketplace_products: {
          marketplace_product_category: {id: {_in: $category}}
        }
        company_origin_shipping_addresses: {
          province_code: {_eq: $province}
          city_code: {_eq: $city}
          status: {_eq: "ACTIVE"}
          deleted_at: {_is_null: true}
        }
        _and: {
          _or: [
            {marketplace_product_reviews: {value: {_in: $reviews}}}
            {
              _and: {
                _not: {marketplace_product_reviews: {}}
                id: {_is_null: $filter}
              }
            }
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    company_profiles(
      where: {
        types: {_ilike: "__1%"}
        _or: [
          {brand_name: {_ilike: $search}}
          {legal_name: {_ilike: $search}}
          {marketplace_products: {name: {_ilike: $search}}}
          {marketplace_products: {description: {_ilike: $search}}}
        ]
        marketplace_products: {
          marketplace_product_category: {id: {_in: $category}}
        }
        company_origin_shipping_addresses: {
          province_code: {_eq: $province}
          city_code: {_eq: $city}
          status: {_eq: "ACTIVE"}
          deleted_at: {_is_null: true}
        }
        _and: {
          _or: [
            {marketplace_product_reviews: {value: {_in: $reviews}}}
            {
              _and: {
                _not: {marketplace_product_reviews: {}}
                id: {_is_null: $filter}
              }
            }
          ]
        }
      }
      limit: $limit
      offset: $offset
      order_by: [
        {date_added: $orderLatest}
        {brand_name: $orderName}
        {marketplace_product_reviews_aggregate: {count: $orderSeller}}
      ]
    ) {
      id
      logo
      brand_name
      legal_name
      profile_field
      global_company_industry_type {
        id
        name
      }
      marketplace_product_reviews_aggregate {
        aggregate {
          count
          avg {
            value
          }
        }
      }
    }
  }
`

export const GET_HOME_BANNERS = gql`
  {
    global_module_settings(where: {module_name: {_eq: "marketplace"}}) {
      id
      module_name
      variable_values
    }
  }
`

export const GET_FOLLOWED_VENDOR = gql`
  query {
    getFollowingCompany {
      company
    }
  }
`

export const GET_ID_CHAT_VENDOR = gql`
  query($company: uuid) {
    people_work_placements(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {role: {_ilike: "1%"}}
          {company_job_profile: {roles: {_ilike: "1%"}}}
        ]
      }
      limit: 1
      order_by: [{id: asc}]
    ) {
      user
    }
  }
`

export const GET_REVIEW_INVOICE = gql`
  query($user: uuid, $product: uuid, $invoice: uuid) {
    marketplace_product_reviews_aggregate(
      where: {
        user_buyer: {_eq: $user}
        product_id: {_eq: $product}
        invoice_id: {_eq: $invoice}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
