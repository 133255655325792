import React from 'react'

const usePrevious = previous => {
  const ref = React.useRef(previous)

  React.useLayoutEffect(() => {
    ref.current = previous
  }, [previous])

  return ref.current
}

export default usePrevious
