import React from 'react'

import clsx from 'classnames'
import {
  makeStyles,
  CircularProgress,
  Divider,
  TablePagination,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  list: {
    display: 'grid',
    gridGap: theme.spacing(3),
    padding: theme.spacing(3),
  },
  empty: {
    textAlign: 'center',
  },

  loadingContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    inset: 0,
    zIndex: 2,
  },
}))

const DEFAULT_LIMIT_OPTIONS = [20, 50, 100]
const EMPTY_OBJ = {}

function Listing(props) {
  const {
    component: Component,
    classes = EMPTY_OBJ,

    data,
    totalCount,
    loading,
    page,
    limit,
    limitOptions = DEFAULT_LIMIT_OPTIONS,
    onChangePage,
    onChangeLimit,

    resetKeys = [],
    ...rest
  } = props

  const styles = useStyles()

  const list = React.useMemo(() => {
    if (!data) {
      return []
    }

    return data.map((data, index) => (
      <Component {...rest} key={index} data={data} />
    ))
  }, [data, ...resetKeys])

  return (
    <div className={clsx(styles.root, classes.root)}>
      <div className={clsx(styles.list, classes.list)}>
        {list.length ? (
          list
        ) : (
          <div className={styles.empty}>No records to display.</div>
        )}
      </div>

      {loading && (
        <div
          className={clsx(styles.loadingContainer, classes.loadingContainer)}
        >
          <CircularProgress />
        </div>
      )}

      <Divider />

      <TablePagination
        count={totalCount}
        page={page}
        rowsPerPage={limit}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeLimit}
        rowsPerPageOptions={limitOptions}
      />
    </div>
  )
}

export default Listing
