import gql from 'graphql-tag'

export const UPDATE_ORDER = gql`
  mutation($status: String!, $id: String!) {
    updateInvoice(status: $status, id: $id) {
      success
    }
  }
`

export const CANCEL_ORDER = gql`
  mutation($paymentId: uuid!) {
    update_global_payments(
      where: {id: {_eq: $paymentId}}
      _set: {payment_status: "cancelled"}
    ) {
      affected_rows
    }
    update_global_invoices(
      where: {payment_id: {_eq: $paymentId}}
      _set: {status: "payment_cancelled"}
    ) {
      affected_rows
    }
  }
`
