import gql from 'graphql-tag'

export const GET_ALL_ADDRESS = gql`
  query($offset: Int, $limit: Int) {
    people_shipping_addresses(
      offset: $offset
      limit: $limit
      order_by: {id: asc}
    ) {
      id
      label_name
      province_code
      city_code
      district_code
      province_name
      city_name
      district_name
      post_code
      address
      date_added
      recipient_name
      phone
      location_fields
    }
  }
`
