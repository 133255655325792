import React from 'react'
import {
  BiggerTextBlack,
  FlexColumnFirst,
  FlexRowFrist,
  FlexColumnCenter,
  TextGrey,
  FlexRowEnd,
  FlexBetween,
} from '../../../utils-components/VendorStyles'

import {TitleReview, ButtonMini, Line, ProgressStar} from '../ProductStyles'

import StarIcon from '@material-ui/icons/Star'
import Comment from './Comment'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {
  GET_PRODUCT_AGGREGATE_SCORES,
  GET_PRODUCT_REVIEWS,
  GET_STORE_AGGREGATE_SCORES,
  GET_STORE_REVIEWS,
} from '../../../../graphql/queries/getReviewProduct'
import {CircularProgress} from '@material-ui/core'
import Star from './Star'
import {generatePersentase} from '../../../../utils/helpers'

function Review(props) {
  const {type, id, company, name, isAdmin = false} = props

  return (
    <FlexColumnFirst style={{paddingTop: '10px'}}>
      <BiggerTextBlack>Customer Feedback</BiggerTextBlack>

      <AggregateReviews type={type} id={id} />
      <IndividualReviews
        type={type}
        id={id}
        company={company}
        name={name}
        isAdmin={isAdmin}
      />
    </FlexColumnFirst>
  )
}

export default Review

const CONTEXT = {
  headers: {
    'X-Hasura-Role': 'public',
  },
}

function AggregateReviews(props) {
  const {type, id} = props

  const isStore = type === 'store'
  const query = isStore
    ? GET_STORE_AGGREGATE_SCORES
    : GET_PRODUCT_AGGREGATE_SCORES

  const {data, error} = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    context: CONTEXT,
    variables: {
      id: id,
    },
  })

  if (error) {
    console.error(error)
    return <div>Something went wrong</div>
  }

  if (!data) {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress />
      </div>
    )
  }

  const percentages = [
    {
      star: 5,
      count: generatePersentase(
        data.total.aggregate.count,
        data.star5.aggregate.count
      ),
    },
    {
      star: 4,
      count: generatePersentase(
        data.total.aggregate.count,
        data.star4.aggregate.count
      ),
    },
    {
      star: 3,
      count: generatePersentase(
        data.total.aggregate.count,
        data.star3.aggregate.count
      ),
    },
    {
      star: 2,
      count: generatePersentase(
        data.total.aggregate.count,
        data.star2.aggregate.count
      ),
    },
    {
      star: 1,
      count: generatePersentase(
        data.total.aggregate.count,
        data.star1.aggregate.count
      ),
    },
  ]

  return (
    <FlexRowFrist>
      <FlexColumnCenter>
        <TitleReview>
          {data.total.aggregate.avg.value
            ? data.total.aggregate.avg.value.toFixed(1)
            : '0.0'}
        </TitleReview>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: '#039be5',
          }}
        >
          <Star star={data.total.aggregate.avg.value} size={30} />
        </div>
        <TextGrey
          style={{textAlign: 'center', fontSize: 12, fontWeight: '500'}}
        >
          Based on {data.total.aggregate.count} Reviews
        </TextGrey>
      </FlexColumnCenter>
      <FlexColumnFirst style={{width: '100%'}}>
        {percentages.map((row, index) => (
          <FlexBetween key={index} style={{paddingTop: '8px'}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: '#039be5',
                paddingLeft: '20px',
              }}
            >
              <Star star={row.star} size={25} />
            </div>
            <div
              style={{
                width: '100%',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
            >
              <ProgressStar
                variant="determinate"
                value={row.count}
                color="secondary"
              />
            </div>
            <TextGrey style={{width: '42px'}}>{row.count}%</TextGrey>
          </FlexBetween>
        ))}
      </FlexColumnFirst>
    </FlexRowFrist>
  )
}

const STYLE_SELECTED = {backgroundColor: 'rgba(0, 0, 0, 0.1)'}

function IndividualReviews(props) {
  const {type, id, company, name, isAdmin} = props

  const [score, setScore] = React.useState(null)

  const isStore = type === 'store'
  const query = isStore ? GET_STORE_REVIEWS : GET_PRODUCT_REVIEWS

  const {data, refetch, error} = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    context: CONTEXT,
    variables: {
      id: id,
      value: score,
    },
  })

  return (
    <>
      <FlexRowFrist style={{paddingTop: '40px'}}>
        <BiggerTextBlack style={{paddingRight: '290px'}}>
          Review
        </BiggerTextBlack>
        <FlexRowEnd style={{width: '100%', justifyContent: 'space-between'}}>
          <ButtonMini
            style={score === null ? STYLE_SELECTED : null}
            onClick={() => setScore(null)}
            variant="outlined"
          >
            All
          </ButtonMini>
          <Line />
          <ButtonMini
            style={score === 5 ? STYLE_SELECTED : null}
            variant="outlined"
            onClick={() => setScore(5)}
            startIcon={<StarIcon style={{color: '#039be5'}} />}
          >
            5
          </ButtonMini>
          <ButtonMini
            style={score === 4 ? STYLE_SELECTED : null}
            variant="outlined"
            onClick={() => setScore(4)}
            startIcon={<StarIcon style={{color: '#039be5'}} />}
          >
            4
          </ButtonMini>
          <ButtonMini
            style={score === 3 ? STYLE_SELECTED : null}
            variant="outlined"
            onClick={() => setScore(3)}
            startIcon={<StarIcon style={{color: '#039be5'}} />}
          >
            3
          </ButtonMini>
          <ButtonMini
            style={score === 2 ? STYLE_SELECTED : null}
            variant="outlined"
            onClick={() => setScore(2)}
            startIcon={<StarIcon style={{color: '#039be5'}} />}
          >
            2
          </ButtonMini>
          <ButtonMini
            style={score === 1 ? STYLE_SELECTED : null}
            variant="outlined"
            onClick={() => setScore(1)}
            startIcon={<StarIcon style={{color: '#039be5'}} />}
          >
            1
          </ButtonMini>
        </FlexRowEnd>
      </FlexRowFrist>

      {error ? (
        <div>{error}</div>
      ) : !data ||
        (score && data.reviews[0] && data.reviews[0].value !== score) ? (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 12}}>
          <CircularProgress />
        </div>
      ) : (
        <Comment
          data={data.reviews}
          company={company}
          id={id}
          name={name}
          isAdmin={isAdmin}
          loadQuery={refetch}
          type={type}
        />
      )}
    </>
  )
}
