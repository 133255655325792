import styled from 'styled-components'

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${props => (props.paddingTop ? props.paddingTop : 0)};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : 0)};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : 0)};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : 0)};
`

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${props => (props.padding ? props.padding : 0)};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : 0)};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : 0)};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : 0)};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : 0)};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
`
