import gql from 'graphql-tag'
export const GET_CATEGORIES_PRODUCT = gql`
  {
    marketplace_product_categories(where: {parent: {_is_null: true}}) {
      id
      code
      name
      fa_class
      thumbnail
      parent
      count_course
      marketplace_product_categories {
        id
        code
        name
        fa_class
        thumbnail
        parent
        count_course
      }
    }
  }
`
