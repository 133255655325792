import {ApolloClient} from 'apollo-boost'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {onError} from 'apollo-link-error'
import {HttpLink} from 'apollo-link-http'

import {GRAPHQL_URL, ROLE, SSO_URL} from '../utils/globals'
import history from '../utils/history'

const cache = new InMemoryCache()
const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: 'include',
  fetch: (uri, options) => {
    return fetch(uri, {
      ...options,
      headers: {
        'X-Hasura-Role': ROLE,
        ...options.headers,
      },
    })
  },
})

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.map(({message, extensions}) => {
      if (process.env.NODE_ENV !== 'development') {
        if (
          extensions.code === 'invalid-jwt' ||
          extensions.code === 'invalid-headers'
        ) {
          window.location = SSO_URL
        } else if (extensions.code === 'access-denied') {
          history.push('/access-denied')
          window.location.reload()
        }
      } else {
        console.log(`[GraphQL error]: Message: ${message}`)
      }
    })
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache,
})

export default client
