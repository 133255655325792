import React from 'react'
import Awaiting from '../../components/vendor-tabs/component-transaction/Awaiting'
import TransactionPage from '../../components/vendor-tabs/component-transaction/Transaction'

const Transaction = props => {
  let regexUrl = ''
  if (props.location.pathname) {
    const regexPattern = /[a-z0-9-]+/gm
    regexUrl = props.location.pathname.match(regexPattern)
  }

  const type = regexUrl[1] ? `${regexUrl[1]}` : 'awaiting'

  return (
    <>
      {type === 'awaiting' && <Awaiting />}
      {type !== 'awaiting' && <TransactionPage />}
    </>
  )
}

export default Transaction
