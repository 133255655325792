import gql from 'graphql-tag'

export const GET_USER_BANK_ACCOUNTS = gql`
  query($userId: uuid!, $search: String) {
    accounts: people_profile_banks(
      where: {
        user: {_eq: $userId}
        account_name: {_ilike: $search}
        deletedAt: {_is_null: true}
      }
    ) {
      id
      account_name
      account_number
      provider: global_bank {
        id
        name
        logo
      }
    }
  }
`

export const GET_BANK_PROVIDERS = gql`
  query($search: String) {
    providers: global_banks(where: {name: {_ilike: $search}}) {
      id
      name
      logo
    }
  }
`
