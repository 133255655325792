import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'

import usePrevious from '../../../hooks/usePrevious'
import {GET_VENDOR_SHIPPING_ADDRESSES} from '../../../graphql/queries/getShippingVendor.query'
import {GET_VENDOR_COURIERS} from '../../../graphql/queries/profile/getListShipping'
import {GET_COURIER_PRICING} from '../../../graphql/queries/getCalculateCost.query'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import {ModalContent, ModalHeader} from '../../../components/modal/Modal'
import {convertToRupiah} from '../../../utils/helpers'

const EMPTY_ARRAY = []

const STATE_LOADING = 1
const STATE_ERROR = 2
const STATE_READY = 3

const ChooseCourierModal = props => {
  const {
    open,
    onClose,
    onCourierConfirm,
    courierData,
    destination,
    companyId,
    productWeight,
  } = props

  const prevOpen = usePrevious(open)
  const isNeverOpen = !prevOpen && prevOpen === open

  const client = useApolloClient()
  const [state, setState] = React.useState(STATE_LOADING)
  const [options, setOptions] = React.useState(EMPTY_ARRAY)
  const [selected, setSelected] = React.useState(null)

  const {data: addressData, error: addressError} = useQuery(
    GET_VENDOR_SHIPPING_ADDRESSES,
    {
      skip: !companyId,
      fetchPolicy: 'cache-and-network',
      variables: {
        companyId: companyId,
      },
    }
  )

  const {data: vendorCourierData, error: vendorCourierError} = useQuery(
    GET_VENDOR_COURIERS,
    {
      skip: !companyId,
      fetchPolicy: 'cache-and-network',
      variables: {
        vendor: companyId,
        limit: 1,
      },
    }
  )

  React.useEffect(() => {
    setSelected(null)
    setState(STATE_LOADING)
    setOptions(EMPTY_ARRAY)

    if (!vendorCourierData || !addressData) {
      return
    }

    const vendor = vendorCourierData.company_shipping_availables[0]
    const origin = addressData.company_origin_shipping_addresses[0]

    const calculate = async provider => {
      const {data} = await client.query({
        query: GET_COURIER_PRICING,
        variables: {
          courier: provider,
          origin: origin.district_code,
          destination: destination.district_code,
          weight: productWeight,
        },
      })

      return data.calculateCost[0].costs.map(cost => ({...cost, provider}))
    }

    const promises = []

    if (vendor) {
      for (const provider of vendor.courier_services) {
        const promise = calculate(provider.courier)
        promises.push(promise)
      }
    }

    let cancelled = false
    const settled = Promise.allSettled(promises)

    settled.then(array => {
      if (cancelled) {
        return
      }

      let next = STATE_READY
      const options = []

      for (const promised of array) {
        if (promised.status === 'rejected') {
          next = STATE_ERROR
          continue
        }

        for (const item of promised.value) {
          options.push(item)
        }
      }

      setState(next)
      setOptions(options)
    })

    return () => {
      cancelled = true
    }
  }, [vendorCourierData, addressData, destination])

  if (isNeverOpen) {
    return null
  }

  const handleCourierChange = event => {
    setSelected(parseInt(event.target.value))
  }

  const handleConfirm = () => {
    const row = options[selected]

    if (onCourierConfirm) {
      onCourierConfirm(row)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <ModalHeader onClose={onClose}>Shipping Service</ModalHeader>

      <ModalContent>
        <Box display="grid" gridGap={16}>
          <Typography>Choose Available Shipping Service</Typography>

          {addressError || vendorCourierError ? (
            <div>Something went wrong while retrieving shipping services</div>
          ) : state === STATE_LOADING ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress />
            </div>
          ) : (
            options.length < 1 && (
              <div>The vendor has not provided any shipping services yet.</div>
            )
          )}

          <RadioGroup value={selected} onChange={handleCourierChange}>
            <Box display="grid" gridGap={16}>
              {options.map((item, idx) => {
                const provider = courierData.global_shipping_providers.find(
                  provider => provider.name === item.provider
                )

                return (
                  <Box key={idx} display="flex">
                    <FormControlLabel value={idx} control={<Radio />} />

                    <img
                      src={provider.logo}
                      height="48"
                      width="80"
                      style={{objectFit: 'contain'}}
                    />

                    <Box display="grid" alignContent="center" ml={3}>
                      <Typography>
                        {item.provider} {item.service}
                      </Typography>
                      <Typography>
                        {convertToRupiah(item.cost[0].value)} (
                        {item.cost[0].etd || '0'} day)
                      </Typography>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </RadioGroup>

          <Button
            disabled={selected == null}
            onClick={handleConfirm}
            variant="contained"
            color="secondary"
            size="large"
          >
            Confirm
          </Button>
        </Box>
      </ModalContent>
    </Dialog>
  )
}

export default ChooseCourierModal
