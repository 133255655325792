import React from 'react'

import DetailVendorDescription from './DetailVendorDescription'
import DetailVendorReview from './DetailVendorReview'
import DetailVendorProducts from './DetailVendorProducts'

const DetailVendor = ({
  selectedSideBar,
  id,
  dataDetail,
  filter,
  setFilter,
  title,
}) => {
  if (selectedSideBar === 'description') {
    return <DetailVendorDescription dataDetail={dataDetail} id={id} />
  } else if (selectedSideBar === 'review') {
    return <DetailVendorReview id={id} dataDetail={dataDetail} />
  } else if (selectedSideBar === 'products') {
    return (
      <DetailVendorProducts
        title={title}
        id={id}
        filter={filter}
        setFilter={setFilter}
      />
    )
  }
}

export default DetailVendor
