import React from 'react'
import {GlobalHeader} from '@worklifebeyond/wlb-utils-components'

export const Header = ({hideHeader}) => {
  const env = {
    REACT_APP_SOSMED: process.env.REACT_APP_HC_SOSMED + '/',
    REACT_APP_HC_ADMIN: process.env.REACT_APP_HC_ADMIN,
    REACT_APP_HC_HOLDING: process.env.REACT_APP_HC_HOLDING,
    REACT_APP_DOMAIN: process.env.REACT_APP_COOKIE,
    HOME_URL: process.env.REACT_APP_VENDOR,
    REACT_APP_CHAT: process.env.REACT_APP_CHAT,
    // notif_path dev = vendor, staging,prod = all
    NOTIF_PATH: 'all',
    SSO_URL: process.env.REACT_APP_SSO + '/',
    REACT_APP_LEARNING: process.env.REACT_APP_LEARNING_URL,
    rest_url: process.env.REACT_APP_API_PCS_URL,
    wlb_rest_url: process.env.REACT_APP_API_URL,
  }

  return !hideHeader ? <GlobalHeader env={env} language="indonesian" /> : null
}
