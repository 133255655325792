import React, {useEffect} from 'react'
import {TypeMenuContainer} from '../../../components/job-portal/JobPortalStyles'
import {
  TextDeepTeal,
  TextBlack1,
} from '../../../components/utils-components/VendorStyles'
import {
  Card,
  FormGroup,
  makeStyles,
  List,
  Collapse,
  ListItem,
  CircularProgress,
} from '@material-ui/core'

import {ListItemTexts} from './SidebarStyles'

// grapql
import {useQuery} from '@apollo/react-hooks'
import {GET_CATEGORIES_PRODUCT} from '../../../graphql/queries/getCategoryProduct'

// import HomeSidebar from './sidebar-component-collections/Home'
import Filter from './sidebar-component-collections/Filter'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Category from './sidebar-component-collections/Category'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 360,
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
    width: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  fullWidth: {
    width: '100% !important',
  },
  fontSize14: {
    fontSize: '14px !important',
  },
  heightForm: {
    paddingTop: '15px',
  },
  paddingBottom15: {
    paddingBottom: '15px !important',
  },
  paddingTop0: {
    paddingTop: '0px !important',
  },
}))

// const Sidebar = ({ setTypeFilter, ƒ }) => {
const Sidebar = ({setCategoryFilter, setTypeFilter, typeTab}) => {
  const classes = useStyles()
  const [categories, setCategories] = React.useState([])
  const {data, error} = useQuery(GET_CATEGORIES_PRODUCT)

  useEffect(() => {
    if (data) {
      const dataCategories = []
      data.marketplace_product_categories.forEach((row) => {
        const childCategories = []
        row.marketplace_product_categories &&
          row.marketplace_product_categories.forEach((child) => {
            childCategories.push({
              id: child.id,
              name: child.name,
            })
          })
        dataCategories.push({
          id: row.id,
          name: row.name,
          collapse: childCategories.length !== 0 ? false : null,
          children: childCategories,
        })
      })
      if (categories.length === 0) {
        setCategories(dataCategories)
      }
    }
  }, [categories, data, setCategories])

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  function ListItemLink(props) {
    const {name, open, ...other} = props
    // const primary = breadcrumbNameMap[to];

    return (
      <li>
        <ListItem button {...other}>
          <ListItemTexts primary={name} />
          {open != null ? (
            open ? (
              <ExpandLess />
            ) : (
              <ArrowForwardIosIcon className={classes.fontSize14} />
            )
          ) : null}
        </ListItem>
      </li>
    )
  }
  const handleClick = (id) => {
    const dataCategories = categories
    const category = dataCategories.find((val) => val.id === id)
    if (category.collapse !== null) {
      category.collapse = !category.collapse
      setCategories([...dataCategories])
    }
  }

  return (
    <>
      <TextBlack1 className={classes.paddingBottom15}>Filter</TextBlack1>
      <Card className={classes.paddingTop0}>
        <TypeMenuContainer>
          <TextDeepTeal>Category</TextDeepTeal>
          <FormGroup column="true" className={classes.fullWidth}>
            {/* <HomeSidebar type={'type'} filter={setTypeFilter} /> */}
            <nav className={classes.lists} aria-label="mailbox folders">
              <List>
                {categories &&
                  categories.map((row, key) => {
                    return (
                      <>
                        <ListItemLink
                          key={key}
                          name={row.name}
                          open={row.collapse}
                          onClick={() => handleClick(row.id)}
                        />
                        {row.children && (
                          <Collapse
                            // key={k}
                            component="li"
                            in={row.collapse}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List disablePadding>
                              <Category
                                data={row.children}
                                filter={setCategoryFilter}
                              />
                            </List>
                          </Collapse>
                        )}
                      </>
                    )
                  })}
              </List>
            </nav>
          </FormGroup>
          <Filter setTypeFilter={setTypeFilter} typeTab={typeTab} />
        </TypeMenuContainer>
      </Card>
    </>
  )
}

export default Sidebar
