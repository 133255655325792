import gql from 'graphql-tag'

export const REQUEST_CLAIM = gql`
  mutation($invoiceIds: [String]!) {
    requestClaim(claim_type: 2, invoice_ids: $invoiceIds) {
      success
    }
  }
`

export const SET_CLAIM_STATUS = gql`
  mutation($requestId: bigint!, $status: String!) {
    update_people_work_claims_by_pk(
      pk_columns: {id: $requestId}
      _set: {status: $status}
    ) {
      id
      status
    }
  }
`

export const REQUEST_CLAIM_INVOICE_EXPORTS = gql`
  mutation($invoiceId: String!) {
    exportInvoice(invoiceId: $invoiceId) {
      success
    }
  }
`
