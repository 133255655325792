import React, {useState} from 'react'
import ReplyIcon from '@material-ui/icons/Reply'
import {BoxChildComment} from '../ProductStyles'
import {
  FlexBetween,
  FlexColumnFirst,
  FlexRowEnd,
  FlexRowFrist,
  TextComment,
  TextDeepTeal,
  TextSmallBlack,
  TextSmallGrey,
} from '../../../utils-components/VendorStyles'
import {getDate} from '../../../../utils/helpers'
import {
  Menu,
  Button,
  Divider,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core'
import {REPLY_REVIEW} from '../../../../graphql/mutations/transaction/reviewProduct.mutation'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import MoreHoriz from '@material-ui/icons/MoreHoriz'

function ReplyComment({row, company, loadQuery, isAdmin}) {
  const [isComment, setComment] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [respond, setRespon] = useState(row.respond)
  const {enqueueSnackbar} = useSnackbar()
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const open = Boolean(anchorEl)

  const [replyReview] = useMutation(REPLY_REVIEW)

  const handleSave = (isDelete = false) => {
    replyReview({
      variables: {
        reviewId: row.id,
        respond: !isDelete ? respond : '',
      },
      context: {headers: {'X-Hasura-Role': 'organization-administrator'}},
    })
      .then(() => {
        enqueueSnackbar('Reply success')
        loadQuery()
        setComment(false)
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar('Reply  Failed, try again later')
      })
  }
  return (
    <>
      {isComment ? (
        <FormComment
          respond={respond}
          setRespon={setRespon}
          handleSave={handleSave}
        />
      ) : (
        <>
          {row.respond !== '' ? (
            <BoxChildComment>
              <FlexBetween>
                <TextSmallGrey style={{color: '#A9A8A8'}}>
                  {company.brand_name} , {getDate(row.last_modified)}
                </TextSmallGrey>
                {isAdmin && (
                  <>
                    {' '}
                    <IconButton
                      title="More"
                      aria-controls={`menus-${row.id}`}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreHoriz />
                    </IconButton>
                    <Menu
                      id={`menus-${row.id}`}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                    >
                      <MenuItem
                        style={{color: '#014a62'}}
                        onClick={() => setComment(true)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        style={{color: '#ef4d5e'}}
                        onClick={() => {
                          handleSave(true)
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </FlexBetween>

              <TextComment>{row.respond}</TextComment>
            </BoxChildComment>
          ) : (
            isAdmin && (
              <FlexRowFrist style={{alignItems: 'center'}}>
                <ReplyIcon
                  onClick={() => setComment(true)}
                  style={{
                    padding: '15px 0px',
                    fontSize: '20px',
                    color: '#014a62',
                    cursor: 'pointer',
                  }}
                />
                <TextDeepTeal>Reply</TextDeepTeal>
              </FlexRowFrist>
            )
          )}
        </>
      )}

      <Divider style={{backgroundColor: '#eff2f4', margin: '10px 0px'}} />
    </>
  )
}

export default ReplyComment

function FormComment(props) {
  const {setComment, respond, setRespon, handleSave} = props

  return (
    <FlexColumnFirst style={{width: '100%'}}>
      <TextSmallBlack>Reply</TextSmallBlack>
      <TextField
        value={respond}
        onChange={e => setRespon(e.target.value)}
        fullWidth
        multiline
        maxRows={4}
        variant="outlined"
      />
      <FlexRowEnd style={{padding: '10px 0px'}}>
        <Button
          onClick={() => setComment(false)}
          // disabled={load}
          variant="text"
          color="error"
          style={{color: '#EF4D5E'}}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSave(false)}
          // disabled={load}
          variant="contained"
          color="secondary"
          // fullWidth
        >
          Reply
        </Button>
      </FlexRowEnd>
    </FlexColumnFirst>
  )
}
