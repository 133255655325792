import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Card,
  Typography,
  Divider,
  Button,
  CircularProgress,
} from '@material-ui/core'

import {convertToRupiah, getYearStartEndDate} from '../../../../utils/helpers'
import {USER_ID} from '../../../../utils/globals'
import {GET_USER_BALANCE} from '../../../../graphql/queries/getBalanceUser.query'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.spacing(1.75),
    fontWeight: 700,
  },

  root: {
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(3),
  },

  table: {
    borderSpacing: `0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(-2),

    '& th': {
      color: theme.palette.grey[600],
      fontSize: theme.spacing(1.75),
      lineHeight: `${theme.spacing(3.25)}px`,
      textAlign: 'left',
      verticalAlign: 'top',
    },
    '& td': {
      textAlign: 'right',
    },
  },

  nominal: {
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
  },
  amount: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.grey[600],
  },
  available: {
    fontWeight: 700,
  },

  error: {
    fontSize: theme.spacing(1.75),
  },
}))

function RequestSummaryCard(props) {
  const {selection, hasBankAccount, disabled, loading, onSubmit} = props

  const styles = useStyles()

  const ranges = React.useMemo(() => getYearStartEndDate(), [])

  const {data, loading: loadingBalance, error} = useQuery(GET_USER_BALANCE, {
    variables: {
      userID: USER_ID,
      startDate: ranges.start,
      endDate: ranges.end,
    },
    fetchPolicy: 'cache-and-network',
  })

  const total = data?.total.aggregate.sum.value || 0
  const claimed = data?.claimed.aggregate.sum.value || 0

  const available = total - claimed

  const totalNominal = React.useMemo(
    () => selection.reduce((accu, next) => accu + next.final_price, 0),
    [selection]
  )

  const formattedNominal = React.useMemo(() => convertToRupiah(totalNominal), [
    totalNominal,
  ])

  const formattedAvailable = React.useMemo(() => convertToRupiah(available), [
    available,
  ])

  const isNotSelected = !selection.length
  const isOverLimit = totalNominal > available

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  return (
    <Card className={styles.root}>
      <Typography className={styles.title}>Claim Summary</Typography>

      <Divider />

      {loading || loadingBalance ? (
        <CircularProgress style={{justifySelf: 'center', marginTop: 8}} />
      ) : (
        <>
          {!hasBankAccount && (
            <>
              <Typography color="error" className={styles.error}>
                Your main bank account has not been set, please contact your
                administrator.
              </Typography>

              <Divider />
            </>
          )}

          <table className={styles.table}>
            <tbody>
              <tr>
                <th>Total</th>
                <td>
                  <Typography
                    className={styles.nominal}
                    color={isOverLimit ? 'error' : 'primary'}
                  >
                    {formattedNominal}
                  </Typography>
                  <Typography className={styles.amount}>
                    {selection.length} transactions
                  </Typography>
                </td>
              </tr>
              <tr>
                <th>Available Limit</th>
                <td>
                  <Typography className={styles.available}>
                    {formattedAvailable}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>

          <Button
            disabled={
              disabled || !hasBankAccount || isNotSelected || isOverLimit
            }
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            fullWidth
          >
            Request Claim
          </Button>
        </>
      )}
    </Card>
  )
}

export default RequestSummaryCard
