import gql from 'graphql-tag'

export const GET_PRINT_BILLS_PENDING = gql`
  query($paymentID: uuid!) {
    details: global_payments_by_pk(id: $paymentID) {
      id
      code
      payment_status
      date_created
      final_price
      payment_method
      payment_data
      payment_proof
      global_wallet_account {
        id
        latest_balance
        global_users {
          id
          name
          avatar
        }
      }
      global_invoices {
        id
        global_wallet_vendor {
          company_profiles {
            id
            brand_name
            legal_name
            logo
          }
        }
        final_price
        delivery_option
        delivery_data
        products: global_invoice_items(where: {type: {_neq: "courier"}}) {
          id
          type
          itemname
          single_price
          thumbnail
          weight
          qty
        }
        courier: global_invoice_items(where: {type: {_eq: "courier"}}) {
          id
          type
          itemname
          single_price
          thumbnail
          weight
          qty
        }
      }
    }
  }
`

export const GET_PRINT_BILLS_PAID = gql`
  query($invoiceID: uuid!) {
    details: global_invoices_by_pk(id: $invoiceID) {
      id
      code
      date_added
      global_wallets {
        id
        global_users {
          id
          name
          avatar
        }
        latest_balance
      }
      global_wallet_vendor {
        id
        company_profiles {
          id
          legal_name
          brand_name
          logo
        }
      }
      final_price
      delivery_option
      delivery_data
      products: global_invoice_items(where: {type: {_neq: "courier"}}) {
        id
        type
        itemname
        single_price
        thumbnail
        weight
        qty
        note
      }
      courier: global_invoice_items(where: {type: {_eq: "courier"}}) {
        id
        type
        itemname
        single_price
        thumbnail
        weight
        qty
      }
      global_payment {
        id
        code
        date_created
        final_price
        payment_method
        payment_data
        payment_proof
        payment_status
      }
    }
  }
`
