import React, {useState} from 'react'
import {
  TransactionContainer,
  TitleTransactionDarkBlue,
} from '../../utils-components/VendorStyles'
import {FormControl, Select} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'

import {withRouter} from 'react-router-dom'

import Filter from '../../Filter/Filter'
import ListAwaiting from './list-transcation/ListAwaiting'
// import ButtonMidtrans from './ButtonMidtrans'

const Awaiting = () => {
  const [filter, setFilter] = useState({
    search_filter: null,
    dateFrom: null,
    dateTo: null,
    totalFrom: null,
    totalTo: null,
    orderBy: 'desc',
  })

  const handleChangeFilter = event => {
    setFilter({...filter, [event.target.name]: event.target.value})
  }
  // console.log(props.match)
  return (
    <TransactionContainer>
      <TitleTransactionDarkBlue>Awaiting</TitleTransactionDarkBlue>
      <div style={{paddingTop: '25px'}}>
        <Filter
          filter={filter}
          setFilter={setFilter}
          target={['Date Range', 'Price Range']}
        />
        <FormControl variant="standard" style={{paddingTop: '10px'}}>
          <Select
            select
            variant="standard"
            disableUnderline
            style={{width: '100%'}}
            onChange={e => handleChangeFilter(e)}
            value={filter.orderBy}
            inputProps={{
              // disableUnderline: true,
              name: 'orderBy',
            }}
          >
            <MenuItem value="desc">Latest</MenuItem>
            <MenuItem value="asc">Oldest</MenuItem>
          </Select>
        </FormControl>
      </div>
      <ListAwaiting filter={filter} />
      {/* <ButtonMidtrans token={props.match.params.token} /> */}
    </TransactionContainer>
  )
}

export default withRouter(Awaiting)
