import React, {Fragment} from 'react'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {withRouter, Link} from 'react-router-dom'
import {useSnackbar} from 'notistack'

import {
  Dialog,
  Box,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core'

import {AttachmentCard} from '@worklifebeyond/wlb-utils-components'

import {ModalHeader, ModalContent} from '../../../modal/Modal'
import {
  TwoColumnTrasaction,
  TextBlack,
  LitleTextGrey,
  FlexRowFrist,
  TextBlue,
  TextGreen,
  PaperTransaction,
  LitleTextWaterMelon,
  ButtonRed,
  PrintBox,
} from '../../../utils-components/VendorStyles'
import useStyles from '../../../../pages/transaction/TransactionPageStyles'

import {convertToRupiah, getLongDate} from '../../../../utils/helpers'

import {UPDATE_ORDER} from '../../../../graphql/mutations/transaction/updateOrder.mutation'
import DetailShipping from './DetailShipping'
import DetailProduct from './DetailProduct'

import {MATRIX_URL} from '../../../../utils/globals'
import {GET_ID_CHAT_VENDOR} from '../../../../graphql/queries'

const getPurchaseStatus = (status, isInvoiceData) => {
  switch (status) {
    case 'pending':
      return isInvoiceData
        ? 'Awaiting for Payment Confirmation'
        : 'Awaiting for Payment'
    case 'paid':
    case 'refund_pending':
      return 'Awaiting Vendor Confirmation'
    case 'packing':
    case 'review':
      return 'On Process'
    case 'shipping':
      return 'On Delivery'
    case 'completed':
      return 'Completed'
    case 'refund_paid':
      return 'Refunded'
    case 'rejected':
      return 'Rejected'
  }
}

const getPurchaseStatusColor = status => {
  switch (status) {
    case 'pending':
      return '#ffa000'
    case 'paid':
    case 'refund_pending':
      return '#a35a31'
    case 'packing':
    case 'review':
      return '#874caf'
    case 'shipping':
      return '#039be5'
    case 'completed':
    case 'refund_paid':
    case 'rejected':
      return '#4caf50'
  }
}

// FIXME(intrnl): The `data` property here can either be a `global_invoices` or
// a `global_payments` depending on which transactions listing you were in,
// this is not ideal, we should be enforcing `global_invoices` only.

// For the time being, we'll assume that if you pass `global_payments` here, it
// means you're dealing with a purchase that is still awaiting payment.
const PurchaseDetails = props => {
  const {
    open,
    onClose,
    data,
    title = 'Purchase Details',
    setId,
    onCancelOrder,
    onHowToPay,
    onAskRefund,
  } = props

  const {enqueueSnackbar} = useSnackbar()

  const isInvoiceData = data && data.__typename === 'global_invoices'

  const status = data && isInvoiceData ? data.status : 'pending'

  const classes = useStyles()

  const [jumlahProduct, setjumlahProduct] = React.useState(0)
  const [total_priceProduct, setTotalPrice] = React.useState(0)
  const [total_weight, setWeight] = React.useState(0)
  const [priceShipping, setPriceShipping] = React.useState(0)

  const [UPDATE_ORDER_RECIVIED] = useMutation(UPDATE_ORDER, {
    skip: !data,
    variables: {
      status: 'completed',
      id: data?.id,
    },
  })

  const {data: dataChat, loading: loading2, error: error2} = useQuery(
    GET_ID_CHAT_VENDOR,
    {
      skip: !data || !isInvoiceData,
      variables: {
        company: data?.global_wallet_vendor?.company_profiles[0]?.id,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'public',
        },
      },
    }
  )

  if (loading2) {
    return <CircularProgress />
  } else if (error2 !== undefined) {
    return <div>{error2}</div>
  }

  const handleUpdate = () => {
    UPDATE_ORDER_RECIVIED()
    onClose()
    enqueueSnackbar('Item has been received')
  }

  const handleButtonDetail = () => {
    const nodes = []
    let key = 0

    if (status === 'pending') {
      nodes.push(
        <ButtonRed
          key={key++}
          onClick={() => {
            setId(data.id)
            onCancelOrder()
          }}
          variant="outlined"
          fullWidth
        >
          Cancel Order
        </ButtonRed>
      )

      nodes.push(
        <Button
          key={key++}
          onClick={() => onHowToPay(data)}
          variant="contained"
          color="secondary"
          fullWidth
        >
          Pay Now
        </Button>
      )
    }

    if (
      onAskRefund &&
      (status === 'paid' ||
        status === 'refund_pending' ||
        status === 'packing' ||
        status === 'review')
    ) {
      nodes.push(
        <Button
          key={key++}
          disabled={
            status === 'refund_pending' ||
            data.marketplace_refund_requests.length > 0 ||
            data.global_wallet_vendor?.company_profiles.length < 1
          }
          onClick={onAskRefund}
          variant="outlined"
          color="secondary"
        >
          Ask for Refund
        </Button>
      )
    }

    if (status !== 'pending') {
      nodes.push(
        <Button
          key={key++}
          href={
            process.env.REACT_APP_CHAT +
            `/?chat=@${
              data.global_wallet_vendor?.company_profiles.length > 0
                ? dataChat.people_work_placements.length > 0
                  ? dataChat.people_work_placements[0].user
                  : null
                : data.global_wallet_vendor.global_users[0].id
            }:${MATRIX_URL}#/`
          }
          target="_blank"
          variant={status === 'shipping' ? 'outlined' : 'contained'}
          color="secondary"
          fullWidth
        >
          Chat Vendor
        </Button>
      )
    }

    if (status === 'shipping') {
      nodes.push(
        <Button
          key={key++}
          onClick={handleUpdate}
          variant="contained"
          color="secondary"
          fullWidth
        >
          Confirm Items Received
        </Button>
      )
    }

    return nodes
  }

  const transferProofs = isInvoiceData
    ? data?.global_payment.global_direct_transfer?.global_direct_transfer_proofs
    : data?.global_direct_transfer?.global_direct_transfer_proofs

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      {data && (
        <Fragment>
          <ModalHeader onClose={onClose}>
            <Typography>{title}</Typography>
          </ModalHeader>
          <ModalContent>
            <Box display="grid" gridGap={16}>
              <div>
                <LitleTextGrey>Invoice Number</LitleTextGrey>
                <FlexRowFrist>
                  <TextBlack>
                    ({!isInvoiceData ? data.global_invoices[0].code : data.code}
                    )
                  </TextBlack>
                  <PrintBox
                    style={{paddingLeft: '8px'}}
                    component={Link}
                    to={{
                      pathname: `/transaction/print-bills/show/${data.id}/${
                        isInvoiceData ? 'paid' : 'Awaiting'
                      }`,
                    }}
                  >
                    Print
                  </PrintBox>
                </FlexRowFrist>
              </div>

              <div>
                <LitleTextGrey>Status</LitleTextGrey>
                <div
                  style={{
                    backgroundColor: getPurchaseStatusColor(status),
                    padding: '5px',
                    color: '#ffffff',
                    borderRadius: '5px',
                    display: 'inline-block',
                    marginTop: 4,
                  }}
                >
                  {getPurchaseStatus(status, isInvoiceData)}
                </div>
              </div>

              <div>
                <LitleTextGrey>
                  {isInvoiceData ? 'Payment date' : 'Due date'}
                </LitleTextGrey>
                <TextBlack>
                  {getLongDate(
                    isInvoiceData
                      ? data.global_payment.date_created
                      : data.global_direct_transfer.expires_at
                  )}
                </TextBlack>
              </div>

              <div>
                <LitleTextGrey>Payment Status</LitleTextGrey>
                {status === 'refund_paid' ? (
                  <LitleTextWaterMelon>Refunded</LitleTextWaterMelon>
                ) : status === 'rejected' ? (
                  <LitleTextWaterMelon>Rejected</LitleTextWaterMelon>
                ) : isInvoiceData ? (
                  <TextGreen>Paid</TextGreen>
                ) : transferProofs?.length > 0 ? (
                  <LitleTextWaterMelon style={{color: '#ffa000'}}>
                    Waiting for Payment Confirmation
                  </LitleTextWaterMelon>
                ) : (
                  <LitleTextWaterMelon>Not Paid</LitleTextWaterMelon>
                )}
              </div>

              {transferProofs?.length > 0 && (
                <Box display="flex">
                  {transferProofs.map(file => (
                    <AttachmentCard
                      key={file.id}
                      name={file.name}
                      url={file.file}
                      style={{height: 110, width: 110}}
                    />
                  ))}
                </Box>
              )}

              {status === 'refund_paid' && (
                <>
                  <div>
                    <LitleTextGrey>Refund Receipt</LitleTextGrey>
                    <Box display="flex" mt={1}>
                      {data.marketplace_refund_requests[0]?.marketplace_refund_request_files.map(
                        file => (
                          <AttachmentCard
                            key={file.id}
                            name={file.name}
                            url={file.file}
                            style={{height: 110, width: 110}}
                          />
                        )
                      )}
                    </Box>
                  </div>
                </>
              )}
            </Box>
            <Box className={classes.bgGrey} mx={-3} px={3} my={3} py={2}>
              <DetailShipping
                type={isInvoiceData ? null : 'awaiting'}
                data={data}
                setPriceShipping={setPriceShipping}
              />

              <TextBlack style={{paddingTop: '15px'}}>Product List</TextBlack>
              <DetailProduct
                data={data}
                type={isInvoiceData ? null : 'awaiting'}
                setjumlahProduct={setjumlahProduct}
                setTotalPrice={setTotalPrice}
                setWeight={setWeight}
                status={status}
              />
            </Box>
            <div>
              <TextBlack>Payment</TextBlack>
              <PaperTransaction>
                <TwoColumnTrasaction>
                  <TextBlack>
                    Product Total ({jumlahProduct} Products)
                  </TextBlack>
                  <TextBlack>{convertToRupiah(total_priceProduct)}</TextBlack>
                </TwoColumnTrasaction>
                <TwoColumnTrasaction>
                  <TextBlack>Delivery Fee ({total_weight} Gram)</TextBlack>
                  <TextBlack>{convertToRupiah(priceShipping)}</TextBlack>
                </TwoColumnTrasaction>
                <TwoColumnTrasaction className={classes.marginTop10}>
                  <TextBlack>Grand Total</TextBlack>
                  <TextBlue>{convertToRupiah(data.final_price)}</TextBlue>
                </TwoColumnTrasaction>
              </PaperTransaction>

              <Box display="grid" gridGap={8} mt={1}>
                {handleButtonDetail()}
              </Box>
            </div>
          </ModalContent>
        </Fragment>
      )}
    </Dialog>
  )
}
export default withRouter(PurchaseDetails)
