import {getCookie} from './helpers'

const {
  REACT_APP_GRAPHQL_URL,
  REACT_APP_GRAPHQL_URL_WS,
  REACT_APP_SSO,
  REACT_APP_UPLOAD_URL,
  REACT_APP_MAP_KEY,
  REACT_APP_COOKIE,
  REACT_APP_API_PCS_URL,
  REACT_APP_WLB_URL,
  REACT_APP_MATRIX_URL,
  REACT_APP_LEARNING_URL,
  REACT_APP_HC_SOSMED,
  REACT_APP_SENTRY,
  REACT_APP_SENTRY_ENV,
  REACT_APP_NEW_LEARNING,
} = process.env
export const userData = getCookie('userData')
  ? JSON.parse(getCookie('userData'))
  : []
let access_token = ''
let company_id = ''
let company_name = ''
let company_logo = ''
let user_id = ''
let roles = []
let organization = ''
let avatar = ''
if (userData && userData.oauth) {
  company_id = userData.company_id
  company_name = userData.company_name
  company_logo = userData.company_logo
  access_token = userData.oauth.access_token
  user_id = userData.id
  roles = userData.roles
  organization = userData.position_id ? userData.position_id : 'sa'
  avatar = userData.avatar
}
export const GRAPHQL_URL = REACT_APP_GRAPHQL_URL
export const API_PCS_URL = REACT_APP_API_PCS_URL
export const GRAPHQL_WS_URL = REACT_APP_GRAPHQL_URL_WS
export const TOKEN = access_token
export const COMPANY_ID = company_id
export const COMPANY_NAME = company_name
export const COMPANY_LOGO = company_logo
export const ROLE = 'user'
export const ROLES = roles
export const USER_ID = user_id
export const ORGANIZATION = organization
export const UPLOAD_URL = REACT_APP_UPLOAD_URL
export const SSO_URL = REACT_APP_SSO
export const AVATAR = avatar
export const MAP_KEY = REACT_APP_MAP_KEY
export const APP_COOKIE = REACT_APP_COOKIE
export const WLB_URL = REACT_APP_WLB_URL
export const MATRIX_URL = REACT_APP_MATRIX_URL
export const LEARNING_URL = REACT_APP_LEARNING_URL
export const NEW_LEARNING_URL = REACT_APP_NEW_LEARNING
export const HC_URL = REACT_APP_HC_SOSMED
export const SENTRY_URL = REACT_APP_SENTRY
export const SENTRY_ENV = REACT_APP_SENTRY_ENV
