import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import {ModalContent, ModalHeader} from '../../../components/modal/Modal'

import defaultProductImage from '../../../assets/images/default_product.png'

import usePrevious from '../../../hooks/usePrevious'
import {convertToRupiah} from '../../../utils/helpers'
import {GET_VENDORS_BANK_ACCOUNTS} from '../../../graphql/queries/profile/getCart.query'

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: '#eff2f4',
    borderRadius: 4,
    padding: 16,
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: 700,
    margin: 0,
  },
  headerPrice: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 700,
    margin: `8px 0 0`,
  },

  content: {
    display: 'grid',
    gridGap: 16,

    '& .MuiLink-button': {
      fontFamily: 'inherit',
      fontSize: 12,
    },
  },
  card: {
    padding: 16,
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
  },
  cardHeader: {
    display: 'flex',
    marginBottom: 16,
  },
  cardTitle: {
    marginLeft: 16,
    flexGrow: 1,
  },
  cardChooseTitle: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 16,
  },
  cardBanks: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gridGap: 8,
  },

  bankImage: {
    width: '100%',
    height: 64,
    objectFit: 'contain',
  },
  bankSelectedBtn: {
    outline: `2px solid ${theme.palette.secondary.main}`,
    outlineOffset: -1,
  },

  flex: {
    lineHeight: '22px',
    display: 'flex',
    marginBottom: 4,
  },
  avatar: {
    height: 22,
    width: 22,
    marginRight: 8,
  },
  companyName: {
    flexGrow: 1,
    fontSize: 14,
    fontWeight: 700,
  },
  cost: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 700,
  },

  blurb: {
    color: theme.palette.grey[700],
    fontSize: 12,
    flexGrow: 1,
    display: 'block',
  },

  summaryTable: {
    fontSize: 14,
    tableLayout: 'fixed',
    borderCollapse: 'collapse',

    '& img': {
      height: 48,
      width: 48,
      objectFit: 'cover',
      verticalAlign: 'middle',
      borderRadius: 4,
    },

    '& th': {
      textAlign: 'left',
      padding: '8px 16px',
    },
    '& td': {
      textAlign: 'right',
      padding: 16,
    },

    '& thead th': {
      color: theme.palette.text.secondary,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& thead th:not(:first-of-type)': {
      textAlign: 'right',
      width: '20%',
    },

    '& tbody th': {
      display: 'flex',
      alignItems: 'center',
    },
    '& tbody td:last-of-type': {
      color: theme.palette.secondary.main,
      fontWeight: 700,
    },

    '& tfoot :is(th, td)': {
      textAlign: 'right',
      padding: '8px 16px',
    },
    '& tfoot tr:last-of-type td': {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    '& tfoot tr:last-of-type :is(th, td)': {
      fontSize: 16,
      padding: 16,
      borderTop: `1px solid ${theme.palette.divider}`,
    },

    '& div': {
      display: 'inline-block',
      marginLeft: 16,
    },

    '& h3': {
      fontSize: 'inherit',
      margin: 0,
    },
    '& span': {
      color: theme.palette.text.secondary,
      fontWeight: 400,
    },
  },
  summaryFooter: {
    textAlign: 'right',
  },

  grid: {
    height: 124,
    width: 124,
    display: 'grid',
    grid: `repeat(2, minmax(0, 1fr)) / repeat(2, minmax(0, 1fr))`,
    gridGap: 8,
    justifyItems: 'center',
    flexShrink: 0,
  },
  gridLone: {
    height: 124,
    width: 124,
    borderRadius: 4,
    objectFit: 'cover',
    flexShrink: 0,
  },
  gridImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: 4,

    'div&': {
      fontSize: 18,
      fontWeight: 700,
      backgroundColor: theme.palette.grey[200],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

const EMPTY_MAP = new Map()

const PaymentMethodModal = props => {
  const {
    open,
    onClose,
    // eslint-disable-next-line no-unused-vars
    onPaymentConfirm,
    groupedCart,
    groupedCost,
    couriersMap,
    productMap,
    totalCost,
  } = props

  const prevOpen = usePrevious(open)
  const isNeverOpen = !prevOpen && prevOpen === open

  const [viewing, setViewing] = React.useState(null)
  const [methods, setMethods] = React.useState(EMPTY_MAP)

  const handleMethodChange = (vendorId, bank) => {
    const next = new Map(methods)
    const existing = methods.get(vendorId)

    if (existing && existing.id === bank.id) {
      next.delete(vendorId)
    } else {
      next.set(vendorId, bank)
    }

    setMethods(next)
  }

  const handleConfirm = () => {
    if (methods.size < groupedCart.length) {
      return
    }

    if (onPaymentConfirm) {
      onPaymentConfirm(methods)
    }
  }

  const handleSummaryBack = () => {
    setViewing(null)
  }

  const handleBack = () => {
    if (viewing !== null) {
      setViewing(null)
    }

    if (onClose) {
      onClose()
    }
  }

  if (isNeverOpen) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={handleBack}
      fullWidth
      maxWidth={viewing === null ? 'sm' : 'md'}
    >
      <ModalHeader onClose={viewing !== null ? handleSummaryBack : handleBack}>
        {viewing !== null && (
          <IconButton
            onClick={handleSummaryBack}
            edge="start"
            style={{marginTop: -12, marginBottom: -12}}
          >
            <ArrowBackIcon />
          </IconButton>
        )}

        <Typography>
          {viewing === null ? 'Pilih Pembayaran' : 'Detail Keranjang'}
        </Typography>
      </ModalHeader>

      {viewing === null ? (
        <PaymentMethodView
          groupedCart={groupedCart}
          groupedCost={groupedCost}
          productMap={productMap}
          totalCost={totalCost}
          methods={methods}
          onMethodChange={handleMethodChange}
          onVendorDetails={setViewing}
          onConfirm={handleConfirm}
        />
      ) : (
        <VendorSummaryView
          groupedCart={groupedCart}
          groupedCost={groupedCost}
          couriersMap={couriersMap}
          selectedVendor={viewing}
          onBack={handleSummaryBack}
        />
      )}
    </Dialog>
  )
}

export default PaymentMethodModal

const PaymentMethodView = props => {
  const {
    groupedCart,
    groupedCost,
    productMap,
    totalCost,
    methods,
    onMethodChange,
    onVendorDetails,
    onConfirm,
  } = props

  const styles = useStyles()

  const {companyIds, instructorIds} = React.useMemo(() => {
    const companyIds = []
    const instructorIds = []

    for (const grouped of groupedCart) {
      const firstItem = grouped[0]
      const vendorId = firstItem.item_vendor_id

      if (firstItem.item_vendor_table === 'company_profiles') {
        companyIds.push(vendorId)
      } else {
        instructorIds.push(vendorId)
      }
    }

    return {companyIds, instructorIds}
  }, [groupedCart])

  const {data: paymentData, error: paymentError} = useQuery(
    GET_VENDORS_BANK_ACCOUNTS,
    {
      variables: {
        companyIds: companyIds,
        instructorIds: instructorIds,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'public',
        },
      },
    }
  )

  const groupedMethods = React.useMemo(() => {
    const map = new Map()

    if (paymentData) {
      for (const company of paymentData.company_profiles) {
        map.set(company.id, company)
      }

      for (const instructor of paymentData.global_users) {
        map.set(instructor.id, instructor)
      }
    }

    return map
  }, [paymentData])

  const isDisabled = methods.size < groupedCart.length

  return (
    <ModalContent className={styles.content}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>Jumlah yang harus dibayar:</h2>
        <span className={styles.headerPrice}>{convertToRupiah(totalCost)}</span>
      </div>

      <Typography>
        Pilih Metode Pembayaran yang Tersedia untuk tiap Penjual
      </Typography>

      {paymentError ? (
        <div>Something went wrong when trying to retrieve bank accounts</div>
      ) : !paymentData ? (
        <div style={{justifySelf: 'center'}}>
          <CircularProgress />
        </div>
      ) : (
        groupedCart.map((items, index) => {
          const firstItem = items[0]

          const isVendor = firstItem.item_table === 'marketplace_products'
          const vendorType = isVendor ? 'vendor' : 'kontributor pembelajaran'

          const vendorId = firstItem.item_vendor_id
          const vendorDetail = groupedMethods.get(vendorId)
          const vendorBanks = vendorDetail.banks

          const selectedBank = methods.get(vendorId)

          const images = []

          for (const item of items) {
            const productId = item.item_id
            const product = productMap.get(productId)

            const image = product ? product.images[0] : item.item_object.image

            images.push(image || defaultProductImage)
          }

          return (
            <div key={index} className={styles.card}>
              <div className={styles.cardHeader}>
                <GridView images={images} />
                <div className={styles.cardTitle}>
                  <div className={styles.flex}>
                    <Avatar
                      className={styles.avatar}
                      src={vendorDetail.avatar}
                      alt={vendorDetail.name}
                    />
                    <span className={styles.companyName}>
                      {vendorDetail.name}
                    </span>
                    <span className={styles.cost}>
                      {convertToRupiah(groupedCost.get(vendorId))}
                    </span>
                  </div>
                  <div className={styles.flex}>
                    <span className={styles.blurb}>
                      {'' + items.length} Item
                    </span>
                    <Link
                      onClick={() => onVendorDetails(vendorId)}
                      component="button"
                      color="secondary"
                    >
                      Lihat Detail
                    </Link>
                  </div>
                </div>
              </div>

              <Typography className={styles.cardChooseTitle}>
                Metode Pembayaran yang Tersedia
              </Typography>

              {vendorBanks.length < 1 && (
                <div style={{display: 'flex'}}>
                  <ErrorOutlineIcon
                    color="error"
                    style={{fontSize: 20, marginTop: 2, marginRight: 8}}
                  />
                  <Typography variant="subtitle2" color="error">
                    Maaf, Anda tidak dapat memproses pembayaran untuk{' '}
                    {vendorType} ini, karena {vendorType} ini belum memiliki
                    rekening bank terdaftar.
                  </Typography>
                </div>
              )}

              <div className={styles.cardBanks}>
                {vendorBanks.map(bank => (
                  <Button
                    key={bank.id}
                    title={bank.provider.name}
                    onClick={() => onMethodChange(vendorId, bank)}
                    variant="outlined"
                    className={
                      selectedBank && selectedBank.id === bank.id
                        ? styles.bankSelectedBtn
                        : ''
                    }
                  >
                    <img
                      className={styles.bankImage}
                      src={bank.provider.logo}
                    />
                  </Button>
                ))}
              </div>
            </div>
          )
        })
      )}

      <Button
        disabled={isDisabled}
        onClick={onConfirm}
        variant="contained"
        color="secondary"
      >
        Kirim Metode Pembayaran
      </Button>
    </ModalContent>
  )
}

const VendorSummaryView = props => {
  const {groupedCart, groupedCost, couriersMap, selectedVendor, onBack} = props

  const styles = useStyles()

  const cart = React.useMemo(() => {
    for (const items of groupedCart) {
      const firstItem = items[0]
      const vendorId = firstItem.item_vendor_id

      if (vendorId === selectedVendor) {
        return items
      }
    }

    return null
  }, [groupedCart, selectedVendor])

  const firstItem = cart[0]
  const companyName = firstItem.item_vendor
  const totalCost = groupedCost.get(selectedVendor)
  const deliveryCost = couriersMap.get(selectedVendor)?.cost[0].value || 0
  const productCost = totalCost - deliveryCost

  const nodes = []

  for (const item of cart) {
    const itemId = item.id
    const itemObj = item.item_object
    const itemQty = item.item_quantity

    const name = itemObj.name
    const image = itemObj.image
    const price = +itemObj.price
    const note = itemObj.note

    nodes.push(
      <tr key={itemId}>
        <th>
          <img src={image || defaultProductImage} />
          <div>
            <h3>{name}</h3>

            {note && <span>Catatan: {note}</span>}
          </div>
        </th>
        <td>{'' + itemQty}</td>
        <td>{convertToRupiah(price * itemQty)}</td>
      </tr>
    )
  }

  return (
    <ModalContent className={styles.content}>
      <div>
        <Typography>{companyName}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {'' + cart.length} Item
        </Typography>
      </div>

      <table className={styles.summaryTable}>
        <thead>
          <tr>
            <th>Daftar Produk</th>
            <th>Jumlah</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
        <tfoot>
          <tr>
            <th colSpan={2}>Total Produk:</th>
            <td>{convertToRupiah(productCost)}</td>
          </tr>
          <tr>
            <th colSpan={2}>Biaya Pengiriman:</th>
            <td>{convertToRupiah(deliveryCost)}</td>
          </tr>
          <tr>
            <th colSpan={2}>Total:</th>
            <td>{convertToRupiah(totalCost)}</td>
          </tr>
        </tfoot>
      </table>

      <Button onClick={onBack} variant="contained" color="secondary">
        Kembali ke Metode Pembayaran
      </Button>
    </ModalContent>
  )
}

// TODO(intrnl): this replaces the existing <DynamicPhoto> component, move
// this out so it can be used on other pages, perhaps after some adjustments
const GridView = props => {
  const {images} = props

  const styles = useStyles()

  const length = images.length

  if (length === 1) {
    return (
      <img src={images[0] || defaultProductImage} className={styles.gridLone} />
    )
  }

  const max = Math.min(4, length > 4 ? 3 : length)
  const nodes = []
  let idx = 0

  for (; idx < max; idx++) {
    const image = images[idx]
    nodes.push(<img key={idx} src={image} className={styles.gridImage} />)
  }

  for (; idx < 4; idx++) {
    const text = idx === 3 && length > 4 && `+${length - 3}`
    nodes.push(
      <div key={idx} className={styles.gridImage}>
        {text}
      </div>
    )
  }

  return <div className={styles.grid}>{nodes}</div>
}
