import React from 'react'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Card,
  Divider,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core'

import FieldTooltip from '../../../../components/utils-components/FieldTooltip'

import {convertToRupiah, getYearStartEndDate} from '../../../../utils/helpers'
import {USER_ID} from '../../../../utils/globals'
import {GET_USER_BALANCE} from '../../../../graphql/queries/getBalanceUser.query'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(3),
  },

  title: {
    fontSize: theme.spacing(1.75),
    fontWeight: 700,
  },

  balance: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2.75),
    fontWeight: 700,
    textDecoration: 'none',
  },

  subtitle: {
    fontSize: theme.spacing(1.75),
    fontWeight: 700,
    color: theme.palette.grey[600],
  },
  subbalance: {
    fontWeight: 700,
  },
}))

function ClaimSummaryCard() {
  const styles = useStyles()

  const ranges = React.useMemo(() => getYearStartEndDate(), [])

  const {data, error} = useQuery(GET_USER_BALANCE, {
    variables: {
      userID: USER_ID,
      startDate: ranges.start,
      endDate: ranges.end,
    },
    fetchPolicy: 'cache-and-network',
  })

  const total = data?.total.aggregate.sum.value || 0
  const claimed = data?.claimed.aggregate.sum.value || 0

  const available = total - claimed

  return (
    <Card className={styles.root}>
      {!data && !error ? (
        <CircularProgress />
      ) : error ? (
        <div>{JSON.stringify(error)}</div>
      ) : (
        <>
          <Typography className={styles.title}>
            Available Claim Limit
            <ClaimInformationTooltip />
          </Typography>

          <div>
            <Typography className={styles.balance}>
              {convertToRupiah(available)}
            </Typography>
            <Typography color="textSecondary" variant="subtitle2">
              of {convertToRupiah(total)} total
            </Typography>
          </div>

          <Divider />

          <div>
            <Typography className={styles.subtitle}>Claimed</Typography>
            <Typography className={styles.subbalance}>
              {convertToRupiah(claimed)}
            </Typography>
          </div>
        </>
      )}

      <Button
        component={Link}
        to="/profile/claim/request"
        color="primary"
        variant="contained"
        size="large"
        fullWidth
      >
        Request Claim
      </Button>
    </Card>
  )
}

export default ClaimSummaryCard

function ClaimInformationTooltip() {
  return (
    <FieldTooltip
      title="A Claim Limit is a budget provided by a company that can be used
      for shopping on the WorkLifeAndBeyond Marketplace and WorkLifeAndBeyond
      Learning. This Claim Limit cannot be used directly as a payment method,
      but you can submit a claim based on the transactions that have been made."
    />
  )
}
