import React from 'react'

import {makeStyles, Box, Avatar, Typography} from '@material-ui/core'

import {getCookie} from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
  },

  name: {
    fontSize: theme.spacing(2.25),
    fontWeight: 700,
  },
  position: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
  },
}))

const rawUserData = getCookie('userData')
const data = rawUserData && JSON.parse(rawUserData)

function UserSummarySection() {
  const styles = useStyles()

  if (!data) {
    return null
  }

  return (
    <Box
      display="grid"
      justifyItems="center"
      textAlign="center"
      gridGap={8}
      p={3}
    >
      <Avatar
        alt={data.name}
        src={data.avatar || require('../../assets/slicing/default-avatar.png')}
        className={styles.avatar}
      />

      <Typography className={styles.name}>{data.name}</Typography>

      <Typography className={styles.position}>
        {data.position_name} at {data.company_name}
      </Typography>
    </Box>
  )
}

export default UserSummarySection
