import gql from 'graphql-tag'
export const GET_TYPES = gql`
  {
    marketplace_vendor_classifications {
      id
      name
      description
    }
  }
`
