import React from 'react'
import {Typography} from '@material-ui/core'
import NoListImage from '../../assets/images/nodatalist.png'

const NoDataListFree = ({message1, image, message2, button}) => {
  return (
    <div
      style={{
        margin: '0px auto',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        height: '50vh',
        alignItems: 'center',
      }}
    >
      <div>
        <img
          src={image || NoListImage}
          alt="no-data"
          height="200px"
          width="auto"
          style={{marginBottom: 24}}
        ></img>
        <Typography variant="h6">{message1}</Typography>
        <Typography style={{color: '#a9a8a8'}} variant="body1">
          {message2}
        </Typography>
        {button && <div style={{marginTop: 20}}>{button}</div>}
      </div>
    </div>
  )
}

export default NoDataListFree
