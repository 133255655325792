import gql from 'graphql-tag'

export const PRODUCT_BY_CATEGORY = gql`
  query productByCategory(
    $category: [Int!]
    $search: String
    $orderBy: [marketplace_products_order_by!]
    $classification: [jsonb!]
  ) {
    total: marketplace_products_aggregate(
      where: {
        marketplace_product_category: {_or: [{id: {_in: $category}}]}
        _or: [
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
          {company_profile: {brand_name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    marketplace_products(
      limit: 10
      where: {
        marketplace_product_category: {_or: [{id: {_in: $category}}]}
        _or: [
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
          {company_profile: {brand_name: {_ilike: $search}}}
        ]
      }
      order_by: $orderBy
    ) {
      id
      name
      company_profile {
        id
        brand_name
        logo
      }
      marketplace_product_category {
        id
        name
      }
      marketplace_vendor_showcase {
        id
        name
      }
      marketplace_product_reviews_aggregate {
        aggregate {
          count
          avg {
            value
          }
        }
      }
      description
      minimum_order
      current_stock
      weight
      date_added
      last_modified
      images
      price
      is_always_available
      is_digital_product
      is_preorder
      preorder_time
      detail_field
    }
  }
`
export const PRODUCT_BY_CATEGORY_FILTER = gql`
  query productByCategory(
    $category: [Int!]
    $offset: Int
    $limit: Int
    $search: String
    $vendor: uuid
    $showcase: bigint
    $orderBy: [marketplace_products_order_by!]
    $priceFrom: Int
    $priceTo: Int
    $province: Int
    $city: Int
    $rating1: Boolean
    $rating2: Boolean
    $rating3: Boolean
    $rating4: Boolean
    $rating5: Boolean
    $all_ratings: Boolean
    $relatedProduct: [uuid!]
  ) {
    total: marketplace_products_aggregate(
      where: {
        marketplace_product_category: {
          _or: [{id: {_in: $category}}, {parent: {_in: $category}}]
          marketplace_products: {id: {_in: $relatedProduct}}
        }
        _or: [
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
          {company_profile: {brand_name: {_ilike: $search}}}
        ]
        vendor: {_eq: $vendor}
        showcase: {_eq: $showcase}
        price: {_gte: $priceFrom, _lte: $priceTo}
        company_profile: {
          company_origin_shipping_addresses: {
            status: {_eq: "ACTIVE"}
            province_code: {_eq: $province}
            city_code: {_eq: $city}
          }
        }
        _and: {
          _or: [
            {
              v_marketplace_product: {rating_average: {_gte: 1, _lt: 2}}
              _and: {_not: {id: {_is_null: $rating1}}}
            }
            {
              v_marketplace_product: {rating_average: {_gte: 2, _lt: 3}}
              _and: {_not: {id: {_is_null: $rating2}}}
            }
            {
              v_marketplace_product: {rating_average: {_gte: 3, _lt: 4}}
              _and: {_not: {id: {_is_null: $rating3}}}
            }
            {
              v_marketplace_product: {rating_average: {_gte: 4, _lt: 5}}
              _and: {_not: {id: {_is_null: $rating4}}}
            }
            {
              v_marketplace_product: {rating_average: {_gte: 5}}
              _and: {_not: {id: {_is_null: $rating5}}}
            }
            {_and: {_not: {id: {_is_null: $all_ratings}}}}
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    marketplace_products(
      where: {
        marketplace_product_category: {
          _or: [{id: {_in: $category}}, {parent: {_in: $category}}]
          marketplace_products: {id: {_in: $relatedProduct}}
        }
        _or: [
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
          {company_profile: {brand_name: {_ilike: $search}}}
        ]
        vendor: {_eq: $vendor}
        showcase: {_eq: $showcase}
        price: {_gte: $priceFrom, _lte: $priceTo}
        company_profile: {
          company_origin_shipping_addresses: {
            status: {_eq: "ACTIVE"}
            province_code: {_eq: $province}
            city_code: {_eq: $city}
          }
        }
        _and: {
          _or: [
            {
              v_marketplace_product: {rating_average: {_gte: 1, _lt: 2}}
              _and: {_not: {id: {_is_null: $rating1}}}
            }
            {
              v_marketplace_product: {rating_average: {_gte: 2, _lt: 3}}
              _and: {_not: {id: {_is_null: $rating2}}}
            }
            {
              v_marketplace_product: {rating_average: {_gte: 3, _lt: 4}}
              _and: {_not: {id: {_is_null: $rating3}}}
            }
            {
              v_marketplace_product: {rating_average: {_gte: 4, _lt: 5}}
              _and: {_not: {id: {_is_null: $rating4}}}
            }
            {
              v_marketplace_product: {rating_average: {_gte: 5}}
              _and: {_not: {id: {_is_null: $rating5}}}
            }
            {_and: {_not: {id: {_is_null: $all_ratings}}}}
          ]
        }
      }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id
      name
      v_marketplace_product {
        is_archived
        available_stock
      }
      company_profile {
        id
        brand_name
        logo
      }
      marketplace_product_category {
        id
        name
      }
      marketplace_vendor_showcase {
        id
        name
      }
      marketplace_product_reviews_aggregate {
        aggregate {
          count
          avg {
            value
          }
        }
      }
      description
      minimum_order
      current_stock
      weight
      date_added
      last_modified
      images
      price
      is_always_available
      is_digital_product
      is_preorder
      preorder_time
      detail_field
    }
  }
`
export const CHECK_DELETE_PRODUCT = gql`
  query($productId: String!) {
    global_invoice_items_aggregate(
      where: {
        item_table_id: {_eq: $productId}
        item_table: {_eq: "marketplace_products"}
        global_invoice: {
          status: {_neq: "cancelled"}
          global_payment: {
            _or: [
              {payment_status: {_eq: "paid"}}
              {
                payment_status: {_eq: "pending"}
                expires_at: {_is_null: false, _gt: "now()"}
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const DELETE_PRODUCT = gql`
  mutation($productId: String!) {
    deleteMarketplaceProduct(productId: $productId) {
      success
    }
  }
`

export const UPDATE_ARCHIVE = gql`
  mutation($productData: marketplace_products_set_input, $productId: uuid!) {
    update_marketplace_products(
      _set: $productData
      where: {id: {_eq: $productId}}
    ) {
      affected_rows
    }
  }
`
