import React from 'react'
import DetailProduct from '../../../components/vendor-tabs/card-product/DetailProduct'

export default function Detail(props) {
  let regexUrl = ''
  if (props.location.pathname) {
    const regexPattern = /[a-z0-9-]+/gm
    regexUrl = props.location.pathname.match(regexPattern)
  }
  return <>{regexUrl[2] && <DetailProduct id={props.match.params.id} />}</>
}
