import styled from 'styled-components'

export const TextForm = styled.div`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #95989a;
`
