import {ApolloProvider} from '@apollo/react-hooks'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core'
import {StylesProvider} from '@material-ui/styles'
import React from 'react'
import {ThemeProvider} from 'styled-components'
import AppContainer from './AppContainer'
import client from './graphql/connector'
import * as Sentry from '@sentry/browser'
import {SENTRY_ENV, SENTRY_URL, userData} from './utils/globals'

const theme = createMuiTheme({
  font: 'muli',
  borderColor: 'rgb(204, 204, 204)',
  palette: {
    primary: {
      main: '#014a62',
    },
    secondary: {
      main: '#039be5',
    },
  },
  typography: {
    fontFamily: ['muli'],
    button: {
      textTransform: 'capitalize',
    },
  },
})

Sentry.init({
  dsn: SENTRY_URL,
  environment: SENTRY_ENV,
})

Sentry.configureScope(scope => scope.setExtra('userData', userData))

const App = () => {
  return (
    <ApolloProvider client={client}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <AppContainer />
          </MuiThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  )
}

export default App
