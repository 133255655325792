import styled from 'styled-components'

import { Tabs, ListItemText } from '@material-ui/core'

export const StyledTabs = styled(Tabs)`
  span {
    left: 0;
  }
`

export const ListItemTexts = styled(ListItemText)`
  font-family: Muli;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
`
