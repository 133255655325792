import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {format as formatDate} from 'date-fns'
import {APP_COOKIE, SSO_URL} from './globals'

const nominalFormatter = new Intl.NumberFormat('id-ID')

const idrFormatterPadded = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
})

const idrFormatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  minimumFractionDigits: 0,
})

export function convertToRupiah(nominal, padded) {
  const formatter = padded ? idrFormatterPadded : idrFormatter

  // The formatter does rounding when truncating fractions,
  // if you have 0.999, it should not mean that you have Rp 1,00
  const formatted = formatter.format(truncateFractional(nominal, 2))

  // Additionally, there shouldn't be a space between currency sign and nominal
  return formatted.replace(/\s+/g, '')
}

export function truncateFractional(number, digit) {
  const pow = 10 ** digit
  return Math.trunc(number * pow) / pow
}

export function formatNominal(nominal) {
  return nominalFormatter.format(nominal)
}

export function convertToAngka(rupiah) {
  return parseInt(rupiah.replace(/[^0-9]/g, ''), 10)
}

export function nextAlphabet(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}

export function countDownTimer(seconds) {
  const second = seconds / 1000
  let h = Math.floor(second / 3600)
  let m = Math.floor((second % 3600) / 60)
  let s = Math.floor((second % 3600) % 60)

  if (h < 10) h = '0' + h
  if (m < 10) m = '0' + m
  if (s < 10) s = '0' + s

  return h + ':' + m + ':' + s
}

export const getShortDate = date => {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  return formatDate(date, 'MMMM dd, yyyy')
}

export const getLongDate = date => {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  return formatDate(date, "EEEE, MMMM dd, yyyy 'at' hh:mm a")
}

/** @deprecated */
export function getDate(date) {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  return formatDate(date, 'dd MMMM yyyy')
}

/** @deprecated */
export function getDetailDate2(date) {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  return formatDate(date, 'd MMM yyyy')
}

/** @deprecated */
export function getDetailDate(date) {
  if (!date) {
    return null
  }

  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  return formatDate(date, "EEEE, dd MMMM yyyy 'at' HH.mm")
}

export function createCookie(name, value, days) {
  let expires
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toGMTString()
  } else {
    expires = ''
  }
  document.cookie =
    name + '=' + value + expires + '; path=/; domain=' + APP_COOKIE
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=')
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end === -1) {
        c_end = document.cookie.length
      }
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}

export function getWithin(start_date) {
  if (start_date !== '') {
    // var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    // var firstDate = new Date(start_date);
    // var secondDate = new Date();
    // var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));

    // return diffDays
    // var date2 = new Date(start_date);
    // var date1 = new Date();
    // var diff = Math.floor(date1.getTime() - date2.getTime());
    // var day = 1000 * 60 * 60 * 24;

    // var days = Math.floor(diff / day);
    // // var weeks = Math.floor(diff / 7);
    // var months = Math.floor(days / 31);
    // var years = Math.floor(months / 12);

    // var message = '';
    // message += " was "
    // message += days + " days "
    // // message += weeks + " weeks "
    // message += months + " months "
    // message += years + " years ago \n"

    // return message
    // Calculate time between two dates:
    const date1 = new Date(start_date) // the date you already commented/ posted
    const date2 = new Date() // today

    let message = ''

    const diffInSeconds = Math.abs(date2 - date1) / 1000
    const days = Math.floor(diffInSeconds / 60 / 60 / 24)
    const hours = Math.floor((diffInSeconds / 60 / 60) % 24)
    const minutes = Math.floor((diffInSeconds / 60) % 60)

    const months = Math.floor(days / 31)
    const years = Math.floor(months / 12)

    // the below object is just optional
    // if you want to return an object instead of a message

    // check if difference is in years or months
    if (years === 0 && months === 0) {
      // show in days if no years / months
      if (days > 0) {
        if (days === 1) {
          message = days + ' day'
        } else {
          message = days + ' days'
        }
      } else if (hours > 0) {
        if (hours === 1) {
          message = hours + ' hour'
        } else {
          message = hours + ' hours'
        }
      } else {
        // show in minutes if no years / months / days
        if (minutes === 1) {
          message = minutes + ' minute'
        } else {
          message = minutes + ' minutes'
        }
      }
    } else if (years === 0 && months > 0) {
      // show in months if no years
      if (months === 1) {
        message = months + ' month'
      } else {
        message = months + ' months'
      }
    } else if (years > 0) {
      // show in years if years exist
      if (years === 1) {
        message = years + ' year'
      } else {
        message = years + ' years'
      }
    }

    return 'Within ' + message
  }
}

export function setRoutes(config) {
  let routes = [...config.routes]

  if (config.settings || config.auth) {
    routes = routes.map(route => {
      let auth = config.auth ? [...config.auth] : []
      auth = route.auth ? [...auth, ...route.auth] : auth
      return {
        ...route,
        settings: {
          ...config.settings,
          ...route.settings,
        },
        auth,
      }
    })
  }

  return [...routes]
}

export function generateRoutesFromConfigs(configs) {
  let allRoutes = []
  configs.forEach(config => {
    allRoutes = [...allRoutes, ...setRoutes(config)]
  })
  return allRoutes
}

export function generatePersentase(total, bagian) {
  const jumlah = (bagian / total) * 100
  return isNaN(jumlah) ? 0 : jumlah % 1 !== 0 ? jumlah.toFixed(2) : jumlah
}

export function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const hasModule = (data, moduleName) =>
  data?.company_module_settings?.length === 0 ||
  data?.company_module_settings?.some(o => o.global_module.name === moduleName)

export const ROW_PAGENATION = [10, 25, 50]

export function getYearStartEndDate(date = new Date()) {
  const year = date.getFullYear()

  const start = `${year}-01-01T00:00:00.000`
  const end = `${year}-12-31T23:59:59.999`

  return {start, end}
}

export function listenCookieChange() {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (!getCookie('userData')) {
        window.location.href = SSO_URL
      }
    }
  })
}

/**
 * @template T, V
 * @param {Promise<T>} promise
 * @param {(error: any, value: T) => V} cb
 * @returns {Promise<V>}
 */
export const cbify = (promise, cb) => {
  return promise.then(
    res => cb(null, res),
    err => cb(err, null)
  )
}

export const useSearchParams = () => {
  const history = useHistory()
  const location = useLocation()

  const searchParams = React.useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const setSearchParams = React.useCallback(
    (nextParams, options = {}) => {
      const next =
        typeof nextParams !== 'string'
          ? new URLSearchParams(nextParams)
          : nextParams
      const mode = options.replace ? 'replace' : 'push'

      history[mode]({search: next.toString()}, options.state)
    },
    [history]
  )

  return [searchParams, setSearchParams]
}
