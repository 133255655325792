import React from 'react'
import {
  SubFooterContainer,
  LogoContainer,
  VertikalBox,
  FlexRowEnd,
  TextBlue,
} from './FooterStyles'
import {Typography} from '@material-ui/core'
import {WLB_URL} from '../../utils/globals'

export const Footer = ({hide}) => {
  const toUrl = (hastag) => {
    setTimeout(() => {
      window.location = WLB_URL + '#' + hastag
    }, 500)
  }
  const toUrlTern = (param) => {
    let url = 'terms'
    if (param !== '') {
      url = 'terms/#privacy'
    }
    setTimeout(() => {
      window.location = WLB_URL + url
    }, 500)
  }

  const toSosmed = () => {
    setTimeout(() => {
      window.location = process.env.REACT_APP_HC_SOSMED
    }, 500)
  }
  return (
    <>
      {!hide ? (
        <React.Fragment>
          <SubFooterContainer>
            <LogoContainer onClick={() => toSosmed()}>
              <img
                alt="wlb-logo"
                src={require('../../assets/slicing/logo-wlb.svg')}
                height="auto"
                width="80px"
                style={{padding: '10px 0'}}
              />
            </LogoContainer>
            <VertikalBox />
            <Typography style={{fontSize: '12px', color: '#014a62'}}>
              © 2019 WorkLife&Beyond by PT. Kreasi inovasi Digital. All Rights
              Reserved
            </Typography>
            <FlexRowEnd>
              <TextBlue onClick={() => toUrl('home')}>Beranda</TextBlue>
              <TextBlue onClick={() => toUrl('services')}>Layanan</TextBlue>
              <TextBlue onClick={() => toUrlTern('')}>Ketentuan</TextBlue>
              <TextBlue onClick={() => toUrlTern('privacy')}>
                Kebijakan Privasi
              </TextBlue>
            </FlexRowEnd>
          </SubFooterContainer>
        </React.Fragment>
      ) : null}
    </>
  )
}
