import React from 'react'
import {useMutation, useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Link,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  Typography,
} from '@material-ui/core'
import {AttachmentCard} from '@worklifebeyond/wlb-utils-components'

import {ModalHeader, ModalContent} from '../../../../components/modal/Modal'
import {ButtonRed} from '../../../../components/utils-components/VendorStyles'
import DetailsTable from '../../../../components/utils-components/DetailsTable'

import {getClaimType, STATUS_COLORS} from '../ClaimStyles'

import {
  Capitalize,
  convertToRupiah,
  getShortDate,
} from '../../../../utils/helpers'
import {
  GET_CLAIM_ACTIVITY_DETAILS,
  GET_CLAIM_INVOICE_EXPORTS,
  GET_CLAIM_USER_DETAILS,
} from '../../../../graphql/queries/profile/getClaims.query'
import {REQUEST_CLAIM_INVOICE_EXPORTS} from '../../../../graphql/mutations/profile/requestClaim.mutation'

const useStyles = makeStyles(theme => ({
  status: {
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
    padding: theme.spacing(0.5, 1.5),
    marginRight: 8,
    borderRadius: 4,
  },
}))

const SUBMISSION_CONFIG = [
  {title: 'Employee Name', render: x => x.employee.user.name},
  {title: 'Employee ID', render: x => x.employee.regno},
  {title: 'Position', render: x => x.employee.profile.title},
  {title: 'Submission Date', render: x => getShortDate(x.date_added)},
  {title: 'Submission Status', render: renderSubmissionStatus},
]

const REQUEST_CONFIG = [
  {title: 'Request ID', render: x => x.formatted_id},
  {
    title: 'Invoice ID',
    hide: x => !x.invoice,
    render: x => x.invoice.code,
  },
  {
    title: 'Nominal',
    render: x => convertToRupiah(x.invoice ? x.invoice.final_price : x.nominal),
  },
  {
    title: 'Description',
    hide: data => data.invoice,
    render: data => data.description,
  },
  {
    title: 'Rejected Statement',
    hide: data => data.status !== 'rejected',
    render: data => data.claim_fields?.reason,
  },
  {
    title: 'Transaction Invoice',
    render: x =>
      x.invoice ? (
        <TransactionInvoiceExports invoiceId={x.invoice.id} />
      ) : (
        <TransactionAttachments files={x.attachments || []} />
      ),
  },
]

const TRANSFER_CONFIG = [
  {title: 'Bank Account', render: x => x.bank?.provider?.name || '-'},
  {title: 'Account Number', render: x => x.bank?.account_number || '-'},
  {title: 'Account Name', render: x => x.bank?.account_name || '-'},
  {
    title: 'Transfer Evidence',
    hide: x => x.status === 'processing',
    render: x => <TransactionAttachments files={x.proofs} />,
  },
]

const StatusColors = {
  approved: '#4caf50',
  processing: '#a35a31',
  waiting: '#ffa000',
  cancelled: '#a9a8a8',
  rejected: '#ef4d5e',
}

function ClaimSubmissionModal(props) {
  const {open, id, onClose, onClaimCancel} = props

  const styles = useStyles()

  const {data, loading, error} = useQuery(GET_CLAIM_ACTIVITY_DETAILS, {
    skip: !id,
    variables: {
      requestID: id,
    },
  })

  const details = data?.details

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <ModalHeader onClose={onClose}>
        {details && !loading ? (
          <>
            <div
              className={styles.status}
              style={STATUS_COLORS[details.status]}
            >
              {Capitalize(details.status)}
            </div>
            <span>
              {' '}
              {details.policy
                ? details.policy.name
                : `${getClaimType(details)} Claim`}
            </span>
          </>
        ) : (
          <Typography>Claim Submission Detail</Typography>
        )}
      </ModalHeader>

      <ModalContent>
        {!id || loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <div>{JSON.stringify(error)}</div>
        ) : (
          <>
            <DetailsTable fields={SUBMISSION_CONFIG} data={details} />
            <Divider style={{marginTop: 24, marginBottom: 24}} />
            <DetailsTable fields={REQUEST_CONFIG} data={details} />
            <Divider style={{marginTop: 24, marginBottom: 24}} />
            <DetailsTable fields={TRANSFER_CONFIG} data={details} />

            {details.status === 'waiting' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={3}
              >
                <ButtonRed
                  onClick={onClaimCancel}
                  variant="outlined"
                  style={{width: 'auto'}}
                >
                  Cancel
                </ButtonRed>
              </Box>
            )}
          </>
        )}
      </ModalContent>
    </Dialog>
  )
}

export default ClaimSubmissionModal

function renderSubmissionStatus(x) {
  const status = x.status

  const el = <b style={{color: StatusColors[status]}}>{Capitalize(status)}</b>

  if (status === 'waiting') {
    return (
      <>
        {el} for <b>Finance Admin</b> approval
      </>
    )
  } else if (status === 'approved' || status === 'rejected') {
    const finalizer = x.claim_fields.finalizer || {}
    const userId = finalizer.user
    const positionId = finalizer.position

    return (
      <>
        {el} by <StatusUser userId={userId} positionId={positionId} />
      </>
    )
  }

  return el
}

function StatusUser(props) {
  const {userId, positionId} = props

  const {data, error} = useQuery(GET_CLAIM_USER_DETAILS, {
    skip: !userId && !positionId,
    variables: {
      userId,
      positionId,
    },
  })

  if (!data) {
    return <>- {error && '(failed to retrieve user)'}</>
  }

  return (
    <>
      <b>{data.user.name}</b>, <b>{data.position.title}</b>
    </>
  )
}

function TransactionInvoiceExports(props) {
  const {invoiceId} = props

  const {data, error, refetch} = useQuery(GET_CLAIM_INVOICE_EXPORTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      invoiceId: invoiceId,
    },
  })

  const [dispatchInvoiceRequest, {error: requestError}] = useMutation(
    REQUEST_CLAIM_INVOICE_EXPORTS,
    {
      variables: {
        invoiceId: invoiceId,
      },
    }
  )
  // We use `data?.invoice_exports?.length` as dependency than `data` here,
  // because it seems that the effect is getting rerun twice despite being the
  // "exact" same.

  React.useEffect(() => {
    if (data && data.invoice_exports.length < 1) {
      dispatchInvoiceRequest().then(() => refetch())
    }
  }, [data?.invoice_exports?.length])

  if (requestError) {
    return <div>{'' + requestError}</div>
  }

  if (error) {
    return <div>{'' + error}</div>
  }

  if (!data) {
    return <CircularProgress />
  }

  if (data.invoice_exports.length < 1) {
    return <div>Invoice is still being generated, please wait.</div>
  }

  const files = data.invoice_exports.map((invoice, idx) => ({
    name: `invoice${idx > 0 ? `-${idx + 1}` : ''}.pdf`,
    url: invoice.url,
  }))

  return <TransactionAttachments files={files} />
}

function TransactionAttachments(props) {
  const {files, max = 3} = props

  const [more, setMore] = React.useState(false)

  const memoized = React.useMemo(
    () =>
      files
        .slice(0, more ? files.length : max)
        .map((item, index) => (
          <AttachmentCard key={index} name={item.name} url={item.url} />
        )),
    [files, max, more]
  )

  return (
    <div>
      {memoized}

      {files.length > max && (
        <Link
          component="button"
          onClick={() => setMore(!more)}
          display="block"
          color="secondary"
        >
          {more ? 'View Less' : 'View More'}
        </Link>
      )}
    </div>
  )
}
