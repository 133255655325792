import React, {useEffect} from 'react'

// Styled
import useStyles, {
  CardContained,
  CardProduct,
  Content2,
  Content1,
  TextProduct,
  TextGrey,
  TextBlack,
} from './ProductStyles'

import {FlexRowCenter} from '../../utils-components/VendorStyles'

import {Link} from 'react-router-dom'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {
  GET_STORE_BY_FILTER,
  GET_FOLLOWED_VENDOR,
} from '../../../graphql/queries/index'
import {CircularProgress} from '@material-ui/core'
import RatingStars from '../../rating-stars/RatingStars'
import ButtonFollowVendor from './component/ButtonFollowVendor'
import NoDataListFree from '../NoDataListFree'
import defaultVendot from '../../../assets/images/default_vendor.png'

function ListVendor({
  filter,
  categoryFilterContext,
  typeFilterContext,
  setCount,
  type,
  view = 'sidebar',
  page = 0,
  rowsPerPage = 100,
}) {
  const classes = useStyles()

  let orderLatest = null
  let orderName = null
  let orderSeller = null
  if (filter) {
    if (filter.orderBy) {
      if (filter.orderBy === 'l') {
        orderLatest = 'desc'
        orderName = null
        orderSeller = null
      } else if (filter.orderBy === 'o') {
        orderLatest = 'asc'
        orderName = null
        orderSeller = null
      } else if (filter.orderBy === 'az') {
        orderName = 'asc'
        orderLatest = null
        orderSeller = null
      } else if (filter.orderBy === 'za') {
        orderName = 'desc'
        orderLatest = null
        orderSeller = null
      } else if (filter.orderBy === 'bs') {
        orderName = null
        orderLatest = null
        orderSeller = 'desc'
      }
    }
  }

  const {data, error} = useQuery(GET_STORE_BY_FILTER, {
    variables: {
      category:
        categoryFilterContext.length !== 0 ? categoryFilterContext : null,
      offset: page * rowsPerPage,
      limit: page * rowsPerPage + rowsPerPage,
      search: filter.search ? '%' + filter.search + '%' : null,
      province: typeFilterContext.province ? typeFilterContext.province : null,
      city: typeFilterContext.city ? typeFilterContext.city : null,
      reviews: typeFilterContext.rating ? typeFilterContext.rating : null,
      filter:
        typeFilterContext.rating !== undefined
          ? typeFilterContext.rating
            ? typeFilterContext.rating.length >= 1
            : false
          : false,
      orderLatest,
      orderName,
      orderSeller,
    },
  })

  const {
    data: dataFollow,
    loading,
    error: error1,
  } = useQuery(GET_FOLLOWED_VENDOR)

  useEffect(() => {
    if (type === 'see-all') {
      if (data) {
        setCount(data.total ? data.total.aggregate.count : 0)
      }
    }
  }, data)
  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  if (loading) {
    return <CircularProgress />
  } else if (error1 !== undefined) {
    return <div>{error1}</div>
  }
  const vendors =
    type === 'followed'
      ? data.company_profiles.filter((row) => {
          const cek = dataFollow.getFollowingCompany.company.find(
            (val) => row.id === val
          )

          return cek
        })
      : data
  if (type === 'followed') {
    vendors.company_profiles = vendors
  }

  return (
    <CardContained>
      {vendors.company_profiles.length > 0 ? (
        vendors.company_profiles.map((vendor, index) => {
          const avgRating = vendor.marketplace_product_reviews_aggregate
            .aggregate.avg.value
            ? vendor.marketplace_product_reviews_aggregate.aggregate.avg.value
            : 0

          const isFollowed = dataFollow.getFollowingCompany.company.some(
            (val) => val === vendor.id
          )

          return (
            <CardProduct
              key={index}
              className={
                view === 'sidebar' ? classes.cardSidebar : classes.cardFull
              }
            >
              <Content1
                style={{
                  backgroundImage: `url(${
                    vendor.logo ? vendor.logo : defaultVendot
                  })`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'Center center',
                  backgroundSize: 'cover',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              ></Content1>
              <Content2>
                {/* <FlexColumn1 style={{margin: '0 !important'}}> */}
                <center>
                  <TextProduct
                    style={{textAlign: 'center'}}
                    component={Link}
                    to={'/profile/detail/vendor/' + vendor.id}
                  >
                    {vendor.brand_name.length > 27
                      ? vendor.brand_name.slice(0, 27) + '. . .'
                      : vendor.brand_name}
                  </TextProduct>
                  <TextGrey style={{textAlign: 'center'}}>
                    {vendor.global_company_industry_type
                      ? vendor.global_company_industry_type.name
                      : ''}
                  </TextGrey>
                </center>
                <FlexRowCenter
                  style={{
                    paddingTop: '10px',
                    textAlign: 'center',
                    color: '#039be5',
                  }}
                >
                  <RatingStars
                    data={avgRating.toFixed(1)}
                    // changeValueStarts={changeValueStarts}
                    size="small"
                    disabled={true}
                  />

                  <TextBlack style={{textAlign: 'center'}}>
                    {avgRating.toFixed(1)}
                  </TextBlack>
                </FlexRowCenter>
                <FlexRowCenter className={classes.paddingLeft3}>
                  <TextGrey>
                    (
                    {
                      vendor.marketplace_product_reviews_aggregate.aggregate
                        .count
                    }{' '}
                    ratings)
                  </TextGrey>
                </FlexRowCenter>
                {/* </FlexColumn> */}
                <FlexRowCenter style={{padding: '10px'}}>
                  <ButtonFollowVendor
                    followed={isFollowed}
                    companyId={vendor.id}
                    name={vendor.brand_name}
                  />
                </FlexRowCenter>
              </Content2>
            </CardProduct>
          )
        })
      ) : (
        <NoDataListFree
          message1="Sorry, No List"
          message2="No Vendor listed currently"
        />
      )}
    </CardContained>
  )
}

export default ListVendor
