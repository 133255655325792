import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Toolbar,
  Typography,
  Card,
  Button,
  Divider,
  OutlinedInput,
  InputAdornment,
  Dialog,
  CircularProgress,
  DialogContent,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import Listing from '../../../../components/utils-components/Listing'
import NoDataListFree from '../../../../components/vendor-tabs/NoDataListFree'

import {ClaimHistoryItem} from '../ClaimList'

import {USER_ID} from '../../../../utils/globals'
import {
  GET_UNCLAIMED_INVOICE,
  GET_UNCLAIMED_TRANSACTIONS,
} from '../../../../graphql/queries/profile/getClaims.query'
import PurchaseDetails from '../../../../components/vendor-tabs/component-transaction/modal-transaction/PurchaseDetails'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) 320px',
    gridGap: theme.spacing(3),
  },

  title: {
    fontWeight: 600,
    flexGrow: 1,
  },

  removeSelected: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(2),
  },
  selectAll: {
    color: theme.palette.secondary.main,
  },

  searchContainer: {
    margin: theme.spacing(3),
    marginBottom: 0,
  },
}))

function ClaimRequestHistory(props) {
  const {selection, onSelectionChange, onSelectAll, onRemoveAll} = props

  const styles = useStyles()

  const [detailsState, setDetailsState] = React.useState(null)
  const [search, setSearch] = React.useState('')

  const [pagination, setPagination] = React.useState({
    page: 0,
    limit: 20,
  })

  const {data, loading, error} = useQuery(GET_UNCLAIMED_TRANSACTIONS, {
    variables: {
      userID: USER_ID,
      search: `%${search}%`,
      offset: pagination.page * pagination.limit,
      limit: pagination.limit,
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleSelectAll = () => {
    if (!data) {
      return
    }

    onSelectAll(data.list)
  }

  const handleSearchChange = event => {
    setSearch(event.target.value)
  }

  // eslint-disable-next-line no-unused-vars
  const handleChangePage = (event, page) => {
    setPagination({...pagination, page: page})
  }

  const handleChangeLimit = event => {
    setPagination({page: 0, limit: event.target.value})
  }

  const handleDetailsOpen = id => {
    setDetailsState({open: true, id})
  }

  const handleDetailsClose = () => {
    setDetailsState({...detailsState, open: false})
  }

  React.useLayoutEffect(() => {
    if (pagination.page !== 0) {
      handleChangePage(null, 0)
    }
  }, [search])

  return (
    <Card>
      <Toolbar>
        <Typography variant="subtitle1" className={styles.title}>
          Transaction List
        </Typography>

        <Button onClick={onRemoveAll} className={styles.removeSelected}>
          Remove Selected
        </Button>
        <Button onClick={handleSelectAll} className={styles.selectAll}>
          Select All
        </Button>
      </Toolbar>

      <Divider />

      <div className={styles.searchContainer}>
        <OutlinedInput
          value={search}
          onChange={handleSearchChange}
          placeholder="Search..."
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon color="primary" />
            </InputAdornment>
          }
        />
      </div>

      {error ? (
        <div>{JSON.stringify(error)}</div>
      ) : !loading && !data?.total.aggregate.count ? (
        <NoDataListFree
          message1="Sorry, No List"
          message2="There are no transactions that can be claimed"
        />
      ) : (
        <Listing
          component={ClaimHistoryItem}
          noDiff
          selection={selection}
          onSelectionChange={onSelectionChange}
          onDetailsClick={handleDetailsOpen}
          data={data?.list}
          totalCount={data?.total.aggregate.count}
          loading={loading}
          page={pagination.page}
          limit={pagination.limit}
          onChangePage={handleChangePage}
          onChangeLimit={handleChangeLimit}
          resetKeys={[selection]}
        />
      )}

      <PurchaseDetailsWrapper {...detailsState} onClose={handleDetailsClose} />
    </Card>
  )
}

export default ClaimRequestHistory

// <PurchaseDetails> requires data to be passed through it, which would have to
// fetched ahead of time, we don't want to do that, we'll work that around by
// showing a placeholder modal before swapping
function PurchaseDetailsWrapper(props) {
  const {open = false, id, onClose} = props

  const {data, loading, error} = useQuery(GET_UNCLAIMED_INVOICE, {
    skip: !id,
    variables: {
      invoiceId: id,
    },
  })

  return (
    <>
      {!data || loading ? (
        <Dialog open={open} onClose={onClose}>
          <DialogContent>
            {error ? <div>Something went wrong.</div> : <CircularProgress />}
          </DialogContent>
        </Dialog>
      ) : (
        <PurchaseDetails
          type="transaction"
          status="Purchase History"
          title="Detail Transaction"
          open={open}
          onClose={onClose}
          data={data.invoice}
        />
      )}
    </>
  )
}
