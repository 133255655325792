import React, {useState, useEffect} from 'react'

import useStyles, {
  TextFieldSearch,
  FormControlSearch,
  ContainerFilter,
  ContentFilter,
  ContainerFilterBottom,
  FlexRow,
  TextFilter,
  ApplyDiv,
  ResetDiv,
  CancelDiv,
  TitleHoverable,
  FlexBetween,
  TabLabel,
  LabelTabs,
} from './FilterStyles'

import PropTypes from 'prop-types'

import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'

import {format as formatDate} from 'date-fns'

import TabsDate from './TabsDate'
import {
  InputAdornment,
  FormControl,
  Typography,
  IconButton,
  Menu,
  MenuList,
  Box,
  Tabs,
  Grid,
  Badge,
} from '@material-ui/core'
import TabsPrice from './TabsPrice'
import {convertToAngka} from '../../utils/helpers'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function Filter(props) {
  const {filter, setFilter, target, trigerReset = 'default'} = props
  const [tabsFilter, setTabsFilter] = useState(0)

  const [badge, setBadge] = useState(
    target.map((row) => {
      return {
        target: row,
        count: null,
      }
    })
  )

  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)

  const [priceFrom, setPriceFrom] = useState(null)
  const [priceTo, setPriceTo] = useState(null)
  useEffect(() => {
    if (trigerReset) {
      handleResetAll()
      setTimeout(() => {
        setFilter({
          search_filter: null,
          dateFrom: null,
          dateTo: null,
          totalFrom: null,
          totalTo: null,
          orderBy: 'desc',
        })
      }, 500)
    }
  }, [trigerReset])

  useEffect(() => {
    const new_badge = target.map((row) => ({
      target: row,
      count: null,
    }))

    if (priceFrom && priceTo) {
      new_badge.forEach((row) => {
        if (row.target === 'Price Range') {
          row.count = 2
        }
      })
    } else if ((priceFrom && !priceTo) || (!priceFrom && priceTo)) {
      new_badge.forEach((row) => {
        if (row.target === 'Price Range') {
          row.count = 1
        }
      })
    }
    if (dateTo && dateFrom) {
      new_badge.forEach((row) => {
        if (row.target === 'Date Range') {
          row.count = 2
        }
      })
    } else if ((dateFrom && !dateTo) || (!dateFrom && dateTo)) {
      new_badge.forEach((row) => {
        if (row.target === 'Date Range') {
          row.count = 1
        }
      })
    }
    setBadge(new_badge)
    setFilter({
      ...filter,
      loading: filter.loading ? !filter.loading : true,
    })
  }, [priceFrom, priceTo, dateTo, dateFrom])
  const handleChangeFilter = (event) => {
    setFilter({...filter, [event.target.name]: event.target.value})
  }

  const handleResetDate = () => {
    setDateFrom(null)
    setDateTo(null)
  }

  const handleResetPrice = () => {
    setPriceFrom(null)
    setPriceTo(null)
    const before = tabsFilter
    setTabsFilter(-1)
    setTimeout(() => {
      setTabsFilter(before)
    }, 1)
  }

  const handleResetAll = () => {
    handleResetDate()
    handleResetPrice()
  }

  const handleApply = () => {
    setFilter({
      ...filter,
      dateTo: dateTo !== null ? formatDate(dateTo, 'yyyy-MM-dd') : null,
      dateFrom: dateFrom !== null ? formatDate(dateTo, 'yyyy-MM-dd') : null,
      totalFrom: priceFrom !== '' ? priceFrom : null,
      totalTo: priceTo !== '' ? priceTo : null,
    })
    setopenFilter(false)
  }

  const classes = useStyles()

  const [openFilter, setopenFilter] = useState(null)

  const handleOpenFilter = (event) => {
    setopenFilter(event.currentTarget)
  }

  const handleCloseFilter = () => {
    setopenFilter(null)
  }

  const ChangePriceF = (event) => {
    setPriceFrom(
      !Number.isNaN(convertToAngka(event.target.value))
        ? convertToAngka(event.target.value)
        : null
    )
  }
  const ChangePriceT = (event) => {
    setPriceTo(
      !Number.isNaN(convertToAngka(event.target.value))
        ? convertToAngka(event.target.value)
        : null
    )
  }

  const ChangeDFrom = (date) => {
    setDateFrom(date)
  }
  const ChangeDTo = (date) => {
    setDateTo(date)
  }

  function TabsIndex(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    }
  }

  const handleChangeFilterTab = (event, newValue) => {
    event.preventDefault()
    setTabsFilter(newValue)
  }

  const FilterTabs = (tabs) => {
    switch (tabs) {
      case 'Price Range':
        return (
          <TabsPrice
            priceFrom={priceFrom}
            priceTo={priceTo}
            ChangePriceF={ChangePriceF}
            ChangePriceT={ChangePriceT}
            handleResetPrice={handleResetPrice}
          />
        )
      case 'Date Range':
        return (
          <TabsDate
            handleResetDate={handleResetDate}
            dateFrom={dateFrom}
            ChangeDFrom={ChangeDFrom}
            dateTo={dateTo}
            ChangeDTo={ChangeDTo}
          />
        )
      default:
        return <h1>Tidak Ada</h1>
    }
  }
  const lefttMenu = (targetValue, count, key) => {
    return (
      <TabLabel
        key={key}
        label={
          <FlexBetween style={{padding: '5px'}}>
            <LabelTabs>{targetValue}</LabelTabs>
            <Badge
              classes={{
                badge:
                  key === tabsFilter
                    ? classes.customBadgeActive
                    : classes.customBadge,
              }}
              badgeContent={count}
            ></Badge>
          </FlexBetween>
        }
        {...TabsIndex(key)}
      />
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item sm={10}>
        <FormControlSearch variant="outlined">
          <TextFieldSearch
            style={{backgroundColor: '#ffffff'}}
            placeholder="Search"
            onChange={handleChangeFilter}
            value={filter.search_filter}
            name="search_filter"
            // size="small"
            classes={{
              notchedOutline: {
                noBorder: {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="center">
                  <IconButton edge="center">
                    <SearchIcon style={{color: '#014a62'}} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControlSearch>
      </Grid>

      <Grid item sm={2}>
        <FormControl
          style={{
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: ' 5px',
            boxShadow: ' 0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            border: '0px',
          }}
          className={classes.filter}
        >
          <TitleHoverable
            style={{padding: 0, justifyContent: 'space-evenly', width: 120}}
            onClick={handleOpenFilter}
          >
            <FilterListIcon
              style={{padding: 0}}
              className={classes.iconFilter}
            />
            <TextFilter variant="h5">Filter</TextFilter>
          </TitleHoverable>
          <Menu
            id="simple-menu"
            anchorEl={openFilter}
            keepMounted
            open={Boolean(openFilter)}
            onClose={handleCloseFilter}
            MenuListProps={{disablePadding: true}}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuList style={{padding: '0px'}}>
              <ContainerFilter>
                <ContentFilter>
                  <Tabs
                    orientation="vertical"
                    value={tabsFilter}
                    style={{float: 'left'}}
                    onChange={handleChangeFilterTab}
                    aria-label="Vertical tabs example"
                    inkBarStyle={{background: '#014a62'}}
                  >
                    {badge.map((row, key) => {
                      return lefttMenu(row.target, row.count, key)
                    })}
                  </Tabs>
                  <div style={{width: '270px'}}>
                    {badge.map((row, key) => {
                      return (
                        <TabPanel
                          key={key}
                          className={classes.tabPanel}
                          value={tabsFilter}
                          index={key}
                        >
                          {FilterTabs(row.target)}
                        </TabPanel>
                      )
                    })}
                  </div>
                </ContentFilter>
                <ContainerFilterBottom>
                  <div
                    style={{
                      width: '262px',
                      height: '0',
                      border: 'solid 1px #eff2f4',
                    }}
                  ></div>
                  <FlexRow>
                    <ResetDiv onClick={handleResetAll}>Reset All</ResetDiv>
                    <CancelDiv onClick={handleCloseFilter}>Cancel</CancelDiv>
                    <ApplyDiv onClick={handleApply}>Apply</ApplyDiv>
                  </FlexRow>
                </ContainerFilterBottom>
              </ContainerFilter>
            </MenuList>
          </Menu>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Filter
