import {makeStyles} from '@material-ui/styles'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import {Typography, Tab, Select} from '@material-ui/core'
import {KeyboardDatePicker} from '@material-ui/pickers'

export const TextFieldSearch = styled(TextField)`
  border: solid #ffffff;
  background-color: #ffffff;
  height: 50px;
  border-radius: 5px;
  padding-top: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  & .muifilledinput-root: {
    background: #ffffff;
  }
  &:-webkit-autofill: {
    webkitboxshadow: 0 0 0 1000px white inset;
  }
  &.muiselect-select: {
    backgroundcolor: #fffff;
  }
`
export const FormControlSearch = styled(FormControl)`
  width: 100%;
  background-color: #ffffff;
  height: 50px;
`
export const ContainerFilter = styled.div`
  font-family: Muli;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  & .muilist-padding : {
    padding-top: 0px;
  }
`

export const ContentFilter = styled.div`
  flex-grow: 1;
  display: flex;
  & .muilist-padding : {
    padding-top: 0px;
  }
`
export const ContainerFilterBottom = styled.div`
  z-index: 0;
  width: 264px;
  border-top-color: solid 1px #eff2f4;
  flex-direction: column;
  margin-top: -63px;
  display: flex;
  padding: 0px !important;
`
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const TextFilter = styled(Typography)`
  width: 32px;
  height: 18px;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  lette-spacing: normal;
  text-align: center;
  color: #000000;
`
export const ApplyDiv = styled.div`
  cursor: pointer;
  width: 33px;
  height: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
  color: #039be5;
  padding: 12px;
`
export const ResetDiv = styled.div`
  cursor: pointer;
  width: 105px;
  height: 15px;
  line-height: 15px;
  text-align: left;
  color: #040404;
  padding: 12px;
`
export const CancelDiv = styled.div`
  cursor: pointer;
  width: 38px;
  height: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  lette-spacing: normal;
  text-align: right;
  color: #a9a8a8;
  padding: 12px;
`

export const TitleHoverable = styled.div`
  display: flex;
  align-items: center;
  color: #014a62;
  width: max-content;
  cursor: pointer;
  padding: 8px 0 16px;
`

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const LabelSearchDate = styled(Typography)`
  width: 100%;
  height: 15px;
  padding-bottom: 7px;
  padding-top: 13px;
  font-family: Muli;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`

export const Psm = styled.p`
  margin: 0;
  font-size: 12px;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 14px;
  }
`
export const TabLabel = styled(Tab)`
  width: 270px;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-right: 20px;
  background-color: #ffffff;
  &:hover {
    background-color: #014a62;
    color: #ffffff;
  }
  &.MuiTab-textColorInherit.Mui-selected {
    background-color: #014a62;
    color: #ffffff;
  }
`

export const LabelTabs = styled(Typography)`
  width: 100%;
  padding-right: 5px;
  height: 15px;
  font-family: Muli;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`

export const SelectFilter = styled(Select)`
  border: solid #ffffff;
  background-color: #ffffff;
  height: 50px;
  border-radius: 5px;
  padding-top: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  & .muifilledinput-root: {
    background: #ffffff;
  }
  &:-webkit-autofill: {
    webkitboxshadow: 0 0 0 1000px white inset;
  }
  &.muiselect-select: {
    backgroundcolor: #fffff;
  }
`
export const DatePickerFilter = styled(KeyboardDatePicker)`
  & .MuiOutlinedInput-adornedEnd {
    height: 40px;
  }
`
//

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  dropdownArchive: {
    width: '162.1px',
    height: '44.2px !important',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
  },
  filter: {
    // margin: theme.spacing(1),
    width: '153.1px',
    height: '44.2px',
    borderRadius: '5px',
    border: 'solid 1px #a9a8a8',
    backgroundColor: '#ffffff',
  },
  iconFilter: {
    paddingLeft: '38.6px',
    paddingRight: '15px',
    width: '24px',
    height: '24px',
    objectFit: 'contain',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    // width: '270px',
    height: '240px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#f7f8f9',
  },
  buttonFeed: {
    width: '93px',
    height: '35px',
    borderRadius: '5px',
    border: 'solid 1px #014a62',
    fontFamily: 'Muli',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'left',
  },
  customBadge: {
    backgroundColor: '#014a62',
    color: 'white',
  },
  customBadgeActive: {
    backgroundColor: 'white',
    color: '#014a62',
  },
  removePadding: {},
}))

export default useStyles
