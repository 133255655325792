import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import classNames from 'classnames'
import {makeStyles, CircularProgress, Grid, Box} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ShareIcon from '@material-ui/icons/Share'
import {useSnackbar} from 'notistack'

import useStyles, {
  BoxWhite,
  BoxFoto,
  TextDetail,
  BoxCardFoto,
  BoxCarousel,
} from './ProductStyles'
import {
  FlexRow,
  FlexColumnFirst,
  FlexColumnCenter,
  BiggerTextBlack,
  FlexRowFrist,
  TextBlack,
  TextGrey,
  TextBlue,
  TitleTextBlack,
  FlexRowEnd,
  TextDeepTealSmall,
  StyledTabs,
  StyledTab,
} from '../../utils-components/VendorStyles'

import Carousel from 'nuka-carousel'

import FavoriteComponent from './component/FavoriteComponent'
import CardVendor from './component/CardVendor'
import Description from './component/Description'
import Shipping from './component/Shipping'
import Review from './component/Review'
import CardAmount from './component/CardAmount'

import {
  GET_DETAIL_PRODUCT,
  GET_WISHLIST,
  GET_USER_REVIEW_PRODUCT,
} from '../../../graphql/queries/profile/getProducts.query'
import {convertToRupiah, createCookie, getCookie} from '../../../utils/helpers'
import {GET_PRODUCT_AGGREGATE_SCORES} from '../../../graphql/queries/getReviewProduct'
import Star from './component/Star'

import CarouselProduct from './CarouselProduct'
import {
  CategoryFilterContext,
  TypeFilterContext,
} from '../../../contexts/FilterContext'
import {USER_ID} from '../../../utils/globals'
import ReviewModal from '../component-transaction/modal-transaction/ReviewModal'
import defaultProduct from '../../../assets/images/default_product.png'

const root = makeStyles({
  paddingTop12: {
    paddingTop: '12px',
  },
  paddingTop15: {
    paddingTop: '15px !important',
  },
  paddingTop3: {
    paddingTop: '3px',
  },
  textBold: {
    textDecoration: 'bold',
  },
  width75: {
    width: '75%',
  },
  iconAzura: {
    color: '#039be5',
    fontSize: '19px',
  },
  paddingLeft15: {
    paddingLeft: '15px',
  },
  paddingLeft25: {
    paddingLeft: '25px',
  },
  marginTopMin20: {
    marginTop: '-20px',
  },
})

function DetailProduct({id}) {
  const {enqueueSnackbar} = useSnackbar()

  const classes = useStyles()
  const classRoot = root()

  const [activeTab, setValue] = useState(0)
  const [openReview, setOpenReview] = React.useState(false)

  const TypeFilterContextValue = React.useContext(TypeFilterContext)
  const CategoryFilterContextValue = React.useContext(CategoryFilterContext)

  const {data, error} = useQuery(GET_DETAIL_PRODUCT, {
    variables: {
      product: `${id}`,
    },
  })
  const {
    data: wl,
    error: error2,
    refetch,
  } = useQuery(GET_WISHLIST, {
    variables: {
      // offset: 0,
      // limit: 10,
      order: [{date_added: 'desc'}],
    },
  })
  const {data: review, error: error1} = useQuery(GET_PRODUCT_AGGREGATE_SCORES, {
    variables: {
      id,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  const {
    data: cek_review,
    error: errorcekReview,
    refetch: refectReview,
  } = useQuery(GET_USER_REVIEW_PRODUCT, {
    variables: {
      userId: USER_ID,
      productId: `${id}`,
      itemTableId: `${id}`,
    },
  })

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  if (wl === undefined && error2 === undefined) {
    return <CircularProgress />
  } else if (error2 !== undefined) {
    return <div>{error2}</div>
  }
  if (review === undefined && error1 === undefined) {
    return <CircularProgress />
  } else if (error1 !== undefined) {
    return <div>{error1}</div>
  }

  if (cek_review === undefined && errorcekReview === undefined) {
    return <CircularProgress />
  } else if (errorcekReview !== undefined) {
    return <div>{errorcekReview}</div>
  }

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
  }
  const detailProduct = data.v_marketplace_product[0]
  // wishlist
  let id_wl = null
  let cek_wl = false
  wl.marketplace_user_wishlists
    .filter((val) => val.item_id === detailProduct.id)
    .forEach((row) => {
      id_wl = row.id
      cek_wl = true
    })
  let typeFav = 'Non Favorite'
  let item = detailProduct
  if (cek_wl) {
    item = {
      id: id_wl,
    }
    typeFav = 'Favorite'
  } else {
    typeFav = 'Non Favorite'
  }
  // let volume = ''
  // detailProduct.detail_field.volume.map((row, index) => {
  //   console.log(row)
  //   console.log(row[index])
  // })
  // console.log(data)
  // console.log(review)

  const rating_product =
    review.total.aggregate.avg.value !== null
      ? review.total.aggregate.avg.value
      : 0

  const handleReviewClose = () => {
    setOpenReview(false)
  }
  const handleShareProfile = () => {
    // window.location.href;
    navigator.clipboard.writeText(window.location.href).then(() => {
      enqueueSnackbar('URL copied to clipboard')
    })
  }

  const relatedProduct = []
  relatedProduct.push(id)

  createCookie(`related-product${USER_ID}`, JSON.stringify(relatedProduct), 7)
  const dataCK = getCookie(`related-product${USER_ID}`)
  const cookie = dataCK !== '' ? JSON.parse(dataCK) : []

  const soldout =
    detailProduct.available_stock === 0 || detailProduct.is_archived
  return (
    <BoxWhite>
      <FlexRowFrist>
        <FlexColumnFirst className={classRoot.width75}>
          <FlexRowFrist>
            <FlexColumnCenter>
              <BoxFoto src={detailProduct.images[0] || defaultProduct} />

              <Carousel
                slidesToShow={4}
                slidesToScroll={4}
                className={classNames(
                  classes.noHighlight,
                  classRoot.paddingTop12
                )}
                autoplay={true}
                renderCenterLeftControls={({previousSlide}) => (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      borderRadius: 5,
                      marginLeft: '-25px',
                      cursor: 'pointer',
                    }}
                  >
                    <ChevronLeftIcon
                      onClick={previousSlide}
                      style={{color: '#014a62'}}
                    />
                  </div>
                )}
                renderCenterRightControls={({nextSlide}) => (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      borderRadius: 5,
                      marginRight: '-25px',
                      cursor: 'pointer',
                    }}
                  >
                    <ChevronRightIcon
                      onClick={nextSlide}
                      style={{color: '#014a62'}}
                    />
                  </div>
                )}
              >
                {detailProduct.images.map((row, key) => (
                  <BoxCarousel key={key}>
                    <BoxCardFoto src={`${row}`} />
                  </BoxCarousel>
                ))}
              </Carousel>
            </FlexColumnCenter>
            <FlexColumnFirst style={{paddingLeft: '35px', width: '100%'}}>
              <TitleTextBlack>{detailProduct.name}</TitleTextBlack>
              <FlexRow>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    color: '#039be5',
                  }}
                >
                  <Star star={review.total.aggregate.avg.value} size={20} />
                  {/* <StarIcon style={{fontSize: '12px'}} />
                  <StarIcon style={{fontSize: '12px'}} />
                  <StarIcon style={{fontSize: '12px'}} />
                  <StarHalfIcon style={{fontSize: '12px'}} />
                  <StarBorderIcon
                   style={{fontSize: '12px'}} /> */}
                </div>

                <TextBlack style={{fontSize: '12px', paddingLeft: '5px'}}>
                  {rating_product.toFixed(1)}
                </TextBlack>
                <TextGrey style={{fontSize: '12px', paddingLeft: '5px'}}>
                  {' '}
                  ({review.total.aggregate.count} ratings)
                </TextGrey>
                {cek_review.global_invoice_items.length > 0 && (
                  <TextBlue
                    style={{
                      fontSize: '12px',
                      paddingLeft: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setOpenReview(true)
                    }}
                  >
                    Submit a review
                  </TextBlue>
                )}
              </FlexRow>
              <FlexRowFrist className={classRoot.paddingTop12}>
                <BiggerTextBlack>
                  {convertToRupiah(detailProduct.price)}
                </BiggerTextBlack>
              </FlexRowFrist>
              <FlexRowFrist>
                <FlexColumnFirst>
                  <TextDetail className={classRoot.textBold}>
                    Availability:
                  </TextDetail>
                  <TextDetail className={classRoot.textBold}>
                    Category:
                  </TextDetail>
                  <TextDetail className={classRoot.textBold}>
                    Min. Order:
                  </TextDetail>
                  {!detailProduct.is_digital_product && (
                    <>
                      <TextDetail className={classRoot.textBold}>
                        Weight:
                      </TextDetail>
                      <TextDetail className={classRoot.textBold}>
                        Volume:
                      </TextDetail>
                    </>
                  )}
                </FlexColumnFirst>

                <FlexColumnFirst style={{paddingLeft: '64px'}}>
                  <TextDetail>
                    {soldout
                      ? 'Sold Out'
                      : detailProduct.is_always_available
                      ? 'Is Always Available'
                      : detailProduct.available_stock}
                  </TextDetail>
                  <TextDetail>
                    {detailProduct.marketplace_product_category.name}
                  </TextDetail>
                  <TextDetail>{detailProduct.minimum_order}</TextDetail>
                  {!detailProduct.is_digital_product && (
                    <>
                      <TextDetail>{detailProduct.weight} Gramm</TextDetail>
                      <TextDetail>
                        {detailProduct.detail_field.volume.l} x{' '}
                        {detailProduct.detail_field.volume.p} x{' '}
                        {detailProduct.detail_field.volume.t}cm
                      </TextDetail>
                    </>
                  )}
                </FlexColumnFirst>
              </FlexRowFrist>
              <FlexRowEnd className={classRoot.paddingTop15}>
                <FavoriteComponent
                  type={typeFav}
                  refetch={refetch}
                  item={item}
                  color={'014a62'}
                />

                <TextDeepTealSmall>Wishlist</TextDeepTealSmall>
                <ShareIcon
                  onClick={() => handleShareProfile()}
                  style={{
                    padding: '1px',
                    fontSize: '20px',
                    paddingLeft: '34px',
                    color: '#014a62',
                    cursor: 'pointer',
                  }}
                />
                <TextDeepTealSmall
                  onClick={() => handleShareProfile()}
                  style={{paddingLeft: '10px', cursor: 'pointer'}}
                >
                  Share
                </TextDeepTealSmall>
              </FlexRowEnd>
            </FlexColumnFirst>
          </FlexRowFrist>

          <FlexColumnFirst style={{paddingTop: '10px'}}>
            <StyledTabs
              value={activeTab}
              onChange={handleChange}
              // style={customStyle.tabs}
            >
              <StyledTab label="Description" value={0} />
              {!detailProduct.is_digital_product && (
                <StyledTab label="Shipping" value={1} />
              )}
              <StyledTab label="Review" value={2} />
            </StyledTabs>
            {activeTab === 0 && (
              <Description text={detailProduct.description} />
            )}
            {activeTab === 1 && (
              <Shipping
                id_vendor={detailProduct.company_profile.id}
                weight={detailProduct.weight}
                district={
                  detailProduct.company_profile
                    .company_origin_shipping_addresses[0].district_code
                }
                price={detailProduct.price}
              />
            )}
            {activeTab === 2 && (
              <Review
                name={detailProduct.name}
                type={'product'}
                id={detailProduct.id}
                company={detailProduct.company_profile}
              />
            )}
          </FlexColumnFirst>
        </FlexColumnFirst>
        <Box display="grid" gridGap={16} ml={3} width={360}>
          <CardVendor data={detailProduct.company_profile} />
          <CardAmount soldout={soldout} data={detailProduct} />
        </Box>
      </FlexRowFrist>
      <br />
      <br />
      <br />
      <Grid item sm={12} style={{paddingTop: '10px'}}>
        <FlexRow>
          <TextGrey>Related Product</TextGrey>
          <TextBlue
            component={Link}
            to={'/see-all/product/Related Product'}
            style={{paddingLeft: '33px'}}
          >
            {' '}
            See all
          </TextBlue>
        </FlexRow>
      </Grid>
      <Grid item sm={12} style={{paddingTop: '19px'}}>
        <CarouselProduct
          searchText={null}
          typeFilterContext={TypeFilterContextValue}
          categoryFilterContext={CategoryFilterContextValue}
          type="new"
          relatedProduct={cookie}
        />
      </Grid>

      <ReviewModal
        product={{
          product: {
            item_table_id: id,
            itemname: data.v_marketplace_product[0].name,
            single_price: data.v_marketplace_product[0].price,
            item_table: 'v_marketplace_product',
            thumbnail: data.v_marketplace_product[0].images[0],
          },
          vendorId: data.v_marketplace_product[0].company_profile.id,
        }}
        namaToko={data.v_marketplace_product[0].company_profile.brand_name}
        puchaseId={
          cek_review && cek_review.global_invoice_items.length > 0
            ? cek_review.global_invoice_items[0]?.invoice_id
            : null
        }
        displayCode={
          cek_review && cek_review.global_invoice_items.length > 0
            ? cek_review.global_invoice_items[0]?.invoice_id
            : null
        }
        open={openReview}
        type={'detailProduct'}
        close={handleReviewClose}
        refetch={refectReview}
        dataUpdate={
          cek_review && cek_review.marketplace_product_reviews.length > 0
            ? cek_review.marketplace_product_reviews[0]
            : null
        }
      />
    </BoxWhite>
  )
}

export default DetailProduct
