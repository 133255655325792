import React from 'react'
import useStyles, {
  ShoppingContainer,
  BiggestTextDarkBlue,
  TwoColumnTrasaction,
  WraperTrasanction,
  TextBlackP,
  TextLightBlueP,
  WhiteShadowButton,
  TextBlackBold,
  TextBlack1,
  TextBlue,
  TextBlack,
} from '../../../components/utils-components/VendorStyles'
import {
  Icon,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@material-ui/core'
import ClassNames from 'classnames'
import {useQuery} from '@apollo/react-hooks'
import {Capitalize, convertToRupiah, getShortDate} from '../../../utils/helpers'
import {
  GET_PRINT_BILLS_PENDING,
  GET_PRINT_BILLS_PAID,
} from '../../../graphql/queries/index'

const PrintBills = (props) => {
  const classes = useStyles()
  let regexUrl = ''
  if (props.location.pathname) {
    const regexPattern = /[a-z0-9-]+/gm
    regexUrl = props.location.pathname.match(regexPattern)
  }
  const query =
    props.match.params?.status === 'Awaiting'
      ? GET_PRINT_BILLS_PENDING
      : GET_PRINT_BILLS_PAID

  const variable =
    props.match.params?.status === 'Awaiting'
      ? {paymentID: props.match.params.id}
      : {invoiceID: props.match.params.id}

  const {data, error, loading} = useQuery(query, {
    variables: variable,
  })
  React.useEffect(() => {
    if (!loading && regexUrl[2] === 'print') {
      setTimeout(() => {
        window.print()
      }, 1000)
    }
  }, [data, loading])

  if (loading) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const totalPrice =
    props.match.params?.status === 'Awaiting'
      ? data.details.final_price
      : data.details.final_price
  const latestBalance =
    props.match.params?.status === 'Awaiting'
      ? data.details.global_wallet_account.latest_balance
      : data.details.global_wallets &&
        data.details.global_wallets.latest_balance

  let shiping_price = 0
  const shiping_name = []

  props.match.params?.status === 'Awaiting'
    ? data.details.global_invoices.forEach((row1) => {
        row1.courier.forEach((courier) => {
          shiping_price = shiping_price + courier.single_price
          shiping_name.push(Capitalize(courier.itemname))
        })
      })
    : data.details.courier.forEach((courier) => {
        shiping_price = shiping_price + courier.single_price
        shiping_name.push(Capitalize(courier.itemname))
      })

  const handlePrint = () => {
    const printWindow = window.open(
      `/transaction/print-bills/print/${props.match.params.id}/${props.match.params?.status}`,
      '',
      'fullscreen=yes,sidebar: false, header: false, tabbar: false, footer: false,'
    )
    printWindow.document.close()
  }

  return (
    <ShoppingContainer id="printBill">
      <WraperTrasanction>
        <TwoColumnTrasaction>
          <WraperTrasanction>
            <BiggestTextDarkBlue>WorkLife&Beyond</BiggestTextDarkBlue>
            <TextBlackP className={classes.marginTop15}>
              RS. Fatmawati Street No.35A
            </TextBlackP>
          </WraperTrasanction>
          {regexUrl[2] !== 'print' && (
            <WraperTrasanction>
              <WhiteShadowButton size="medium" onClick={() => handlePrint()}>
                <Icon className={classes.marginRight10}>print</Icon>
                Print
              </WhiteShadowButton>
            </WraperTrasanction>
          )}
        </TwoColumnTrasaction>
        <TextBlackP>Cilandak, South Jakarta, Jakarta 12430</TextBlackP>
        <TwoColumnTrasaction>
          <WraperTrasanction className={classes.width70P}>
            <TextBlackP>Indonesia</TextBlackP>
            <TextLightBlueP>wlb.co.id</TextLightBlueP>
          </WraperTrasanction>
          <WraperTrasanction className={classes.width30P}>
            <TwoColumnTrasaction>
              <TextBlackBold className={classes.width30P}>Date</TextBlackBold>
              <TextBlackBold className={classes.width10P}>:</TextBlackBold>
              <TextBlackP className={classes.width60P}>
                {getShortDate(
                  props.match.params?.status === 'Awaiting'
                    ? data.details.date_created
                    : data.details.date_added
                )}
              </TextBlackP>
            </TwoColumnTrasaction>
            <TwoColumnTrasaction>
              <TextBlackBold className={classes.width30P}>Order#</TextBlackBold>
              <TextBlackBold className={classes.width10P}>:</TextBlackBold>
              <TextBlackP className={classes.width60P}>
                {data.details.code}
              </TextBlackP>
            </TwoColumnTrasaction>
          </WraperTrasanction>
        </TwoColumnTrasaction>
      </WraperTrasanction>
      <Divider className={classes.marginTop15} />
      <TextBlackBold className={classes.marginY20}>
        Invoice to :{' '}
        <TextBlack1>
          {props.match.params?.status === 'Awaiting'
            ? data.details.global_wallet_account &&
              data.details.global_wallet_account.global_users[0].name
            : data.details.global_wallets &&
              data.details.global_wallets.global_users[0].name}
        </TextBlack1>
      </TextBlackBold>
      <Divider />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.fontWeight600}>Item</TableCell>
            <TableCell className={classes.fontWeight600} align="right">
              Price
            </TableCell>
            <TableCell className={classes.fontWeight600} align="right">
              Quantity
            </TableCell>
            <TableCell className={classes.fontWeight600} align="right">
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.match.params?.status === 'Awaiting'
            ? data.details.global_invoices.map((row1) => {
                return row1.products.map((row, key) => {
                  const totalItem = row.single_price * row.qty
                  return (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {row.itemname}
                      </TableCell>
                      <TableCell align="right">
                        {convertToRupiah(row.single_price)}
                      </TableCell>
                      <TableCell align="right">{row.qty}</TableCell>
                      <TableCell align="right">
                        {convertToRupiah(totalItem)}
                      </TableCell>
                    </TableRow>
                  )
                })
              })
            : data.details.products.map((row, key) => {
                const totalItem = row.single_price * row.qty
                return (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {row.itemname}
                    </TableCell>
                    <TableCell align="right">
                      {convertToRupiah(row.single_price)}
                    </TableCell>
                    <TableCell align="right">{row.qty}</TableCell>
                    <TableCell align="right">
                      {convertToRupiah(totalItem)}
                    </TableCell>
                  </TableRow>
                )
              })}
        </TableBody>
      </Table>
      <WraperTrasanction className={classes.marginTop50}>
        <TextBlue>Payment Info :</TextBlue>
        <TwoColumnTrasaction style={{alignItems: 'flex-start'}}>
          <WraperTrasanction className={classes.width70P}>
            <TwoColumnTrasaction className={classes.marginTop15}>
              <TextBlack className={classes.width15P}>Method</TextBlack>
              <TextBlack className={classes.width5P}>:</TextBlack>
              <TextBlack
                className={ClassNames(
                  classes.width80P,
                  classes.fontWeightNormal
                )}
              >
                {props.match.params?.status === 'Awaiting'
                  ? data.details.payment_method
                  : data.details.global_payment.payment_method}
              </TextBlack>
            </TwoColumnTrasaction>
            <TwoColumnTrasaction className={classes.marginTop15}>
              <TextBlack className={classes.width15P}>Status</TextBlack>
              <TextBlack className={classes.width5P}>:</TextBlack>
              <TextBlack
                className={ClassNames(
                  classes.width80P,
                  classes.fontWeightNormal
                )}
              >
                {Capitalize(
                  props.match.params?.status === 'Awaiting'
                    ? data.details.payment_status
                    : data.details.global_payment.payment_status
                )}
              </TextBlack>
            </TwoColumnTrasaction>
            <TwoColumnTrasaction className={classes.marginTop15}>
              <TextBlack className={classes.width15P}>
                Shipping Service
              </TextBlack>
              <TextBlack className={classes.width5P}>:</TextBlack>
              <TextBlack
                className={ClassNames(
                  classes.width80P,
                  classes.fontWeightNormal
                )}
              >
                {shiping_name.length > 0 ? shiping_name.join(', ') : '-'}
              </TextBlack>
            </TwoColumnTrasaction>
          </WraperTrasanction>
          <WraperTrasanction className={classes.width30P}>
            <TwoColumnTrasaction className={classes.marginTop15}>
              <TextBlue className={classes.width40P}>Current Balance</TextBlue>
              <TextBlack className={classes.width10P}>:</TextBlack>
              <TextBlack
                className={ClassNames(classes.width50P, classes.textAlignRight)}
              >
                {convertToRupiah(
                  props.match.params?.status === 'Awaiting'
                    ? latestBalance
                    : latestBalance + totalPrice + shiping_price
                )}
              </TextBlack>
            </TwoColumnTrasaction>
            <TwoColumnTrasaction className={classes.marginTop15}>
              <TextBlue className={classes.width40P}>Shipping Price</TextBlue>
              <TextBlack className={classes.width10P}>:</TextBlack>
              <TextBlack
                className={ClassNames(classes.width50P, classes.textAlignRight)}
              >
                {shiping_price > 0 ? convertToRupiah(shiping_price) : '-'}
              </TextBlack>
            </TwoColumnTrasaction>
            <TwoColumnTrasaction className={classes.marginTop15}>
              <TextBlue className={classes.width40P}>Total Price</TextBlue>
              <TextBlack className={classes.width10P}>:</TextBlack>
              <TextBlack
                className={ClassNames(classes.width50P, classes.textAlignRight)}
              >
                {convertToRupiah(totalPrice)}
              </TextBlack>
            </TwoColumnTrasaction>
            <TwoColumnTrasaction className={classes.marginTop15}>
              <TextBlue className={classes.width40P}>
                Remaining Balance
              </TextBlue>
              <TextBlack className={classes.width10P}>:</TextBlack>
              <TextBlack
                className={ClassNames(classes.width50P, classes.textAlignRight)}
              >
                {convertToRupiah(latestBalance)}
              </TextBlack>
            </TwoColumnTrasaction>
          </WraperTrasanction>
        </TwoColumnTrasaction>
      </WraperTrasanction>
    </ShoppingContainer>
  )
}

export default PrintBills
