import HomePage from './HomePageComponent'
import SeeAllPage from './SeeAllPage'
import SearchGlobal from './SearchGlobal'

export const HomePageConfig = {
  routes: [
    {
      path: '/home',
      component: HomePage,
      state: 'home',
      exact: true,
      settings: {
        layout: {
          config: {
            sidebar: true,
          },
        },
      },
    },
    {
      path: '/see-all/:type/:text',
      component: SeeAllPage,
      state: 'profile',
      exact: true,
      settings: {
        layout: {
          config: {
            sidebar: true,
          },
        },
      },
    },
    {
      path: '/search/:type/:text',
      component: SearchGlobal,
      state: 'profile',
      exact: true,
      settings: {
        layout: {
          config: {
            sidebar: true,
          },
        },
      },
    },
  ],
}
