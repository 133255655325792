import gql from 'graphql-tag'

export const GET_BANK_ACCOUNT_BY_INVOICE = gql`
  query($invoiceId: String!) {
    account: getBankAccountByInvoiceId(invoice_id: $invoiceId) {
      bank
      bank_name
      account_name
      account_number
    }
  }
`

export const GET_BANK_PAYMENT_GUIDE = gql`
  query($bankId: Int!) {
    bank: global_banks_by_pk(id: $bankId) {
      id
      name
      logo
    }
    payment_guides: global_bank_payment_guides(
      where: {bank_id: {_eq: $bankId}}
    ) {
      id
      name
      instructions
      via
      group_type
    }
  }
`
