import ProfilePage from './ProfilePageComponent'
import VendorDetailPage from './VendorDetailPageComponent'
import Detail from './view/Detail'
import DetailAdmin from './view/DetailAdmin'

import CartPage from './cart/CartPage'
import CheckoutPage from './cart/CheckoutPage'

import ClaimActivityPage from './claim/activity/ClaimActivity'
import ClaimRequestPage from './claim/request/ClaimRequest'

export const ProfilePageConfig = {
  routes: [
    {
      path: '/profile',
      component: ProfilePage,
      state: 'profile',
      exact: true,
      settings: {
        layout: {
          config: {
            sidebar: true,
          },
        },
      },
    },
    {
      path: '/profile/wishlist',
      component: ProfilePage,
      state: 'profile',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
          },
        },
      },
    },
    {
      path: '/profile/followed-vendor',
      component: ProfilePage,
      state: 'profile',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
          },
        },
      },
    },
    {
      path: '/profile/address-list',
      component: ProfilePage,
      state: 'profile',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
          },
        },
      },
    },
    {
      path: '/profile/balance',
      component: ProfilePage,
      state: 'profile',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
          },
        },
      },
    },
    {
      path: '/profile/detail/produk/:id',
      component: Detail,
      state: 'profile',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
          },
        },
      },
    },
    {
      path: '/vendor/detail/:vendor/product/:id',
      component: DetailAdmin,
      state: 'profile',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
            tabbar: false,
          },
        },
      },
    },
    {
      path: '/profile/wishlist',
      component: ProfilePage,
      state: 'profile',
      exact: false,
    },
    {
      path: '/profile/followed-vendor',
      component: ProfilePage,
      state: 'profile',
      exact: false,
    },
    {
      path: '/profile/address-list',
      component: ProfilePage,
      state: 'profile',
      exact: false,
    },
    {
      path: '/profile/detail/vendor/:id',
      component: VendorDetailPage,
      state: 'profile',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
          },
        },
      },
    },

    {
      path: '/profile/shopping-cart',
      component: CartPage,
      state: 'profile',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
            footer: false,
          },
        },
      },
    },
    {
      path: '/checkout',
      component: CheckoutPage,
      state: 'profile',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
            footer: false,
          },
        },
      },
    },

    {
      path: '/profile/claim',
      component: ClaimActivityPage,
      exact: true,
      settings: {
        layout: {
          config: {
            sidebar: false,
          },
        },
      },
    },
    {
      path: '/profile/claim/request',
      component: ClaimRequestPage,
      exact: true,
      settings: {
        layout: {
          config: {
            sidebar: false,
          },
        },
      },
    },
  ],
}
