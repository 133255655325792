import gql from 'graphql-tag'
export const GET_PRODUCTS = () => {
  return gql`
    company_profiles(    
    order_by:[
      {date_added:desc},
      {
        marketplaceStoreReview_aggregate:{
          count:desc
        }
      }
    ]
  ){
    id
    logo
    brand_name
    legal_name
    global_company_industry_type{
      id
      name
    }
    marketplaceStoreReview_aggregate{
      aggregate{
        count
        avg{
          value
        }
      }
    }
  }
  `
}

export const GET_WISHLIST = gql`
  query(
    $offset: Int
    $limit: Int
    $order: [marketplace_user_wishlists_order_by!]
  ) {
    marketplace_user_wishlists(
      offset: $offset
      limit: $limit
      order_by: $order
    ) {
      id
      item_id
      item_object
      item_quantity
      item_table
      item_vendor
      item_vendor_id
      item_vendor_table
      date_added
    }
  }
`

export const GET_RECOMENDED_PRODUCT = gql`
  query productByCategory(
    $category: Int
    $offset: Int
    $limit: Int
    $search: String
    $vendor: uuid
    $showcase: bigint
    $orderBy: [marketplace_products_order_by!]
  ) {
    total: marketplace_products_aggregate(
      where: {
        marketplace_product_category: {
          _or: [{id: {_eq: $category}}, {parent: {_eq: $category}}]
        }
        _or: [
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
          {company_profile: {brand_name: {_ilike: $search}}}
        ]
        vendor: {_eq: $vendor}
        showcase: {_eq: $showcase}
      }
    ) {
      aggregate {
        count
      }
    }
    marketplace_products(
      where: {
        marketplace_product_category: {
          _or: [{id: {_eq: $category}}, {parent: {_eq: $category}}]
        }
        _or: [
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
          {company_profile: {brand_name: {_ilike: $search}}}
        ]
        vendor: {_eq: $vendor}
        showcase: {_eq: $showcase}
      }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id
      name
      company_profile {
        id
        brand_name
        logo
      }
      marketplace_product_category {
        id
        name
      }
      marketplace_vendor_showcase {
        id
        name
      }
      marketplace_product_reviews_aggregate {
        aggregate {
          count
          avg {
            value
          }
        }
      }
      description
      minimum_order
      current_stock
      weight
      date_added
      last_modified
      images
      price
      is_always_available
      is_digital_product
      is_preorder
      preorder_time
      detail_field
    }
  }
`
export const GET_DETAIL_PRODUCT = gql`
  query($product: uuid, $productId: String) {
    sold: global_invoice_items_aggregate(
      where: {
        item_table_id: {_eq: $productId}
        global_invoice: {status: {_eq: "completed"}}
      }
    ) {
      aggregate {
        sum {
          qty
        }
      }
    }
    v_marketplace_product(where: {id: {_eq: $product}}) {
      id
      company_profile {
        id
        brand_name
        logo
        marketplaceStoreReview_aggregate {
          aggregate {
            count
            avg {
              value
            }
          }
        }
        marketplace_products_aggregate {
          aggregate {
            count
          }
        }
        company_origin_shipping_addresses(
          where: {status: {_eq: "ACTIVE"}}
          limit: 1
        ) {
          id
          province_code
          province_name
          city_code
          city_name
          district_code
          district_name
          post_code
        }
      }
      name
      marketplace_product_category {
        id
        name
      }
      marketplace_vendor_showcase {
        id
        name
      }
      description
      minimum_order
      available_stock
      weight
      date_added
      last_modified
      images
      price
      is_always_available
      is_digital_product
      is_preorder
      preorder_time
      detail_field
      is_archived
    }
  }
`
export const GET_PRODUCT_REVIEW = gql`
  query($userId: uuid!, $itemTable: String!, $itemId: String!) {
    global_invoice_items(
      where: {
        global_invoice: {
          global_wallets: {foreign_owner_id: {_eq: $userId}}
          status: {_eq: "completed"}
        }
        item_table: {_eq: $itemTable}
        item_table_id: {_eq: $itemId}
      }
    ) {
      id
      invoice_id
    }
  }
`
export const GET_USER_REVIEW_PRODUCT = gql`
  query($userId: uuid!, $productId: uuid!, $itemTableId: String!) {
    global_invoice_items(
      where: {
        global_invoice: {
          global_payment: {
            global_wallet_account: {foreign_owner_id: {_eq: $userId}}
          }
        }
        item_table_id: {_eq: $itemTableId}
      }
      limit: 1
      order_by: {global_invoice: {date_added: asc_nulls_last}}
    ) {
      id
      invoice_id
    }
    marketplace_product_reviews(
      where: {user_buyer: {_eq: $userId}, product_id: {_eq: $productId}}
      limit: 1
      order_by: {date_added: asc_nulls_last}
    ) {
      id
      comment
      respond
      value
      user_buyer
      product_id
      invoice_id
    }
  }
`
