import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import * as DOMPurify from 'dompurify'

import {
  CircularProgress,
  Dialog,
  makeStyles,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {ModalHeader, ModalContent, ModalActions} from '../../../modal/Modal'

import {
  GET_BANK_ACCOUNT_BY_INVOICE,
  GET_BANK_PAYMENT_GUIDE,
} from '../../../../graphql/queries'
import {convertToRupiah, getLongDate} from '../../../../utils/helpers'

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.grey[200],
    padding: 8,
    display: 'grid',
    justifyContent: 'center',
    borderRadius: 4,
    marginBottom: 16,
  },
  bankImg: {
    width: 148,
    height: 48,
    objectFit: 'contain',
  },
  accountBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
  },
  accountInfo: {
    marginLeft: 8,
  },
  accountText: {
    fontWeight: 600,
  },
  expiryNotice: {
    color: theme.palette.error.main,
    fontWeight: 600,
    fontSize: 14,
    marginTop: 12,
  },

  price: {
    fontWeight: 600,
  },

  guideHeader: {
    marginTop: 16,
  },
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },

    '& + &': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },

    '&::before': {
      display: 'none',
    },
  },
  accordionTitle: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  accordionSummary: {
    padding: 0,
    minHeight: 48,

    '&.Mui-expanded': {
      minHeight: 48,
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: `12px 0`,
    },
  },
  accordionDetails: {
    fontSize: 14,
    padding: `0 0 16px 16px`,

    '& .wlb-highlight': {
      color: theme.palette.secondary.main,
    },
  },
}))

const HowToPayModal = props => {
  const {open, onClose, onPaid, paymentData} = props

  const styles = useStyles()

  const invoiceData = paymentData?.global_invoices[0]

  const {
    data: accountData,
    loading: loadingAccount,
    error: accountError,
  } = useQuery(GET_BANK_ACCOUNT_BY_INVOICE, {
    skip: !paymentData,
    fetchPolicy: 'cache-and-network',
    variables: {
      invoiceId: invoiceData?.id,
    },
  })

  const {data: guideData, error: guideError} = useQuery(
    GET_BANK_PAYMENT_GUIDE,
    {
      skip: !accountData,
      fetchPolicy: 'cache-first',
      variables: {
        bankId: accountData?.account.bank,
      },
    }
  )

  const instructions = React.useMemo(() => {
    if (!accountData || !guideData) {
      return null
    }

    const wrap = str => `<span class='wlb-highlight'>${str}</span>`

    const accountNumber = wrap(accountData.account.account_number)
    const finalPrice = wrap(convertToRupiah(paymentData.final_price))

    return guideData.payment_guides.map(res => {
      const text = res.instructions
        .replace('#account_number#', accountNumber)
        .replace(/Rp.\s*#total_price#/, finalPrice)

      return {
        id: res.id,
        via: res.via,
        text: DOMPurify.sanitize(text),
      }
    })
  }, [accountData, guideData])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <ModalHeader onClose={onClose}>
        <Typography>How To Pay</Typography>
      </ModalHeader>

      <ModalContent>
        {guideError || accountError ? (
          <Typography>
            Something went wrong while retrieving payment data
          </Typography>
        ) : !accountData || !guideData || loadingAccount ? (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={styles.card}>
              <Typography color="textSecondary" align="center">
                Pay to
              </Typography>

              <div className={styles.accountBox}>
                <img className={styles.bankImg} src={guideData.bank.logo} />

                <div className={styles.accountInfo}>
                  <Typography color="primary" className={styles.accountText}>
                    {accountData.account.account_name}
                  </Typography>
                  <Typography color="primary" className={styles.accountText}>
                    {accountData.account.account_number}
                  </Typography>
                </div>
              </div>

              <Typography className={styles.expiryNotice}>
                Pay before {getLongDate(paymentData.expires_at)}
              </Typography>
            </div>

            <div>
              <Typography color="textSecondary" variant="body2">
                Amount to pay:
              </Typography>
              <Typography color="primary" className={styles.price}>
                {convertToRupiah(paymentData.final_price)}
              </Typography>

              <Typography
                color="textSecondary"
                variant="body2"
                className={styles.guideHeader}
              >
                How to pay:
              </Typography>
            </div>

            <div>
              {instructions.map(instruct => (
                <ExpansionPanel
                  key={instruct.id}
                  elevation={0}
                  className={styles.accordion}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={styles.accordionSummary}
                  >
                    <Typography
                      variant="body2"
                      className={styles.accordionTitle}
                    >
                      {instruct.via}
                    </Typography>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails className={styles.accordionDetails}>
                    <div dangerouslySetInnerHTML={{__html: instruct.text}} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </div>
          </>
        )}
      </ModalContent>

      <ModalActions>
        <Button
          disabled={!accountData || !guideData || loadingAccount}
          onClick={onPaid}
          variant="contained"
          color="secondary"
          fullWidth
        >
          I Already Paid
        </Button>
      </ModalActions>
    </Dialog>
  )
}

export default HowToPayModal
