import React, {useEffect} from 'react'
// graphql
import {useQuery} from '@apollo/react-hooks'
import {GET_WISHLIST} from '../../../graphql/queries/profile/getProducts.query'

import {PRODUCT_BY_CATEGORY_FILTER} from '../../../graphql/queries/index'

// Styled
import useStyles, {
  CardContained,
  CardProduct,
  Content2,
  Content1,
  TextProduct,
  TextGrey,
  TextBlack,
} from './ProductStyles'
import {FlexRow} from '../../utils-components/VendorStyles'
import {CircularProgress} from '@material-ui/core'
import FavoriteComponent from './component/FavoriteComponent'
import {Link} from 'react-router-dom'
import {convertToRupiah} from '../../../utils/helpers'
import RatingStars from '../../rating-stars/RatingStars'
import NoDataListFree from '../NoDataListFree'
import defaultProduct from '../../../assets/images/default_product.png'

function ListProduct({
  type,
  filter,
  categoryFilterContext = [],
  typeFilterContext = [],
  setCount,
  page = 0,
  view = 'sidebar',
  rowsPerPage = 100,
  relatedProduct = null,
}) {
  const classes = useStyles()
  // const [limit, setLimit] = useState(10)

  const limit = 100
  let query = PRODUCT_BY_CATEGORY_FILTER
  let parameter = 'marketplace_products'
  let variable = {}
  let _orderBy =
    type === 'New Product'
      ? [{date_added: 'desc'}]
      : type === 'Top Product'
      ? [{marketplace_product_reviews_aggregate: {count: 'desc'}}]
      : null
  if (filter.orderBy) {
    _orderBy = [
      {
        marketplace_product_reviews_aggregate: {
          count: filter.orderBy === 'bs' ? 'desc' : null,
        },
      },
      {
        date_added:
          filter.orderBy === 'l' || filter.orderBy === 'o'
            ? filter.orderBy === 'l'
              ? 'desc'
              : 'asc'
            : null,
      },
      {
        name:
          filter.orderBy === 'az' || filter.orderBy === 'za'
            ? filter.orderBy === 'az'
              ? 'asc'
              : 'desc'
            : null,
      },
      {
        price:
          filter.orderBy === 'priceLow' || filter.orderBy === 'priceHigh'
            ? filter.orderBy === 'priceLow'
              ? 'asc_nulls_last'
              : 'desc_nulls_last'
            : null,
      },
    ]
  }

  if (type === 'Wishlists') {
    parameter = 'marketplace_user_wishlists'
    query = GET_WISHLIST
    variable = {
      offset: 0,
      limit,
    }
  } else if (categoryFilterContext) {
    variable = {
      category:
        categoryFilterContext.length !== 0 ? categoryFilterContext : null,
      offset: page * rowsPerPage,
      limit: page * rowsPerPage + rowsPerPage,
      search: filter.search ? '%' + filter.search + '%' : null,
      orderBy: _orderBy,
      priceFrom: typeFilterContext.priceFrom
        ? typeFilterContext.priceFrom
        : null,
      priceTo: typeFilterContext.priceTo ? typeFilterContext.priceTo : null,
      province: typeFilterContext.province ? typeFilterContext.province : null,
      city: typeFilterContext.city ? typeFilterContext.city : null,
      vendor: filter.vendor ? filter.vendor : null,
      showcase: filter.showcase ? filter.showcase : null,
      rating1: typeFilterContext.rating
        ? typeFilterContext.rating.find((row) => row === 1) === 1
        : false,
      rating2: typeFilterContext.rating
        ? typeFilterContext.rating.find((row) => row === 2) === 2
        : false,
      rating3: typeFilterContext.rating
        ? typeFilterContext.rating.find((row) => row === 3) === 3
        : false,
      rating4: typeFilterContext.rating
        ? typeFilterContext.rating.find((row) => row === 4) === 4
        : false,
      rating5: typeFilterContext.rating
        ? typeFilterContext.rating.find((row) => row === 5) === 5
        : false,
      all_ratings:
        typeFilterContext.rating !== undefined
          ? typeFilterContext.rating
            ? typeFilterContext.rating.length === 0
            : true
          : true,
      relatedProduct:
        relatedProduct !== null
          ? relatedProduct.length > 0
            ? relatedProduct
            : null
          : null,
    }
  }
  const {data, error} = useQuery(query, {
    variables: variable,
  })

  useEffect(() => {
    if (type !== 'Wishlist') {
      if (data) {
        if (type !== 'vendor') {
          setCount(data.total ? data.total.aggregate.count : 0)
        }
      }
    }
  }, data)

  const {
    data: wl,
    error: error2,
    refetch,
  } = useQuery(GET_WISHLIST, {
    variables: {
      // offset: 0,
      // limit: 10,
      order: [{date_added: 'desc'}],
    },
  })

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  if (wl === undefined && error2 === undefined) {
    return <CircularProgress />
  } else if (error2 !== undefined) {
    return <div>{error2}</div>
  }

  const dataWishlist = []
  data[parameter].forEach((row) => {
    if (
      wl.marketplace_user_wishlists.filter((val) => val.item_id === row.id)
        .length !== 0
    ) {
      dataWishlist.push(row)
    }
  })
  const products = type === 'Wishlist' ? dataWishlist : data[parameter]
  // console.log(products)
  // console.log(dataWishlist)

  return (
    <CardContained>
      {products.length > 0 ? (
        products.map((product, index) => {
          let id_wl = null
          let cek_wl = false
          let id_product = product.id
          wl.marketplace_user_wishlists
            .filter((val) => val.item_id === product.id)
            .forEach((row) => {
              id_wl = row.id
              cek_wl = true
            })
          let typeFav = 'Non Favorite'
          let item = null
          if (type === 'Wishlists') {
            item = product
            id_product = product.item_id
            typeFav = 'Favorite'
          } else if (cek_wl) {
            item = {
              id: id_wl,
            }
            typeFav = 'Favorite'
          } else {
            item = product
            typeFav = 'Non Favorite'
          }

          const avgRating =
            type !== 'Wishlists'
              ? product.marketplace_product_reviews_aggregate.aggregate.avg
                  .value
                ? product.marketplace_product_reviews_aggregate.aggregate.avg
                    .value
                : 0
              : 0
          return (
            <CardProduct
              key={index}
              className={
                view === 'sidebar' ? classes.cardSidebar : classes.cardFull
              }
            >
              <Content1
                style={{
                  backgroundImage: `url(${
                    type === 'Wishlists'
                      ? product.item_object.image || defaultProduct
                      : product.images[0] || defaultProduct
                  })`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'Center center',
                  backgroundSize: 'cover',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              >
                <FavoriteComponent
                  type={typeFav}
                  refetch={refetch}
                  item={item}
                  conditionQuery={type}
                />
              </Content1>
              <Content2>
                <TextProduct
                  variant="body1"
                  component={Link}
                  to={'/profile/detail/produk/' + id_product}
                >
                  {type === 'Wishlists'
                    ? product.item_object.name.length > 27
                      ? product.item_object.name.slice(0, 27) + '. . .'
                      : product.item_object.name
                    : product.name.length > 27
                    ? product.name.slice(0, 27) + '. . .'
                    : product.name}
                </TextProduct>
                <TextGrey>
                  {type === 'Wishlists'
                    ? product.item_vendor
                    : product.company_profile.brand_name}
                </TextGrey>
                <FlexRow style={{paddingTop: '10px'}}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      color: '#039be5',
                    }}
                  >
                    {type !== 'Wishlists' && (
                      <RatingStars
                        data={avgRating.toFixed(1)}
                        size="small"
                        disabled={true}
                      />
                    )}
                  </div>
                  <TextBlack>
                    {' '}
                    {type !== 'Wishlists' && avgRating.toFixed(1)}
                  </TextBlack>
                  <TextGrey className={classes.paddingLeft3}>
                    {' '}
                    {type !== 'Wishlists' &&
                      ` (${product.marketplace_product_reviews_aggregate.aggregate.count})`}
                  </TextGrey>
                </FlexRow>
                <FlexRow style={{marginTop: 12, justifyContent: 'center'}}>
                  <TextProduct>
                    {convertToRupiah(
                      type === 'Wishlists'
                        ? product.item_object.price
                        : product.price
                    )}
                  </TextProduct>
                </FlexRow>
              </Content2>
            </CardProduct>
          )
        })
      ) : (
        <NoDataListFree
          message1="Sorry, No List"
          message2="No product listed currently"
        />
      )}
    </CardContained>
  )
}

export default ListProduct
