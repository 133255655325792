import gql from 'graphql-tag'

export const REQUEST_REFUND = gql`
  mutation($data: marketplace_refund_requests_insert_input!) {
    insert_marketplace_refund_requests_one(object: $data) {
      id
      invoice: global_invoice {
        id
        status
      }
    }
  }
`
