import React, {useState} from 'react'
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'

import classNames from 'classnames'
import {makeStyles, CircularProgress, Grid, IconButton} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import {useSnackbar} from 'notistack'

import useStyles, {
  BoxWhite,
  BoxFoto,
  TextDetail,
  BoxCardFoto,
  BoxCarousel,
} from './ProductStyles'
import {
  FlexRow,
  FlexColumnFirst,
  FlexColumnCenter,
  BiggerTextBlack,
  FlexRowFrist,
  TextBlack,
  TextGrey,
  TitleTextBlack,
  FlexRowEnd,
  StyledTabs,
  StyledTab,
  WraperTrasanction,
  BiggerTextBlack1,
  TextLightBlueP,
  BigTextRed,
} from '../../utils-components/VendorStyles'

import Carousel from 'nuka-carousel'

import Description from './component/Description'
import Shipping from './component/Shipping'
import Review from './component/Review'

import {GET_DETAIL_PRODUCT} from '../../../graphql/queries/profile/getProducts.query'
import {convertToRupiah, createCookie, getCookie} from '../../../utils/helpers'
import {GET_PRODUCT_AGGREGATE_SCORES} from '../../../graphql/queries/getReviewProduct'
import Star from './component/Star'

import CarouselProduct from './CarouselProduct'
import {
  CategoryFilterContext,
  TypeFilterContext,
} from '../../../contexts/FilterContext'
import {HC_URL, USER_ID} from '../../../utils/globals'
import defaultProduct from '../../../assets/images/default_product.png'
import ArrowBack from '@material-ui/icons/ArrowBack'
import EditIcon from '@material-ui/icons/Edit'
import ArchiveIcon from '@material-ui/icons/Archive'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  CHECK_DELETE_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_ARCHIVE,
} from '../../../graphql/queries'
import {
  ModalDeleteCustomCheck,
  ModalNotifWlbCheck,
  ModalConfirmation,
} from '../../index'
import NotFoundComponent from '../../../pages/error/NotFoundComponent'

const root = makeStyles({
  paddingTop12: {
    paddingTop: '12px',
  },
  paddingTop15: {
    paddingTop: '15px !important',
  },
  paddingTop3: {
    paddingTop: '3px',
  },
  textBold: {
    textDecoration: 'bold',
  },
  width75: {
    width: '75%',
  },
  iconAzura: {
    color: '#039be5',
    fontSize: '19px',
  },
  paddingLeft15: {
    paddingLeft: '15px',
  },
  paddingLeft25: {
    paddingLeft: '25px',
  },
  marginTopMin20: {
    marginTop: '-20px',
  },
})

function DetailForAdmin(props) {
  const {id, idVendor} = props

  const [open, setOpen] = useState({
    confirmation: false,
    openArchive: false,
    id: null,
    name: '',
    delete: false,
  })

  const {enqueueSnackbar} = useSnackbar()

  const client = useApolloClient()

  const classes = useStyles()
  const classRoot = root()

  const [activeTab, setValue] = useState(0)
  const [indexImage, setImage] = useState(0)

  const TypeFilterContextValue = React.useContext(TypeFilterContext)
  const CategoryFilterContextValue = React.useContext(CategoryFilterContext)
  const [deleteProduct] = useMutation(DELETE_PRODUCT)
  const [ArchiveProduct] = useMutation(UPDATE_ARCHIVE)

  const {data, error, refetch: loadQuery} = useQuery(GET_DETAIL_PRODUCT, {
    variables: {
      product: `${id}`,
      productId: `${id}`,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })
  const {data: review, error: error1} = useQuery(GET_PRODUCT_AGGREGATE_SCORES, {
    variables: {
      id: `${id}`,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  if (review === undefined && error1 === undefined) {
    return <CircularProgress />
  } else if (error1 !== undefined) {
    return <div>{error1}</div>
  }

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
  }
  const detailProduct = data.v_marketplace_product[0]
  if (detailProduct.company_profile.id !== idVendor) {
    return <NotFoundComponent />
  }
  const rating_product =
    review.total.aggregate.avg.value !== null
      ? review.total.aggregate.avg.value
      : 0

  const handleShareProfile = () => {
    // window.location.href;
    navigator.clipboard.writeText(window.location.href).then(() => {
      enqueueSnackbar('URL copied to clipboard')
    })
  }

  const relatedProduct = []
  relatedProduct.push(id)

  createCookie(`related-product${USER_ID}`, JSON.stringify(relatedProduct), 7)
  const dataCK = getCookie(`related-product${USER_ID}`)
  const cookie = dataCK !== '' ? JSON.parse(dataCK) : []

  const cekDelete = async (productId, name) => {
    const {data: check} = await client.query({
      query: CHECK_DELETE_PRODUCT,
      variables: {
        productId: productId,
      },
      context: {headers: {'X-Hasura-Role': 'organization-hr-admin'}},
    })
    if (check) {
      if (check.global_invoice_items_aggregate.aggregate.count === 0) {
        setOpen({
          ...open,
          confirmation: true,
          name: name,
          id: productId,
        })
      } else {
        setOpen({
          ...open,
          noDelete: true,
        })
      }
    }
  }

  const handleDelete = () => {
    deleteProduct({
      variables: {
        productId: open.id,
      },
      context: {headers: {'X-Hasura-Role': 'organization-hr-admin'}},
    })
      .then(() => {
        enqueueSnackbar('Delete Product success')
        props.history.goBack()
      })
      .catch(() => {
        enqueueSnackbar('Delete Product Failed, try again later')
      })
  }

  const handleArchive = () => {
    ArchiveProduct({
      variables: {
        productId: open.id,
        productData: {
          is_archived_manual: open.type !== 'Archived',
        },
      },
      context: {headers: {'X-Hasura-Role': 'organization-administrator'}},
    })
      .then(() => {
        setOpen({
          ...open,
          confirmation: false,
          noDelete: false,
          openArchive: false,
        })
        enqueueSnackbar('Archive Product success')
        loadQuery()
        if (open.stok === 0) {
          props.history.push(
            `${HC_URL}/vendor/${detailProduct.company_profile.id}/product/${id}`
          )
        }
      })
      .catch(() => {
        enqueueSnackbar('Archive Product Failed, try again later')
      })
  }
  const changeImage = status => {
    let index = indexImage
    const indexLength = detailProduct.images.length - 1
    index = status === 'prev' ? index - 1 : index + 1
    if (indexLength !== 0) {
      setImage(
        status === 'prev'
          ? index < 0
            ? indexLength
            : index
          : index > indexLength
          ? 0
          : index
      )
    }
  }

  const soldout =
    detailProduct.available_stock === 0 || detailProduct.is_archived

  return (
    <BoxWhite>
      <WraperTrasanction style={{display: 'flex'}}>
        <div style={{width: '53%', alignItems: 'center'}}>
          <IconButton onClick={props.history.goBack} aria-label="back">
            <ArrowBack />
          </IconButton>
          <BiggerTextBlack1
            style={{fontSize: '20px', alignItems: 'center'}}
            className={classes.marginLeft20}
          >
            Product Details
          </BiggerTextBlack1>
        </div>

        <FlexRowEnd style={{alignItems: 'center'}}>
          <DeleteIcon
            onClick={() => handleShareProfile()}
            style={{
              padding: '1px',
              fontSize: '20px',
              paddingLeft: '34px',
              color: '#ef4d5e',
              cursor: 'pointer',
            }}
          />
          <BigTextRed
            onClick={() => cekDelete(id, detailProduct.name)}
            style={{paddingLeft: '10px', cursor: 'pointer'}}
          >
            Delete Product
          </BigTextRed>

          <ArchiveIcon
            onClick={() => handleShareProfile()}
            style={{
              padding: '1px',
              fontSize: '20px',
              paddingLeft: '34px',
              color: '#039be5',
              cursor: 'pointer',
            }}
          />
          <TextLightBlueP
            onClick={() => {
              setOpen({
                ...open,
                openArchive: true,
                id: detailProduct.id,
                stok: !detailProduct.is_always_available
                  ? detailProduct.available_stock
                  : 99,
                type: detailProduct.is_archived ? 'Archived' : 'Unarchive',
              })
            }}
            style={{paddingLeft: '10px', cursor: 'pointer'}}
          >
            {`${detailProduct.is_archived ? 'Unarchive' : 'Archive'} Product`}
          </TextLightBlueP>

          <EditIcon
            onClick={() => handleShareProfile()}
            style={{
              padding: '1px',
              fontSize: '20px',
              paddingLeft: '34px',
              color: '#039be5',
              cursor: 'pointer',
            }}
          />
          <a
            href={`${HC_URL}/vendor/${detailProduct.company_profile.id}/product/${id}`}
            style={{
              paddingLeft: '10px',
              cursor: 'pointer',
              textDecoration: 'none',
              fontFamily: 'Muli',
              margin: ' 5px 0px',
              fontSize: '16px',
              fontWeight: '200',
              color: '#039be5',
            }}
          >
            Edit Product
          </a>
        </FlexRowEnd>
      </WraperTrasanction>
      <FlexRowFrist>
        <FlexColumnFirst className={classRoot.width75}>
          <FlexRowFrist>
            <FlexColumnCenter>
              <BoxFoto
                src={detailProduct.images[indexImage] || defaultProduct}
              />

              <Carousel
                slidesToShow={4}
                slidesToScroll={4}
                className={classNames(
                  classes.noHighlight,
                  classRoot.paddingTop12
                )}
                autoplay={true}
                renderCenterLeftControls={({previousSlide}) => (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      borderRadius: 5,
                      marginLeft: '-25px',
                      cursor: 'pointer',
                    }}
                  >
                    <ChevronLeftIcon
                      onClick={() => {
                        previousSlide()
                        changeImage('prev')
                      }}
                      style={{color: '#014a62'}}
                    />
                  </div>
                )}
                renderCenterRightControls={({nextSlide}) => (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      borderRadius: 5,
                      marginRight: '-25px',
                      cursor: 'pointer',
                    }}
                  >
                    <ChevronRightIcon
                      onClick={() => {
                        nextSlide()
                        changeImage('next')
                      }}
                      style={{color: '#014a62'}}
                    />
                  </div>
                )}
              >
                {detailProduct.images.map((row, index, key) => (
                  <BoxCarousel key={key}>
                    <BoxCardFoto
                      onClick={() => {
                        setImage(index)
                      }}
                      src={`${row}`}
                    />
                  </BoxCarousel>
                ))}
              </Carousel>
            </FlexColumnCenter>
            <FlexColumnFirst style={{paddingLeft: '35px', width: '100%'}}>
              <TitleTextBlack>{detailProduct.name}</TitleTextBlack>
              <FlexRow>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    color: '#039be5',
                  }}
                >
                  <Star star={review.total.aggregate.avg.value} size={20} />
                  {/* <StarIcon style={{fontSize: '12px'}} />
                  <StarIcon style={{fontSize: '12px'}} />
                  <StarIcon style={{fontSize: '12px'}} />
                  <StarHalfIcon style={{fontSize: '12px'}} />
                  <StarBorderIcon
                   style={{fontSize: '12px'}} /> */}
                </div>

                <TextBlack style={{fontSize: '12px', paddingLeft: '5px'}}>
                  {rating_product.toFixed(1)}
                </TextBlack>
                <TextGrey style={{fontSize: '12px', paddingLeft: '5px'}}>
                  {' '}
                  ({review.total.aggregate.count} ratings)
                </TextGrey>
              </FlexRow>
              <FlexRowFrist className={classRoot.paddingTop12}>
                <BiggerTextBlack>
                  {convertToRupiah(detailProduct.price)}
                </BiggerTextBlack>
              </FlexRowFrist>
              <FlexRowFrist>
                <FlexColumnFirst>
                  <TextDetail className={classRoot.textBold}>
                    Availability:
                  </TextDetail>
                  <TextDetail className={classRoot.textBold}>
                    Category:
                  </TextDetail>
                  <TextDetail className={classRoot.textBold}>
                    Min. Order:
                  </TextDetail>
                  {detailProduct.weight !== 0 && (
                    <>
                      <TextDetail className={classRoot.textBold}>
                        Weight:
                      </TextDetail>
                      <TextDetail className={classRoot.textBold}>
                        Volume:
                      </TextDetail>
                    </>
                  )}
                  <TextDetail className={classRoot.textBold}>
                    Product Sold:
                  </TextDetail>
                </FlexColumnFirst>

                <FlexColumnFirst style={{paddingLeft: '64px'}}>
                  <TextDetail>
                    {soldout
                      ? 'Sold Out'
                      : detailProduct.is_always_available
                      ? 'Is Always Available'
                      : detailProduct.available_stock}
                  </TextDetail>
                  <TextDetail>
                    {detailProduct.marketplace_product_category.name}
                  </TextDetail>
                  <TextDetail>{detailProduct.minimum_order}</TextDetail>
                  {detailProduct.weight !== 0 && (
                    <>
                      <TextDetail>{detailProduct.weight} Gramm</TextDetail>
                      <TextDetail>
                        {detailProduct.detail_field.volume.l} x{' '}
                        {detailProduct.detail_field.volume.p} x{' '}
                        {detailProduct.detail_field.volume.t}cm
                      </TextDetail>
                    </>
                  )}
                  <TextDetail>{data.sold.aggregate.sum.qty || '-'}</TextDetail>
                </FlexColumnFirst>
              </FlexRowFrist>

              {/* <FlexRowEnd className={classRoot.paddingTop15}>
                <ShareIcon
                  onClick={() => handleShareProfile()}
                  style={{
                    padding: '1px',
                    fontSize: '20px',
                    paddingLeft: '34px',
                    color: '#014a62',
                    cursor: 'pointer',
                  }}
                />
                <TextDeepTealSmall
                  onClick={() => handleShareProfile()}
                  style={{paddingLeft: '10px', cursor: 'pointer'}}
                >
                  Share
                </TextDeepTealSmall>
              </FlexRowEnd> */}
            </FlexColumnFirst>
          </FlexRowFrist>

          <FlexColumnFirst style={{paddingTop: '10px'}}>
            <StyledTabs
              value={activeTab}
              onChange={handleChange}
              // style={customStyle.tabs}
            >
              <StyledTab label="Description" value={0} />
              {detailProduct.weight !== null && detailProduct.weight > 0 && (
                <StyledTab label="Shipping" value={1} />
              )}
              <StyledTab label="Review" value={2} />
            </StyledTabs>
            {activeTab === 0 && (
              <Description text={detailProduct.description} />
            )}
            {activeTab === 1 && (
              <Shipping
                id_vendor={detailProduct.company_profile.id}
                weight={detailProduct.weight}
                district={
                  detailProduct.company_profile
                    .company_origin_shipping_addresses[0].district_code
                }
                price={detailProduct.price}
              />
            )}
            {activeTab === 2 && (
              <Review
                name={detailProduct.name}
                type={'product'}
                id={detailProduct.id}
                company={detailProduct.company_profile}
                isAdmin={true}
              />
            )}
          </FlexColumnFirst>
        </FlexColumnFirst>
        {/* <Box display="grid" gridGap={16} ml={3} width={360}>
          <CardVendor data={detailProduct.company_profile} />
          <CardAmount data={detailProduct} />
        </Box> */}
      </FlexRowFrist>
      <br />
      <br />
      <br />
      <Grid item sm={12} style={{paddingTop: '10px'}}>
        <FlexRow>
          <TextGrey>Product</TextGrey>
          {/* <TextBlue
            component={Link}
            to={'/see-all/product/Related Product'}
            style={{paddingLeft: '33px'}}
          >
            {' '}
            See all
          </TextBlue> */}
        </FlexRow>
      </Grid>
      <Grid item sm={12} style={{paddingTop: '19px'}}>
        <CarouselProduct
          searchText={null}
          typeFilterContext={TypeFilterContextValue}
          categoryFilterContext={CategoryFilterContextValue}
          type="new"
          relatedProduct={cookie}
          idVendor={idVendor}
        />
      </Grid>

      <ModalDeleteCustomCheck
        open={open.confirmation}
        centered
        target={open.name}
        message={
          'I understand that deleting this product is permanent and cannot be undone.'
        }
        onClose={() => {
          setOpen({
            ...open,
            confirmation: false,
            delete: true,
          })
        }}
        onDelete={handleDelete}
      />

      <ModalNotifWlbCheck
        open={open.noDelete}
        centered
        target="Unable to Delete"
        message="unable to delete this product because there is on ongoing sales on this product"
        confirm="confirm"
        onConfirm={() => {
          setOpen({
            ...open,
            noDelete: false,
          })
        }}
      />

      <ModalConfirmation
        open={open.openArchive}
        title={
          open.type === 'Archived' ? 'Unarchive Product?' : 'Archive Product?'
        }
        onCancel={() => {
          setOpen({
            ...open,
            openArchive: false,
          })
        }}
        content={
          open.type === 'Archived'
            ? 'Are you sue you want to unarchive this product? This product will be go back to showcase and user will be able to purchase it.'
            : 'Are you sure you want to archive this product? This product will not be able to be purchased by any user if you archive it'
        }
        textPrimary="Confirm"
        onSubmit={() => handleArchive()}
      />
    </BoxWhite>
  )
}

export default DetailForAdmin
