import TransactionPage from './TransactionPageComponent'
import Awaiting from '../../components/vendor-tabs/component-transaction/Awaiting'
import Transaction from '../../components/vendor-tabs/component-transaction/Transaction'
import PrintBills from '../../components/vendor-tabs/component-transaction/PrintBills'

export const TransactionPageConfig = {
  routes: [
    {
      path: '/transaction',
      component: TransactionPage,
      state: 'transaction',
      exact: true,
      settings: {
        layout: {
          config: {
            sidebar: true,
          },
        },
      },
    },

    {
      path: '/transaction/awaiting/:token',
      component: Awaiting,
      state: 'awaiting',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: true,
          },
        },
      },
    },
    {
      path: '/transaction/awaiting',
      component: Awaiting,
      state: 'awaiting',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: true,
          },
        },
      },
    },
    {
      path: '/transaction/print-bills/show/:id/:status',
      component: PrintBills,
      state: 'print-bills',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
          },
        },
      },
    },
    {
      path: '/transaction/print-bills/print/:id/:status',
      component: PrintBills,
      state: 'print-bills',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: false,
            header: false,
            tabbar: false,
            footer: false,
          },
        },
      },
    },
    {
      path: '/transaction/:type',
      component: Transaction,
      state: 'awaiting',
      exact: false,
      settings: {
        layout: {
          config: {
            sidebar: true,
          },
        },
      },
    },
  ],
}
