import React from 'react'

// Styled
import useStyles, {CardContained} from './ProductStyles'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// Carousel
import Carousel from 'nuka-carousel'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {GET_HOME_BANNERS} from '../../../graphql/queries/index'

import {CircularProgress} from '@material-ui/core'

function CarouselPromotion() {
  const classes = useStyles()

  const {data, error} = useQuery(GET_HOME_BANNERS)
  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const banners = data
    ? data.global_module_settings[0].variable_values.homebanner
    : []

  return (
    <CardContained>
      <Carousel
        slidesToShow={1}
        slidesToScroll={1}
        className={classes.noHighlight}
        autoplay={true}
        renderCenterLeftControls={({previousSlide}) => (
          <div
            style={{
              backgroundColor: '#ffffff',
              borderRadius: 5,
              cursor: 'pointer',
            }}
          >
            <ChevronLeftIcon
              onClick={previousSlide}
              style={{color: '#014a62'}}
            />
          </div>
        )}
        renderCenterRightControls={({nextSlide}) => (
          <div
            style={{
              backgroundColor: '#ffffff',
              borderRadius: 5,
              cursor: 'pointer',
            }}
          >
            <ChevronRightIcon onClick={nextSlide} style={{color: '#014a62'}} />
          </div>
        )}
      >
        {banners.map((banner, index) => (
          <img src={banner.link} key={index} />
        ))}
      </Carousel>
    </CardContained>
  )
}

export default CarouselPromotion
