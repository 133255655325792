import React from 'react'

import {
  withStyles,
  Dialog,
  Button,
  Typography,
  Tooltip,
  Box,
  FormHelperText,
} from '@material-ui/core'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

import {
  UploadButton,
  AttachmentItem,
  getFileType,
} from '@worklifebeyond/wlb-utils-components'

import useStyles from './StyleForm'
import {ModalActions, ModalContent, ModalHeader} from '../../../modal/Modal'

import axios from 'axios'
import {TOKEN, UPLOAD_URL} from '../../../../utils/globals'

const InfoTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: '#eff2f4',
    color: '#000',
    fontSize: 12,
    borderRadius: 5,
  },
  arrow: {
    color: '#eff2f4',
    backgroundColor: '#eff2f4',
  },
}))(Tooltip)

const INITIAL_FORM_DATA = {
  failedIndex: -1,
  invalidType: false,
  overSize: false,
  files: [],
}

const STATE_INITIAL = 1
const STATE_UPLOADING = 2

const UploadPaymentModal = props => {
  const {open, onClose, onCancel, onConfirm} = props

  const [formState, setFormState] = React.useState(STATE_INITIAL)
  const [formData, setFormData] = React.useState(INITIAL_FORM_DATA)

  const isDisabled = formState >= STATE_UPLOADING

  const handleModalClose = formState !== STATE_UPLOADING ? onClose : null

  const handleAttachmentAdd = ev => {
    const pending = ev.target.files
    const files = []

    let invalidType = false
    let overSize = false

    for (const file of pending) {
      const name = file.name
      const size = file.size
      const type = getFileType(name)

      let skip = false

      if (!type) {
        skip = invalidType = true
      }

      if (size > 25 * 1024 * 1024) {
        skip = overSize = true
      }

      if (skip) {
        continue
      }

      files.push({
        name: name,
        size: size,
        type: type,
        url: URL.createObjectURL(file),
        blob: file,
        progress: null,
      })
    }

    setFormData({
      ...formData,
      invalidType: invalidType,
      overSize: overSize,
      files: files.length ? formData.files.concat(files) : formData.files,
    })
  }

  const bindAttachmentNameChange = idx => event => {
    const next = formData.files.slice()
    next[idx] = {...next[idx], name: event.target.value}

    setFormData({...formData, files: next})
  }

  const bindAttachmentRemove = idx => () => {
    const next = formData.files.slice()
    next.splice(idx, 1)

    setFormData({
      ...formData,
      files: next,
      failedIndex: idx === formData.failedIndex ? -1 : formData.failedIndex,
    })
  }

  const handleConfirm = async () => {
    if (isDisabled) {
      return
    }

    setFormState(STATE_UPLOADING)

    for (let idx = 0; idx < formData.files.length; idx++) {
      const file = formData.files[idx]
      const blob = file.blob

      if (!blob) {
        continue
      }

      const form = new FormData()
      form.append('file', blob)

      let response

      try {
        response = await axios.post(UPLOAD_URL, form, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: event => {
            setFormData(formData => {
              const next = formData.files.slice()
              next[idx] = {...next[idx], progress: event.loaded}

              return {...formData, files: next}
            })
          },
        })
      } catch (error) {
        console.error(error)

        setFormState(STATE_INITIAL)
        setFormData(formData => {
          return {...formData, failedIndex: idx}
        })
        return
      }

      URL.revokeObjectURL(file.url)

      setFormData(formData => {
        const next = formData.files.slice()
        next[idx] = {
          ...next[idx],
          blob: null,
          url: response.data.url,
          progress: null,
        }

        return {...formData, files: next}
      })
    }

    setFormState(STATE_INITIAL)

    if (onConfirm) {
      setFormData(formData => {
        onConfirm({files: formData.files})
        return formData
      })
    }
  }

  return (
    <Dialog open={open} onClose={handleModalClose} fullWidth maxWidth="xs">
      <ModalHeader onClose={handleModalClose}>
        <Typography>Upload Payment Attachment</Typography>
      </ModalHeader>

      <ModalContent>
        <Typography
          variant="subtitle2"
          style={{fontWeight: 600, marginBottom: 8}}
        >
          Attachment <AttachmentInfoTooltip />
        </Typography>

        <UploadButton
          disabled={isDisabled}
          onChange={handleAttachmentAdd}
          multiple
        />

        {formData.failedIndex > -1 && (
          <FormHelperText error>
            The attachment &quot;{formData.files[formData.failedIndex].name}
            &quot; failed to upload, please try again or remove the attachment
          </FormHelperText>
        )}

        {formData.invalidType && (
          <FormHelperText error>
            Attachments can only be in specified file types
          </FormHelperText>
        )}
        {formData.overSize && (
          <FormHelperText error>
            Attachments cannot exceed 25 MB in size
          </FormHelperText>
        )}

        <Box display="grid" mt={1} gridGap={8}>
          {formData.files.map((file, idx) => (
            <AttachmentItem
              key={idx}
              disabled={isDisabled}
              name={file.name}
              size={file.size}
              url={file.url}
              type={file.type}
              isUploading={file.progress != null}
              progress={file.progress}
              onNameChange={bindAttachmentNameChange(idx)}
              onRemove={bindAttachmentRemove(idx)}
            />
          ))}
        </Box>
      </ModalContent>

      <ModalActions>
        <Button disabled={isDisabled} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          disabled={isDisabled || formData.files.length < 1}
          onClick={handleConfirm}
          variant="contained"
          color="primary"
        >
          Upload
        </Button>
      </ModalActions>
    </Dialog>
  )
}

const AttachmentInfoTooltip = React.memo(() => {
  const classes = useStyles()

  return (
    <InfoTooltip
      // arrow
      title={
        <>
          <Typography className={classes.textTooltip}>
            Appropriate file extension:
          </Typography>
          <div className={classes.tooltipWrapper}>
            <Typography className={classes.textTooltip}>
              Document {'( '}
            </Typography>
            <Typography className={classes.textBlue}>.pdf,</Typography>
            <Typography className={classes.textTooltip}>{' )'}</Typography>
          </div>
          <div className={classes.tooltipWrapper}>
            <Typography className={classes.textTooltip}>
              Image {'( '}
            </Typography>
            <Typography className={classes.textBlue}>
              .jpg, .jpeg, .png,
            </Typography>
            <Typography className={classes.textTooltip}>{' )'}</Typography>
          </div>
          <Typography className={classes.textTooltip}>
            Maximum file size of 25MB
          </Typography>
        </>
      }
      placement="right"
    >
      <ErrorOutlineOutlinedIcon
        className={classes.iconInfo}
        style={{verticalAlign: -4}}
        color="primary"
      />
    </InfoTooltip>
  )
})

export default UploadPaymentModal
