import gql from 'graphql-tag'

export const GET_LIST_STORE_FRONT = gql`
  query($vendor: uuid, $offset: Int, $limit: Int) {
    total: marketplace_vendor_showcase_aggregate(
      where: {vendor: {_eq: $vendor}}
    ) {
      aggregate {
        count
      }
    }

    marketplace_vendor_showcase(
      where: {vendor: {_eq: $vendor}}
      order_by: [{name: asc}]
      offset: $offset
      limit: $limit
    ) {
      id
      name
      description
    }
  }
`
