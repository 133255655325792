// NOTE(intrnl): This page serves as a placeholder while we wait for the module
// configuration to resolve before redirecting to the correct place.

import React from 'react'
import {Redirect} from 'react-router-dom'

import {CircularProgress} from '@material-ui/core'
import {GetModularSettings} from '@worklifebeyond/wlb-utils-components'

import {COMPANY_ID, HC_URL} from '../utils/globals'

// NOTE(intrnl): We need to do the same check in case the user has wandered off
// into `/home` route without going here first.
export let hasResolved = false

const PlaceholderIndexPage = () => {
  const {data} = GetModularSettings(COMPANY_ID)

  if (!data) {
    return (
      <div style={{display: 'grid', height: 'calc(100vh - 110px)'}}>
        <CircularProgress style={{placeSelf: 'center'}} />
      </div>
    )
  }

  let hasVendorModule = false
  let hasLearningModule = false

  if (data.company_module_settings) {
    for (const mod of data.company_module_settings) {
      const moduleName = mod.global_module.name

      if (moduleName === 'vendor') {
        hasVendorModule = true
      } else if (moduleName === 'learning') {
        hasLearningModule = true
      }
    }

    // Empty indicates that the company has access to everything
    if (data.company_module_settings.length === 0) {
      hasVendorModule = true
      hasLearningModule = true
    }
  }

  hasResolved = true

  if (!hasVendorModule && !hasLearningModule) {
    window.location = HC_URL
    return null
  }

  return <Redirect to={hasVendorModule ? '/home' : '/transaction'} />
}

export default PlaceholderIndexPage
