import React from 'react'
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import {
  VendorNameLabel,
  VendorBioLabel,
  // VendorTypeLabel,
} from '../../header/HeaderStyles'
import {
  VerticalContainer,
  HorizontalContainer,
} from '../../general-container/FlexContainerStyles'
import DetailVendorShipmentVendor from './DetailVendorShipmentVendor'
import LocationOnIcon from '@material-ui/icons/LocationOn'

import * as DOMPurify from 'dompurify'

// grapql
import {useQuery} from '@apollo/react-hooks'
import {
  GET_COURIER_PROVIDERS,
  GET_VENDOR_COURIERS,
} from '../../../graphql/queries/profile/getListShipping'

const DetailVendorDescription = ({dataDetail, id}) => {
  const {data, error} = useQuery(GET_VENDOR_COURIERS, {
    variables: {
      vendor: `${id}`,
    },
  })
  const {data: all, error: error2} = useQuery(GET_COURIER_PROVIDERS)

  const bio = React.useMemo(() => {
    return DOMPurify.sanitize(dataDetail.profile_field.description)
  }, [dataDetail])

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  if (all === undefined && error2 === undefined) {
    return <CircularProgress />
  } else if (error2 !== undefined) {
    return <div>{error2}</div>
  }

  return (
    <Card style={{marginLeft: 15, width: '100%'}}>
      <CardContent>
        <VerticalContainer
          paddingTop={'30px'}
          paddingLeft={'47px'}
          paddingRight={'22px'}
          paddingBottom={'62px'}
        >
          <VendorNameLabel>Vendor Description</VendorNameLabel>
          <VendorBioLabel marginTop={'22px'}>
            <div dangerouslySetInnerHTML={{__html: bio}} />
          </VendorBioLabel>

          <Typography
            style={{fontSize: 22, margin: '30px 0px 10px', fontWeight: '600'}}
          >
            Address
          </Typography>
          <div style={{margin: '10px 0px 0px'}}>
            {dataDetail.company_addresses.map((res, i) => {
              return (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 4,
                  }}
                >
                  <LocationOnIcon style={{color: '#a9a8a8', marginRight: 10}} />
                  <Typography>
                    {res && res.address ? res.address : '-'}
                  </Typography>
                </div>
              )
            })}
          </div>
          {/* <VendorTypeLabel marginTop={'10px'}>{dataDetail.company_addresses ? dataDetail.company_addresses[0].global_city.name : '-'}</VendorTypeLabel> */}
          {/* <VendorTypeLabel marginTop/={'10px'}> */}
          {/* Open Since August 2019 */}
          {/* </VendorTypeLabel> */}
          <VendorNameLabel marginTop={'30px'}>Shipping Service</VendorNameLabel>
          <HorizontalContainer paddingTop={'17px'} style={{flexWrap: 'wrap'}}>
            {data.company_shipping_availables[0] &&
              data.company_shipping_availables[0].courier_services.map(
                (row, index) => {
                  const image = all.global_shipping_providers.find(
                    (val) =>
                      val.name.toUpperCase() === row.courier.toUpperCase()
                  )

                  return (
                    <DetailVendorShipmentVendor
                      name={row.courier}
                      type={row.type.join(', ')}
                      image={image ? image.logo : ''}
                      key={index}
                    />
                  )
                }
              )}
            {/* {shipmentVendors.map((vendor, index) => (
              <DetailVendorShipmentVendor
                name={vendor.name}
                type={vendor.type}
                vendorImage={vendor.vendorImage}
                key={index}
              />
            ))} */}
          </HorizontalContainer>
        </VerticalContainer>
      </CardContent>
    </Card>
  )
}

export default DetailVendorDescription
