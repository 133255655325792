import React, {useState} from 'react'
import {useMutation, useQuery, useApolloClient} from '@apollo/react-hooks'
import Geocode from 'react-geocode'
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete'

import {
  makeStyles,
  Dialog,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import RoomIcon from '@material-ui/icons/Room'
import {useSnackbar} from 'notistack'

import {ModalHeader, ModalContent} from '../../../modal/Modal'
import {TextForm} from './ModalStyles'
import {SelectCust, FlexRowFrist} from '../../../utils-components/VendorStyles'
import MapsMarker from './MapsMarker'

import {MAP_KEY} from '../../../../utils/globals'
import {
  ADD_ADDRESS,
  UPDATE_ADDRESS,
} from '../../../../graphql/mutations/profile/addAddress'
import {
  LIST_PROVINCE,
  LIST_CITY,
  LIST_DISTRICT,
} from '../../../../graphql/queries/getPlacement.query'

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100% !important',
  },
}))

export default function DialogAddress({
  open,
  handleTogleModal,
  title,
  dataEdit,
  refetch,
  type = 'profile',
}) {
  const {enqueueSnackbar} = useSnackbar()

  const [currentLatitude, setCurrentLatitude] = useState()
  const [currentLongitude, setCurrentLongitude] = useState()
  const [addressForm, setAddressForm] = useState('')

  const [latitude, setLatitude] = useState(currentLatitude)
  const [longitude, setLongitude] = useState(currentLongitude)

  const client = useApolloClient()
  const classes = useStyles()
  const [showMaps, setShowMaps] = useState(false)
  const optionsProv = [
    {
      value: '',
      label: '',
    },
  ]
  const [optionsCity, setOptionsCity] = useState([
    {
      value: '',
      label: '',
    },
  ])
  const [optionsDistrict, setOptionsDistrict] = useState([
    {
      value: '',
      label: '',
    },
  ])

  const [prov, setProv] = useState([
    {
      value: '',
      label: '',
    },
  ])

  const [city, setCity] = useState([
    {
      value: '',
      label: '',
    },
  ])

  const [district, setDistrict] = useState([
    {
      value: '',
      label: '',
    },
  ])

  const {data: province, error: error3} = useQuery(LIST_PROVINCE)

  const [mutationAdd] = useMutation(ADD_ADDRESS)
  const [mutationUpdate] = useMutation(UPDATE_ADDRESS)
  const [loadUseEffect, setLoadUseEffect] = useState(true)

  const [value, setValue] = useState({
    labelName: '',
    name: '',
    addres: '',
    phone: '',
    zipCode: '',
  })

  React.useEffect(() => {
    if (open) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCurrentLatitude(position.coords.latitude)
        setCurrentLongitude(position.coords.longitude)
      })
    }
  }, [open])

  React.useEffect(() => {
    if (title !== 'Edit Address') {
      setLatitude(currentLatitude)
      setLongitude(currentLongitude)
      setValue({
        ...value,
        labelName: '',
        name: '',
        addres: '',
        phone: '',
        zipCode: '',
      })

      setProv({
        value: '',
        label: '',
      })

      setCity({
        value: '',
        label: '',
      })

      setDistrict({
        value: '',
        label: '',
      })
    }

    if (loadUseEffect || title === 'Edit Address') {
      setLatitude(
        dataEdit.location_fields
          ? dataEdit.location_fields.lat
          : currentLatitude
      )
      setLongitude(
        dataEdit.location_fields
          ? dataEdit.location_fields.lng
          : currentLongitude
      )

      setValue({
        ...value,
        labelName: dataEdit.label_name,
        name: dataEdit.recipient_name,
        addres: dataEdit.address,
        phone: dataEdit.phone,
        zipCode: dataEdit.post_code,
      })

      setProv({
        value: dataEdit.province_code,
        label: dataEdit.province_name,
      })

      setCity({
        value: dataEdit.city_code,
        label: dataEdit.city_name,
      })

      setDistrict({
        value: dataEdit.district_code,
        label: dataEdit.district_name,
      })
    }
    setLoadUseEffect(false)
  }, [dataEdit, loadUseEffect])

  const handleSave = () => {
    let validasi = true
    if (
      prov.value === '' ||
      city.value === '' ||
      district.value === '' ||
      value.labelName === '' ||
      value.zipCode === '' ||
      value.addeess === '' ||
      value.name === '' ||
      value.phone === ''
    ) {
      validasi = false
    }
    if (validasi) {
      if (title !== 'Edit Address') {
        const objects = {
          label_name: `${value.labelName}`,
          province_code: prov.value,
          city_code: city.value,
          district_code: district.value,
          province_name: `${prov.label}`,
          city_name: `${city.label}`,
          district_name: `${district.label}`,
          post_code: value.zipCode,
          address: `${value.addres}`,
          recipient_name: `${value.name}`,
          phone: `${value.phone}`,
          location_fields: {lat: latitude, lng: longitude},
        }

        mutationAdd({variables: {objects}}).then(
          () => {
            if (refetch && type === 'checkout') {
              refetch()
            }

            handleClose()
            enqueueSnackbar('Address added')
          },
          () => enqueueSnackbar('Failed to add address')
        )
      } else {
        mutationUpdate({
          variables: {
            id: dataEdit.id,
            label_name: `${value.labelName}`,
            province_code: prov.value,
            city_code: city.value,
            district_code: district.value,
            province_name: `${prov.label}`,
            city_name: `${city.label}`,
            district_name: `${district.label}`,
            post_code: value.zipCode,
            address: `${value.addres}`,
            recipient_name: `${value.name}`,
            phone: `${value.phone}`,
            location_fields: {lat: latitude, lng: longitude},
          },
        }).then(
          () => {
            if (refetch && type === 'checkout') {
              refetch()
            }

            handleClose()
            enqueueSnackbar('Address saved')
          },
          () => {
            enqueueSnackbar('Failed to save address')
          }
        )
      }
    } else {
      enqueueSnackbar('Field is required')
    }
  }

  const handleGeo = (address, cek = false) => {
    if (showMaps) {
      Geocode.setApiKey(`${MAP_KEY}`)
      geocodeByAddress(address)
        .then((results) => {
          // console.log(results)
          // setLocationInfo(results)
          return getLatLng(results[0])
        })
        .then((latLng) => {
          setLatitude(latLng.lat)
          setLongitude(latLng.lng)
        })
        .catch(() => {})
    }
    if (cek && !showMaps) {
      Geocode.setApiKey(`${MAP_KEY}`)
      geocodeByAddress(address)
        .then((results) => {
          // console.log(results)
          // setLocationInfo(results)
          return getLatLng(results[0])
        })
        .then((latLng) => {
          setLatitude(latLng.lat)
          setLongitude(latLng.lng)
        })
        .catch(() => {})
    }
  }

  const handleChangeProv = async (e) => {
    setProv(e)
    const obj = []
    try {
      const {loading, data: cty} = await client.query({
        query: LIST_CITY(e.value),
      })
      if (!loading) {
        setOptionsCity([])
        cty &&
          cty.getCity.forEach((row) => {
            obj.push({
              value: row.city_id,
              label: row.type + ' ' + row.city_name,
              postal_code: row.postal_code,
            })
          })
        setOptionsCity(obj)
        handleGeo(e.label)
      }
    } catch (error) {
      return <div>{error}</div>
    }
  }

  const handleChangeCity = async (e) => {
    setCity(e)
    setValue({
      ...value,
      zipCode: e.postal_code,
    })
    const obj = []
    // handleGeo(e.label)
    try {
      const {loading, data: dstr} = await client.query({
        query: LIST_DISTRICT(e.value),
      })
      if (!loading) {
        setOptionsDistrict([])
        dstr &&
          dstr.getSubdistrict.forEach((row) => {
            obj.push({
              value: row.subdistrict_id,
              label: row.subdistrict_name,
            })
          })
        setOptionsDistrict(obj)
        handleGeo(e.label + ', ' + prov.label)
      }
    } catch (error) {
      return <div>{error}</div>
    }
  }

  const handleChangeDistrict = async (e) => {
    setDistrict(e)
    setAddressForm(e.label + ', ' + city.label + ', ' + prov.label)
    handleGeo(e.label + ', ' + city.label + ', ' + prov.label)
  }

  province &&
    province.getProvince.forEach((row) => {
      optionsProv.push({
        value: row.province_id,
        label: row.province,
      })
    })

  const handleSetValue = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    })
  }

  const handleSetValueNumber = (e) => {
    const re = /^[0-9\b]+$/

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleClose = () => {
    setLoadUseEffect(true)
    handleTogleModal()
  }
  const toggleMaps = () => {
    setShowMaps(!showMaps)
    setTimeout(() => {
      if (addressForm !== '') {
        handleGeo(addressForm, true)
      }
    }, 3000)
  }

  if (province === undefined && error3 === undefined) {
    if (!open) {
      return null
    }

    return <CircularProgress />
  } else if (error3 !== undefined) {
    return <div>{error3}</div>
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <ModalHeader onClose={handleClose}>
        <Typography>{title}</Typography>
      </ModalHeader>
      <ModalContent>
        <Box display="grid" gridGap={16}>
          <FormControl>
            <TextForm>Save Address as</TextForm>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={value.labelName}
              name="labelName"
              onChange={handleSetValue}
            />
          </FormControl>
          <FormControl>
            <TextForm>Recipient&apos;s Name</TextForm>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={value.name}
              name="name"
              onChange={handleSetValue}
            />
          </FormControl>
          <FormControl>
            <TextForm>Phone Number</TextForm>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={value.phone}
              name="phone"
              onChange={handleSetValueNumber}
            />
          </FormControl>
          <FormControl>
            <TextForm>Province</TextForm>

            <SelectCust
              className={classes.fullWidth}
              fulWidth
              onChange={handleChangeProv}
              options={optionsProv}
              value={prov}
              id="outlined-basic"
              variant="outlined"
              placeholder="The Province"
            />
          </FormControl>
          <FormControl>
            <TextForm>City/District</TextForm>
            <SelectCust
              className={classes.fullWidth}
              fulWidth
              onChange={handleChangeCity}
              value={city}
              options={optionsCity}
              id="outlined-basic"
              variant="outlined"
              placeholder="City"
            />
          </FormControl>
          <FormControl>
            <TextForm>Sub-District</TextForm>
            <SelectCust
              className={classes.fullWidth}
              fulWidth
              onChange={handleChangeDistrict}
              value={district}
              options={optionsDistrict}
              id="outlined-basic"
              variant="outlined"
              placeholder="Districts"
            />
          </FormControl>

          <FormControl>
            <TextForm>Postal Code</TextForm>
            <TextField
              fullWidth
              id="outlined-basic"
              type="text"
              variant="outlined"
              value={value.zipCode ? value.zipCode : ''}
              name="zipCode"
              onChange={handleSetValueNumber}
            />
          </FormControl>

          <FormControl>
            <TextForm>Full Address</TextForm>
            <TextField
              fullWidth
              id="outlined-basic"
              rows={3}
              multiline={true}
              variant="outlined"
              value={value.addres}
              name="addres"
              onChange={handleSetValue}
            />
          </FormControl>
          <FormControl>
            <TextForm>Location Coordinate (Optional)</TextForm>
            <FlexRowFrist>
              <Button
                onClick={toggleMaps}
                startIcon={<RoomIcon />}
                color="secondary"
              >
                Choose Coordinate
              </Button>
            </FlexRowFrist>
            {showMaps && (
              <MapsMarker
                latitude={latitude}
                setLatitude={setLatitude}
                longitude={longitude}
                setLongitude={setLongitude}
              />
            )}
          </FormControl>

          <Button
            onClick={handleSave}
            variant="contained"
            color="secondary"
            fullWidth
          >
            Save
          </Button>
        </Box>
      </ModalContent>
    </Dialog>
  )
}
