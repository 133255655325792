import React from 'react'
import {
  PaperTransaction,
  TextBlack,
  BoxSpace,
  FlexRow,
  FlexColumnFirst,
} from '../../../utils-components/VendorStyles'

import useStyles, {
  TextAddress,
} from '../../../../pages/transaction/TransactionPageStyles'
function DetailShipping({type, data, setPriceShipping}) {
  const classes = useStyles()
  const dataShipping = []

  const address = data
    ? type === 'awaiting'
      ? data.global_invoices[0].delivery_data
      : data.delivery_data
    : ''

  const shipping = data
    ? type === 'awaiting'
      ? data.global_invoices.filter((r) => r.global_shipping_provider).length >
        0
      : !!data.global_shipping_provider
    : false

  dataShipping.push({
    logo:
      type === 'awaiting'
        ? data
          ? data.global_invoices[0].courier[0]
            ? data.global_invoices[0].courier[0].thumbnail
            : ''
          : ''
        : data
        ? data.global_shipping_provider
          ? data.global_shipping_provider.logo
          : ''
        : '',
    name:
      type === 'awaiting'
        ? data
          ? data.global_invoices[0].courier[0]
            ? data.global_invoices[0].courier[0].itemname
            : ''
          : ''
        : data
        ? data.courier[0]
          ? data.courier[0].itemname
          : ''
        : '',
    price:
      type === 'awaiting'
        ? data
          ? data.global_invoices[0].courier[0]
            ? data.global_invoices[0].courier[0].single_price
            : ''
          : 0
        : data
        ? data.courier.lenght >= 1
          ? data.courier[0].single_price
          : 0
        : 0,
  })

  let price = 0

  if (data.global_invoices) {
    for (const invoice of data.global_invoices) {
      for (const courier of invoice.courier) {
        price += courier.single_price
      }
    }
  }

  if (type !== 'awaiting') {
    setPriceShipping(dataShipping[0].price)
  } else {
    setPriceShipping(price)
  }

  return (
    <>
      {shipping && (
        <>
          <TextBlack>Shipping</TextBlack>
          <PaperTransaction className={classes.paperDetailTransaction}>
            <TextBlack>{address ? address.recipient_name : ''} </TextBlack>
            <TextAddress style={{}}>
              {address ? address.address : ''}
            </TextAddress>
            {shipping && type !== 'awaiting' && (
              <BoxSpace>
                <FlexRow>
                  <img
                    style={{
                      width: '94px',
                      height: '39px',
                      paddingRight: '12px',
                    }}
                    src={`${dataShipping[0].logo}`}
                  />
                  <FlexColumnFirst>
                    <TextBlack style={{textTransform: 'uppercase'}}>
                      {dataShipping[0].name}
                    </TextBlack>
                    <TextAddress>{`Receipt Number : ${
                      data.delivery_data.id_shipping !== ''
                        ? data.delivery_data.id_shipping
                        : '-'
                    }`}</TextAddress>
                  </FlexColumnFirst>
                </FlexRow>
              </BoxSpace>
            )}
            {shipping &&
              type === 'awaiting' &&
              data.global_invoices &&
              data.global_invoices.forEach((res, key) => {
                if (res.global_shipping_provider != null) {
                  return (
                    <BoxSpace key={key}>
                      <FlexRow>
                        <img
                          style={{
                            width: '94px',
                            height: '39px',
                            paddingRight: '12px',
                          }}
                          src={`${res.global_shipping_provider.logo}`}
                        />
                        <FlexColumnFirst>
                          <TextBlack
                            style={{
                              textTransform: 'uppercase',
                            }}
                          >
                            {`${res.global_shipping_provider.name} ${res.delivery_data.service}`}
                          </TextBlack>
                          <TextAddress>{`Receipt Number : ${
                            res.delivery_data.id_shipping !== ''
                              ? res.delivery_data.id_shipping
                              : '-'
                          }`}</TextAddress>
                        </FlexColumnFirst>
                      </FlexRow>
                    </BoxSpace>
                  )
                }
              })}
          </PaperTransaction>
        </>
      )}
    </>
  )
}

export default DetailShipping
