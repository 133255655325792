import styled from 'styled-components'

export const ModalWrapperDeleteCheck = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  .header {
    padding: 24px 20px;
    color: #ef4d5e;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #eff2f4;
  }

  .body {
    font-size: 12px;
    padding: 23px 20px;

    .check {
      display: flex;
      flex-direction: row;

      .check-action {
        margin-right: 10px;
      }

      .ant-checkbox-wrapper-checked {
        .ant-checkbox-inner {
          background-color: #ef4d5e !important;
          border-color: #ef4d5e !important;
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 22px 24px;

    .cancel {
      cursor: pointer;
      margin-right: 24px;
    }

    .delete {
      cursor: pointer;
    }
  }
`
