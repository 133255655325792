import React from 'react'

// material
import {Grid, IconButton, MenuItem, InputAdornment} from '@material-ui/core'

// icon
import SearchIcon from '@material-ui/icons/Search'
import {
  TextFieldSearch,
  FormControlSearch,
  SelectFilter,
} from '../../Filter/FilterStyles'

export default function Search({filter, setFilter, type}) {
  const handleChangeFilter = event => {
    setFilter({...filter, [event.target.name]: event.target.value})
  }
  return (
    <>
      {type === 'home' && (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <FormControlSearch variant="outlined">
              <TextFieldSearch
                style={{backgroundColor: '#ffffff'}}
                placeholder="Search"
                onChange={handleChangeFilter}
                value={filter.search}
                name="search"
                // size="small"
                classes={{
                  notchedOutline: {
                    noBorder: {
                      border: 'none',
                    },
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="center">
                      <IconButton edge="center">
                        <SearchIcon style={{color: '#014a62'}} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControlSearch>
          </Grid>
        </Grid>
      )}
      {type !== 'home' && (
        <Grid container spacing={1}>
          <Grid item sm={9}>
            <FormControlSearch variant="outlined">
              <TextFieldSearch
                style={{backgroundColor: '#ffffff'}}
                placeholder="Search"
                onChange={handleChangeFilter}
                value={filter.search}
                name="search"
                // size="small"
                classes={{
                  notchedOutline: {
                    noBorder: {
                      border: 'none',
                    },
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="center">
                      <IconButton edge="center">
                        <SearchIcon style={{color: '#014a62'}} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControlSearch>
          </Grid>
          <Grid item sm={3}>
            <FormControlSearch variant="outlined">
              <SelectFilter
                variant="standard"
                placeholder="Sort By"
                disableUnderline
                style={{backgroundColor: '#ffffff'}}
                onChange={handleChangeFilter}
                inputProps={{
                  name: 'orderBy',
                }}
                value={filter.orderBy}
                displayEmpty
                renderValue={
                  filter.orderBy !== undefined
                    ? undefined
                    : () => <MenuItem>Sort By</MenuItem>
                }
              >
                <MenuItem value="bs">Best Seller</MenuItem>
                <MenuItem value="l">Latest</MenuItem>
                <MenuItem value="o">Oldest</MenuItem>
                <MenuItem value="az">A - Z</MenuItem>
                <MenuItem value="za">Z - A</MenuItem>
                <MenuItem value="priceLow">Price (Low - High)</MenuItem>
                <MenuItem value="priceHigh">Price (High - Low)</MenuItem>
              </SelectFilter>
            </FormControlSearch>
          </Grid>
        </Grid>
      )}
    </>
  )
}
