import gql from 'graphql-tag'

export const CalculateCost = data =>
  gql`
{
  calculateCost(
    courier: "${data.courier}",
    originType: "subdistrict",
    origin: ${data.origin},
    destinationType: "city",
    destination: ${data.city}, 
    weight: ${data.weight}
  ) {
    code
    costs {
      service
      cost {
        value
        note
        etd
      }
    }
  }
}
`

export const GET_COURIER_PRICING = gql`
  query($courier: String!, $origin: Int, $destination: Int!, $weight: Int!) {
    calculateCost(
      courier: $courier
      originType: "subdistrict"
      origin: $origin
      destinationType: "subdistrict"
      destination: $destination
      weight: $weight
    ) {
      code
      costs {
        service
        cost {
          value
          note
          etd
        }
      }
    }
  }
`
