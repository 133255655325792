import React from 'react'
import {
  TwoColumnTrasaction,
  WraperTrasanction,
  BiggestTextDarkBlue,
  TransactionContainer,
  FlexBetween,
  TextGrey,
  TablePaginationCus,
} from '../../components/utils-components/VendorStyles'
import {IconButton, Grid, MenuItem} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import useStyles from '../transaction/TransactionPageStyles'
import classNames from 'classnames'
import Search from '../../components/vendor-tabs/component-profile/Search'
import ListProduct from '../../components/vendor-tabs/card-product/ListProduct'
import ListVendor from '../../components/vendor-tabs/card-product/ListVendor'
import {
  TypeFilterContext,
  CategoryFilterContext,
} from '../../contexts/FilterContext'
import {getCookie, ROW_PAGENATION} from '../../utils/helpers'
import {USER_ID} from '../../utils/globals'
import {
  SelectFilter,
  FormControlSearch,
} from '../../components/Filter/FilterStyles'

function SeeAllPage(props) {
  const classes = useStyles()
  const [filter, setFilter] = React.useState({})

  const TypeFilterContextValue = React.useContext(TypeFilterContext)
  const CategoryFilterContextValue = React.useContext(CategoryFilterContext)
  const [rowsPerPage, setRowsPerPage] = React.useState(ROW_PAGENATION[0])
  const [page, setPage] = React.useState(0)
  const [count, setCount] = React.useState(0)

  const handleChangeFilter = (event) => {
    setFilter({...filter, [event.target.name]: event.target.value})
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  let relatedProduct = null
  if (
    props.match.params.text === 'Related Product' ||
    props.match.params.text === 'You might also like'
  ) {
    const dataCK = getCookie(`related-product${USER_ID}`)
    const cookie = dataCK !== '' ? JSON.parse(dataCK) : []
    relatedProduct = cookie
  }
  return (
    <TransactionContainer style={{paddingTop: '19px'}}>
      <TwoColumnTrasaction
        className={classNames(classes.widthShoppingOuterCard)}
      >
        <WraperTrasanction>
          <IconButton
            edge="end"
            onClick={props.history.goBack}
            aria-label="back"
            className={classes.paddingLeft0}
          >
            <ArrowBack />
          </IconButton>
          <BiggestTextDarkBlue
            style={{fontSize: '20px'}}
            className={classes.marginLeft20}
          >
            {props.match.params.text}
          </BiggestTextDarkBlue>
        </WraperTrasanction>
      </TwoColumnTrasaction>
      <Grid countainer>
        <Grid Item sm={12} xs={12}>
          <Search type="home" filter={filter} setFilter={setFilter} />
          <FlexBetween className={classes.marginTop30}>
            <TextGrey>
              {count} Results for {props.match.params.text}
            </TextGrey>
            {props.match.params.type === 'product' && (
              <FormControlSearch
                variant="outlined"
                className={classes.widthShopping}
              >
                <SelectFilter
                  variant="standard"
                  placeholder="Sort By"
                  disableUnderline
                  style={{backgroundColor: '#ffffff'}}
                  onChange={handleChangeFilter}
                  inputProps={{
                    name: 'orderBy',
                  }}
                  value={filter.orderBy}
                  displayEmpty
                  renderValue={
                    filter.orderBy !== undefined
                      ? undefined
                      : () => <MenuItem>Sort By</MenuItem>
                  }
                >
                  <MenuItem value="bs">Best Seller</MenuItem>
                  <MenuItem value="l">Latest</MenuItem>
                  <MenuItem value="o">Oldest</MenuItem>
                  <MenuItem value="az">A - Z</MenuItem>
                  <MenuItem value="za">Z - A</MenuItem>
                  <MenuItem value="priceLow">Price (Low - High)</MenuItem>
                  <MenuItem value="priceHigh">Price (High - Low)</MenuItem>
                </SelectFilter>
              </FormControlSearch>
            )}
          </FlexBetween>
          <div className={classes.marginTop15}>
            {props.match.params.type === 'product' && (
              <ListProduct
                className={classes.marginTop30}
                // searchText={filter.search}

                rowsPerPage={rowsPerPage}
                page={page}
                filter={filter}
                typeFilterContext={TypeFilterContextValue}
                categoryFilterContext={CategoryFilterContextValue}
                setCount={setCount}
                type={props.match.params.text}
                relatedProduct={relatedProduct}
              />
            )}
            {props.match.params.type === 'store' && (
              <ListVendor
                className={classes.marginTop30}
                filter={filter}
                rowsPerPage={rowsPerPage}
                page={page}
                typeFilterContext={TypeFilterContextValue}
                categoryFilterContext={CategoryFilterContextValue}
                setCount={setCount}
                type={'see-all'}
              />
            )}
          </div>
        </Grid>
        <TablePaginationCus
          rowsPerPageOptions={ROW_PAGENATION}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </TransactionContainer>
  )
}

export default SeeAllPage
