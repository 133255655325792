import React from 'react'
import {withRouter} from 'react-router-dom'

import {makeStyles, Card, Divider} from '@material-ui/core'

import Sidebar from '../../pages/shared-component/sidebar-menu/Sidebar'
import UserSummarySection from './UserSummarySection'
import UserWalletSection from './UserWalletSection'
import {
  UserNavigationSection,
  UserTransactionSection,
} from './UserNavigationSection'

const useStyles = makeStyles(theme => ({
  userCard: {
    marginBottom: theme.spacing(3),
  },
}))

function UserLoggedIn(props) {
  const styles = useStyles()

  const regexUrl = props.location.pathname.match(/[a-z0-9-]+/gi)

  const activeTab = regexUrl ? regexUrl[0] : '/'
  const typeTab = regexUrl ? regexUrl[1] : '/'

  const setTypeFilter = props.setTypeFilter
  const setCategoryFilter = props.setCategoryFilter

  return (
    <>
      <Card className={styles.userCard}>
        <UserSummarySection />
        <Divider />

        <UserWalletSection />
        <Divider />

        {activeTab !== 'transaction' && <UserNavigationSection />}
        {activeTab === 'transaction' && <UserTransactionSection />}
      </Card>

      {renderSidebar(activeTab, typeTab, setTypeFilter, setCategoryFilter)}
    </>
  )
}

export default withRouter(UserLoggedIn)

function renderSidebar(activeTab, typeTab, setTypeFilter, setCategoryFilter) {
  switch (activeTab) {
    case 'transaction':
      return null
    default:
      return (
        <Sidebar
          typeTab={typeTab}
          setTypeFilter={setTypeFilter}
          setCategoryFilter={setCategoryFilter}
        />
      )
  }
}
