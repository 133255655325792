import {Button, Checkbox, Dialog} from '@material-ui/core'
import React, {memo, useState} from 'react'
import {ModalWrapperDeleteCheck} from './ModalDeleteCheck.style'

export default memo(function ModalDeleteCheck({
  open,
  target,
  message,
  onClose,
  onDelete,
}) {
  const [checked, setChecked] = useState(false)

  const handleClose = () => {
    onClose()
    setChecked(false)
  }

  const handleDelete = () => {
    onDelete()
    setChecked(false)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <ModalWrapperDeleteCheck>
        <div className="header">Delete {target}?</div>
        <div className="body">
          <p>
            Are you sure to delete this{' '}
            <span style={{color: '#ef4d5e'}}>{target.toLowerCase()}</span>{' '}
            permanently?
          </p>
          <div className="check">
            <Checkbox
              // style={{backgroundColor: '#ef4d5e', borderColor: '#ef4d5e'}}
              checked={checked}
              onChange={() => setChecked(!checked)}
              className="check-action"
            />
            <p>{message}</p>
          </div>
        </div>
        <div className="action">
          <span className="cancel" onClick={handleClose}>
            Cancel
          </span>
          <Button
            disabled={!checked}
            onClick={handleDelete}
            type="danger"
            className="delete"
          >
            Delete
          </Button>
        </div>
      </ModalWrapperDeleteCheck>
    </Dialog>
  )
})
