import {makeStyles, TextField, Typography} from '@material-ui/core'
import styled from 'styled-components'

export const FormContainer = styled.div`
  padding: 1.6rem;
`

export const FormChildContainerFull = styled.div`
  margin-bottom: 1.2rem;
`

export const FormChildTitle = styled.div`
  display: flex;
  align-items: center;
`
export const TitleName = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`

export const TextFieldName = styled(TextField)`
  background: white;
  margin: 0 10px;
  & .muioutlinedinput-input : {
    padding: 8px 14px !important;
  }
  & label.mui-focused : {
    border-color: #039be5;
  }
`

const useStyles = makeStyles(() => ({
  rootDialog: {
    maxWidth: 750,
  },
  textTitle: {
    fontSize: 14,
    margin: 0,
    fontWeight: '600',
    color: '#014a62',
  },
  rootTite: {
    padding: '25px 20px',
    borderBottom: '1px solid #eff2f4',
  },
  rootContent: {
    padding: '22px 20px',
    fontSize: 12,
  },
  rootActions: {
    padding: '24px',
  },

  buttonFile: {
    margin: '0px 8.2px 0 0',
    width: '70px',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    width: '50%',
    height: '50%',
    color: '#039be5',
  },
  iconInfo: {
    marginLeft: 10,
    width: 18,
    height: 18,
  },
  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileUploadded: {
    backgroundColor: '#eff2f4',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    borderRadius: 5,
  },
  top: {
    display: 'flex',
    flexDirection: 'row ',
  },
  fileName: {
    backgroundColor: '#fff',
    padding: '10px 16px',
    width: '100%',
    margin: '0px 10px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    marginTop: 5,
    marginRight: '3vw',
  },
  size: {
    color: '#000',
    fontSize: 10,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: '#a9a8a8',
    borderRadius: '50%',
    margin: '0px 10px',
  },
  preview: {
    color: '#039be5',
    fontSize: 10,
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  textError: {
    color: '#f44336',
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    fontWeight: '400',
  },
  pdf: {
    color: '#ef4d5e',
  },
  xls: {
    color: '#2c5a1a',
  },
  ppt: {
    color: '#ef4d5e',
  },
  doc: {
    color: '#039be5',
  },
}))

export default useStyles
