import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {withRouter} from 'react-router-dom'

import {
  Button,
  Box,
  Dialog,
  Typography,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
} from '@material-ui/core'

import {ModalHeader, ModalContent} from '../../../components/modal/Modal'
import DialogAddress from '../../../components/vendor-tabs/component-profile/modal-profile/DialogAddress'

import usePrevious from '../../../hooks/usePrevious'
import {GET_ALL_ADDRESS} from '../../../graphql/queries/profile/getAllAddressPerson'

const EMPTY_OBJECT = {}

const INITIAL_EDIT_STATE = {
  open: false,
  data: EMPTY_OBJECT,
}

const ChooseAddressModal = props => {
  const {open, onClose, onAddressConfirm} = props

  const [editState, setEditState] = React.useState(INITIAL_EDIT_STATE)
  const [selected, setSelected] = React.useState(0)

  const prevOpen = usePrevious(open)
  const isNeverOpen = !prevOpen && prevOpen === open

  const {data, error, refetch} = useQuery(GET_ALL_ADDRESS, {
    skip: isNeverOpen,
    fetchPolicy: 'cache-and-network',
  })

  if (isNeverOpen) {
    return null
  }

  const handleEditToggle = () => {
    setEditState({...editState, open: !editState.open})
  }

  const handleChangeRadio = event => {
    setSelected(parseInt(event.target.value))
  }

  const handleAddressEdit = address => {
    setEditState({open: true, data: address})
  }

  const handleConfirm = () => {
    const row = data.people_shipping_addresses[selected]

    if (onAddressConfirm) {
      onAddressConfirm(row)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <ModalHeader onClose={onClose}>Address</ModalHeader>

      <ModalContent>
        <Box display="grid" gridGap={24}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>Choose Shipping Address</Typography>

            <Button
              onClick={() => handleAddressEdit(EMPTY_OBJECT)}
              variant="contained"
              color="primary"
            >
              Add New Address
            </Button>
          </Box>

          <RadioGroup value={selected} onChange={handleChangeRadio}>
            <Box display="grid" gridGap={16}>
              {error ? (
                <div>Something went wrong while retrieving addres data</div>
              ) : (
                !data && (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress />
                  </div>
                )
              )}

              {data?.people_shipping_addresses.map((row, index) => (
                <Box key={index} display="flex">
                  <div>
                    <FormControlLabel value={index} control={<Radio />} />
                  </div>
                  <Box flexGrow={1}>
                    <Typography style={{fontWeight: 600}}>
                      {row.label_name}
                    </Typography>
                    <Typography>
                      {row.recipient_name} ({row.phone})
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                      {`${row.address} ${row.post_code} ${row.district_name} ${row.city_name}, ${row.province_name}`}
                    </Typography>
                  </Box>

                  <div>
                    <Link
                      component="button"
                      onClick={() => handleAddressEdit(row)}
                      color="secondary"
                    >
                      Edit
                    </Link>
                  </div>
                </Box>
              ))}
            </Box>
          </RadioGroup>

          <Button
            disabled={!data}
            onClick={handleConfirm}
            variant="contained"
            color="secondary"
            size="large"
          >
            Confirm
          </Button>
        </Box>
      </ModalContent>

      <DialogAddress
        open={editState.open}
        handleTogleModal={handleEditToggle}
        title={editState.data !== EMPTY_OBJECT ? 'Edit Address' : 'Add Address'}
        dataEdit={editState.data}
        refetch={refetch}
        type="checkout"
      />
    </Dialog>
  )
}

export default withRouter(ChooseAddressModal)
