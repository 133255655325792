import React from 'react'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import HistoryIcon from '@material-ui/icons/History'

import FieldTooltip from '../utils-components/FieldTooltip'

import {convertToRupiah, getYearStartEndDate} from '../../utils/helpers'
import {USER_ID} from '../../utils/globals'
import {GET_USER_BALANCE} from '../../graphql/queries/getBalanceUser.query'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.spacing(1.75),
    color: theme.palette.grey[700],
    fontWeight: 700,
  },

  balance: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2.75),
    fontWeight: 700,
    textDecoration: 'none',
  },
}))

function UserWalletSection() {
  const styles = useStyles()

  const ranges = React.useMemo(() => getYearStartEndDate(), [])

  const {data, error} = useQuery(GET_USER_BALANCE, {
    variables: {
      userID: USER_ID,
      startDate: ranges.start,
      endDate: ranges.end,
    },
    fetchPolicy: 'cache-and-network',
  })

  const total = data?.total.aggregate.sum.value || 0
  const claimed = data?.claimed.aggregate.sum.value || 0

  const available = total - claimed

  return (
    <Box px={3} pt={2} pb={1.25} display="grid" gridGap={8}>
      <Typography className={styles.title}>
        Available Claim Limit
        <ClaimInformationTooltip />
      </Typography>

      {!data && !error ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={33} />
        </Box>
      ) : error ? (
        <div>{JSON.stringify(error)}</div>
      ) : (
        <div>
          <Typography className={styles.balance}>
            {convertToRupiah(available, true)}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            of {convertToRupiah(total, true)} total
          </Typography>
        </div>
      )}

      <Box display="flex">
        <Button
          component={Link}
          to="/profile/claim/request"
          color="secondary"
          startIcon={<AttachMoneyIcon />}
          style={{marginRight: 8, whiteSpace: 'nowrap'}}
        >
          Request Claim
        </Button>

        <Button
          component={Link}
          to="/profile/claim"
          color="secondary"
          startIcon={<HistoryIcon />}
        >
          History
        </Button>
      </Box>
    </Box>
  )
}

export default UserWalletSection

function ClaimInformationTooltip() {
  return (
    <FieldTooltip
      title="A Claim Limit is a budget provided by a company that can be used
      for shopping on the WorkLifeAndBeyond Marketplace and WorkLifeAndBeyond
      Learning. This Claim Limit cannot be used directly as a payment method,
      but you can submit a claim based on the transactions that have been
      made."
    />
  )
}
