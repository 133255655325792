import React from 'react'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  CircularProgress,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {VendorBioLabel, VendorTypeLabel} from '../header/HeaderStyles'
import {VerticalContainer} from '../general-container/FlexContainerStyles'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {GET_LIST_STORE_FRONT} from '../../graphql/queries/getListStoreFront.query'

const VendorDetailExpansionPanel = ({
  id,
  setSelectedSideBar,
  filter,
  setFilter,
  setTitle,
}) => {
  const {data, error, loading} = useQuery(GET_LIST_STORE_FRONT, {
    variables: {
      vendor: id,
      offset: 0,
      limit: 10,
    },
  })

  if (loading && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  return (
    <VerticalContainer>
      <ExpansionPanel style={{width: 290}}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <VendorBioLabel>Vendor Products</VendorBioLabel>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List>
            <ListItem>
              <VendorTypeLabel
                pointer
                onClick={() => {
                  setSelectedSideBar('products')
                  setTitle('All Products')
                  setFilter({...filter, showcase: null})
                }}
              >
                All Products
              </VendorTypeLabel>
            </ListItem>
            {data &&
              data.marketplace_vendor_showcase.map((row, index) => (
                <ListItem key={index}>
                  <VendorTypeLabel
                    pointer
                    onClick={() => {
                      setSelectedSideBar('products')
                      setTitle(row.name)
                      setFilter({...filter, showcase: row.id})
                    }}
                  >
                    {row.name}
                  </VendorTypeLabel>
                </ListItem>
              ))}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel style={{width: 290}}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <VendorBioLabel>Information</VendorBioLabel>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List>
            <ListItem>
              <VendorTypeLabel
                pointer
                onClick={() => setSelectedSideBar('description')}
              >
                Detail
              </VendorTypeLabel>
            </ListItem>
            <ListItem>
              <VendorTypeLabel
                pointer
                onClick={() => setSelectedSideBar('review')}
              >
                Review
              </VendorTypeLabel>
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </VerticalContainer>
  )
}

export default VendorDetailExpansionPanel
