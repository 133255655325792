import React from 'react'

import clsx from 'classnames'
import {
  withStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const headerStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    margin: 0,
    padding: theme.spacing(3),
    paddingRight: theme.spacing(3 + 3 + 1.5),
    position: 'relative',
  },

  white: {
    backgroundColor: theme.palette.common.white,
  },

  close: {
    margin: theme.spacing(-1.5),
    position: 'absolute',
    right: theme.spacing(3),
  },
})

function ModalHeader_(props) {
  const {children, classes, className, white, onClose, ...rest} = props

  const cn = clsx(white && classes.white, className)

  return (
    <DialogTitle disableTypography className={cn} classes={classes} {...rest}>
      {children}

      <IconButton
        title="Close dialog"
        onClick={onClose}
        className={classes.close}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  )
}

export const ModalHeader = withStyles(headerStyles)(ModalHeader_)

export const ModalContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  dividers: {
    borderBottom: 0,
  },
}))(DialogContent)

export const ModalActions = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  spacing: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}))(DialogActions)
