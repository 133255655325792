import gql from 'graphql-tag'

/// Claim Activity
export const GET_CLAIM_ACTIVITIES = gql`
  query (
    $userID: uuid!
    $status: [String!]
    $startDate: timestamp
    $endDate: timestamp
    $search: String
    $orderBy: order_by
    $offset: Int
    $limit: Int
  ) {
    total: people_work_claims_aggregate(
      where: {
        people_work_placement: {user: {_eq: $userID}}
        status: {_in: $status, _nin: ["pending"]}
        date_added: {_gte: $startDate, _lte: $endDate}
        invoice_id: {_is_null: false}
        formatted_id: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
    list: people_work_claims(
      where: {
        people_work_placement: {user: {_eq: $userID}}
        status: {_in: $status, _nin: ["pending"]}
        date_added: {_gte: $startDate, _lte: $endDate}
        invoice_id: {_is_null: false}
        formatted_id: {_ilike: $search}
      }
      order_by: {id: $orderBy}
      limit: $limit
      offset: $offset
    ) {
      id
      formatted_id
      date_added
      date_issued
      status
      nominal: value
      policy: finance_claim_policy_child {
        id
        name
      }
      invoice: global_invoice {
        id
        code
        module_name
        final_price
      }
      transaction: global_transaction {
        id
        type
        nominal: value
      }
      claimed: value
    }
  }
`

export const GET_CLAIM_ACTIVITY_DETAILS = gql`
  query ($requestID: bigint!) {
    details: people_work_claims_by_pk(id: $requestID) {
      id
      formatted_id
      status
      date_added
      description
      nominal: value
      attachments
      proofs
      claim_fields
      approved_by
      employee: people_work_placement {
        id
        regno
        user: global_user {
          id
          name
        }
        profile: company_job_profile {
          id
          title
        }
        company: company_profile {
          id
          name: legal_name
        }
      }
      invoice: global_invoice {
        id
        code
        module_name
        final_price
      }
      bank: people_profile_bank {
        id
        account_number
        account_name
        provider: global_bank {
          id
          name
        }
      }
    }
  }
`

export const GET_CLAIM_INVOICE_EXPORTS = gql`
  query ($invoiceId: uuid!) {
    invoice_exports: global_invoice_exports(
      where: {invoice_id: {_eq: $invoiceId}}
    ) {
      id
      invoice_id
      url
    }
  }
`

export const GET_CLAIM_USER_DETAILS = gql`
  query ($userId: uuid!, $positionId: Int!) {
    user: global_users_by_pk(id: $userId) {
      id
      name
    }
    position: company_job_profiles_by_pk(id: $positionId) {
      id
      title
    }
  }
`

/// Claim History
export const GET_CLAIM_HISTORY = gql`
  query (
    $userID: uuid!
    $startDate: timestamp
    $endDate: timestamp
    $search: String
    $orderBy: order_by
    $offset: Int
    $limit: Int
  ) {
    total: global_transactions_aggregate(
      where: {
        _or: [
          {
            transaction_item_table: {_eq: "system"}
            date_created: {_gte: $startDate, _lte: $endDate}
            formatted_id: {_ilike: $search}
          }
          {
            people_work_claim: {
              status: {_eq: "approved"}
              date_added: {_gte: $startDate, _lte: $endDate}
              formatted_id: {_ilike: $search}
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    list: global_transactions(
      where: {
        _or: [
          {
            transaction_item_table: {_eq: "system"}
            date_created: {_gte: $startDate, _lte: $endDate}
            formatted_id: {_ilike: $search}
          }
          {
            people_work_claim: {
              status: {_eq: "approved"}
              date_added: {_gte: $startDate, _lte: $endDate}
              formatted_id: {_ilike: $search}
            }
          }
        ]
      }
      order_by: {id: $orderBy}
      limit: $limit
      offset: $offset
    ) {
      id
      formatted_id
      date_created
      nominal: value
    }
  }
`

export const GET_CLAIM_HISTORY_DETAILS = gql`
  query ($userID: uuid!, $transactionID: Int!) {
    user: global_users_by_pk(id: $userID) {
      id
      name
      placements: people_work_placements(limit: 1, order_by: {id: desc}) {
        id
        regno
        profile: company_job_profile {
          id
          title
        }
      }
    }
    details: global_transactions_by_pk(id: $transactionID) {
      id
      formatted_id
      date_created
      nominal: value
      description
    }
  }
`

/// Request Claim
export const GET_UNCLAIMED_TRANSACTIONS = gql`
  query ($userID: uuid!, $search: String, $offset: Int, $limit: Int) {
    total: global_invoices_aggregate(
      where: {
        vendor_id: {_is_null: false}
        global_wallets: {global_users: {id: {_eq: $userID}}}
        status: {_eq: "completed"}
        payment_method: {_neq: "wallet"}
        code: {_ilike: $search}
        final_price: {_gt: 0}
        _or: [
          {_not: {people_work_claim: {}}}
          {people_work_claim: {status: {_in: ["rejected", "cancelled"]}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    list: global_invoices(
      where: {
        vendor_id: {_is_null: false}
        global_wallets: {global_users: {id: {_eq: $userID}}}
        status: {_eq: "completed"}
        payment_method: {_neq: "wallet"}
        code: {_ilike: $search}
        final_price: {_gt: 0}
        _or: [
          {_not: {people_work_claim: {}}}
          {people_work_claim: {status: {_in: ["rejected", "cancelled"]}}}
        ]
      }
      order_by: {id: desc}
      limit: $limit
      offset: $offset
    ) {
      id
      code
      date_added
      final_price
      date_added
    }
  }
`

export const GET_UNCLAIMED_INVOICE = gql`
  query ($invoiceId: uuid!) {
    invoice: global_invoices_by_pk(id: $invoiceId) {
      id
      code
      status
      global_wallet_vendor {
        id
        company_profiles {
          id
          legal_name
          brand_name
          logo
        }
        global_users {
          id
        }
      }
      final_price
      delivery_option
      delivery_data
      global_shipping_provider {
        id
        name
        logo
      }
      products: global_invoice_items(where: {type: {_neq: "courier"}}) {
        item_table
        item_table_id
        id
        type
        itemname
        single_price
        thumbnail
        weight
        qty
        note
      }
      courier: global_invoice_items(where: {type: {_eq: "courier"}}) {
        id
        type
        itemname
        single_price
        thumbnail
        weight
        qty
      }
      global_payment {
        id
        code
        date_created
        final_price
        payment_method
        payment_data
        payment_proof
        payment_status
      }
    }
  }
`

export const GET_CLAIM_REQUEST_INFO = gql`
  query ($userID: uuid!) {
    placements: people_work_placements(
      where: {user: {_eq: $userID}}
      distinct_on: [user]
      order_by: [{user: asc}, {id: desc}]
    ) {
      id
      accounts: people_work_salaries(
        where: {
          active: {_eq: true}
          deletedAt: {_is_null: true}
          people_profile_bank: {deletedAt: {_is_null: true}}
        }
        order_by: [{id: desc}]
        limit: 1
      ) {
        id: primary_bank
      }
    }
  }
`
