import React from 'react' // style
import {
  TextGrey,
  TextBlue,
  FlexRow,
  TwoColumnTrasaction,
  WraperTrasanction2,
  BiggestTextDarkBlue,
  ShoppingContainer,
} from '../../../components/utils-components/VendorStyles'

// material
import {Grid, IconButton} from '@material-ui/core'

// Component
import Search from '../../../components/vendor-tabs/component-profile/Search'
import ListProduct from '../../../components/vendor-tabs/card-product/ListProduct'
import ListVendor from '../../../components/vendor-tabs/card-product/ListVendor'
import CarouselProduct from '../../../components/vendor-tabs/card-product/CarouselProduct'
import CarouselVendor from '../../../components/vendor-tabs/card-product/CarouselVendor'
import {
  TypeFilterContext,
  CategoryFilterContext,
} from '../../../contexts/FilterContext'
import {Link, withRouter} from 'react-router-dom'
import useStyles from '../../transaction/TransactionPageStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import classNames from 'classnames'

const List = props => {
  const {type} = props
  const classes = useStyles()
  const [filter, setFilter] = React.useState({})
  const TypeFilterContextValue = React.useContext(TypeFilterContext)
  const CategoryFilterContextValue = React.useContext(CategoryFilterContext)

  let titleVendor = ''
  if (type === 'wishlist') {
    titleVendor = 'My Wishlist'
  } else if (type === 'followed-vendor') {
    titleVendor = 'Followed Vendor'
  }

  return (
    <ShoppingContainer>
      <TwoColumnTrasaction
        className={classNames(classes.widthShoppingOuterCard)}
      >
        <WraperTrasanction2>
          <IconButton
            edge="end"
            onClick={props.history.goBack}
            aria-label="back"
            // className={classes.paddingLeft0}
          >
            <ArrowBack />
          </IconButton>
          <BiggestTextDarkBlue className={classes.marginLeft20}>
            {titleVendor}
          </BiggestTextDarkBlue>
          {/* <TitleVendor className={classes.marginLeft20}>{}</TitleVendor> */}
        </WraperTrasanction2>
      </TwoColumnTrasaction>
      <Search
        filter={filter}
        setFilter={setFilter}
        orderShow={type === 'wishlist' ? 'product' : 'vendor'}
      />
      <Grid container spacing={2}>
        <Grid item sm={12}>
          {type === 'wishlist' && (
            <ListProduct filter={filter} type={'Wishlist'} view={'full'} />
          )}
          {type === 'followed-vendor' && (
            <ListVendor
              filter={filter}
              typeFilterContext={TypeFilterContextValue}
              categoryFilterContext={CategoryFilterContextValue}
              view={'full'}
              type={'followed'}
              // setCount={setCount}
              // type={regexUrl[2]}
            />
          )}
        </Grid>
        <Grid item sm={12} style={{paddingTop: '10px'}}>
          <FlexRow>
            <TextGrey>
              Recommendation {type === 'wishlist' ? 'Product' : 'Vendor'}
            </TextGrey>
            <TextBlue
              component={Link}
              to={
                type === 'wishlist'
                  ? '/see-all/product/Top Product'
                  : '/see-all/store/Vendor'
              }
              style={{paddingLeft: '33px'}}
            >
              {' '}
              See all
            </TextBlue>
          </FlexRow>
        </Grid>
        <Grid item sm={12}>
          {type === 'wishlist' && (
            <CarouselProduct
              searchText={filter.search}
              typeFilterContext={TypeFilterContextValue}
              categoryFilterContext={CategoryFilterContextValue}
              type="top"
              view={'full'}
            />
          )}
          {type === 'followed-vendor' && (
            <CarouselVendor
              filter={filter}
              typeFilterContext={TypeFilterContextValue}
              categoryFilterContext={CategoryFilterContextValue}
              view={'full'}
            />
          )}
        </Grid>
      </Grid>
    </ShoppingContainer>
  )
}

export default withRouter(List)
